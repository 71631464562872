import React from "react";
import PropTypes from "prop-types";
import uuidv1 from "uuid/v1";
import Table from "react-bootstrap/Table";
// import { ExhibitionEntryPropType } from "../../../customPropTypes";
import Content from "../../../content/AdminTextContent";
import CroatianDateFormat from "../../common/CroatianDateFormat";
import RejectButton from "../../common/RejectButton";
import ApproveButton from "../exhibition/ApproveButton";

function AdminExhibitionSubjectsList({
    data, showApproveButton, showRejectButton, onApproveClicked, onRejectClicked,
}) {
    const { approvedSubjects } = Content.exhibitionSubjects;

    return (
        <div>
            <Table striped bordered>
                <thead>
                    <tr>
                        <th>{approvedSubjects.orderTitle}</th>
                        <th>{approvedSubjects.dateTitle}</th>
                        <th>{approvedSubjects.usernameTitle}</th>
                        <th>{approvedSubjects.entryTitleTitle}</th>
                        <th>{approvedSubjects.descriptionTitle}</th>
                        <th>{approvedSubjects.actionTitle}</th>
                        <th>{approvedSubjects.votesTitle}</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((subject, index) => (
                        <tr key={uuidv1()}>
                            <td>{index + 1}</td>
                            <td><CroatianDateFormat date={subject.created} /></td>
                            <td>{subject.userName}</td>
                            <td>{subject.title}</td>
                            <td>{subject.description}</td>
                            <td>
                                {showApproveButton ? (
                                    <ApproveButton
                                        onApproveCallbabck={() => onApproveClicked(subject)}
                                        entryTitle={subject.title}
                                    />
                                ) : null}
                                {showRejectButton ? (
                                    <RejectButton
                                        modalText="Jeste li sigurni da želite promijeniti status na &quot;ODBIJEN&quot;?"
                                        buttonText="Odbij"
                                        executeButtonLabel="Promijeni"
                                        onRejectCallback={() => onRejectClicked(subject)}
                                        modalTitle={subject.title}
                                    />
                                ) : null}
                            </td>
                            <td>{subject.votes}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
}

export default AdminExhibitionSubjectsList;

AdminExhibitionSubjectsList.propTypes = {
    // data: PropTypes.arrayOf(ExhibitionEntryPropType).isRequired,
    showApproveButton: PropTypes.bool,
    showRejectButton: PropTypes.bool,
    onApproveClicked: PropTypes.func,
    onRejectClicked: PropTypes.func,
};

AdminExhibitionSubjectsList.defaultProps = {
    showApproveButton: false,
    showRejectButton: false,
    onApproveClicked: () => {},
    onRejectClicked: () => {},
};
