/* eslint max-len: 0 */
import React from "react";
import PropTypes from "prop-types";

const ORIGINAL_WIDTH = 578.271;
const ORIGINAL_HEIGHT = 291.352;

function SVG({ scale }) {
    const width = ORIGINAL_WIDTH * scale;
    const height = ORIGINAL_HEIGHT * scale;
    const widthString = `${width}px`;
    const heightString = `${height}px`;

    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width={widthString}
            height={heightString}
            viewBox={`0 0 ${ORIGINAL_WIDTH} ${ORIGINAL_HEIGHT}`}
            enableBackground={`new 0 0 ${ORIGINAL_WIDTH} ${ORIGINAL_HEIGHT}`}
        >
            <switch>
                <g>
                    <polygon fill="#7AB852" points="385.824,0 385.824,57.285 0,57.285 0,231.918 385.824,231.918 385.824,291.352 578.271,146.751" />
                </g>
            </switch>
        </svg>
    );
}

export default SVG;

SVG.propTypes = {
    scale: PropTypes.number,
};

SVG.defaultProps = {
    scale: 1.3,
};
