import React from "react";
import PropTypes from "prop-types";
import SVGShareButton from "../../../media/svgs/map/share-experience-button";
import SVGShareButtonHover from "../../../media/svgs/map/share-experience-button-hover";

function ShareExperienceButton({ onClickCallback }) {
    const [isButtonHovering, setIsButtonHovering] = React.useState(false);
    return (
        <button
            type="button"
            href="#"
            onClick={() => {
                onClickCallback();
                setIsButtonHovering(false);
            }}
            className="no-style-button custom-map-share-button"
            onMouseEnter={() => setIsButtonHovering(true)}
            onMouseLeave={() => setIsButtonHovering(false)}
            style={{ marginBottom: "55px" }}
        >
            {isButtonHovering ? <SVGShareButtonHover /> : <SVGShareButton />}
        </button>
    );
}

export default ShareExperienceButton;

ShareExperienceButton.propTypes = {
    onClickCallback: PropTypes.func.isRequired,
};
