const exhibitionSubjectVoteActions = {
    vote: (id, votes) => (dispatch) => {
        dispatch({
            type: "SUBJECT_UPVOTE",
            payload: { id, votes },
        });
    },
    unVote: (id, votes) => (dispatch) => {
        dispatch({
            type: "SUBJECT_DOWNVOTE",
            payload: { id, votes },
        });
    },
    getVotes: (subjectVotes) => (dispatch) => {
        dispatch({
            type: "GET_SUBJECT_VOTES",
            payload: { ...subjectVotes },
        });
    },
};

export default exhibitionSubjectVoteActions;
