import moment from "moment";
import { setToken, setExpirationDate } from "./auth";
import {
    setExhibitionEntryVote, canExhibitionEntryVote, resetExhibitionEntryVote,
    setSurveyIdVote, canSurveyIdVote,
    canExhibitionSubjectVote, setExhibitionSubjectVote, resetExhibitionSubjectVote,
} from "./voting";
import {
    storeHideBackgrounds, storeShowBackgrounds, getExtraSettingsBackgroundsData, storeIncreaseFontSize,
    getExtraSettingsFontsizeData, storeResetFontSize, acceptCookiesUsage, getExtraSettingsCookies,
} from "./extraSettings";
import {
    BASIC_HEADERS, AUTH_HEADERS, ENDPOINTS, EXHIBITION_ENTRY_STATUS, EXHIBITION_SUBJECT_STATUS,
} from "../helpers/constants";
import exhibitionActions from "../action-creators/admin/exhibitionActions";
import exhibitionEntryVoteActions from "../action-creators/exhibitionEntryVoteActions";
import exhibitionSubjectVoteActions from "../action-creators/exhibitionSubjectVoteActions";
import surveyActions from "../action-creators/admin/surveyActions";
import votingActions from "../action-creators/votingActions";
import extraSettingsActions from "../action-creators/extraSettingsActions";
import mapPinsActions from "../action-creators/mapPinsActions";
import exhibitionSubjectActions from "../action-creators/exhibitionSubjectActions";
import archiveActions from "../action-creators/archiveActions";

const checkStatus = (res) => {
    if (!res.ok) {
        // throw new Error(res.statusText);
        throw res;
    }
    return res;
};

export const extraSettings = {
    hideBackgrounds: () => (dispatch) => {
        storeHideBackgrounds();
        dispatch(extraSettingsActions.hideBackgrounds());
    },
    showBackgrounds: () => (dispatch) => {
        storeShowBackgrounds();
        dispatch(extraSettingsActions.showBackgrounds());
    },
    getBackgroundsData: () => (dispatch) => {
        const hideBackgrounds = getExtraSettingsBackgroundsData();
        if (hideBackgrounds === "yes") dispatch(extraSettingsActions.hideBackgrounds());
        if (hideBackgrounds === "no") dispatch(extraSettingsActions.showBackgrounds());
    },
    increaseFontSize: () => (dispatch) => {
        storeIncreaseFontSize();
        dispatch(extraSettingsActions.increaseFontSize());
    },
    getFontSizeData: () => (dispatch) => {
        const increaseFontSize = getExtraSettingsFontsizeData();
        if (increaseFontSize === "yes") dispatch(extraSettingsActions.increaseFontSize());
    },
    resetFontSize: () => (dispatch) => {
        storeResetFontSize();
        dispatch(extraSettingsActions.resetFontSize());
    },
    acceptCookies: () => (dispatch) => {
        acceptCookiesUsage();
        dispatch(extraSettingsActions.acceptCookies());
    },
    getCookiesData: () => (dispatch) => {
        const cookiesAccepted = getExtraSettingsCookies();
        if (cookiesAccepted === "yes") dispatch(extraSettingsActions.acceptCookies());
    },
};

export const getExhibitionEntriesVotingData = (listOfEntryIds) => (dispatch) => {
    let list = {};
    if (listOfEntryIds && listOfEntryIds.length > 0) {
        list = listOfEntryIds.reduce((accum, currentId) => {
            return { ...accum, [currentId]: canExhibitionEntryVote(currentId) };
        }, {});
        dispatch(votingActions.fetchExhibitionEntryVotes(list));
    }
};

export const getExhibitionSubjectsVotingData = (listOfSubjectIds) => (dispatch) => {
    let list = {};
    if (listOfSubjectIds && listOfSubjectIds.length > 0) {
        list = listOfSubjectIds.reduce((accum, currentId) => {
            return { ...accum, [currentId]: canExhibitionSubjectVote(currentId) };
        }, {});
        dispatch(votingActions.fetchExhibitionSubjectVotes(list));
    }
};

const getSurveyVotingData = (listOfSurveyIds) => (dispatch) => {
    let list = {};
    if (listOfSurveyIds && listOfSurveyIds.length > 0) {
        list = listOfSurveyIds.reduce((accum, currentId) => {
            return { ...accum, [currentId]: canSurveyIdVote(currentId) };
        }, {});
        dispatch(votingActions.fetchSurveyVotes(list));
    }
};

export const survey = {
    add: (values, history) => (dispatch) => {
        const valuesEdited = JSON.stringify({
            ...values,
            startDate: moment(values.startDate).format(),
            endDate: moment(values.endDate).format(),
            status: 0,
        });
        fetch(ENDPOINTS.surveys.one, {
            method: "POST",
            headers: AUTH_HEADERS,
            body: valuesEdited,
        }).then(checkStatus)
            .then((res) => res.json())
            .then((id) => {
                fetch(`${ENDPOINTS.surveys.one}${id}`, {
                    method: "GET",
                    headers: AUTH_HEADERS,
                }).then(checkStatus)
                    .then((res) => res.json())
                    .then((r) => {
                        dispatch(surveyActions.addNew(r));
                        history.push("/admin/ankete");
                    });
            })
            .catch((error) => {
                return Promise.reject();
            });
    },
    changeStatus: (values, status) => (dispatch) => {
        const updatedSurvey = { ...values, status };
        fetch(ENDPOINTS.surveys.one, {
            method: "PUT",
            headers: AUTH_HEADERS,
            body: JSON.stringify(updatedSurvey),
        }).then(checkStatus)
            .then((res) => res.json)
            .then(() => {
                dispatch(surveyActions.updateSurvey(updatedSurvey));
            })
            .catch((error) => {
                Promise.reject(error);
            });
    },
    update: (values, history, surveyId, surveyStatus) => (dispatch) => {
        const valuesEdited = {
            ...values,
            id: surveyId,
            status: surveyStatus,
            startDate: moment(values.startDate).format(),
            endDate: moment(values.endDate).format(),
        };
        fetch(ENDPOINTS.surveys.one, {
            method: "PUT",
            headers: AUTH_HEADERS,
            body: JSON.stringify(valuesEdited),
        }).then(checkStatus)
            .then((res) => res.json())
            .then((id) => {
                fetch(`${ENDPOINTS.surveys.one}${id}`, {
                    method: "GET",
                    headers: AUTH_HEADERS,
                }).then(checkStatus)
                    .then((res) => res.json())
                    .then(() => {
                        dispatch(surveyActions.updateSurvey(valuesEdited));
                        history.push("/admin/ankete");
                    });
            })
            .catch((err) => {
                err.json().then((errorMessage) => {
                    console.error(errorMessage);
                });
            });
    },
    delete: (surveyId) => (dispatch) => {
        fetch(`${ENDPOINTS.surveys.one}${surveyId}`, {
            method: "DELETE",
            headers: AUTH_HEADERS,
        }).then(checkStatus)
            .then(() => {})
            .then(() => {
                dispatch(surveyActions.delete(surveyId));
            });
    },
    getAll: () => (dispatch) => {
        fetch(ENDPOINTS.surveys.all, {
            method: "GET",
            headers: BASIC_HEADERS,
        }).then(checkStatus)
            .then((res) => res.json())
            .then((r) => {
                dispatch(surveyActions.fetchSurveysSuccess(r));
                const listOfIds = r.reduce((accum, current) => [...accum, current.id], []);
                dispatch(getSurveyVotingData(listOfIds));
            });
    },
    vote: (surveyId, votesData, setStatus) => {
        fetch(`${ENDPOINTS.surveys.one}Vote`, {
            method: "PUT",
            headers: BASIC_HEADERS,
            body: JSON.stringify({
                id: surveyId,
                votes: [...votesData],
            }),
        }).then(checkStatus)
            // .then((res) => res.json())
            .then((r) => {
                setStatus({ type: "success", message: "Hvala na glasanju!" });
                setSurveyIdVote(surveyId);
            })
            .catch((error) => {
                console.error(error);
                Promise.reject(error);
            });
    },
};

export const map = {
    getAll: () => (dispatch) => {
        fetch(`${ENDPOINTS.map}PINS`, {
            method: "GET",
            headers: BASIC_HEADERS,
        }).then(checkStatus)
            .then((res) => res.json())
            .then((r) => {
                dispatch(mapPinsActions.fetchMapPinsSuccess(r));
                dispatch(mapPinsActions.assignRandomPinArrows());
            });
    },
    upload: (acceptedFile, successCallback, setStatus, failureCallback) => {
        const formData = new FormData();
        formData.append("file", acceptedFile, acceptedFile.name);

        fetch(`${ENDPOINTS.map}Upload/Pin/Content/`, {
            method: "POST",
            body: formData,
        }).then(checkStatus)
            .then((res) => res.json())
            .then((r) => {
                setStatus({ type: "upload_success" });
                successCallback(r);
            })
            .catch((err) => {
                err.json().then((errorMessage) => {
                    setStatus({ type: "error", message: `Server Error: ${errorMessage}` });
                    failureCallback();
                });
            });
    },
    deleteContent: (fileName) => {
        fetch(`${ENDPOINTS.map}Pin/Content/${fileName}/`, {
            method: "DELETE",
            headers: BASIC_HEADERS,
        }).then(checkStatus)
            .then(() => {
            })
            .catch((error) => {
                console.error(error);
                return Promise.reject();
            });
    },
    addNew: (values, setStatus) => (dispatch) => {
        fetch(`${ENDPOINTS.map}Pin`, {
            method: "POST",
            headers: BASIC_HEADERS,
            body: JSON.stringify({
                ...values,
                id: 0,
            }),
        }).then(checkStatus)
            .then((res) => res.json())
            .then((r) => {
                setStatus({ type: "success", message: "Uspješno poslano!" });
                // dispatch(mapPinsActions.addPin(r));
            })
            .catch((error) => {
                setStatus({ type: "error", message: error.message });
                return Promise.reject();
            });
    },
    deletePinById: (pinId) => (dispatch) => {
        fetch(`${ENDPOINTS.map}Pin/${pinId}`, {
            method: "DELETE",
            headers: AUTH_HEADERS,
        }).then(checkStatus)
            .then(() => {
                dispatch(mapPinsActions.deletePinById(pinId));
            })
            .catch((error) => {
                return Promise.reject();
            });
    },
};

export const exhibitionEntry = {
    getAll: () => (dispatch, getState) => {
        fetch(ENDPOINTS.exhibtionEntries.all, {
            method: "GET",
            headers: BASIC_HEADERS,
        }).then(checkStatus)
            .then((res) => res.json())
            .then((r) => {
                dispatch(exhibitionActions.fetchEntriesSuccess(r));
                const entryVotes = getState().exhibitionEntries.approved.reduce((accum, current) => {
                    return { ...accum, [current.id]: current.votes };
                }, {});
                dispatch(exhibitionEntryVoteActions.getVotes(entryVotes));
            });
    },
    getAllApproved: () => (dispatch, getState) => {
        fetch(`${ENDPOINTS.exhibtionEntries.all}Approved`, {
            method: "GET",
            headers: BASIC_HEADERS,
        }).then(checkStatus)
            .then((res) => res.json())
            .then((r) => {
                dispatch(exhibitionActions.fetchApprovedEntriesSuccess(r));
                const entryVotes = getState().exhibitionEntries.approved.reduce((accum, current) => {
                    return { ...accum, [current.id]: current.votes };
                }, {});
                dispatch(exhibitionEntryVoteActions.getVotes(entryVotes));
            });
    },
    // getEntriesByPageId: (pageId) => (dispatch) => {
    //     fetch(`${ENDPOINTS.exhibtionEntries.all}page/${pageId}`, {
    //         method: "GET",
    //         headers: BASIC_HEADERS,
    //     }).then(checkStatus)
    //         .then((res) => res.json())
    //         .then((r) => {
    //             dispatch(archiveActions.fetchArchive(r));
    //             // dispatch();
    //             // dispatch(exhibitionActions.fetchApprovedEntriesSuccess(r));
    //             // const entryVotes = getState().exhibitionEntries.approved.reduce((accum, current) => {
    //             //     return { ...accum, [current.id]: current.votes };
    //             // }, {});
    //             // dispatch(exhibitionEntryVoteActions.getVotes(entryVotes));
    //         });
    // },
    approve: (entry, action) => (dispatch) => {
        fetch(ENDPOINTS.exhibtionEntries.one, {
            method: "PUT",
            headers: AUTH_HEADERS,
            body: JSON.stringify({
                ...entry,
                status: EXHIBITION_ENTRY_STATUS.APPROVED,
            }),
        }).then(checkStatus)
            .then((res) => res.json())
            .then(() => {
                dispatch(action(entry.id));
            });
    },
    reject: (entry, action) => (dispatch) => {
        fetch(ENDPOINTS.exhibtionEntries.one, {
            method: "PUT",
            headers: AUTH_HEADERS,
            body: JSON.stringify({
                ...entry,
                status: EXHIBITION_ENTRY_STATUS.REJECTED,
            }),
        }).then(checkStatus)
            .then((res) => res.json())
            .then(() => {
                dispatch(action(entry.id));
            });
    },
    vote: (id) => (dispatch, getState) => {
        const currentVotes = getState().exhibitionEntryVotes[id];

        fetch(`${ENDPOINTS.exhibtionEntries.one}Vote/${id}`, {
            method: "PUT",
            headers: BASIC_HEADERS,
        }).then(checkStatus)
            .then(() => {
                setExhibitionEntryVote(id);
                dispatch(exhibitionEntryVoteActions.vote(id, currentVotes + 1));
                dispatch(votingActions.setExhibitionEntries(id, "no"));
            });
    },
    unvote: (id) => (dispatch, getState) => {
        const currentVotes = getState().exhibitionEntryVotes[id];

        fetch(`${ENDPOINTS.exhibtionEntries.one}Unvote/${id}`, {
            method: "PUT",
            headers: BASIC_HEADERS,
        }).then(checkStatus)
            .then(() => {
                resetExhibitionEntryVote(id);
                dispatch(exhibitionEntryVoteActions.unVote(id, currentVotes - 1));
                dispatch(votingActions.setExhibitionEntries(id, "yes"));
            });
    },
    addNew: (values, setStatus) => (dispatch, getState) => {
        fetch(ENDPOINTS.exhibtionEntries.one, {
            method: "POST",
            headers: BASIC_HEADERS,
            body: JSON.stringify({
                ...values,
                exhibitionYear: 2020, // TODO dinamicki odluciti koja godina
                status: 1,
                pageId: getState().exhibitionEntries.pages.current.id,
            }),
        }).then(checkStatus)
            .then((res) => res.json())
            .then(() => {
                setStatus({ type: "success", message: "Uspješno poslano!" });
            })
            .catch((error) => {
                setStatus({ type: "error", message: error.message });
                return Promise.reject();
            });
    },
    upload: (acceptedFile, successCallback, setStatus, failureCallback) => {
        const formData = new FormData();
        formData.append("file", acceptedFile, acceptedFile.name);

        fetch(`${ENDPOINTS.exhibtionEntries.one}Upload/Content/`, {
            method: "POST",
            body: formData,
        }).then(checkStatus)
            .then((res) => res.json())
            .then((r) => {
                setStatus({ type: "upload_success" });
                successCallback(r);
            })
            .catch((err) => {
                err.json().then((errorMessage) => {
                    setStatus({ type: "error", message: `Server Error: ${errorMessage}` });
                    failureCallback();
                });
            });
    },
    deleteContent: (fileName) => {
        fetch(`${ENDPOINTS.exhibtionEntries.one}Content/${fileName}/`, {
            method: "DELETE",
            headers: BASIC_HEADERS,
        }).then(checkStatus)
            .then(() => {
            })
            .catch((error) => {
                console.error(error);
                return Promise.reject();
            });
    },
    getPages: () => (dispatch) => {
        fetch(`${ENDPOINTS.exhibtionEntries.one}Pages`, {
            method: "GET",
            headers: BASIC_HEADERS,
        }).then(checkStatus)
            .then((res) => res.json())
            .then((r) => {
                dispatch(exhibitionActions.fetchPagesSuccess(r));
            });
    },
};

export const exhibitionSubject = {
    getAll: () => (dispatch, getState) => {
        fetch(ENDPOINTS.exhibitionSubjects.all, {
            method: "GET",
            headers: BASIC_HEADERS,
        }).then(checkStatus)
            .then((res) => res.json())
            .then((r) => {
                dispatch(exhibitionSubjectActions.fetchSubjectsSuccess(r));
                const subjectVotes = getState().exhibitionSubjects.approved.reduce((accum, current) => {
                    return { ...accum, [current.id]: current.votes };
                }, {});
                dispatch(exhibitionSubjectVoteActions.getVotes(subjectVotes));
            });
    },
    getAllApproved: () => (dispatch, getState) => {
        fetch(`${ENDPOINTS.exhibitionSubjects.all}Approved`, {
            method: "GET",
            headers: BASIC_HEADERS,
        }).then(checkStatus)
            .then((res) => res.json())
            .then((r) => {
                dispatch(exhibitionSubjectActions.fetchApprovedSubjectsSuccess(r));
                const subjectVotes = getState().exhibitionSubjects.approved.reduce((accum, current) => {
                    return { ...accum, [current.id]: current.votes };
                }, {});
                dispatch(exhibitionSubjectVoteActions.getVotes(subjectVotes));
            });
    },
    approve: (subject, action) => (dispatch) => {
        fetch(ENDPOINTS.exhibitionSubjects.one, {
            method: "PUT",
            headers: AUTH_HEADERS,
            body: JSON.stringify({
                ...subject,
                status: EXHIBITION_SUBJECT_STATUS.APPROVED,
            }),
        }).then(checkStatus)
            .then((res) => res.json())
            .then(() => {
                dispatch(action(subject.id));
            });
    },
    reject: (subject, action) => (dispatch) => {
        fetch(ENDPOINTS.exhibitionSubjects.one, {
            method: "PUT",
            headers: AUTH_HEADERS,
            body: JSON.stringify({
                ...subject,
                status: EXHIBITION_SUBJECT_STATUS.REJECTED,
            }),
        }).then(checkStatus)
            .then((res) => res.json())
            .then(() => {
                dispatch(action(subject.id));
            });
    },
    vote: (id) => (dispatch, getState) => {
        const currentVotes = getState().exhibitionSubjectVotes[id];

        fetch(`${ENDPOINTS.exhibitionSubjects.one}Vote/${id}`, {
            method: "PUT",
            headers: BASIC_HEADERS,
        }).then(checkStatus)
            .then(() => {
                setExhibitionSubjectVote(id);
                dispatch(exhibitionSubjectVoteActions.vote(id, currentVotes + 1));
                dispatch(votingActions.setExhibitionSubjects(id, "no"));
            });
    },
    unvote: (id) => (dispatch, getState) => {
        const currentVotes = getState().exhibitionSubjectVotes[id];

        fetch(`${ENDPOINTS.exhibitionSubjects.one}Unvote/${id}`, {
            method: "PUT",
            headers: BASIC_HEADERS,
        }).then(checkStatus)
            .then(() => {
                resetExhibitionSubjectVote(id);
                dispatch(exhibitionSubjectVoteActions.unVote(id, currentVotes - 1));
                dispatch(votingActions.setExhibitionSubjects(id, "yes"));
            });
    },
    addNew: (values, setStatus) => (dispatch, getState) => {
        fetch(ENDPOINTS.exhibitionSubjects.one, {
            method: "POST",
            headers: BASIC_HEADERS,
            body: JSON.stringify({
                ...values,
                exhibitionYear: 2020,
                status: 1,
                pageId: getState().exhibitionSubjects.pages.current.id,
            }),
        }).then(checkStatus)
            .then((res) => res.json())
            .then(() => {
                setStatus({ type: "success", message: "Uspješno poslano!" });
            })
            .catch((error) => {
                setStatus({ type: "error", message: error.message });
                return Promise.reject();
            });
    },
    getPages: () => (dispatch) => {
        fetch(`${ENDPOINTS.exhibitionSubjects.one}Pages`, {
            method: "GET",
            headers: BASIC_HEADERS,
        }).then(checkStatus)
            .then((res) => res.json())
            .then((r) => {
                dispatch(exhibitionSubjectActions.fetchPagesSuccess(r));
            });
    },
};

export const pages = {
    addNew: (values, setStatus) => (dispatch) => {
        fetch(ENDPOINTS.pages, {
            method: "POST",
            headers: AUTH_HEADERS,
            body: JSON.stringify({
                ...values,
            }),
        }).then(checkStatus)
            .then((res) => res.json())
            .then(() => {
                setStatus({ type: "success", message: "Uspješno kreirano!" });
                dispatch(exhibitionEntry.getPages());
            })
            .catch((error) => {
                setStatus({ type: "error", message: error.message });
                return Promise.reject();
            });
    },
    updateEntries: (values, pageId, setStatus) => (dispatch) => {
        const valuesEdited = {
            ...values,
            id: pageId,
            startDate: moment(values.activeFrom).format(),
            endDate: moment(values.activeTo).format(),
        };

        fetch(ENDPOINTS.pages, {
            method: "PUT",
            headers: AUTH_HEADERS,
            body: JSON.stringify(valuesEdited),
        }).then(checkStatus)
            .then((res) => res.json())
            .then((id) => {
                fetch(`${ENDPOINTS.pages}${id}`, {
                    method: "GET",
                    headers: AUTH_HEADERS,
                }).then(checkStatus)
                    .then((res) => res.json())
                    .then((r) => {
                        dispatch(exhibitionActions.updatePage(r));
                        setStatus({ type: "success", message: "Uspješno editirano!" });
                    });
            })
            .catch((err) => {
                err.json().then((errorMessage) => {
                    setStatus({ type: "error", message: errorMessage });
                });
            });
    },
    updateSubjects: (values, pageId, setStatus) => (dispatch) => {
        const valuesEdited = {
            ...values,
            id: pageId,
            startDate: moment(values.activeFrom).format(),
            endDate: moment(values.activeTo).format(),
        };

        fetch(ENDPOINTS.pages, {
            method: "PUT",
            headers: AUTH_HEADERS,
            body: JSON.stringify(valuesEdited),
        }).then(checkStatus)
            .then((res) => res.json())
            .then((id) => {
                fetch(`${ENDPOINTS.pages}${id}`, {
                    method: "GET",
                    headers: AUTH_HEADERS,
                }).then(checkStatus)
                    .then((res) => res.json())
                    .then((r) => {
                        dispatch(exhibitionSubjectActions.updatePage(r));
                        setStatus({ type: "success", message: "Uspješno editirano!" });
                    });
            })
            .catch((err) => {
                err.json().then((errorMessage) => {
                    setStatus({ type: "error", message: errorMessage });
                });
            });
    },
    delete: (pageId) => (dispatch) => {
        fetch(`${ENDPOINTS.pages}${pageId}`, {
            method: "DELETE",
            headers: AUTH_HEADERS,
        }).then(checkStatus)
            .then(() => {
                dispatch(exhibitionActions.deletePage(pageId));
            })
            .catch((error) => {
                console.error(error);
                return Promise.reject();
            });
    },
    // getById: (id) => {
    //     fetch(`${ENDPOINTS.pages2}${id}`, {
    //         method: "GET",
    //         headers: AUTH_HEADERS,
    //     }).then(checkStatus)
    //         .then((res) => res.json())
    //         .then((r) => {
    //             return r;
    //         });
    // },
};

export const adminLogin = (values, props, setStatus) => {
    fetch(ENDPOINTS.login, {
        method: "POST",
        headers: BASIC_HEADERS,
        body: JSON.stringify({
            Username: values.username,
            Password: values.password,
        }),
    }).then(checkStatus)
        .then((res) => res.json())
        .then((r) => {
            setToken(r.token);
            setExpirationDate(r.expiration);
            props.history.push("/admin/dashboard");
        })
        .catch((error) => {
            setStatus({ type: "error", message: error.message });
            return Promise.reject();
        });
};
