import React from "react";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import SVGarrowDown from "../../media/svgs/common/arrow-down";
import SVGarrowUp from "../../media/svgs/common/arrow-up";

function InfoAccordion() {
    const [accordionCollapsed, setAccordionCollapsed] = React.useState(false);

    return (
        <Accordion className="custom-homepage-accordion" defaultActiveKey={null}>
            <Card>
                <Accordion.Toggle
                    as={Card.Header}
                    eventKey="1"
                    onClick={() => setAccordionCollapsed(!accordionCollapsed)}
                >
                    <span>Info</span>
                    <span>{accordionCollapsed ? <SVGarrowUp /> : <SVGarrowDown />}</span>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="1">
                    <Card.Body>
                        <div>
                            Projekt Muzej budućnosti - Građansko muzejsko vijeće kao model sudioničkog upravljanja (UP.04.2.1.04.0182) provodi se u okviru Europskog socijalnog fonda, Operativnog programa Učinkoviti ljudski potencijali 2014. – 2020., prioritetne osi 4. Dobro upravljanje. Projekt je usmjeren na razvoj dobrog upravljanja u kulturi jačanjem suradnje organizacija civilnog društva i javnog sektora.
                        </div>
                        <div>
                            Trajanje projekta: 29. 10. 2018. – 29. 6. 2020.
                        </div>
                        <div>
                            Ukupna vrijednost projekta: 742.896,03 HRK
                        </div>
                        <div>
                            Projekt je sufinanciran od strane Europske unije u iznosu od 631.461,63 kuna, odnosno 85 % bespovratnih sredstava.
                        </div>
                        <div>
                            Kontakt osoba projekta: Vana Gović, viša kustosica, vana@ppmhp.hr.
                        </div>
                        <div>
                            Važni linkovi:
                            <br />
                            <a className="custom-link" target="_blank" rel="noopener noreferrer" href="https://www.strukturnifondovi.hr">www.strukturnifondovi.hr</a>
                            <br />
                            <a className="custom-link" target="_blank" rel="noopener noreferrer" href="https://www.esf.hr">www.esf.hr</a>
                        </div>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    );
}

export default InfoAccordion;

