import React from "react";
import PropTypes from "prop-types";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ExhibitionSubjectPropType } from "../../../customPropTypes";
import ThreeColumnList from "./ThreeColumnList";
import TwoColumnList from "./TwoColumnList";
import OneColumnList from "./OneColumnList";

function ExhibitionEntriesArchive({ archive }) {
    let approvedItems;
    let sortedItems;
    let firstFive;
    let theRest;

    if (Object.keys(archive).length > 0) {
        approvedItems = archive.exhibitionSubjects && archive.exhibitionSubjects.reduce((accum, current) => {
            if (current.status === 2) {
                return [...accum, current];
            }
            return accum;
        }, []);

        sortedItems = approvedItems.sort((a, b) => {
            if (a.votes > b.votes) return -1;
            if (a.votes < b.votes) return 1;
            return 0;
        });

        firstFive = sortedItems.slice(0, 5);
        theRest = sortedItems.slice(5, sortedItems.length);
    }

    const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);

    React.useEffect(() => {
        function handleResize() {
            setScreenWidth(window.innerWidth);
        }

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const mainPageText = archive && archive.contents && archive.contents.find((content) => content.title === "Main page text");

    return (
        <div>
            {Object.keys(archive).length > 0 && (
                <div>
                    <Row>
                        <div className="custom-archive-title">
                            Arhiva programa
                            <br />
                            {archive.alias}
                        </div>
                    </Row>
                    <Row className="custom-exhibition-intro-text archive">
                        <Col sm="12" md="12" lg="7">
                            <p>{mainPageText && mainPageText.text}</p>
                        </Col>
                    </Row>
                    <Row className="custom-archive-subtitle">
                        <h4>Odabrani programi</h4>
                    </Row>
                    {(screenWidth > 991 || (screenWidth > 599 && screenWidth < 800)) && (
                        <ThreeColumnList items={firstFive} />
                    )}
                    {((screenWidth > 799 && screenWidth < 992) || (screenWidth > 499 && screenWidth < 600)) && (
                        <TwoColumnList items={firstFive} />
                    )}
                    {screenWidth < 500 && <OneColumnList items={firstFive} />}
                    <Row className="custom-archive-subtitle">
                        <h4>Ostali predloženi programi</h4>
                    </Row>
                    {screenWidth > 991 && <ThreeColumnList items={theRest} />}
                    {screenWidth > 599 && screenWidth < 800 && <ThreeColumnList items={theRest} />}
                    {screenWidth > 799 && screenWidth < 992 && <TwoColumnList items={theRest} />}
                    {screenWidth > 499 && screenWidth < 600 && <TwoColumnList items={theRest} />}
                    {screenWidth < 500 && <OneColumnList items={theRest} />}
                </div>
            )}
        </div>
    );
}

export default ExhibitionEntriesArchive;

ExhibitionEntriesArchive.propTypes = {
    archive: PropTypes.shape({
        alias: PropTypes.string,
        activeFrom: PropTypes.string,
        activeTo: PropTypes.string,
        contents: PropTypes.arrayOf(PropTypes.shape({
            title: PropTypes.string,
            text: PropTypes.string,
            tag: PropTypes.string,
        })),
        exhibitionSubjects: PropTypes.arrayOf(ExhibitionSubjectPropType),
    }),
};

ExhibitionEntriesArchive.defaultProps = {
    archive: {},
};
