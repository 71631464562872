const exhibitionEntryVoteActions = {
    vote: (id, votes) => (dispatch) => {
        dispatch({
            type: "ENTRY_UPVOTE",
            payload: { id, votes },
        });
    },
    unVote: (id, votes) => (dispatch) => {
        dispatch({
            type: "ENTRY_DOWNVOTE",
            payload: { id, votes },
        });
    },
    getVotes: (entryVotes) => (dispatch) => {
        dispatch({
            type: "GET_VOTES",
            payload: { ...entryVotes },
        });
    },
};

export default exhibitionEntryVoteActions;
