const surveyActions = {
    addNew: (survey) => (dispatch) => {
        dispatch({
            type: "ADD_NEW_SURVEY",
            payload: survey,
        });
    },
    fetchSurveysSuccess: (surveys) => (dispatch) => {
        dispatch({
            type: "FETCH_SURVEYS",
            payload: [...surveys],
        });
    },
    updateSurvey: (survey) => (dispatch) => {
        dispatch({
            type: "UPDATE_SURVEY",
            payload: survey,
        });
    },
    delete: (id) => (dispatch) => {
        dispatch({
            type: "DELETE_SURVEY",
            payload: id,
        });
    },
};

export default surveyActions;
