import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router";
import "../../css/App.css";
import Container from "react-bootstrap/Container";
import {
    SurveyListPropType, ExhibitionEntryListPropType, HistoryPropType, MatchPropType,
    ExhibitionSubjectListPropType, MapPinListPropType,
} from "../../customPropTypes";
import AdminNavbar from "./AdminNavbar";
import AdminLoginForm from "./AdminLoginForm";
import AdminDashboard from "./dashboard/AdminDashboard";
import AdminExhibitionEntries from "./exhibition/AdminExhibitionEntries";
import AdminSurveys from "./survey/AdminSurveys";
import AdminSurveyResultsContainer from "../../containers/admin/survey/AdminSurveyResultsContainer";
import AdminSurveyEditContainer from "../../containers/admin/survey/AdminSurveyEditContainer";
import Content from "../../content/AdminTextContent";
import { IsAuthenticated } from "../../services/auth";
import AdminSurveyAddNew from "./survey/AdminSurveyAddNew";
import { adminLogin } from "../../services/requests";
import AdminExhibitionSubjects from "./subjects/AdminExhibitionSubjects";
import AdminMap from "./map/AdminMap";
import ExhibitionEntriesPagesContainer from "../../containers/admin/pages/entries/OverviewContainer";
import ExhibitionSubjectsPagesContainer from "../../containers/admin/pages/subjects/OverviewContainer";
import SubjectsPageCreateContainer from "../../containers/admin/pages/subjects/CreateContainer";
import SubjectsPageEditContainer from "../../containers/admin/pages/subjects/EditContainer";
import EntriesPageEditContainer from "../../containers/admin/pages/entries/EditContainer";
import EntriesPageCreateContainer from "../../containers/admin/pages/entries/CreateContainer";
import PlannedEntriesPagesContainer from "../../containers/admin/pages/entries/PlannedContainer";
import PlannedSubjectPagesContainer from "../../containers/admin/pages/subjects/PlannedContainer";
import ArchivedEntriesPagesContainer from "../../containers/admin/pages/entries/ArchivedContainer";
import ArchivedSubjectPagesContainer from "../../containers/admin/pages/subjects/ArchivedContainer";
import ArchivedEntryItemsContainer from "../../containers/admin/pages/entries/ArchivedItemsContainer";
import ArchivedSubjectItemsContainer from "../../containers/admin/pages/subjects/ArchivedItemsContainer";

function AdminApp({
    match,
    exhibitionEntries, getEntries, onApproveEntry, onRejectEntry,
    onApproveRejectedEntry, onRejectApprovedEntry,
    surveys, onAddNewSurvey, getSurveys, onChangeSurveyStatus, onEditSurvey, onDeleteSurvey,
    exhibitionSubjects, getSubjects, onApproveSubject, onRejectSubject,
    onRejectApprovedSubject, onApproveRejectedSubject,
    mapPins, getAllMapPins, deletePinById, getExhibitionEntryPages, getExhibitionSubjectPages,
}) {
    React.useEffect(() => {
        getEntries();
    }, [getEntries]);

    React.useEffect(() => {
        getSurveys();
    }, [getSurveys]);

    React.useEffect(() => {
        getSubjects();
    }, [getSubjects]);
    
    React.useEffect(() => {
        getExhibitionEntryPages();
    }, [getExhibitionEntryPages]);
    
    React.useEffect(() => {
        getExhibitionSubjectPages();
    }, [getExhibitionSubjectPages]);

    const newEntriesCount = exhibitionEntries.new.length;
    const newSubjectsCount = exhibitionSubjects.new.length;

    const activeEntriesPage = exhibitionEntries.pages && exhibitionEntries.pages.current;
    const activeSubjectsPage = exhibitionSubjects.pages && exhibitionSubjects.pages.current;
    
    return (
        <div className="App">
            <AdminNavbar />
            <Container>
                <Route
                    exact
                    path={match.path}
                    render={(props) => (
                        IsAuthenticated() ? (
                            <Redirect to={`${match.path}/dashboard`} />) :
                            <AdminLoginForm history={props.history} onSubmit={adminLogin} />
                    )}
                />
                <Route
                    exact
                    path={`${match.path}/dashboard`}
                    render={(props) => (
                        IsAuthenticated() ? (
                            <AdminDashboard
                                history={props.history}
                                newEntriesCount={newEntriesCount}
                                newSubjectsCount={newSubjectsCount}
                            />
                        ) :
                            <Redirect to={match.path} />
                    )}
                />
                <Route
                    exact
                    path={`${match.path}/predmeti`}
                    render={(props) => (
                        IsAuthenticated() ?
                            (
                                <AdminExhibitionEntries
                                    title={Content.exhibitionEntries.title}
                                    defaultActiveKey={Content.exhibitionEntries.defaultActiveKey}
                                    newEntries={exhibitionEntries.new}
                                    approvedEntries={exhibitionEntries.approved}
                                    rejectedEntries={exhibitionEntries.rejected}
                                    approveEntry={onApproveEntry}
                                    approveRejectedEntry={onApproveRejectedEntry}
                                    rejectEntry={onRejectEntry}
                                    rejectApprovedEntry={onRejectApprovedEntry}
                                    history={props.history}
                                    activePage={activeEntriesPage}
                                />
                            ) : <Redirect to={match.path} />
                    )}
                />
                <Route
                    exact
                    path={`${match.path}/programi`}
                    render={(props) => (
                        IsAuthenticated() ?
                            (
                                <AdminExhibitionSubjects
                                    history={props.history}
                                    title={Content.exhibitionSubjects.title}
                                    defaultActiveKey={Content.exhibitionSubjects.defaultActiveKey}
                                    newSubjects={exhibitionSubjects.new}
                                    approvedSubjects={exhibitionSubjects.approved}
                                    rejectedSubjects={exhibitionSubjects.rejected}
                                    approveSubject={onApproveSubject}
                                    rejectSubject={onRejectSubject}
                                    approveRejectedSubject={onApproveRejectedSubject}
                                    rejectApprovedSubject={onRejectApprovedSubject}
                                    activePage={activeSubjectsPage}
                                />
                            ) : <Redirect to={match.path} />
                    )}
                />
                <Route
                    exact
                    path={`${match.path}/mapa`}
                    render={(props) => (
                        IsAuthenticated() ?
                            (
                                <AdminMap
                                    history={props.history}
                                    customPins={mapPins}
                                    getAllMapPins={getAllMapPins}
                                    deletePinById={deletePinById}
                                />
                            ) : <Redirect to={match.path} />
                    )}
                />
                <Route
                    exact
                    path={`${match.path}/natjecaji`}
                    render={(props) => (
                        IsAuthenticated() ? (
                            <Redirect to={`${match.path}/natjecaji/predmeti`} />) :
                            <AdminLoginForm history={props.history} onSubmit={adminLogin} />
                    )}
                />
                <Route
                    exact
                    path={`${match.path}/natjecaji/predmeti`}
                    render={(props) => (
                        IsAuthenticated() ?
                            (
                                <ExhibitionEntriesPagesContainer history={props.history} match={props.match} />
                            ) : <Redirect to={match.path} />
                    )}
                />
                <Route
                    exact
                    path={`${match.path}/natjecaji/programi`}
                    render={(props) => (
                        IsAuthenticated() ?
                            (
                                <ExhibitionSubjectsPagesContainer history={props.history} match={props.match} />
                            ) : <Redirect to={match.path} />
                    )}
                />
                <Route
                    exact
                    path={`${match.path}/natjecaji/predmeti/edit/:pageId`}
                    render={(props) => (
                        IsAuthenticated() ?
                            <EntriesPageEditContainer history={props.history} match={props.match} /> :
                            <AdminLoginForm history={props.history} onSubmit={adminLogin} />
                    )}
                />
                <Route
                    exact
                    path={`${match.path}/natjecaji/predmeti/dodaj/`}
                    render={(props) => (
                        IsAuthenticated() ?
                            <EntriesPageCreateContainer history={props.history} match={props.match} /> :
                            <AdminLoginForm history={props.history} onSubmit={adminLogin} />
                    )}
                />
                <Route
                    exact
                    path={`${match.path}/natjecaji/predmeti/buduci/`}
                    render={(props) => (
                        IsAuthenticated() ?
                            (
                                <PlannedEntriesPagesContainer history={props.history} />
                            ) : <AdminLoginForm history={props.history} onSubmit={adminLogin} />
                    )}
                />
                <Route
                    exact
                    path={`${match.path}/natjecaji/predmeti/arhivirani/:pageId/prijedlozi/`}
                    render={(props) => (
                        IsAuthenticated() ?
                            (
                                <ArchivedEntryItemsContainer history={props.history} match={props.match} />
                            ) : <AdminLoginForm history={props.history} onSubmit={adminLogin} />
                    )}
                />
                <Route
                    exact
                    path={`${match.path}/natjecaji/predmeti/arhivirani/`}
                    render={(props) => (
                        IsAuthenticated() ?
                            (
                                <ArchivedEntriesPagesContainer history={props.history} />
                            ) : <AdminLoginForm history={props.history} onSubmit={adminLogin} />
                    )}
                />
                <Route
                    exact
                    path={`${match.path}/natjecaji/programi/dodaj/`}
                    render={(props) => (
                        IsAuthenticated() ?
                            <SubjectsPageCreateContainer history={props.history} match={props.match} /> :
                            <AdminLoginForm history={props.history} onSubmit={adminLogin} />
                    )}
                />
                <Route
                    exact
                    path={`${match.path}/natjecaji/programi/buduci/`}
                    render={(props) => (
                        IsAuthenticated() ?
                            (
                                <PlannedSubjectPagesContainer history={props.history} />
                            ) : <AdminLoginForm history={props.history} onSubmit={adminLogin} />
                    )}
                />
                <Route
                    exact
                    path={`${match.path}/natjecaji/programi/arhivirani/`}
                    render={(props) => (
                        IsAuthenticated() ?
                            (
                                <ArchivedSubjectPagesContainer history={props.history} />
                            ) : <AdminLoginForm history={props.history} onSubmit={adminLogin} />
                    )}
                />
                <Route
                    exact
                    path={`${match.path}/natjecaji/programi/edit/:pageId`}
                    render={(props) => (
                        IsAuthenticated() ?
                            <SubjectsPageEditContainer history={props.history} match={props.match} /> :
                            <AdminLoginForm history={props.history} onSubmit={adminLogin} />
                    )}
                />
                <Route
                    exact
                    path={`${match.path}/natjecaji/programi/arhivirani/:pageId/prijedlozi/`}
                    render={(props) => (
                        IsAuthenticated() ?
                            (
                                <ArchivedSubjectItemsContainer history={props.history} match={props.match} />
                            ) : <AdminLoginForm history={props.history} onSubmit={adminLogin} />
                    )}
                />
                <Route
                    exact
                    path={`${match.path}/ankete/anketa:id`}
                    render={(props) => (
                        IsAuthenticated() ?
                            <AdminSurveyResultsContainer history={props.history} match={props.match} /> :
                            <Redirect to={match.path} />
                    )}
                />
                <Route
                    exact
                    path={`${match.path}/ankete/editiraj/anketa:id`}
                    render={(props) => (
                        IsAuthenticated() ? (
                            <AdminSurveyEditContainer
                                history={props.history}
                                match={props.match}
                                onEditSurvey={onEditSurvey}
                            />
                        ) :
                            <Redirect to={match.path} />
                    )}
                />
                <Route
                    exact
                    path={`${match.path}/ankete`}
                    render={(props) => (
                        IsAuthenticated() ?
                            (
                                <AdminSurveys
                                    data={surveys}
                                    history={props.history}
                                    onChangeSurveyStatus={onChangeSurveyStatus}
                                    onDeleteSurvey={onDeleteSurvey}
                                />
                            ) : <Redirect to={match.path} />
                    )}
                />
                <Route
                    exact
                    path="/admin/ankete/dodaj"
                    render={(props) => (
                        IsAuthenticated() ? (
                            <AdminSurveyAddNew
                                history={props.history}
                                onAddNewSurvey={onAddNewSurvey}
                            />
                        ) :
                            <Redirect to={match.path} />
                    )}
                />
            </Container>
        </div>
    );
}

export default AdminApp;

AdminApp.propTypes = {
    match: MatchPropType.isRequired,
    exhibitionEntries: ExhibitionEntryListPropType.isRequired,
    onApproveEntry: PropTypes.func.isRequired,
    onRejectEntry: PropTypes.func.isRequired,
    onApproveRejectedEntry: PropTypes.func.isRequired,
    onRejectApprovedEntry: PropTypes.func.isRequired,
    surveys: SurveyListPropType,
    getEntries: PropTypes.func.isRequired,
    getSurveys: PropTypes.func.isRequired,
    onAddNewSurvey: PropTypes.func.isRequired,
    onChangeSurveyStatus: PropTypes.func.isRequired,
    history: HistoryPropType.isRequired,
    getAllMapPins: PropTypes.func.isRequired,
    onEditSurvey: PropTypes.func.isRequired,
    onDeleteSurvey: PropTypes.func.isRequired,
    exhibitionSubjects: ExhibitionSubjectListPropType.isRequired,
    getSubjects: PropTypes.func.isRequired,
    onApproveSubject: PropTypes.func.isRequired,
    onRejectSubject: PropTypes.func.isRequired,
    onRejectApprovedSubject: PropTypes.func.isRequired,
    onApproveRejectedSubject: PropTypes.func.isRequired,
    deletePinById: PropTypes.func.isRequired,
    mapPins: MapPinListPropType.isRequired,
    getExhibitionEntryPages: PropTypes.func.isRequired,
    getExhibitionSubjectPages: PropTypes.func.isRequired,
};

AdminApp.defaultProps = {
    surveys: [],
};
