import { connect } from "react-redux";
import { exhibitionSubject } from "../../../../services/requests";
import ArchivedSubjectsPages from "../../../../components/admin/pages/subjects/Archived";

const mapStateToProps = (state) => ({
    pages: state.exhibitionSubjects.pages.archived,
});

const mapDispatchToProps = (dispatch) => ({
    getExhibitionSubjectPages: () => dispatch(exhibitionSubject.getPages()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ArchivedSubjectsPages);
