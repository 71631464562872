import React from "react";
import PropTypes from "prop-types";

function ButtonRemove({ onClickCallback }) {
    return (
        <button
            type="button"
            onClick={onClickCallback}
            className="no-style-button"
            style={{
                position: "absolute", fontSize: "20px", top: "-10px", right: "50px",
            }}
        >
            <i className="fas fa-trash" />
        </button>
    );
}

export default ButtonRemove;

ButtonRemove.propTypes = {
    onClickCallback: PropTypes.func,
};

ButtonRemove.defaultProps = {
    onClickCallback: () => {},
};
