import React from "react";

function SVG() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="585.48" height="1043.979">
            <switch>
                <g>
                    <path fill="#FFDC00" d="M328.065 425.899h243.378L287.916 0 9.207 426.332h243.712c-8.262 33.047-22.451 58.542-46.762 86.599C160.548 567.726 73.166 603.771 0 611.368l2.867 432.611c130.531-17.941 241.922-84.289 290.068-209.43 45.148 123.189 166.168 198.609 292.545 209.43V611.368c-76.082-8.17-180.967-57.885-225.773-119.383-15.088-20.833-25.048-39.735-31.642-66.086" />
                </g>
            </switch>
        </svg>
    );
}

export default SVG;
