import exhibitionReducer from "./admin/exhibitionReducer";
import surveyReducer from "./admin/surveyReducer";
import votingReducer from "./votingReducer";
import extraSettingsReducer from "./extraSettings";
import exhibitionEntryVoteReducer from "./exhibitionEntryVoteReducer";
import exhibitionSubjectVoteReducer from "./exhibitionSubjectVoteReducer";
import mapPinsReducer from "./mapPinsReducer";
import exhibitionSubjectsReducer from "./exhibitionSubjects";
import archiveReducer from "./archive";

const reducers = {
    exhibitionEntries: exhibitionReducer,
    surveys: surveyReducer,
    voting: votingReducer,
    extraSettings: extraSettingsReducer,
    exhibitionEntryVotes: exhibitionEntryVoteReducer,
    mapPins: mapPinsReducer,
    exhibitionSubjects: exhibitionSubjectsReducer,
    exhibitionSubjectVotes: exhibitionSubjectVoteReducer,
    archive: archiveReducer,
};

export default reducers;
