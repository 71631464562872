const EXHIBITION_ENTRY_CAN_VOTE = "exhibition_entry_can_vote";
const EXHIBITION_SUBJECT_CAN_VOTE = "exhibition_subject_can_vote";
const SURVEY_ID_CAN_VOTE = "survey_id_can_vote";

export function setExhibitionEntryVote(id) {
    localStorage.setItem(`${EXHIBITION_ENTRY_CAN_VOTE}_${id}`, "no");
}

export function resetExhibitionEntryVote(id) {
    localStorage.setItem(`${EXHIBITION_ENTRY_CAN_VOTE}_${id}`, "yes");
}

export function canExhibitionEntryVote(id) {
    return localStorage.getItem(`${EXHIBITION_ENTRY_CAN_VOTE}_${id}`) || "yes";
}

export function setSurveyIdVote(id) {
    localStorage.setItem(`${SURVEY_ID_CAN_VOTE}_${id}`, "no");
}

export function canSurveyIdVote(id) {
    return localStorage.getItem(`${SURVEY_ID_CAN_VOTE}_${id}`) || "yes";
}

export function canExhibitionSubjectVote(id) {
    return localStorage.getItem(`${EXHIBITION_SUBJECT_CAN_VOTE}_${id}`) || "yes";
}

export function setExhibitionSubjectVote(id) {
    localStorage.setItem(`${EXHIBITION_SUBJECT_CAN_VOTE}_${id}`, "no");
}

export function resetExhibitionSubjectVote(id) {
    localStorage.setItem(`${EXHIBITION_SUBJECT_CAN_VOTE}_${id}`, "yes");
}
