import React from "react";
import PropTypes from "prop-types";
import Card from "react-bootstrap/Card";
import {
    FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon,
} from "react-share";
import SubjectVoteButtonContainer from "../../../containers/website/SubjectVoteButtonContainer";
import SubjectVotesContainer from "../../../containers/website/SubjectVotesContainer";

function ExhibitionSubjectCard({
    title, description, onVoteSubject, id, onUnvoteSubject,
}) {
    const shareUrl = `${window.location.host}/programi/program${id}`;
    const shareTitle = `Podrži i ti prijedlog programa "${title}" za Muzej budućnosti!`;

    return (
        <Card className="custom-exhibition-entry-card">
            <Card.Body>
                <Card.Title>{title}</Card.Title>
                <Card.Text>
                    {description}
                </Card.Text>
                <SubjectVoteButtonContainer
                    entryId={id}
                    onVoteCallback={onVoteSubject}
                    onUnvoteCallback={onUnvoteSubject}
                />
                <Card.Text className="custom-exhibition-entry-card-action-text">
                    <SubjectVotesContainer id={id} />
                </Card.Text>
                <div className="custom-card-social-share">
                    <span className="custom-exhibition-entry-card-action-text">PODIJELI NA</span>
                    <FacebookShareButton
                        url={shareUrl}
                        quote={shareTitle}
                    >
                        <FacebookIcon size={32} />
                    </FacebookShareButton>
                    <TwitterShareButton
                        url={shareUrl}
                        title={shareTitle}
                    >
                        <TwitterIcon size={32} />
                    </TwitterShareButton>
                </div>
            </Card.Body>
        </Card>
    );
}

export default ExhibitionSubjectCard;

ExhibitionSubjectCard.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    onVoteSubject: PropTypes.func.isRequired,
    id: PropTypes.number.isRequired,
    onUnvoteSubject: PropTypes.func.isRequired,
};
