import React from "react";

function GMVtext() {
    return (
        <div className="custom-homepage-p-wider">
            <p>
            Organizacije građanskog društva u članstvu Građanskog muzejskog vijeća:
            </p>
            <p className="custom-homepage-p-link"><a target="_blank" rel="noopener noreferrer" href="http://czpd.hr/o-nama/" className="custom-link">Centar za poticanje darovitost</a></p>
            <p className="custom-homepage-p-link"><a target="_blank" rel="noopener noreferrer" href="http://www.kombinat.hr/" className="custom-link">Kreativni kolektiv Kombinat</a></p>
            <p className="custom-homepage-p-link"><a target="_blank" rel="noopener noreferrer" href="https://penzici.rijeka.hr/" className="custom-link">Matica umirovljenika Grada Rijeke</a></p>
            <p className="custom-homepage-p-link"><a target="_blank" rel="noopener noreferrer" href="http://www.usr.hr/" className="custom-link">Udruga slijepih Primorsko-goranske županije</a></p>
            <p className="custom-homepage-p-link"><a target="_blank" rel="noopener noreferrer" href="https://zmergo.hr/" className="custom-link">Udruga Žmergo</a></p>
        </div>
    );
}

export default GMVtext;
