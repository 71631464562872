import { updateSurvey, deleteSurvey } from "../../helpers/lists";

const defaultState = [];

export default (state = defaultState, action) => {
    switch (action.type) {
    case "ADD_NEW_SURVEY":
        return [...state, action.payload];
    case "FETCH_SURVEYS":
        return [...action.payload];
    case "UPDATE_SURVEY":
        return updateSurvey(state, action.payload);
    case "DELETE_SURVEY":
        return deleteSurvey(state, action.payload);
    default:
        return state;
    }
};
