import React from "react";
import PropTypes from "prop-types";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { ExhibitionEntryPropType, HistoryPropType, PagePropType } from "../../../customPropTypes";
import AdminSidebar from "../AdminSidebar";
import Content from "../../../content/AdminTextContent";
import AdminExhibitionNewEntry from "./AdminExhibitionNewEntry";
import AdminExhibitionEntriesList from "./AdminExhibitionEntriesList";

function AdminExhibitionEntries({
    title, defaultActiveKey, newEntries, approvedEntries, rejectedEntries, history,
    approveEntry, rejectEntry, approveRejectedEntry, rejectApprovedEntry,
    activePage,
}) {
    const [currentNewEntryIndex, setCurrentNewEntryIndex] = React.useState(0);

    const onNextEntryClicked = () => setCurrentNewEntryIndex(currentNewEntryIndex + 1);
    const onPreviousEntryClicked = () => setCurrentNewEntryIndex(currentNewEntryIndex - 1);
    const resetNewEntriesList = () => {
        if (currentNewEntryIndex > 0) {
            onPreviousEntryClicked();
        }
    };

    const tabsContent = Content.exhibitionEntries.tabs;

    let currentTitle;

    currentTitle =  activePage && activePage.alias ? `${title} "${activePage.alias}"` : title;

    return (
        <div>
            <AdminSidebar history={history} />
            <h3 className="admin-exhibition-title">{currentTitle}</h3>
            <Tabs defaultActiveKey={defaultActiveKey} id="uncontrolled-tab-example">
                <Tab eventKey={tabsContent[0].eventKey} title={tabsContent[0].title}>
                    {newEntries.length > 0 ? (
                        <AdminExhibitionNewEntry
                            entry={newEntries[currentNewEntryIndex]}
                            order={currentNewEntryIndex + 1}
                            totalEntriesNumber={newEntries.length}
                            onNextEntryClicked={onNextEntryClicked}
                            onPreviousEntryClicked={onPreviousEntryClicked}
                            onApproveClicked={(entry) => {
                                approveEntry(entry);
                                resetNewEntriesList();
                            }}
                            onRejectClicked={(entry) => {
                                rejectEntry(entry);
                                resetNewEntriesList();
                            }}
                        />
                    ) : null}
                </Tab>
                <Tab eventKey={tabsContent[1].eventKey} title={tabsContent[1].title}>
                    {approvedEntries.length > 0 ?
                        (
                            <AdminExhibitionEntriesList
                                data={approvedEntries}
                                showRejectButton
                                onRejectClicked={rejectApprovedEntry}
                            />
                        ) : null
                    }
                </Tab>
                <Tab eventKey={tabsContent[2].eventKey} title={tabsContent[2].title}>
                    {rejectedEntries.length > 0 ?
                        (
                            <AdminExhibitionEntriesList
                                data={rejectedEntries}
                                showApproveButton
                                onApproveClicked={approveRejectedEntry}
                            />
                        ) : null
                    }
                </Tab>
            </Tabs>
        </div>
    );
}

export default AdminExhibitionEntries;

AdminExhibitionEntries.propTypes = {
    title: PropTypes.string.isRequired,
    defaultActiveKey: PropTypes.string.isRequired,
    newEntries: PropTypes.arrayOf(ExhibitionEntryPropType),
    approvedEntries: PropTypes.arrayOf(PropTypes.shape({})),
    approveEntry: PropTypes.func.isRequired,
    rejectEntry: PropTypes.func.isRequired,
    rejectedEntries: PropTypes.arrayOf(PropTypes.shape({})),
    approveRejectedEntry: PropTypes.func.isRequired,
    rejectApprovedEntry: PropTypes.func.isRequired,
    history: HistoryPropType.isRequired,
    // activePage: PropTypes.string.isRequired,
    activePage: PagePropType.isRequired,
};

AdminExhibitionEntries.defaultProps = {
    newEntries: [],
    approvedEntries: [],
    rejectedEntries: [],
};
