import React from "react";
import PropTypes from "prop-types";
import uuidv1 from "uuid/v1";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AdminSidebar from "../AdminSidebar";
import AdminSurveyCard from "./AdminSurveyCard";
import Content from "../../../content/AdminTextContent";
import { SurveyListPropType, HistoryPropType } from "../../../customPropTypes";

function AdminSurveys({ history, data, onChangeSurveyStatus, onDeleteSurvey }) {
    const getLinkToSurveyResults = (survey) => `/admin/ankete/anketa${survey.id}`;
    const getLinkToEditSurvey = (survey) => `/admin/ankete/editiraj/anketa${survey.id}`;

    return (
        <div>
            <AdminSidebar history={history} />
            <h3 className="admin-exhibition-title">
                {Content.surveys.title}
                <Link to="/admin/ankete/dodaj">
                    <button
                        type="button"
                        onClick={() => {}}
                        className="admin-add-new-button no-style-button"
                    >
                        <i className="fas fa-plus-circle" />
                    </button>
                </Link>
            </h3>
            <Row className="align-content-center">
                {data.length > 0 && data.map((survey) => (
                    <Col sm="12" md="6" key={uuidv1()}>
                        <AdminSurveyCard
                            survey={survey}
                            history={history}
                            buttonLabel={Content.surveys.card.buttonLabel}
                            buttonLink={getLinkToSurveyResults(survey)}
                            onChangeSurveyStatus={onChangeSurveyStatus}
                            editLink={getLinkToEditSurvey(survey)}
                            onDeleteSurvey={onDeleteSurvey}
                        />
                    </Col>
                ))}
            </Row>
        </div>
    );
}

export default AdminSurveys;

AdminSurveys.propTypes = {
    history: HistoryPropType.isRequired,
    data: SurveyListPropType,
    onChangeSurveyStatus: PropTypes.func.isRequired,
};

AdminSurveys.defaultProps = {
    data: [],
};
