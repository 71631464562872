import React from "react";
import PropTypes from "prop-types";
import Card from "react-bootstrap/Card";

function ExhibitionSubjectCard({ title, description, votes }) {
    return (
        <Card className="custom-exhibition-entry-card archive">
            <Card.Body>
                <Card.Title>{title}</Card.Title>
                <Card.Text>{description}</Card.Text>
                <Card.Text className="custom-exhibition-entry-card-action-text">
                    <span>{`Broj glasova: ${votes}`}</span>
                </Card.Text>
            </Card.Body>
        </Card>
    );
}

export default ExhibitionSubjectCard;

ExhibitionSubjectCard.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    votes: PropTypes.number.isRequired,
};
