import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import LogoLink from "./LogoLink";

const styles = {
    sidebar: {
        width: 230,
        height: "100%",
        overflowY: "hidden",
    },
    divider: {
        margin: "8px 0",
        height: 1,
        backgroundColor: "#757575",
    },
    content: {
        padding: "16px",
        height: "100%",
        backgroundColor: "white",
    },
};

const SideBarContent = ({ onClickItem }) => (
    <div style={{ position: "relative", height: "100%" }}>
        <div title="Menu" style={styles.sidebar}>
            <LogoLink customClassName="custom-sidebar-logo" />
            <div style={styles.content}>
                <Link to="/o-projektu">
                    <button
                        type="button"
                        onClick={onClickItem}
                        className="no-style-button custom-sidebar-link"
                    >
                        O projektu
                    </button>
                </Link>
                <Link to="/prijedlozi">
                    <button
                        type="button"
                        onClick={onClickItem}
                        className="no-style-button custom-sidebar-link"
                    >
                        Predloži predmet
                    </button>
                </Link>
                <Link to="/programi">
                    <button
                        type="button"
                        onClick={onClickItem}
                        className="no-style-button custom-sidebar-link"
                    >
                        Predloži program
                    </button>
                </Link>
                <Link to="/dozivljaji">
                    <button
                        type="button"
                        onClick={onClickItem}
                        className="no-style-button custom-sidebar-link"
                    >
                        Podijeli doživljaj
                    </button>
                </Link>
            </div>
        </div>
        <div className="custom-sidebar-bottom-text">
            Projekt je sufinancirala Europska unija iz Europskog socijalnog fonda. Izradu mrežnih stranica sufinancirala je Europska unija iz Europskog socijalnog fonda. Sadržaj mrežnih stranica isključiva je odgovornost Pomorskog i povijesnog muzeja Hrvatskog primorja Rijeka.
        </div>
    </div>
);

SideBarContent.propTypes = {
    onClickItem: PropTypes.func.isRequired,
};

export default SideBarContent;
