import React from "react";
import PropTypes from "prop-types";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { ExhibitionSubjectPropType, HistoryPropType } from "../../../../customPropTypes";
import AdminSidebar from "../../AdminSidebar";
import Content from "../../../../content/AdminTextContent";
import AdminExhibitionSubjectsList from "../../subjects/AdminExhibitionSubjectsList";
import { EXHIBITION_SUBJECT_STATUS } from "../../../../helpers/constants";
import GoBackButton from "../../common/GoBackButton";

function ArchivedItems({ history, items, getExhibitionSubjectPages }) {
    React.useEffect(() => {
        getExhibitionSubjectPages();
    }, [getExhibitionSubjectPages]);

    const approvedItems = items && items.reduce((accum, current) => {
        if (current.status === EXHIBITION_SUBJECT_STATUS.APPROVED) {
            return [...accum, current];
        }
        return accum;
    }, []);

    const rejectedItems = items && items.reduce((accum, current) => {
        if (current.status === EXHIBITION_SUBJECT_STATUS.REJECTED) {
            return [...accum, current];
        }
        return accum;
    }, []);

    return (
        <div>
            <AdminSidebar history={history} />
            <GoBackButton onClickCallback={() => history.push("/admin/natjecaji/programi/arhivirani")} />
            <h3 className="admin-exhibition-title">
                Pregled arhiviranih natječaja za prikupljanje ideja za programe: lista ideja
            </h3>
            <Tabs defaultActiveKey="approved" id="uncontrolled-tab-example">
                <Tab eventKey="approved" title="Prihvaćeni">
                    {approvedItems && approvedItems.length > 0 ?
                        (
                            <AdminExhibitionSubjectsList data={approvedItems} />
                        ) : null
                    }
                </Tab>
                <Tab eventKey="rejected" title="Odbijeni">
                    {rejectedItems && rejectedItems.length > 0 ?
                        (
                            <AdminExhibitionSubjectsList data={rejectedItems} />
                        ) : null
                    }
                </Tab>
            </Tabs>
        </div>
    );
}

export default ArchivedItems;

ArchivedItems.propTypes = {
    history: HistoryPropType.isRequired,
    getExhibitionSubjectPages: PropTypes.func.isRequired,
    items: PropTypes.arrayOf(ExhibitionSubjectPropType).isRequired,
};
