import React from "react";
import PropTypes from "prop-types";
import Card from "react-bootstrap/Card";
import { checkImageURL, checkVideoURL, checkAudioURL } from "../../../helpers/files";

function ExhibitionEntryCard({
    title, description, contentUrl, votes,
}) {
    return (
        <Card className="custom-exhibition-entry-card archive">
            {checkImageURL(contentUrl) && <Card.Img variant="top" src={contentUrl} />}
            {checkVideoURL(contentUrl) && (
                <video controls className="custom-exhibition-entry-card-video" playsInline>
                    <source src={contentUrl} type="video/mp4" />
                    <source src={contentUrl} type="video/webm" />
                    <track default kind="captions" />
                </video>
            )}
            {checkAudioURL(contentUrl) && (
                <audio controls className="custom-exhibition-entry-card-audio">
                    <source src={contentUrl} />
                    <track default kind="captions" />
                    Your browser does not support the audio element.
                </audio>
            )}
            <Card.Body>
                <Card.Title>{title}</Card.Title>
                <Card.Text>{description}</Card.Text>
                <Card.Text className="custom-exhibition-entry-card-action-text">
                    <span>{`Broj glasova: ${votes}`}</span>
                </Card.Text>
            </Card.Body>
        </Card>
    );
}

export default ExhibitionEntryCard;

ExhibitionEntryCard.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    contentUrl: PropTypes.string.isRequired,
    votes: PropTypes.number.isRequired,
};
