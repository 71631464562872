/* eslint max-len: 0 */
import React from "react";
import PropTypes from "prop-types";

const ORIGINAL_WIDTH = 431.721;
const ORIGINAL_HEIGHT = 755.424;

function SVG({ scale }) {
    const width = ORIGINAL_WIDTH * scale;
    const height = ORIGINAL_HEIGHT * scale;
    const widthString = `${width}px`;
    const heightString = `${height}px`;

    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width={widthString}
            height={heightString}
            viewBox={`0 0 ${ORIGINAL_WIDTH} ${ORIGINAL_HEIGHT}`}
            enableBackground={`new 0 0 ${ORIGINAL_WIDTH} ${ORIGINAL_HEIGHT}`}
        >
            <path fillRule="evenodd" clipRule="evenodd" fill="#E5002E" d="M431.721,198.09L274.947,405.166l0.75-140.721 c0,0-2.791-0.006-3.25,0c-36.109,0.428-32.236-0.617-68.324,0.535c-22.033,0.705-39.961,6.545-48.789,28.492 c-8.775,21.812-3.488,37.893,11.791,54.758c5.104,5.631,10.537,11.008,16.209,16.068c13.18,11.762,39.912,34.867,39.912,34.867 s31.385,28.971,47.254,43.26c17.408,15.676,34.945,31.211,52.568,46.645c27.383,23.973,40.988,54.211,43.631,90.393 c2.984,40.852-3.168,78.992-29.475,111.732c-29.15,36.281-67.232,55.713-113.51,59.855c-29.992,2.684-60.307,4.205-90.48,4.35 c-57.588,0.277-64.354-2.012-121.943-2.066c-3.604-0.004-7.207,0-11.291,0c0-46.936,0-141.268,0-141.268h201.576 c0,0,7.893-0.852,11.637-1.986c11.025-3.34,16.092-13.971,10.67-24.084c-3.117-5.811-7.725-10.18-12.689-14.586 c-43.07-38.211-86.549-75.965-129.553-114.252c-32.045-28.531-55.709-62.68-67.4-104.492 c-21.924-78.414,8.393-153.32,77.256-196.621c29.725-18.689,63.152-27.137,98.223-27.795c37.541-0.703,35.098-0.6,72.645-0.842 c2.973-0.02,11.205-0.002,11.205-0.002L273.947,0L431.721,198.09z" />
        </svg>
    );
}

export default SVG;

SVG.propTypes = {
    scale: PropTypes.number,
};

SVG.defaultProps = {
    scale: 1,
};
