import Moment from "moment";
import exhibitionActions from "../action-creators/admin/exhibitionActions";
import exhibitionSubjectActions from "../action-creators/exhibitionSubjectActions";

export function sortEntriesByHighestRank(entriesList) {
    return [...entriesList].sort((a, b) => {
        if (a.votes < b.votes) return 1;
        if (a.votes > b.votes) return -1;
        return 0;
    });
}

export function sortEntriesByLowesttRank(entriesList) {
    return [...entriesList].sort((a, b) => {
        if (a.votes < b.votes) return -1;
        if (a.votes > b.votes) return 1;
        return 0;
    });
}

export function sortEntriesByNewestFirst(entriesList) {
    return [...entriesList].sort((a, b) => {
        if (new Moment(a.created) < new Moment(b.created)) return 1;
        if (new Moment(a.created) > new Moment(b.created)) return -1;
        return 0;
    });
}

export const sortData = (sortFn) => (dispatch, getState) => {
    const entries = getState().exhibitionEntries.approved;
    const sortedEntries = sortFn(entries);

    dispatch(exhibitionActions.sortEntries(sortedEntries));
};

export const sortSubjects = (sortFn) => (dispatch, getState) => {
    const subjects = getState().exhibitionSubjects.approved;
    const sortedSubjects = sortFn(subjects);

    dispatch(exhibitionSubjectActions.sortSubjects(sortedSubjects));
};
