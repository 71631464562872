import React from "react";
import Sidebar from "react-sidebar";
import SidebarContent from "./SideBarContent";

const styles = {
    contentHeaderMenuLink: {
        textDecoration: "none",
        color: "black",
        padding: 8,
        fontSize: "24px",
        background: "white",
    },
    content: {
        padding: "16px",
    },
};

const mql = window.matchMedia("(min-width: 800px)");

function SideBar() {
    const [docked, setDocked] = React.useState(mql.matches);
    const [open, setOpen] = React.useState(false);

    const mediaQueryChanged = () => {
        setDocked(mql.matches);
        setOpen(false);
    };

    const toggleOpen = (ev) => {
        setOpen(!open);

        if (ev) {
            ev.preventDefault();
        }
    };

    React.useEffect(() => {
        mql.addListener(mediaQueryChanged);
    });

    return (
        <Sidebar
            sidebar={<SidebarContent onClickItem={() => setOpen(false)} />}
            docked={docked}
            open={open}
            onSetOpen={setOpen}
            sidebarClassName="custom-sidebar"
            styles={{ sidebar: { position: "fixed" } }}
        >
            <div className="custom-sidebar-docked-wrapper">
                <span>
                    {!docked && (
                        <button
                            type="button"
                            onClick={toggleOpen}
                            style={styles.contentHeaderMenuLink}
                            className="no-style-button"
                        >
                            <i className="fas fa-bars" />
                        </button>
                    )}
                </span>
            </div>
        </Sidebar>
    );
}

export default SideBar;
