import React from "react";
import PropTypes from "prop-types";
import SVGsuggest from "../../../media/svgs/exhibition-entries/suggest";
import SVGsuggestHover from "../../../media/svgs/exhibition-entries/suggest-hover";

function ExhibitionAddNewButton({ onClickCallback, customClassName }) {
    const [isSuggestButtonHovering, setIsSuggestButtonHovering] = React.useState(false);
    return (
        <button
            type="button"
            href="#"
            onClick={() => {
                onClickCallback();
                setIsSuggestButtonHovering(false);
            }}
            className={`no-style-button custom-exhibition-suggest-button ${customClassName}`}
            onMouseEnter={() => setIsSuggestButtonHovering(true)}
            onMouseLeave={() => setIsSuggestButtonHovering(false)}
        >
            {isSuggestButtonHovering ? <SVGsuggestHover /> : <SVGsuggest />}
        </button>
    );
}

export default ExhibitionAddNewButton;

ExhibitionAddNewButton.propTypes = {
    onClickCallback: PropTypes.func.isRequired,
    customClassName: PropTypes.string,
};

ExhibitionAddNewButton.defaultProps = {
    customClassName: "",
};
