/* eslint max-len: 0 */
import React from "react";

function SVG() {
    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="577.028px"
            height="467.456px"
            viewBox="-89 171.434 577.028 467.456"
            enableBackground="new -89 171.434 577.028 467.456"
        >
            <polygon fill="#6EBF61" points="99.673,178.887 -89,186.831 -51.256,371.531 -15.538,546.202 41.235,473.397 488.029,638.89 219.909,244.254 276.68,171.434 " />
        </svg>
    );
}

export default SVG;
