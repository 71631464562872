const defaultState = {
    exhibitionEntries: {},
    surveys: {},
    exhibitionSubjects: {},
};

export default (state = defaultState, action) => {
    switch (action.type) {
    case "EXHIBITION_ENTRY_VOTE":
        return {
            ...state,
            exhibitionEntries: { ...state.exhibitionEntries, [action.payload.id]: action.payload.canVote },
        };
    case "EXHIBITION_SUBJECT_VOTE":
        return {
            ...state,
            exhibitionSubjects: { ...state.exhibitionSubjects, [action.payload.id]: action.payload.canVote },
        };
    case "FETCH_EXHIBITION_ENTRIES_VOTES":
        return {
            ...state,
            exhibitionEntries: { ...state.exhibitionEntries, ...action.payload },
        };
    case "FETCH_EXHIBITION_SUBJECTS_VOTES":
        return {
            ...state,
            exhibitionSubjects: { ...state.exhibitionSubjects, ...action.payload },
        };
    case "FETCH_SURVEY_VOTES":
        return {
            ...state,
            surveys: { ...state.surveys, ...action.payload },
        };
    default:
        return state;
    }
};
