import { connect } from "react-redux";
import { exhibitionSubject } from "../../../../services/requests";
import ArchivedItems from "../../../../components/admin/pages/subjects/ArchivedItems";

const mapStateToProps = (state, ownProps) => {
    const pageId = parseInt(ownProps.match.params.pageId, 10);
    const items = state.exhibitionSubjects.pages.archived && state.exhibitionSubjects.pages.archived.find(
        (page) => page.id === pageId
    ).exhibitionSubjects;

    return {
        items,
    };
};

const mapDispatchToProps = (dispatch) => ({
    getExhibitionSubjectPages: () => dispatch(exhibitionSubject.getPages()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ArchivedItems);
