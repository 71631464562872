import React from "react";

function SVG() {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="11.546px" height="12.305px" viewBox="0 0 11.546 12.305" enableBackground="new 0 0 11.546 12.305">
            <polygon fill="#1B1C20" points="5.772,0.004 0,5.775 0.506,6.305 5.406,1.359 5.406,12.309 6.119,12.309 6.119,1.359 11.041,6.281 11.546,5.775 " />
        </svg>
    );
}

export default SVG;
