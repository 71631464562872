import {
    groupEntriesByStatus, removeFromList, addToList, updatePages,
} from "../helpers/lists";

const defaultState = {
    new: [],
    approved: [],
    rejected: [],
    pages: {},
};

export default (state = defaultState, action) => {
    switch (action.type) {
    case "APPROVE_SUBJECT":
        return {
            ...state,
            new: removeFromList(state.new, action.payload.id),
            approved: addToList(state.approved, state.new, action.payload.id),
        };
    case "APPROVE_REJECTED_SUBJECT":
        return {
            ...state,
            approved: addToList(state.approved, state.rejected, action.payload.id),
            rejected: removeFromList(state.rejected, action.payload.id),
        };
    case "REJECT_SUBJECT":
        return {
            ...state,
            new: removeFromList(state.new, action.payload.id),
            rejected: addToList(state.rejected, state.new, action.payload.id),
        };
    case "REJECT_APPROVED_SUBJECT":
        return {
            ...state,
            rejected: addToList(state.rejected, state.approved, action.payload.id),
            approved: removeFromList(state.approved, action.payload.id),
        };
    case "FETCH_SUBJECTS":
        return {
            ...state,
            ...groupEntriesByStatus(action.payload),
        };
    case "FETCH_APPROVED_SUBJECTS":
        return {
            ...state,
            approved: action.payload,
        };
    case "SORT_SUBJECTS":
        return {
            ...state,
            approved: action.payload,
        };
    case "FETCH_SUBJECT_PAGES":
        return {
            ...state,
            pages: action.payload,
        };
    case "UPDATE_SUBJECTS_PAGE":
        return {
            ...state,
            pages: updatePages(state.pages, action.payload),
        };
    default:
        return state;
    }
};
