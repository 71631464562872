import { connect } from "react-redux";
import ExhibitionSubjectsArchive from "../../components/website/archives/ExhibitionSubjectsArchive";

const mapStateToProps = (state, ownProps) => {
    const pageAlias = ownProps.match.params.alias;
    let matchedPage;

    const removeSpaces = (str) => str.replace(/ /g, "");

    if (Object.keys(state.exhibitionSubjects.pages).length > 0) {
        const archives = state.exhibitionSubjects.pages.archived;
        matchedPage = archives.find((page) => removeSpaces(page.alias) === pageAlias);
    }

    return {
        archive: matchedPage,
    };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ExhibitionSubjectsArchive);
