import { connect } from "react-redux";
import { exhibitionEntry } from "../../../../services/requests";
import ExhibitionEntriesPages from "../../../../components/admin/pages/entries/Overview";

const mapStateToProps = (state) => ({
    pages: state.exhibitionEntries.pages,
});

const mapDispatchToProps = (dispatch) => ({
    getExhibitionEntryPages: () => dispatch(exhibitionEntry.getPages()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExhibitionEntriesPages);
