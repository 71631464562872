const Content = {
    navbar: {
        title: "Muzej Budućnosti: Administracija",
    },
    dashboard: {
        card1: {
            title: "Prijedlozi predmeta",
            text: "Pregled novih i postojećih prijedloga",
            icon: "chair",
            buttonLabel: "Pogledaj",
            badgeText: "Novih:",
            buttonLink: "/admin/predmeti",
            itemsCount: "entries",
        },
        card2: {
            title: "Ankete",
            text: "Pregled i editiranje anketa, pitanja i odgovora",
            icon: "poll",
            buttonLabel: "Pogledaj",
            buttonLink: "/admin/ankete",
        },
        card3: {
            title: "Prijedlozi programa",
            text: "Pregled novih i postojećih prijedloga programa",
            icon: "align-center",
            buttonLabel: "Pogledaj",
            badgeText: "Novih:",
            buttonLink: "/admin/programi",
            itemsCount: "subjects",
        },
        card4: {
            title: "Interaktivna mapa",
            text: "Pregled spremljenih pinova",
            icon: "map-marker-alt",
            buttonLabel: "Pogledaj",
            buttonLink: "/admin/mapa",
        },
        card5: {
            title: "Natječaji",
            text: "Teskstovi i datumi starih i aktualnih natječaja",
            icon: "quote-right",
            buttonLabel: "Pogledaj i izmjeni",
            buttonLink: "/admin/natjecaji",
        },
    },
    exhibitionEntries: {
        title: "Pregled prijedloga predmeta za natječaj",
        defaultActiveKey: "new",
        tabs: [
            { eventKey: "new", title: "Novi" },
            { eventKey: "approved", title: "Prihvaćeni" },
            { eventKey: "rejected", title: "Odbijeni" },
        ],
        newEntries: {
            userNameTitle: "Korisnik",
            dateTitle: "Datum",
            descriptionTitle: "Opis",
            contactUserTitle: "Kontaktiraj",
            approveButton: "Prihvati",
            rejectButton: "Odbij",
        },
        approvedEntries: {
            orderTitle: "#",
            dateTitle: "Datum",
            usernameTitle: "Korisnik",
            entryTitleTitle: "Naziv prijedloga",
            descriptionTitle: "Opis prijedloga",
            fileTitle: "Datoteka",
            actionTitle: "Akcije",
            votesTitle: "Broj glasova",
        },
    },
    exhibitionSubjects: {
        title: "Pregled prijedloga programa za natječaj",
        defaultActiveKey: "new",
        tabs: [
            { eventKey: "new", title: "Novi" },
            { eventKey: "approved", title: "Prihvaćeni" },
            { eventKey: "rejected", title: "Odbijeni" },
        ],
        newSubjects: {
            userNameTitle: "Korisnik",
            dateTitle: "Datum",
            descriptionTitle: "Opis",
            contactUserTitle: "Kontaktiraj",
            approveButton: "Prihvati",
            rejectButton: "Odbij",
        },
        approvedSubjects: {
            orderTitle: "#",
            dateTitle: "Datum",
            usernameTitle: "Korisnik",
            entryTitleTitle: "Naziv programa",
            descriptionTitle: "Opis programa",
            actionTitle: "Akcije",
            votesTitle: "Broj glasova",
        },
    },
    surveys: {
        title: "Ankete",
        surveyResults: {
            bigTitle: "Anketa",
            question: "Pitanje",
            answer: "Odgovor",
            numberOfVotes: "Broj odgovora",
            specialAnswersTitle: "Posebni odgovori",
            somethingElseAnswers: {
                showMoreButton: "Detaljnije",
                popup: {
                    title: "'Nešto drugo' odgovori",
                    answer: "Odgovor",
                    numberOfVotes: "Broj glasova",
                },
            },
        },
        card: {
            startDate: "Od: ",
            endDate: "Do: ",
            numberOfVotesTitle: "Broj glasova: ",
            buttonLabel: "Pogledaj odgovore",
            status: {
                active: "aktivna",
                inactive: "neaktivna",
            },
            statusChange: {
                tooltip: "Promijeni status ankete",
                popupQuestionStatic: "Jeste li sigurni da želite promijeniti status ove ankete na",
                popupExplanationActive: "Mijenjanje statusa na 'AKTIVNI' objavljuje anketu na websiteu.",
                popupExplanationInactive: "Mijenjanje statusa na 'NEAKTIVNI' sakriva anketu s websitea.",
                buttonClose: "Odustani",
                buttonApprove: "Promijeni status",
            },
        },
        addNew: {
            formElements: {
                title: "Naslov ankete",
                startDate: "Početak prikazivanja",
                endDate: "Kraj prikazivanja",
                questionTitle: "Naslov pitanja",
                addNewQuestion: "Dodaj pitanje",
                deleteQuestion: "Pobriši pitanje",
                addNewAnswer: "Dodaj odgovor",
                questionText: "Tekst pitanja",
                save: "Spremi anketu",
                giveUp: "Odustani",
                allowCustomAnswer: "Dopusti slobodne odgovore",
            },
        },
    },
    login: {
        validation: {
            tooShort: "Prekratko!",
            tooLong: "Predugo!",
            required: "Ovo polje je obavezno.",
        },
        placeholders: {
            username: "Upišite admin korisničko ime",
            password: "Upišite admin lozinku",
        },
        elements: {
            username: "Korisničko Ime",
            password: "Lozinka",
            button: "Ulaz",
        },
    },
    pages: {
        exhibitionEntries: {
            addNew: {
                title: "Dodavanje novog natječaja za prikupljanje predmeta",
                aliasTitle: "Interni naslov natječaja",
                aliasExplanation: "Na webu kao indikator Arhive, npr. '2020'",
                content: {
                    mainPageTextTitle: "Glavni tekst na stranici",
                },
                startDateTitle: "Datum početka natječaja",
                startDateExplanation: "Kada korisnici mogu početi slati prijedloge?",
                endDateTitle: "Datum kraja natječaja",
                submitButton: "Kreiraj",
                cancelButton: "Odustani",
            },
            edit: {
                title: "Editiranje postojećeg natječaja za prikupljanje predmeta",
                aliasTitle: "Interni naslov natječaja",
                aliasExplanation: "Na webu kao indikator Arhive, npr. '2020'",
                content: {
                    mainPageTextTitle: "Glavni tekst na stranici",
                },
                startDateTitle: "Datum početka natječaja",
                startDateExplanation: "Kada korisnici mogu početi slati prijedloge?",
                endDateTitle: "Datum kraja natječaja",
                submitButton: "Spremi",
                cancelButton: "Odustani",
            },
        },
        exhibitionSubjects: {
            addNew: {
                title: "Dodavanje novog natječaja za prikupljanje ideja za programe",
                aliasTitle: "Interni naslov natječaja",
                aliasExplanation: "Na webu kao indikator Arhive, npr. '2020'",
                content: {
                    mainPageTextTitle: "Glavni tekst na stranici",
                },
                startDateTitle: "Datum početka natječaja",
                startDateExplanation: "Kada korisnici mogu početi slati ideje?",
                endDateTitle: "Datum kraja natječaja",
                submitButton: "Kreiraj",
                cancelButton: "Odustani",
            },
            edit: {
                title: "Editiranje postojećeg natječaja za prikupljanje ideja za programe",
                aliasTitle: "Interni naslov natječaja",
                aliasExplanation: "Na webu kao indikator Arhive, npr. '2020'",
                content: {
                    mainPageTextTitle: "Glavni tekst na stranici",
                },
                startDateTitle: "Datum početka natječaja",
                startDateExplanation: "Kada korisnici mogu početi slati ideje?",
                endDateTitle: "Datum kraja natječaja",
                submitButton: "Spremi",
                cancelButton: "Odustani",
            },
        },
    },
};

export default Content;
