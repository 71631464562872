import React from "react";
import PropTypes from "prop-types";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SVGarrowRight from "../../../media/svgs/common/arrow-right";
import {
    sortEntriesByHighestRank, sortEntriesByLowesttRank, sortEntriesByNewestFirst,
} from "../../../helpers/sorting";

function Filters({ sortFn }) {
    const FILTERS = {
        NEWEST: { id: 1, text: "NAJNOVIJE", sortFunction: sortEntriesByNewestFirst },
        MOST_VOTES: { id: 2, text: "NAJVIŠE GLASOVA", sortFunction: sortEntriesByHighestRank },
        FEWEST_VOTES: { id: 3, text: "NAJMANJE GLASOVA", sortFunction: sortEntriesByLowesttRank },
    };

    const [activeFilter, setActiveFilter] = React.useState(FILTERS.NEWEST);

    function isActiveFilter(filterType) {
        return filterType.id === activeFilter.id;
    }

    function onSortClicked(filterType) {
        sortFn(filterType.sortFunction);
        setActiveFilter(filterType);
    }

    return (
        <Row>
            <Col style={{ paddingLeft: "0" }}>
                <div className="custom-filtering-wrapper">
                    <span>
                        FILTRIRAJ PREDMETE PO:
                    </span>
                    <button
                        type="button"
                        className="no-style-button"
                        onClick={() => onSortClicked(FILTERS.MOST_VOTES)}
                    >
                        <div className="custom-filtering-button-text-wrapper">
                            <div className={isActiveFilter(FILTERS.MOST_VOTES) ? "custom-filter active" : "custom-filter "}>
                                {FILTERS.MOST_VOTES.text}
                            </div>
                            <SVGarrowRight />
                        </div>
                    </button>
                    <button
                        type="button"
                        className="no-style-button"
                        onClick={() => onSortClicked(FILTERS.FEWEST_VOTES)}
                    >
                        <div className="custom-filtering-button-text-wrapper">
                            <div className={isActiveFilter(FILTERS.FEWEST_VOTES) ? "custom-filter active" : "custom-filter"}>
                                {FILTERS.FEWEST_VOTES.text}
                            </div>
                            <SVGarrowRight />
                        </div>
                    </button>
                    <button
                        type="button"
                        className="no-style-button"
                        onClick={() => onSortClicked(FILTERS.NEWEST)}
                    >
                        <div className="custom-filtering-button-text-wrapper">
                            <div className={isActiveFilter(FILTERS.NEWEST) ? "custom-filter active" : "custom-filter"}>
                                {FILTERS.NEWEST.text}
                            </div>
                            <SVGarrowRight />
                        </div>
                    </button>
                </div>
            </Col>
        </Row>
    );
}

export default Filters;

Filters.propTypes = {
    sortFn: PropTypes.func.isRequired,
};
