import React from "react";

function SVG() {
    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="1347.854px"
            height="1347.804px"
            viewBox="0 0 947.854 947.804"
            enableBackground="new 0 0 947.854 947.804"
        >
            <switch>
                <g>
                    <path fill="#5C72B4" d="M402.83,529.572L644.778,771.52l-104.685-11.199l-3.602,179.195l411.362,8.288l-8.335-411.336 l-179.201,3.652l11.216,104.675L529.57,402.832c-42.791-42.791-42.791-112.411-0.002-155.201L650.46,126.737L523.722,0 L402.83,120.894c-2.961,2.961-5.801,5.996-8.607,9.053L129.947,394.224c-3.057,2.805-6.094,5.646-9.055,8.605L0,523.722 L126.738,650.46L247.63,529.568C290.417,486.781,360.039,486.781,402.83,529.572" />
                </g>
            </switch>
        </svg>
    );
}

export default SVG;
