import React from "react";
import PropTypes from "prop-types";
import { HistoryPropType, PagesPropType } from "../../../../customPropTypes";
import PagesOverview, { POSSIBLE_ACTIVE_PAGES } from "../common/PagesOverview";

function ExhibitionSubjectsPages({ history, pages, getExhibitionSubjectPages }) {
    React.useEffect(() => {
        getExhibitionSubjectPages();
    }, [getExhibitionSubjectPages]);

    return (
        <PagesOverview history={history} pages={pages} activePage={POSSIBLE_ACTIVE_PAGES.SUBJECTS} />
    );
}

export default ExhibitionSubjectsPages;

ExhibitionSubjectsPages.propTypes = {
    history: HistoryPropType.isRequired,
    pages: PropTypes.shape(),
    getExhibitionSubjectPages: PropTypes.func.isRequired,
};

ExhibitionSubjectsPages.defaultProps = {
    pages: {},
};
