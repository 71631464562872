import { connect } from "react-redux";
import AdminSurveyResults from "../../../components/admin/survey/AdminSurveyResults";

const mapStateToProps = (state, ownProps) => {
    const surveyId = parseInt(ownProps.match.params.id, 10);
    const currentSurvey = state.surveys.find((current) => current.id === surveyId);

    return {
        survey: currentSurvey,
    };
};

export default connect(mapStateToProps)(AdminSurveyResults);
