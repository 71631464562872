import { connect } from "react-redux";
import { exhibitionSubject } from "../../services/requests";
import ExhibitionSubject from "../../components/website/exhibition-subjects/ExhibitionSubject";

const mapStateToProps = (state, ownProps) => {
    const subjectId = parseInt(ownProps.match.params.id, 10);
    const currentSubject = state.exhibitionSubjects.approved.find((current) => current.id === subjectId);

    return {
        subject: currentSubject,
        canVote: state.voting.exhibitionSubjects[subjectId],
    };
};

const mapDispatchToProps = (dispatch) => ({
    voteSubject: (id) => dispatch(exhibitionSubject.vote(id)),
    unvoteSubject: (id) => dispatch(exhibitionSubject.unvote(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExhibitionSubject);
