import {
    groupEntriesByStatus, removeFromList, addToList, updatePages, removePage,
} from "../../helpers/lists";

const defaultState = {
    new: [],
    approved: [],
    rejected: [],
    pages: {},
};

export default (state = defaultState, action) => {
    switch (action.type) {
    case "APPROVE_ENTRY":
        return {
            ...state,
            new: removeFromList(state.new, action.payload.id),
            approved: addToList(state.approved, state.new, action.payload.id),
        };
    case "APPROVE_REJECTED_ENTRY":
        return {
            ...state,
            approved: addToList(state.approved, state.rejected, action.payload.id),
            rejected: removeFromList(state.rejected, action.payload.id),
        };
    case "REJECT_ENTRY":
        return {
            ...state,
            new: removeFromList(state.new, action.payload.id),
            rejected: addToList(state.rejected, state.new, action.payload.id),
        };
    case "REJECT_APPROVED_ENTRY":
        return {
            ...state,
            rejected: addToList(state.rejected, state.approved, action.payload.id),
            approved: removeFromList(state.approved, action.payload.id),
        };
    case "FETCH_ENTRIES":
        return {
            ...state,
            ...groupEntriesByStatus(action.payload),
        };
    case "FETCH_APPROVED_ENTRIES":
        return {
            ...state,
            approved: action.payload,
        };
    case "SORT_ENTRIES":
        return {
            ...state,
            approved: action.payload,
        };
    case "FETCH_PAGES":
        return {
            ...state,
            pages: action.payload,
        };
    case "UPDATE_ENTRIES_PAGE":
        return {
            ...state,
            pages: updatePages(state.pages, action.payload),
        };
    case "DELETE_PAGE":
        return {
            ...state,
            pages: removePage(state.pages, action.payload),
        };
    default:
        return state;
    }
};
