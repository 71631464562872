import React from "react";

function SVG() {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="35px" height="35px" viewBox="0 0 35 35" enableBackground="new 0 0 35 35">
            <path fill="#FFFFFF" d="M17.5,35C27.165,35,35,27.165,35,17.5S27.165,0,17.5,0S0,7.835,0,17.5S7.835,35,17.5,35" />
            <path fill="none" stroke="#1B1C20" d="M20.682,17.5l5.685-5.685c0.879-0.879,0.879-2.303,0-3.182s-2.303-0.879-3.182,0L17.5,14.318 l-5.684-5.685c-0.879-0.879-2.303-0.879-3.182,0s-0.879,2.303,0,3.182l5.684,5.685l-5.684,5.684c-0.879,0.879-0.879,2.303,0,3.182 c0.439,0.439,1.015,0.659,1.591,0.659s1.151-0.22,1.591-0.659l5.684-5.684l5.685,5.684c0.439,0.439,1.015,0.659,1.591,0.659 s1.151-0.22,1.591-0.659c0.879-0.879,0.879-2.303,0-3.182L20.682,17.5z" />
        </svg>
    );
}

export default SVG;
