import { connect } from "react-redux";
import SubjectVoteButton from "../../components/website/exhibition-subjects/SubjectVoteButton";

const mapStateToProps = (state) => ({
    votingData: state.voting.exhibitionSubjects,
    extraSettings: state.extraSettings,
});

const mapDispatchToProps = () => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(SubjectVoteButton);
