import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import SVGarrowEnlarge from "../../../media/svgs/common/arrow-enlarge";
import SVGarrowEnlargeHover from "../../../media/svgs/common/arrow-enlarge-hover";

function EnlargeEntryButton({ entryId }) {
    const [isButtonHovered, setIsButtonHovered] = React.useState(false);

    return (
        <Link
            to={`/prijedlozi/prijedlog${entryId}`}
            onMouseEnter={() => setIsButtonHovered(true)}
            onMouseLeave={() => setIsButtonHovered(false)}
        >
            <div className="custom-exhibition-entry-card-arrow-enlarge">
                {isButtonHovered ? <SVGarrowEnlargeHover /> : <SVGarrowEnlarge />}
            </div>
        </Link>
    );
}

export default EnlargeEntryButton;

EnlargeEntryButton.propTypes = {
    entryId: PropTypes.number.isRequired,
};
