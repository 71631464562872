import React from "react";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import SVGarrowDown from "../../../media/svgs/common/arrow-down";
import SVGarrowUp from "../../../media/svgs/common/arrow-up";

function InfoAccordion() {
    const [accordionCollapsed, setAccordionCollapsed] = React.useState(false);

    return (
        <Accordion className="custom-map-accordion" defaultActiveKey={null}>
            <Card>
                <Accordion.Toggle
                    as={Card.Header}
                    eventKey="1"
                    onClick={() => setAccordionCollapsed(!accordionCollapsed)}
                >
                    <span>Kako podijeliti doživljaj?</span>
                    <span>{accordionCollapsed ? <SVGarrowUp /> : <SVGarrowDown />}</span>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="1">
                    <Card.Body>
                        <div>
                            Označite mjesto na karti i u nekoliko riječi podijelite s nama svoj doživljaj. Zanima nas saznati, ali i označiti ona mjesta našeg kraja koja imaju potencijal trgnuti nas iz svakodnevice i oplemeniti nam trenutak. Radujemo se istražiti našu sposobnost pronalaska ljepote, nadahnuća ili iznenađenja na najmanje očekivanim mjestima.
                        </div>
                        <div>
                            Tko zna, možda pritom označimo i neki baštinski objekt.
                        </div>
                        <div>
                            A možda i povežemo označena mjesta u sasvim neobične i individualizirane tematske puteve.
                        </div>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    );
}

export default InfoAccordion;

