import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { withFormik } from "formik";
import validationSchemas from "../../../helpers/validation";
import { extractFileName } from "../../../helpers/files";
import CustomFormLabel from "../../common/CustomFormLabel";
import NewPinMap, { RIJEKA_CENTER_LOCATION } from "./NewPinMap";
import ButtonRemove from "../../common/ButtonRemove";
import SendFormButton from "../SendFormButton";
import GiveUpButton from "../GiveUpButton";

export function NewMapPinPopup({
    show, onHideCallback, handleSubmit, touched, onUpload, onDeleteFile,
    handleChange, values, errors, handleBlur, status, setFieldValue, setStatus,
    handleReset,
}) {
    const [uploadedSuccessfully, setUploadedSuccessfully] = React.useState(false);
    const [contentUrl, setContentUrl] = React.useState(null);
    const [fileType, setFileType] = React.useState("");
    const [uploadErrorMessage, setUploadErrorMessage] = React.useState(null);

    const isSubmitButtonDisabled = () => ((Object.keys(touched).length === 0) ||
        (Object.keys(errors).length !== 0) ||
        (status && status.type === "error"));

    const onDropRejected = useCallback(() => {
        setUploadErrorMessage("Vaša datoteka je prevelika.");
    }, []);

    const onDropAccepted = useCallback((acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            setUploadedSuccessfully(true);
            setUploadErrorMessage(null);

            const acceptedFile = acceptedFiles[0];
            switch (acceptedFile.type) {
            case "image/png":
                setFileType("image");
                break;
            case "image/jpeg":
                setFileType("image");
                break;
            default:
                break;
            }

            const successCallback = (response) => {
                setContentUrl(response.url);
                setFieldValue("contentUrl", response.url);
            };

            const failureCallback = () => {
                acceptedFiles.splice(0, acceptedFiles.length);
                setContentUrl(null);
                setFieldValue("contentUrl", "");
            };

            onUpload(acceptedFile, successCallback, setStatus, failureCallback);
        }
    }, [setFieldValue, onUpload, setStatus]);

    const {
        acceptedFiles, getRootProps, getInputProps, isDragActive,
    } = useDropzone({
        onDropAccepted, onDropRejected, accept: "image/*", maxSize: 5000000,
    });

    const removeAllFiles = () => {
        if (contentUrl) {
            const fileName = extractFileName(contentUrl);

            acceptedFiles.length = 0;
            acceptedFiles.splice(0, acceptedFiles.length);
            onDeleteFile(fileName);
            setContentUrl(null);
            setFieldValue("contentUrl", "");
        }
    };

    const onHideForm = () => {
        onHideCallback();
        handleReset();
    };

    const showPreview = () => {
        if (fileType === "image") {
            return (
                <img src={contentUrl} alt="" className="upload-preview-image" />
            );
        }

        return <div>Preview datotete nemoguć.</div>;
    };

    const updateLocation = (latitude, longitude) => {
        setFieldValue("latitude", latitude);
        setFieldValue("longitude", longitude);
    };

    const dragDropClassName = "drag-drop";
    const modalTitle = (status && status.type === "success") ? "Vaš doživljaj je spremljen!" : "PODIJELI DOŽIVLJAJ";

    return (
        <Modal show={show} centered onHide={onHideForm} size="lg" dialogClassName="custom-entry-addnew-modal">
            <Modal.Header closeButton>
                <Modal.Title>{modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {status && status.type === "success" ?
                    (
                        <div className="custom-add-entry-success">
                            <div>
                                Hvala na sudjelovanju!
                            </div>
                        </div>
                    ) :
                    (
                        <Form onSubmit={handleSubmit}>
                            <Form.Group as={Row} controlId="formTitle">
                                <CustomFormLabel text="Koji doživljaj želite podijeliti?" disclaimer="40 maks. broj znakova" />
                                <Col sm="12" md="9">
                                    <Form.Control
                                        type="text"
                                        name="title"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.title}
                                        isInvalid={touched.title && !!errors.title}
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formDescription">
                                <CustomFormLabel text="Opis doživljaja" disclaimer="400 maks. broj znakova" customStyle={{ top: "-20px" }} />
                                <Col sm="12" md="9">
                                    <Form.Control
                                        as="textarea"
                                        type="text"
                                        name="description"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.description}
                                        isInvalid={touched.description && !!errors.description}
                                        rows="5"
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formLocation">
                                <CustomFormLabel text="Označite lokaciju" customStyle={{ alignSelf: "flex-start" }} />
                                <Col sm="12" md="9" style={{ width: "400px" }}>
                                    <NewPinMap onMarkerChanged={updateLocation} />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <CustomFormLabel text="Datoteka" disclaimer="Maks. veličina fotografije: 5MB" customStyle={{ alignSelf: "flex-start", marginTop: "-15px" }} />
                                <Col sm="12" md="9">
                                    {uploadedSuccessfully && acceptedFiles.length > 0 && contentUrl && (
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            {showPreview()}
                                            <ButtonRemove onClickCallback={removeAllFiles} />
                                        </div>
                                    )}
                                    {(acceptedFiles.length === 0 || (status && status.type === "error")) && (
                                        <div {...getRootProps()}>
                                            <input id="test-upload" {...getInputProps()} />
                                            {
                                                isDragActive ?
                                                    <p className={dragDropClassName}>POVUCI-SPUSTI OPCIJA / UPLOAD DATOTEKE</p> :
                                                    <p className={dragDropClassName}>POVUCI-SPUSTI OPCIJA / UPLOAD DATOTEKE</p>
                                            }
                                            <div className="custom-error-message">
                                                {errors.contentUrl}
                                            </div>
                                        </div>
                                    )}
                                    {uploadErrorMessage && <em className="text-danger">{uploadErrorMessage}</em>}
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formMediaOwning" style={{ marginTop: "20px", marginBottom: "50px" }}>
                                <Form.Check
                                    label="Autor sam fotografije, ili imam izričito dopuštenje od autora (onoga tko posjeduje autorska prava) za njeno korištenje."
                                    type="checkbox"
                                    id="media-owning-checkbox"
                                    name="mediaOwningAgree"
                                    value={values.mediaOwningAgree}
                                    onChange={handleChange}
                                    isInvalid={touched.mediaOwningAgree && !!errors.mediaOwningAgree}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.mediaOwningAgree}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <div className="custom-form-action-buttons">
                                <SendFormButton disabled={isSubmitButtonDisabled()} />
                                <GiveUpButton onClickCallback={onHideForm} />
                            </div>
                        </Form>
                    )}
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>
    );
}

export const handleSubmit = (values, { props, setStatus }) => {
    props.onSubmit(values, setStatus);
};

export default withFormik({
    mapPropsToValues: () => ({
        title: "",
        description: "",
        contentUrl: "",
        mediaOwningAgree: false,
        latitude: RIJEKA_CENTER_LOCATION.latitude,
        longitude: RIJEKA_CENTER_LOCATION.longitude,
    }),
    validationSchema: validationSchemas.addNewMapPin,
    handleSubmit,
})(NewMapPinPopup);

NewMapPinPopup.propTypes = {
    show: PropTypes.bool.isRequired,
    onHideCallback: PropTypes.func.isRequired,
    values: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        contentUrl: PropTypes.string,
        mediaOwningAgree: PropTypes.bool,
    }).isRequired,
    errors: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        mediaOwningAgree: PropTypes.string,
    }).isRequired,
    touched: PropTypes.shape({
        title: PropTypes.bool,
        description: PropTypes.bool,
        contentUrl: PropTypes.bool,
        mediaOwningAgree: PropTypes.bool,
    }).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    status: PropTypes.shape({
        type: PropTypes.string,
        message: PropTypes.string,
    }),
    handleBlur: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setStatus: PropTypes.func.isRequired,
    onUpload: PropTypes.func.isRequired,
    onDeleteFile: PropTypes.func.isRequired,
    handleReset: PropTypes.func.isRequired,
};

NewMapPinPopup.defaultProps = {
    status: null,
};
