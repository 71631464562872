import React from "react";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

function AdminButtonRemove({ onClickCallback }) {
    return (
        <Col sm="1" className="text-left">
            <Button
                variant="danger"
                onClick={onClickCallback}
                className="admin-button-remove"
            >
                -
            </Button>
        </Col>
    );
}

export default AdminButtonRemove;

AdminButtonRemove.propTypes = {
    onClickCallback: PropTypes.func,
};

AdminButtonRemove.defaultProps = {
    onClickCallback: () => {},
};
