import { connect } from "react-redux";
import App from "../../components/website/App";
import { exhibitionEntry, extraSettings, map, exhibitionSubject } from "../../services/requests";
import { sortData, sortSubjects } from "../../helpers/sorting";

const mapStateToProps = (state) => ({
    approvedEntries: state.exhibitionEntries.approved,
    extraSettings: state.extraSettings,
    customPins: state.mapPins,
    approvedSubjects: state.exhibitionSubjects.approved,
});

const mapDispatchToProps = (dispatch) => ({
    getExtraSettingsBackgroundsData: () => dispatch(extraSettings.getBackgroundsData()),
    getFontSizeData: () => dispatch(extraSettings.getFontSizeData()),

    voteEntry: (id) => dispatch(exhibitionEntry.vote(id)),
    unvoteEntry: (id) => dispatch(exhibitionEntry.unvote(id)),
    addNewEntry: (values, setStatusCallback) => dispatch(exhibitionEntry.addNew(values, setStatusCallback)),
    sortEntries: (sortFn) => dispatch(sortData(sortFn)),
    getExhibitionEntryPages: () => dispatch(exhibitionEntry.getPages()),

    addNewMapPin: (values, setStatusCallback) => dispatch(map.addNew(values, setStatusCallback)),
    getAllMapPins: () => dispatch(map.getAll()),

    voteSubject: (id) => dispatch(exhibitionSubject.vote(id)),
    unvoteSubject: (id) => dispatch(exhibitionSubject.unvote(id)),
    addNewSubject: (values, setStatusCallback) => dispatch(exhibitionSubject.addNew(values, setStatusCallback)),
    getExhibitionSubjects: () => dispatch(exhibitionSubject.getAll()),
    sortSubjects: (sortFn) => dispatch(sortSubjects(sortFn)),
    getExhibitionSubjectPages: () => dispatch(exhibitionSubject.getPages()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
