import React from "react";
import PropTypes from "prop-types";
import Card from "react-bootstrap/Card";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Content from "../../../content/AdminTextContent";
import CroatianDateFormat from "../../common/CroatianDateFormat";
import { checkImageURL, checkVideoURL, checkAudioURL } from "../../../helpers/files";

function AdminExhibitionNewEntry({
    entry, order, totalEntriesNumber,
    onNextEntryClicked, onPreviousEntryClicked, onApproveClicked, onRejectClicked,
}) {
    return (
        <Card>
            <Card.Body className="admin-exhibition-new-wrapper">
                {checkImageURL(entry.contentUrl) && (
                    <Card.Img src={entry.contentUrl} className="admin-exhibition-new-image" />
                )}
                {checkVideoURL(entry.contentUrl) && (
                    <video controls className="admin-exhibition-new-video">
                        <source src={entry.contentUrl} type="video/mp4" />
                        <source src={entry.contentUrl} type="video/webm" />
                        <track default kind="captions" />
                    </video>
                )}
                {checkAudioURL(entry.contentUrl) && (
                    <audio controls>
                        <source src={entry.contentUrl} />
                        <track default kind="captions" />
                        Your browser does not support the audio element.
                    </audio>
                )}
                <Card.Body className="admin-exhibition-new-content">
                    <div className="admin-exhibition-new-header">
                        <h3 className="admin-exhibition-new-numberIndicator">
                            {`${order}/${totalEntriesNumber}`}
                        </h3>
                        { order > 1 ? (
                            <button
                                type="button"
                                onClick={onPreviousEntryClicked}
                                id="exhibition-entry-new-button-previous"
                                className="exhibition-entry-new-button no-style-button"
                            >
                                <i className="fas fa-arrow-circle-left" />
                            </button>
                        ) : null }
                        { order < totalEntriesNumber ? (
                            <button
                                type="button"
                                onClick={onNextEntryClicked}
                                id="exhibition-entry-new-button-next"
                                className="exhibition-entry-new-button no-style-button"
                            >
                                <i className="fas fa-arrow-circle-right" />
                            </button>
                        ) : null }
                    </div>
                    <h2 className="admin-exhibition-new-title">{entry.title}</h2>
                    <div className="admin-exhibition-new-data-wrapper">
                        <div
                            className="admin-exhibition-new-data-row"
                            id="exhibition-entry-new-username"
                        >
                            <h5>{Content.exhibitionEntries.newEntries.userNameTitle}</h5>
                            <p>{entry.userName}</p>
                            <a href={`mailto:${entry.userEmail}`} className="admin-exhibition-new-contact-badge">
                                <Badge variant="warning">
                                    {Content.exhibitionEntries.newEntries.contactUserTitle}
                                </Badge>
                            </a>
                        </div>
                        <div
                            className="admin-exhibition-new-data-row"
                            id="exhibition-entry-new-date"
                        >
                            <h5>{Content.exhibitionEntries.newEntries.dateTitle}</h5>
                            <CroatianDateFormat date={entry.created} />
                        </div>
                        <div
                            className="admin-exhibition-new-data-row"
                            id="exhibition-entry-new-description"
                        >
                            <h5>{Content.exhibitionEntries.newEntries.descriptionTitle}</h5>
                            <p>{entry.description}</p>
                        </div>
                        <div className="admin-exhibition-new-data-row admin-exhibition-new-buttons">
                            <Button
                                variant="success"
                                id="exhibition-entry-button-accept"
                                onClick={() => onApproveClicked(entry)}
                            >
                                {Content.exhibitionEntries.newEntries.approveButton}
                            </Button>
                            <Button
                                variant="danger"
                                id="exhibition-entry-button-reject"
                                onClick={() => onRejectClicked(entry)}
                            >
                                {Content.exhibitionEntries.newEntries.rejectButton}
                            </Button>
                        </div>
                    </div>
                </Card.Body>
            </Card.Body>
        </Card>
    );
}

export default AdminExhibitionNewEntry;

AdminExhibitionNewEntry.propTypes = {
    entry: PropTypes.shape({
        contentUrl: PropTypes.string,
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        userEmail: PropTypes.string,
        userName: PropTypes.string,
        description: PropTypes.string,
        created: PropTypes.string,
    }).isRequired,
    totalEntriesNumber: PropTypes.number.isRequired,
    onNextEntryClicked: PropTypes.func.isRequired,
    onPreviousEntryClicked: PropTypes.func.isRequired,
    onApproveClicked: PropTypes.func.isRequired,
    onRejectClicked: PropTypes.func.isRequired,
    order: PropTypes.number.isRequired,
};
