import React from "react";
import PropTypes from "prop-types";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { ExhibitionEntryPropType, HistoryPropType } from "../../../../customPropTypes";
import AdminSidebar from "../../AdminSidebar";
import Content from "../../../../content/AdminTextContent";
import AdminExhibitionEntriesList from "../../exhibition/AdminExhibitionEntriesList";
import { EXHIBITION_ENTRY_STATUS } from "../../../../helpers/constants";
import GoBackButton from "../../common/GoBackButton";

function ArchivedItems({ history, entries, getExhibitionEntryPages }) {
    React.useEffect(() => {
        getExhibitionEntryPages();
    }, [getExhibitionEntryPages]);

    const approvedEntries = entries && entries.reduce((accum, current) => {
        if (current.status === EXHIBITION_ENTRY_STATUS.APPROVED) {
            return [...accum, current];
        }
        return accum;
    }, []);

    const rejectedEntries = entries && entries.reduce((accum, current) => {
        if (current.status === EXHIBITION_ENTRY_STATUS.REJECTED) {
            return [...accum, current];
        }
        return accum;
    }, []);

    return (
        <div>
            <AdminSidebar history={history} />
            <GoBackButton onClickCallback={() => history.push("/admin/natjecaji/predmeti/arhivirani")} />
            <h3 className="admin-exhibition-title">
                Pregled arhiviranih natječaja za prikupljanje predmeta: lista predmeta
            </h3>
            <Tabs defaultActiveKey="approved" id="uncontrolled-tab-example">
                <Tab eventKey="approved" title="Prihvaćeni">
                    {approvedEntries && approvedEntries.length > 0 ?
                        (
                            <AdminExhibitionEntriesList data={approvedEntries} />
                        ) : null
                    }
                </Tab>
                <Tab eventKey="rejected" title="Odbijeni">
                    {rejectedEntries && rejectedEntries.length > 0 ?
                        (
                            <AdminExhibitionEntriesList data={rejectedEntries} />
                        ) : null
                    }
                </Tab>
            </Tabs>
        </div>
    );
}

export default ArchivedItems;

ArchivedItems.propTypes = {
    history: HistoryPropType.isRequired,
    getExhibitionEntryPages: PropTypes.func.isRequired,
    entries: PropTypes.arrayOf(ExhibitionEntryPropType).isRequired,
};
