const exhibitionActions = {
    approveEntryById: (id) => (dispatch) => {
        dispatch({
            type: "APPROVE_ENTRY",
            payload: { id },
        });
    },
    approveRejectedEntryById: (id) => (dispatch) => {
        dispatch({
            type: "APPROVE_REJECTED_ENTRY",
            payload: { id },
        });
    },
    rejectEntryById: (id) => (dispatch) => {
        dispatch({
            type: "REJECT_ENTRY",
            payload: { id },
        });
    },
    rejectApprovedEntryById: (id) => (dispatch) => {
        dispatch({
            type: "REJECT_APPROVED_ENTRY",
            payload: { id },
        });
    },
    fetchEntriesSuccess: (entries) => (dispatch) => {
        dispatch({
            type: "FETCH_ENTRIES",
            payload: [...entries],
        });
    },
    fetchApprovedEntriesSuccess: (entries) => (dispatch) => {
        dispatch({
            type: "FETCH_APPROVED_ENTRIES",
            payload: [...entries],
        });
    },
    sortEntries: (entries) => (dispatch) => {
        dispatch({
            type: "SORT_ENTRIES",
            payload: entries,
        });
    },
    fetchPagesSuccess: (pages) => (dispatch) => {
        dispatch({
            type: "FETCH_PAGES",
            payload: pages,
        });
    },
    updatePage: (page) => (dispatch) => {
        dispatch({
            type: "UPDATE_ENTRIES_PAGE",
            payload: page,
        });
    },
    deletePage: (pageId) => (dispatch) => {
        dispatch({
            type: "DELETE_PAGE",
            payload: pageId,
        });
    },
};

export default exhibitionActions;
