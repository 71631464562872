const defaultState = {};

export default (state = defaultState, action) => {
    switch (action.type) {
    case "FETCH_ARCHIVE":
        debugger;
        return {
            ...state,
            [action.payload.alias]: action.payload,
        };
    default:
        return state;
    }
};
