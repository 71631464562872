/* eslint max-len: 0 */
import React from "react";
import PropTypes from "prop-types";

const ORIGINAL_WIDTH = 338.241;
const ORIGINAL_HEIGHT = 558.116;

function SVG({ scale }) {
    const width = ORIGINAL_WIDTH * scale;
    const height = ORIGINAL_HEIGHT * scale;
    const widthString = `${width}px`;
    const heightString = `${height}px`;

    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width={widthString}
            height={heightString}
            viewBox={`0 0 ${ORIGINAL_WIDTH} ${ORIGINAL_HEIGHT}`}
            enableBackground={`new 0 0 ${ORIGINAL_WIDTH} ${ORIGINAL_HEIGHT}`}
        >
            <path fill="#6EBF62" d="M80.925,540.211c31.02,25.325,84.03,24.379,109.351-6.645c12.119-14.848,17.358-33.048,16.146-50.772 c-0.278-17.618-1.324-71.43-4.146-111.958c-6.335-90.992,0.923-285.828,0.892-240.74c-0.036,48.672,41.322,106.624,41.322,106.624 l0,0c4.254,6.853,8.11,12.291,14.171,17.241c20.858,17.023,51.568,13.921,68.595-6.928c17.023-20.859,13.915-51.573-6.942-68.598 c-3.961-3.234-8.275-5.735-12.785-7.532c-15.194-8.569-56.37-33.41-76.467-61.46C205.439,73.704,197.783,0,197.783,0 s-31.93,70.733-60.49,95.839c-28.545,25.093-98.998,37.33-98.998,37.33l0.074,0.241c-10.398,2.259-20.133,7.906-27.38,16.79 c-17.03,20.855-13.925,51.562,6.935,68.588c17.344,14.16,41.498,14.384,58.935,2.062c10.846-6.118,29.039-18.045,41.987-35.208 c9.544-12.657,24.188-39.628,36.698-63.953c-34.122,110.957-98.001,340.073-98.001,340.073l0.239,0.039 C48.891,489.383,57.127,520.789,80.925,540.211z" />
        </svg>
    );
}

export default SVG;

SVG.propTypes = {
    scale: PropTypes.number,
};

SVG.defaultProps = {
    scale: 1,
};
