import React from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function ApproveButton({ onApproveCallbabck, entryTitle }) {
    const [showModal, setShowModal] = React.useState(false);

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    return (
        <div>
            <Button
                variant="success"
                id="exhibition-entry-button-accept"
                onClick={handleShow}
            >
                Prihvati
            </Button>
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{entryTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Jeste li sigurni da želite promijeniti status na &quot;PRIHVAĆEN&quot;?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Odustani
                    </Button>
                    <Button variant="primary" onClick={onApproveCallbabck}>
                        Promijeni
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default ApproveButton;

ApproveButton.propTypes = {
    onApproveCallbabck: PropTypes.func.isRequired,
    entryTitle: PropTypes.string.isRequired,
};
