import React from "react";

function SVG() {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="500px"
            height="684.633"
            viewBox="0 0 628.017 859.899"
            enableBackground="new 0 0 628.017 859.899"
        >
            <switch>
                <g>
                    <g>
                        <circle fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" cx="348.85" cy="507.481" r="103.333" />
                        <circle fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" cx="348.85" cy="507.481" r="96.333" />
                        <circle fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" cx="402.35" cy="651.649" r="50.5" />
                        <circle fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" cx="372.616" cy="723.915" r="27.433" />
                        <circle fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" cx="322.518" cy="166.677" r="31.5" />
                        <circle fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" cx="411.684" cy="312.649" r="39.5" />
                        <circle fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" cx="87.351" cy="701.982" r="39.5" />
                        <circle fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" cx="335.684" cy="376.149" r="28" />
                        <circle fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" cx="482.518" cy="416.015" r="25.333" />
                        <path fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" d="M36.162,307.856c0,0-21.733,47.127,0,47.127 C57.783,354.983,36.162,307.856,36.162,307.856z" />
                        <path fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" d="M439.642,711.669c0,0-21.732,47.127,0,47.127 C461.264,758.796,439.642,711.669,439.642,711.669z" />
                        <path fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" d="M109.354,317.024c0,0-58.165,126.125,0,126.125 C167.223,443.149,109.354,317.024,109.354,317.024z" />
                        <path fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" d="M39.842,412.036c0,0-48.014,104.113,0,104.113 C87.611,516.149,39.842,412.036,39.842,412.036z" />
                        <path fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" d="M79.816,340.036c0,0-119.957,260.113,0,260.113 C199.162,600.149,79.816,340.036,79.816,340.036z" />
                        <rect x="1.517" y="600.149" fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" width="255" height="62" />
                        <polyline fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" points="256.517,553.815 256.517,600.149 169.517,600.149 169.517,224.149 256.517,224.149 256.517,459.649" />
                        <polygon fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" points="428.101,246.649 256.767,246.649 256.517,224.149 428.101,224.149" />
                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="315.767" y1="197.899" x2="315.767" y2="222.899" />
                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="315.767" y1="247.399" x2="315.767" y2="312.149" />
                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="332.767" y1="196.899" x2="332.767" y2="224.899" />

                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="332.767" y1="247.399" x2="332.767" y2="298.899" />
                        <polyline fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" points="560.017,380.483 560.017,338.815 539.517,308.815 493.017,308.815 473.85,339.483 519.517,339.353 539.517,308.815" />

                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="481.518" y1="339.353" x2="501.518" y2="308.815" />
                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="491.018" y1="339.353" x2="511.018" y2="308.815" />
                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="500.518" y1="339.353" x2="520.518" y2="308.815" />
                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="510.018" y1="339.353" x2="530.018" y2="308.815" />
                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="256.184" y1="637.481" x2="295.184" y2="696.147" />
                        <polyline fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" points="383.386,223.671 394.459,184.921 445.459,184.921 472.626,341.087" />
                        <polyline fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" points="295.517,419.149 295.517,327.149 370.517,269.149 376.749,247.333" />
                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="295.517" y1="780.399" x2="295.517" y2="595.481" />

                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="519.517" y1="339.353" x2="519.517" y2="507.149" />
                        <polyline fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" points="560.017,451.149 559.454,507.962 463.954,507.962 463.954,735.815 493.267,735.815" />
                        <polyline fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" points="493.017,780.147 362.184,780.147 348.017,780.147 256.517,780.147 256.517,662.483 166.6,662.483 166.6,701.147 166.6,802.483 238.017,802.483 343.392,802.483 353.538,802.483 353.538,821.843 373.716,821.843 373.716,840.235 393.892,840.235 393.892,858.255 430.017,858.255 439.893,858.255 439.893,840.235 419.174,840.235 419.174,820.696 398.453,820.696 398.453,800.815 377.765,800.815 377.765,779.649" />
                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="398.683" y1="800.815" x2="492.767" y2="800.815" />
                        <polyline fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" points="559.517,468.649 595.517,468.649 595.517,676.649 575.157,676.649" />
                        <polyline fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" points="559.517,480.149 584.517,480.149 584.517,666.149 573.767,666.149" />

                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="463.183" y1="611.149" x2="583.85" y2="611.149" />
                        <polyline fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" points="595.683,611.149 615.597,611.149 615.597,545.587 596.517,545.587" />
                        <polyline fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" points="584.017,545.587 539.854,545.69 539.854,508.583" />

                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="463.517" y1="623.149" x2="583.017" y2="623.149" />
                        <polyline fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" points="596.017,623.149 626.517,623.149 626.517,534.649 596.017,534.649" />
                        <polyline fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" points="584.85,534.649 550.853,534.649 550.853,508.147" />
                        <polyline fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" points="300.518,144.483 137.184,144.483 137.184,252.899 72.184,252.899 72.184,269.149 153.184,269.149 153.184,160.483 292.85,160.483" />
                        <polyline fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" points="535.184,185.009 498.851,185.009 498.851,271.983 460.684,271.983" />
                        <polyline fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" points="456.017,249.399 481.517,249.399 481.517,168.649 535.184,168.649" />

                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="256.517" y1="695.804" x2="295.517" y2="695.804" />

                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="389.85" y1="345.815" x2="464.916" y2="434.185" />

                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="446.517" y1="293.815" x2="503.533" y2="401.872" />

                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="474.328" y1="500.149" x2="474.328" y2="610.649" />

                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="491.828" y1="500.149" x2="491.828" y2="610.649" />

                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="473.328" y1="521.149" x2="491.828" y2="521.149" />

                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="473.328" y1="534.149" x2="491.828" y2="534.149" />

                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="473.328" y1="547.149" x2="491.828" y2="547.149" />

                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="473.328" y1="560.149" x2="491.828" y2="560.149" />

                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="473.328" y1="573.149" x2="491.828" y2="573.149" />

                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="473.328" y1="586.149" x2="491.828" y2="586.149" />

                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="473.328" y1="599.149" x2="491.828" y2="599.149" />
                        <polyline fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" points="435.517,162.649 435.517,184.149 404.517,184.149 404.517,162.649" />
                        <circle fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" cx="420.017" cy="118.149" r="48" />
                        <path fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" d="M500.185,592.813" />

                        <line fill="none" stroke="#231F20" strokeWidth="2.5992" strokeMiterlimit="10" x1="534.28" y1="560.593" x2="534.28" y2="568.101" />

                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="348.85" y1="516.981" x2="348.85" y2="603.815" />

                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="348.85" y1="410.649" x2="348.85" y2="497.565" />

                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="352.782" y1="516.724" x2="385.811" y2="596.464" />

                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="311.889" y1="418.003" x2="345.248" y2="498.536" />

                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="345.089" y1="516.312" x2="311.889" y2="596.464" />

                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="385.811" y1="418.003" x2="352.325" y2="498.841" />

                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="339.798" y1="510.981" x2="259.619" y2="544.192" />

                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="438.081" y1="470.271" x2="358.197" y2="503.358" />

                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="339.943" y1="503.542" x2="259.619" y2="470.271" />

                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="438.082" y1="544.192" x2="358.405" y2="511.19" />

                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="341.931" y1="514.065" x2="280.684" y2="575.315" />

                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="416.683" y1="439.315" x2="355.85" y2="500.149" />

                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="341.975" y1="500.606" x2="280.684" y2="439.315" />

                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="416.683" y1="575.315" x2="355.892" y2="514.524" />

                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="338.934" y1="507.149" x2="252.517" y2="507.149" />

                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="445.183" y1="507.149" x2="358.851" y2="507.149" />

                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="256.183" y1="553.815" x2="169.85" y2="553.815" />

                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="256.183" y1="460.149" x2="169.85" y2="460.149" />

                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="169.85" y1="506.815" x2="245.517" y2="506.815" />

                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="256.183" y1="366.649" x2="169.85" y2="366.649" />

                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="256.183" y1="413.315" x2="169.85" y2="413.315" />

                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="256.183" y1="272.149" x2="169.85" y2="272.149" />

                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="256.183" y1="318.815" x2="169.85" y2="318.815" />
                        <circle fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" cx="348.85" cy="507.481" r="9.5" />
                        <circle fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" cx="411.684" cy="312.649" r="7" />
                        <circle fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" cx="539.85" cy="344.349" r="7" />
                        <circle fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" cx="402.35" cy="651.649" r="7" />
                        <circle fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" cx="482.518" cy="416.015" r="5.375" />
                        <circle fill="none" stroke="#231F20" strokeWidth="2.6126" strokeMiterlimit="10" cx="372.616" cy="723.915" r="4.681" />
                        <circle fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" cx="87.351" cy="701.982" r="6.625" />
                        <path fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" d="M238.779,801.901 c-7.989,32.439-37.275,56.498-72.18,56.498c-41.053,0-74.333-33.279-74.333-74.334c0-41.053,33.28-74.332,74.333-74.332" />
                        <path fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" d="M233.576,801.911 c-7.872,29.623-34.875,51.453-66.977,51.453c-38.271,0-69.297-31.027-69.297-69.299s31.025-69.297,69.297-69.297" />
                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="166.6" y1="714.409" x2="166.6" y2="853.364" />

                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="174.249" y1="802.356" x2="193.188" y2="848.075" />

                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="140.012" y1="719.698" x2="163.767" y2="777.462" />

                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="163.767" y1="790.712" x2="140.012" y2="848.075" />

                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="159.267" y1="786.712" x2="102.412" y2="810.474" />

                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="159.392" y1="780.962" x2="102.412" y2="757.298" />

                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="230.789" y1="810.474" x2="211.506" y2="802.399" />

                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="161.142" y1="789.337" x2="117.564" y2="832.862" />

                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="161.142" y1="778.712" x2="117.564" y2="735.028" />

                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="215.396" y1="832.862" x2="184.952" y2="802.419" />

                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="158.391" y1="783.827" x2="97.303" y2="783.827" />
                        <path fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" d="M165.225,790.899 c-3.774,0-6.834-3.059-6.834-6.834c0-3.773,3.06-6.834,6.834-6.834" />

                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="541.184" y1="376.032" x2="541.184" y2="455.79" />

                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="543.392" y1="420.587" x2="556.445" y2="452.755" />

                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="556.445" y1="379.067" x2="543.267" y2="411.149" />

                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="578.027" y1="400.649" x2="546.329" y2="413.774" />

                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="578.028" y1="431.171" x2="546.454" y2="418.274" />

                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="569.192" y1="387.868" x2="545.767" y2="411.712" />

                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="569.192" y1="444.022" x2="545.454" y2="420.024" />

                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="580.96" y1="415.876" x2="547.954" y2="415.876" />
                        <path fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" d="M541.184,376.237v79.553 c21.968,0,39.776-17.809,39.776-39.775C580.96,394.046,563.151,376.237,541.184,376.237z" />
                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="126.85" y1="701.983" x2="47.85" y2="701.983" />
                        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="87.35" y1="741.483" x2="87.35" y2="662.483" />
                        <path fill="none" stroke="#231F20" strokeWidth="2.9498" strokeMiterlimit="10" d="M87.35,663.024 c10.816,0,19.584,8.768,19.584,19.582c0,10.816-8.769,19.584-19.584,19.584" />
                        <path fill="none" stroke="#231F20" strokeWidth="2.9498" strokeMiterlimit="10" d="M86.098,741.149 c-10.816,0-19.584-8.768-19.583-19.584c-0.001-10.816,8.768-19.582,19.583-19.582" />
                        <path fill="none" stroke="#231F20" strokeWidth="2.9498" strokeMiterlimit="10" d="M126.517,702.712 c-0.001,10.816-8.768,19.584-19.583,19.584c-10.816,0-19.584-8.768-19.584-19.582" />
                        <path fill="none" stroke="#231F20" strokeWidth="2.9498" strokeMiterlimit="10" d="M47.851,701.46 c-0.001-10.816,8.767-19.584,19.584-19.584c10.815,0,19.581,8.77,19.581,19.584" />
                        <g>
                            <line fill="#FFFFFF" stroke="#231F20" strokeWidth="2.2105" strokeMiterlimit="10" x1="335.684" y1="353.149" x2="335.684" y2="360.649" />
                            <line fill="#FFFFFF" stroke="#231F20" strokeWidth="2.2105" strokeMiterlimit="10" x1="335.684" y1="392.649" x2="335.684" y2="400.149" />
                        </g>
                        <g>
                            <line fill="#FFFFFF" stroke="#231F20" strokeWidth="2.2105" strokeMiterlimit="10" x1="359.184" y1="376.649" x2="351.684" y2="376.649" />
                            <line fill="#FFFFFF" stroke="#231F20" strokeWidth="2.2105" strokeMiterlimit="10" x1="319.684" y1="376.649" x2="312.184" y2="376.649" />
                        </g>
                        <g>
                            <line fill="#FFFFFF" stroke="#231F20" strokeWidth="2.2105" strokeMiterlimit="10" x1="352.301" y1="393.267" x2="346.997" y2="387.962" />
                            <line fill="#FFFFFF" stroke="#231F20" strokeWidth="2.2105" strokeMiterlimit="10" x1="324.37" y1="365.335" x2="319.066" y2="360.032" />
                        </g>
                        <g>
                            <line fill="#FFFFFF" stroke="#231F20" strokeWidth="2.2105" strokeMiterlimit="10" x1="319.067" y1="393.267" x2="324.371" y2="387.962" />
                            <line fill="#FFFFFF" stroke="#231F20" strokeWidth="2.2105" strokeMiterlimit="10" x1="346.998" y1="365.335" x2="352.302" y2="360.032" />
                        </g>
<polyline fill="none" stroke="#231F20" strokeWidth="2.2105" strokeMiterlimit="10" points="321.434,376.649 335.684,376.649 335.684,366.649" />
<circle fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" cx="335.684" cy="344.149" r="4" />
<path fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" d="M541.184,410.556 c3.015,0,5.458,2.445,5.458,5.459s-2.443,5.457-5.458,5.457" />

<line fill="#FFFFFF" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="213.017" y1="225.649" x2="213.017" y2="600.649" />

<line fill="#FFFFFF" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="299.267" y1="225.149" x2="299.267" y2="246.483" />

<line fill="#FFFFFF" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="342.228" y1="225.149" x2="342.228" y2="246.483" />

<line fill="#FFFFFF" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="385.228" y1="225.149" x2="385.228" y2="246.483" />
<path fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" d="M169.517,224.149" />

<line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="169.517" y1="224.149" x2="256.183" y2="318.815" />

<line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="256.183" y1="224.149" x2="169.517" y2="318.815" />

<line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="212.683" y1="224.899" x2="169.767" y2="271.649" />

<line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="256.183" y1="271.899" x2="213.267" y2="318.649" />
<polyline fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" points="213.267,224.899 256.183,271.649  306.85,271.649 306.85,317.149" />

<line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="169.767" y1="271.899" x2="212.683" y2="318.649" />

<line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="257.267" y1="224.149" x2="278.517" y2="246.899" />

<line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="299.267" y1="224.149" x2="278.017" y2="246.899" />

<line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="299.517" y1="224.149" x2="320.767" y2="246.899" />

<line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="341.517" y1="224.149" x2="320.267" y2="246.899" />

<line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="342.767" y1="224.149" x2="364.017" y2="246.899" />

<line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="384.767" y1="224.149" x2="363.517" y2="246.899" />

<line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="385.767" y1="224.149" x2="407.017" y2="246.899" />

<line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="427.767" y1="224.149" x2="406.517" y2="246.899" />

<line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="256.183" y1="318.817" x2="169.517" y2="413.483" />

<line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="212.683" y1="319.567" x2="169.767" y2="366.317" />

<line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="256.183" y1="366.567" x2="213.267" y2="413.317" />

<line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="213.267" y1="319.567" x2="256.183" y2="366.317" />

<line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="169.767" y1="366.567" x2="212.683" y2="413.317" />

<line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="169.517" y1="318.817" x2="256.183" y2="413.483" />

<line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="256.183" y1="459.983" x2="169.85" y2="459.983" />

<line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="256.183" y1="412.149" x2="169.517" y2="506.815" />

<line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="212.683" y1="412.899" x2="169.767" y2="459.649" />

<line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="256.183" y1="459.899" x2="213.267" y2="506.649" />

<line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="213.267" y1="412.899" x2="256.183" y2="459.649" />

<line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="169.767" y1="459.899" x2="212.683" y2="506.649" />

<line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="169.517" y1="412.149" x2="246.184" y2="495.481" />

<line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="256.183" y1="553.481" x2="169.85" y2="553.481" />

<line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="256.183" y1="553.315" x2="169.85" y2="553.315" />

<line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="246.184" y1="516.149" x2="169.517" y2="600.149" />

<line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="212.683" y1="506.233" x2="169.767" y2="552.981" />

<line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="256.183" y1="553.231" x2="213.267" y2="599.981" />

<line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="213.267" y1="506.233" x2="256.183" y2="552.981" />

<line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="169.767" y1="553.231" x2="212.683" y2="599.981" />

<line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="169.517" y1="505.483" x2="256.183" y2="600.149" />

<line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="369.587" y1="727.644" x2="341.175" y2="752.948" />

<rect x="531.851" y="471.315" fill="none" stroke="#231F20" strokeWidth="3.11" strokeMiterlimit="10" width="19.002" height="36.332" />
<g>
<polyline fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" points="1.517,624.062 21.13,638.236 40.74,624.062 60.352,638.236 79.968,624.062 99.579,638.236 119.193,624.062 138.808,638.236 158.424,624.062 178.041,638.236 197.658,624.062 217.275,638.236 236.896,624.062 256.517,638.236	" />
</g>

<rect x="523.517" y="296.149" fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" width="7.667" height="12.666" />
<polyline fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" points="256.017,282.983 295.517,282.983 295.517,326.483" />
<rect x="475.518" y="455.815" fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" width="14" height="22" />
<rect x="316.517" y="640.649" fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" width="14" height="22" />
<rect x="269.017" y="729.649" fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" width="14" height="22" />
<polyline fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" points="493.405,801.149 493.405,695.804 575.155,695.804 575.155,719.544" />
<path fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" d="M493.403,695.804v-20.016 c0-22.574,18.301-40.877,40.875-40.877c22.578,0,40.879,18.303,40.879,40.877v19.602" />
<g>
<polygon fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" points="471.822,821.649 493.489,821.649 493.489,800.815 511.156,800.815 511.156,780.315 526.822,780.315 526.822,759.649 543.489,759.649 543.489,738.481 558.822,738.481 558.822,717.483 575.155,717.483 575.155,840.149 471.822,840.149	" />
<rect x="528.239" y="716.149" fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" width="14" height="22" />
</g>

<line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="285.85" y1="696.149" x2="256.684" y2="651.815" />

<line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="276.516" y1="695.815" x2="256.85" y2="666.815" />

<line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="266.184" y1="695.815" x2="256.516" y2="681.149" />

<line fill="#FFFFFF" stroke="#231F20" strokeWidth="3.101" strokeMiterlimit="10" x1="405.904" y1="165.522" x2="436.596" y2="176.478" />

<line fill="#FFFFFF" stroke="#231F20" strokeWidth="3.101" strokeMiterlimit="10" x1="405.279" y1="173.397" x2="435.971" y2="184.353" />
<path fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" d="M396.854,310.942 c0,8.33,6.752,15.082,15.081,15.082c8.33,0,16.082-6.375,16.082-21.875v-66.5" />
<path fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" d="M423.849,166.149" />
<g>
<path fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" d="M426.184,126.421H414.35 c-4.327,0-8.834-3.508-8.834-7.834s3.591-7.891,8.84-7.891v54.703" />
<path fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" d="M425.516,109.817" />
<path fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" d="M425.684,126.421 c4.327,0,8.834-3.508,8.834-7.834s-4.507-7.834-8.834-7.834v54.564" />
</g>
<g>
<g>
    <path fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" d="M116.461,270.013 c-4.172,20.674-22.44,36.242-44.344,36.24c-24.983,0-45.237-20.252-45.237-45.236s20.254-45.236,45.237-45.236 c21.491,0,39.566,15.203,44.399,36.035" />
    
        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="72.117" y1="215.544" x2="72.117" y2="306.253" />
    
        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="54.76" y1="218.997" x2="70.267" y2="256.524" />
    
        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="70.517" y1="264.817" x2="54.761" y2="302.8" />
    
        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="66.517" y1="262.899" x2="30.216" y2="278.257" />
    
        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="66.767" y1="258.649" x2="30.216" y2="243.542" />
    
        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="68.85" y1="263.483" x2="40.107" y2="292.87" />
    
        <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="68.017" y1="256.899" x2="40.107" y2="229.007" />
    <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="66.892" y1="260.86" x2="26.88" y2="260.86" />
    <path fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" d="M70.742,265.478 c-2.464,0-4.461-1.996-4.461-4.461s1.997-4.461,4.461-4.461" />
</g>

    <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="114.02" y1="243.542" x2="90.517" y2="252.983" />

    <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="89.475" y1="218.997" x2="74.684" y2="253.149" />

    <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="103.971" y1="229.007" x2="79.142" y2="253.274" />

    <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="114.02" y1="278.255" x2="93.684" y2="268.983" />

    <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="103.971" y1="292.87" x2="79.85" y2="269.149" />
<line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="75.85" y1="270.149" x2="89.475" y2="302.8" />
</g>
<path fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" d="M292.351,157.487 c7.415-14.357,24.991-19.729,39.349-12.311c11.486,5.932,15.988,20.053,10.055,31.539c-4.744,9.189-16.042,12.791-25.229,8.045 c-7.353-3.797-10.235-12.834-6.437-20.186c3.037-5.881,10.267-8.186,16.148-5.148c4.705,2.43,6.549,8.215,4.119,12.918 c-1.943,3.764-6.571,5.24-10.335,3.295c-3.011-1.555-4.192-5.256-2.636-8.266c1.243-2.41,4.204-3.355,6.614-2.111 c1.927,0.996,2.681,3.363,1.688,5.293" />

<line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="120.517" y1="581.815" x2="168.017" y2="581.815" />

<polyline fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#231F20" strokeWidth="3.02" strokeMiterlimit="10" points="39.35,581.815 1.517,581.815 1.602,599.733" />
<g>

    <rect x="190.237" y="697.733" fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" width="45.329" height="62.137" />

    <rect x="196.094" y="704.05" fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" width="33.615" height="49.504" />

    <line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="229.709" y1="704.05" x2="235.566" y2="697.733" />

    <line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="229.709" y1="753.554" x2="235.796" y2="759.64" />

    <line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="196.094" y1="753.554" x2="190.657" y2="758.989" />

    <line fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="190.237" y1="697.733" x2="196.094" y2="704.05" />
</g>
<path fill="none" stroke="#000000" strokeWidth="3" strokeMiterlimit="10" d="M466.048,416.015 c0-9.096,7.374-16.471,16.47-16.471s16.47,7.375,16.47,16.471" />
<g>
<path fill="none" stroke="#231F20" strokeWidth="2.5992" strokeMiterlimit="10" d="M501.858,599.659 c0-17.904,14.516-32.422,32.42-32.422c17.908,0,32.424,14.518,32.424,32.422H501.858z" />

    <line fill="none" stroke="#231F20" strokeWidth="2.5992" strokeMiterlimit="10" x1="534.278" y1="599.292" x2="534.28" y2="634.149" />

    <line fill="none" stroke="#231F20" strokeWidth="2.5992" strokeMiterlimit="10" x1="534.278" y1="567.237" x2="534.278" y2="599.87" />
<path fill="none" stroke="#231F20" strokeWidth="2.5992" strokeMiterlimit="10" d="M535.766,567.241 c11.835,6.326,20.118,18.43,21.08,32.543" />
<path fill="none" stroke="#231F20" strokeWidth="2.5992" strokeMiterlimit="10" d="M533.235,567.241 c-11.835,6.326-20.117,18.43-21.079,32.543" />
<path fill="none" stroke="#231F20" strokeWidth="2.5992" strokeMiterlimit="10" d="M522.572,599.804 c0-13.35,4.586-25.195,11.667-32.605" />
<path fill="none" stroke="#231F20" strokeWidth="2.5992" strokeMiterlimit="10" d="M545.862,599.804 c0-13.35-4.586-25.195-11.667-32.605" />
</g>
<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" d=" M386.339,733.95c-0.445,0.607-0.93,1.186-1.45,1.729c-1.598,1.666-3.532,3.008-5.692,3.916c-2.024,0.852-4.247,1.32-6.58,1.32" />
<path fillRule="evenodd" clipRule="evenodd" fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" d=" M355.616,723.915c0-9.389,7.612-17,17-17s17,7.611,17,17" />
<path fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" d="M387.28,680.265 c-5.991-3.162-10.868-8.148-13.893-14.221" />
<path fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" d="M402.35,619.317 c17.857,0,32.333,14.475,32.333,32.332s-14.476,32.334-32.333,32.334" />
<path fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" d="M262.293,91.171 c-0.178-8.834-7.483-17.852-16.318-17.674c-5.089,0.102-9.565,2.578-12.421,6.34c-0.687-10.332-9.364-18.404-19.823-18.195 c-10.768,0.217-19.32,9.121-19.104,19.889c0.003,0.162,0.026,0.316,0.034,0.477c-2.924-4.43-7.974-7.318-13.672-7.203 c-8.835,0.178-15.854,7.533-15.676,16.367H262.293z" />
<path fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" d="M282.517,117.524 c0-7.318,5.933-14.375,13.25-14.375c4.347,0,8.192,2.104,10.608,5.336c2.17-5.189,7.291-8.836,13.267-8.836 c6.24,0,11.536,3.982,13.523,9.539c2.586-2.496,6.099-4.039,9.977-4.039c7.939,0,14.375,4.436,14.375,12.375H282.517z" />
<path fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" d="M480.185,111.817 c0-8.652,7.014-15.668,15.666-15.668c6.058,0,11.301,3.445,13.908,8.475c2.078-2.527,5.229-4.141,8.758-4.141 c4.984,0,9.209,3.223,10.726,7.695c1.729-1.068,3.76-1.695,5.941-1.695c6.259,0,11.333,4.074,11.333,10.334h-66.332V111.817z" />
<g>
<path fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" d="M533.819,210.483 c0,7.964-7.938,7.964-7.938,15.928c0,7.967,7.938,7.967,7.938,15.933c0,7.967-7.938,7.967-7.938,15.934 c0,7.968,7.938,7.968,7.938,15.937c0,7.968-7.938,7.968-7.938,15.936" />
</g>

<line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="370.571" y1="40.021" x2="392.317" y2="70.278" />
<line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="361.253" y1="27.124" x2="365.39" y2="32.847" />
<g>

    <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="471.499" y1="42.021" x2="449.753" y2="72.278" />

    <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="480.817" y1="29.124" x2="476.681" y2="34.847" />
</g>
<g>

    <line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="436.642" y1="27.384" x2="430.622" y2="64.104" />
<line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="440.78" y1="0.271" x2="437.729" y2="20.622" />
</g>

<line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="408.636" y1="38.239" x2="411.284" y2="64.104" />

<line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="407.108" y1="23.317" x2="408.151" y2="33.515" />
<line fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" x1="404.78" y1="0.149" x2="406.472" y2="17.101" />

<ellipse fill="none" stroke="#231F20" strokeWidth="3" strokeMiterlimit="10" cx="534.239" cy="176.884" rx="3.857" ry="8.266" />
<path fill="#231F20" d="M332.516,743.708c0.9,0.725,4.702,2.992,6.806,3.756c2.507,0.904,8.207-1.051,8.207-1.051l1.352-2.264 c0,0-7.777,0.273-9.027-0.377c-1.249-0.648-2.892-2.061-4.298-3.273c0,0-1.806-1.322-2.219-1.58l-0.006,0.033 c-0.491-0.426-1.716-1.242-4.209-1.629c0.866-0.697,1.456-2.047,1.443-3.615c-0.018-2.262-1.267-4.084-2.787-4.07 c-1.521,0.01-2.741,1.854-2.723,4.115c0.011,1.404,0.497,2.637,1.229,3.369c-2.378,0.131-4.118,0.877-4.932,1.752 c-1.976,2.127-3.223,18.023-2.759,21.291c0.106,4.715,1.471,9.693,0.895,10.264c-1.557,1.525-11.478,1.977-11.478,1.977v6.475 h1.352l0.312-2.293c0,0,10.913-0.6,13.729-2.094c1.998-1.061,2.056-6.236,2.377-8.832c4.326,0.842,8.959,2.412,9.46,2.879 c1.54,1.43,1.79,10.15,1.79,10.15h5.904v-1.443l-1.966-0.258c0,0-0.596-9.459-2.16-12.105c-1.047-1.77-5.634-4.479-8.288-5.551 l1.14-9.193c1.11,0.793,2.4,1.607,3.492,2.002c2.505,0.906,8.205-1.051,8.205-1.051l1.159-2.451c0,0-7.585,0.459-8.837-0.189 c-0.687-0.357-2.123-1.611-3.457-2.852l0.22-1.773C332.442,743.825,332.472,743.78,332.516,743.708z" />
<path fill="#231F20" d="M396.202,388.813c-0.803-0.82-1.643-7.885-2.256-14.363c1.458-0.787,5.324-3.084,5.839-5.553 c0.659-3.158-1.172-5.42-2.183-6.627c-1.528-1.824-5.124-3.418-5.547-3.604c-0.691-0.309-2.65-1.07-5.029-1.025 c0.674-0.783,1.068-2.049,0.977-3.449c-0.149-2.254-1.5-4-3.017-3.898c-1.517,0.1-2.627,2.008-2.479,4.262 c0.104,1.562,0.791,2.865,1.705,3.494c-3.028,0.766-4.56,2.213-4.56,2.213c-1.026,1.172-4.56,4.953-5.773,5.666 c-1.216,0.709-7.529,0.564-7.529,0.564v2.453c0,0,5.786,1.668,8.241,0.639c2.456-1.029,5.864-4.328,5.864-4.328l2.053,13.188 c0,0-6.662,4.627-8.158,11.822c-0.161,0.779-0.621,2.984-0.704,3.328c-1.116,4.057-1.885,9.885-1.885,9.885 s0.013,0.002,0.018,0.002c-0.02,0.131-0.03,0.205-0.03,0.205l-2.354,0.217l-0.108,1.367l6.605,1.004 c0,0,1.918-9.449,3.243-13.465c0,0,0.616-2.379,0.87-2.916c1.556-3.295,8.336-6.709,8.336-6.709s1.387,7.871,4.171,9.422 c2.782,1.549,13.272,2.197,13.272,2.197l0.39,2.264h1.333l0.125-6.543C407.632,390.524,397.725,390.37,396.202,388.813z M393.049,363.579c0.477,0.283,2.758,1.725,3.164,3.604c0.229,1.055-1.543,2.996-2.562,4.01 C393.327,367.45,393.106,364.378,393.049,363.579z" />
<path fill="#231F20" d="M293.253,179.351c0,0-6.606,0.285-7.896-0.432c-1.286-0.717-5.729-4.994-6.497-5.738 c-0.767-0.744-2.094-1.219-4.482-1.322c0.845-0.797,1.348-2.256,1.204-3.891c-0.209-2.357-1.665-4.154-3.251-4.014 c-1.584,0.141-2.702,2.162-2.496,4.521c0.131,1.463,0.742,2.709,1.565,3.412c-2.47,0.336-4.796,0.947-5.571,1.93 c-2.98,2.869-5.305,6.994-6.275,9.184c-1.125,2.549-0.546,10.738-0.546,10.738l2.569,0.049c0,0,0.65-8.711,1.42-9.973 c0.265-0.434,1.063-1.705,1.916-2.498c-0.439,5.691-0.32,10.828,0.75,15.207c0.639,2.496,1.79,5.178,2.041,7.709 c0.113,1.141,0.044,3.297,0.044,3.297c-0.001,4.023-0.855,15.213-0.855,15.213l7.014-0.02l0.126-1.432l-2.515-0.393 c0,0,0.108-0.578,0.154-0.865c0.279-1.551,1.093-6.289,1.276-9.92c0.03-0.369,0.299-2.715,0.395-3.543 c0.333-2.906,0.232-5.545-0.02-7.703c0.939,1.912,1.91,4.125,2.312,5.822c0.145,0.605,0.26,3.18,0.26,3.18 c0.43,3.895,0.605,9.521,0.646,11.02l-0.005,3.834h6.993l0.001-1.354l-2.478-0.502c0,0,0.072-0.406,0.094-0.791 c0,0,0.428-6.408,0.2-10.213c-0.011-0.369-0.003-2.73,0.001-3.564c0.012-2.906-1.685-9.25-2.352-14.9 c-0.801-6.781-0.6-12.73-0.6-12.73c1.248,1.025,4.328,3.316,6.293,4.096c2.594,1.023,8.617-0.846,8.617-0.846L293.253,179.351z"
/>
</g>
</g>
</switch>

</svg>
    );
}

export default SVG;
