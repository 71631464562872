import { connect } from "react-redux";
import ExhibitionEntriesList from "../../components/website/exhibition-entries/ExhibitionEntriesList";
import { exhibitionEntry, getExhibitionEntriesVotingData } from "../../services/requests";

const mapStateToProps = (state) => ({
    currentEntriesPage: state.exhibitionEntries.pages.current,
    archive: state.exhibitionEntries.pages.archived,
});

const mapDispatchToProps = (dispatch) => ({
    getVotingData: (listOfIds) => dispatch(getExhibitionEntriesVotingData(listOfIds)),
    getExhibitionEntries: () => dispatch(exhibitionEntry.getAllApproved()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExhibitionEntriesList);
