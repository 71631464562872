/* eslint max-len: 0 */
import React from "react";
import PropTypes from "prop-types";

const ORIGINAL_WIDTH = 8;
const ORIGINAL_HEIGHT = 8;

function SVG({ scale }) {
    const width = ORIGINAL_WIDTH * scale;
    const height = ORIGINAL_HEIGHT * scale;
    const widthString = `${width}px`;
    const heightString = `${height}px`;

    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width={widthString}
            height={heightString}
            viewBox={`0 0 ${ORIGINAL_WIDTH} ${ORIGINAL_HEIGHT}`}
            enableBackground={`new 0 0 ${ORIGINAL_WIDTH} ${ORIGINAL_HEIGHT}`}
        >
            <rect x="0.5" y="0.5" fill="#FFDE14" stroke="#1B1C20" strokeMiterlimit="10" width="7" height="7" />
        </svg>
    );
}

export default SVG;

SVG.propTypes = {
    scale: PropTypes.number,
};

SVG.defaultProps = {
    scale: 2,
};
