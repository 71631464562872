import React from "react";
import uuidv1 from "uuid/v1";
import PropTypes from "prop-types";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ExhibitionEntryCard from "./ExhibitionEntryCard";
import ExhibitionSubjectCard from "./ExhibitionSubjectCard";

function ThreeColumnList({ items, isEntries }) {
    let leftColumnEntries = [];
    let middleColumnEntries = [];
    let rightColumnEntries = [];

    if (items.length > 0) {
        rightColumnEntries = items.reduce((accum, current, currentIndex) => {
            if ((currentIndex + 1) % 3 === 0) {
                return [...accum, current];
            }
            return accum;
        }, []);
        middleColumnEntries = items.reduce((accum, current, index) => {
            if ((index - 1) % 3 === 0) {
                return [...accum, current];
            }
            return accum;
        }, []);
        leftColumnEntries = items.reduce((accum, current, index) => {
            if (index % 3 === 0) {
                return [...accum, current];
            }
            return accum;
        }, []);
    }

    const renderCard = (item) => (
        isEntries ? (
            <ExhibitionEntryCard
                title={item.title}
                description={item.description}
                contentUrl={item.contentUrl}
                votes={item.votes}
            />
        ) : (
            <ExhibitionSubjectCard
                title={item.title}
                description={item.description}
                votes={item.votes}
            />
        )
    );

    return (
        <Row className="justify-content-between">
            <Col className="custom-exhibition-list-column" sm="4" md="4" lg="4">
                {leftColumnEntries.map((item) => (
                    <div key={uuidv1()} className="custom-exhibition-column-wrapper">
                        {renderCard(item)}
                    </div>
                ))}
            </Col>
            {middleColumnEntries.length > 0 && (
                <Col className="custom-exhibition-list-column" sm="4" md="4" lg="4">
                    {middleColumnEntries.map((item) => (
                        <div key={uuidv1()} className="custom-exhibition-column-wrapper">
                            {renderCard(item)}
                        </div>
                    ))}
                </Col>
            )}
            {rightColumnEntries.length > 0 && (
                <Col className="custom-exhibition-list-column" sm="4" md="4" lg="4">
                    {rightColumnEntries.map((item) => (
                        <div key={uuidv1()} className="custom-exhibition-column-wrapper">
                            {renderCard(item)}
                        </div>
                    ))}
                </Col>
            )}
        </Row>
    );
}

export default ThreeColumnList;

ThreeColumnList.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape).isRequired,
    isEntries: PropTypes.bool,
};

ThreeColumnList.defaultProps = {
    isEntries: false,
};
