import React from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { withFormik } from "formik";
import validationSchemas from "../../helpers/validation";
import Content from "../../content/AdminTextContent";

export function AdminLoginFormInner({
    handleSubmit,
    handleChange,
    values,
    errors,
    status,
}) {
    const loginContent = Content.login;

    return (
        <Row className="justify-content-sm-center">
            <Col sm="5">
                <div className="admin-form">
                    <Form noValidate onSubmit={handleSubmit}>
                        <Form.Group controlId="formUsername">
                            <Form.Label>{loginContent.elements.username}</Form.Label>
                            <Form.Control
                                type="text"
                                name="username"
                                onChange={handleChange}
                                value={values.username}
                                placeholder={loginContent.placeholders.username}
                                isInvalid={!!errors.username}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.username}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="formPassword">
                            <Form.Label>{loginContent.elements.password}</Form.Label>
                            <Form.Control
                                type="password"
                                name="password"
                                onChange={handleChange}
                                value={values.password}
                                placeholder={loginContent.placeholders.password}
                                isInvalid={!!errors.password}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.password}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <div className="admin-error-message">
                            {(status && status.type) === "error" && status.message}
                        </div>
                        <Button type="submit">{loginContent.elements.button}</Button>
                    </Form>
                </div>
            </Col>
        </Row>
    );
}

export const handleSubmit = (values, { props, setStatus }) => {
    props.onSubmit(values, props, setStatus);
};

export default withFormik({
    mapPropsToValues: () => ({
        username: "",
        password: "",
    }),
    validationSchema: validationSchemas.adminLoginForm,
    handleSubmit,
})(AdminLoginFormInner);

AdminLoginFormInner.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    values: PropTypes.shape({
        username: PropTypes.string,
        password: PropTypes.string,
    }).isRequired,
    errors: PropTypes.shape({
        username: PropTypes.string,
        password: PropTypes.string,
    }).isRequired,
    status: PropTypes.shape({
        type: PropTypes.string,
        message: PropTypes.string,
    }),
};

AdminLoginFormInner.defaultProps = {
    status: null,
};
