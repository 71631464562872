import React from "react";
import PropTypes from "prop-types";
import Card from "react-bootstrap/Card";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Toggle from "react-bootstrap-toggle";
import Content from "../../../content/AdminTextContent";
import CroatianDateFormat from "../../common/CroatianDateFormat";
import { SURVEY_STATUS } from "../../../helpers/constants";
import { SurveyPropType, HistoryPropType } from "../../../customPropTypes";
import RejectButton from "../../common/RejectButton";

function AdminSurveyCard({
    history, numberOfVotes, buttonLabel, buttonLink, survey, onChangeSurveyStatus, editLink, onDeleteSurvey,
}) {
    const [showModal, setShowModal] = React.useState(false);

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    const onToggleClick = () => {
        handleShow();
    };

    const handleSurveyStatusChange = () => {
        let newSurveyStatus;
        if (survey.status === SURVEY_STATUS.ACTIVE) {
            newSurveyStatus = SURVEY_STATUS.INACTIVE;
        } else {
            newSurveyStatus = SURVEY_STATUS.ACTIVE;
        }

        onChangeSurveyStatus(survey, newSurveyStatus);
        handleClose();
    };

    const text = Content.surveys.card;
    const SURVEY_ACTIVITY_LOCALE = {
        [SURVEY_STATUS.ACTIVE]: text.status.inactive.toUpperCase(),
        [SURVEY_STATUS.INACTIVE]: text.status.active.toUpperCase(),
    };

    const STATUS_CHANGE_EXPLANATION = {
        [SURVEY_STATUS.ACTIVE]: text.statusChange.popupExplanationInactive,
        [SURVEY_STATUS.INACTIVE]: text.statusChange.popupExplanationActive,
    };


    return (
        <div style={{ marginBottom: "30px" }}>
            <Card
                bg={survey.status === SURVEY_STATUS.ACTIVE ? "light" : "secondary"}
                text={survey.status === SURVEY_STATUS.ACTIVE ? "black" : "white"}
            >
                <Card.Body>
                    <div className="admin-survey-card-toggle-wrapper">
                        <OverlayTrigger
                            key="bottom"
                            placement="bottom"
                            overlay={(
                                <Tooltip id="tooltip-bottom">
                                    {text.statusChange.tooltip}
                                </Tooltip>
                            )}
                        >
                            <Toggle
                                onClick={onToggleClick}
                                on="aktivna"
                                off="neaktivna"
                                size="xs"
                                onstyle="success"
                                offstyle="danger"
                                active={survey.status === SURVEY_STATUS.ACTIVE}
                            />
                        </OverlayTrigger>
                    </div>
                    <h3>{survey.title}</h3>
                    <p className="top-more-space">
                        <span>{text.startDate}</span>
                        <CroatianDateFormat date={survey.startDate} />
                    </p>
                    <p>
                        <span>{text.endDate}</span>
                        <CroatianDateFormat date={survey.endDate} />
                    </p>
                    {numberOfVotes > 0 && (
                        <div className="top-more-space">
                            <span>{text.numberOfVotesTitle}</span>
                            <Badge
                                id="numberOfVotesBadge"
                                variant="secondary"
                                className="admin-dashboard-badge"
                            >
                                {numberOfVotes}
                            </Badge>
                        </div>
                    )}
                    <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "flex-end" }}>
                        <Button
                            className="top-more-space"
                            variant="primary"
                            onClick={() => { history.push(buttonLink); }}
                        >
                            {buttonLabel}
                        </Button>
                        <Button
                            className="top-more-space"
                            variant="warning"
                            onClick={() => { history.push(editLink); }}
                        >
                            Editiraj
                        </Button>
                        <RejectButton
                            onRejectCallback={() => onDeleteSurvey(survey.id)}
                            modalTitle={survey.title}
                            modalText="Jeste li sigurni da želite pobrisati ovu anketu?"
                            buttonText="Pobriši"
                            executeButtonLabel="Pobriši"
                        />
                    </div>
                </Card.Body>
            </Card>
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{survey.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {`${text.statusChange.popupQuestionStatic} ${SURVEY_ACTIVITY_LOCALE[survey.status]}?`}
                    <br />
                    {STATUS_CHANGE_EXPLANATION[survey.status]}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {text.statusChange.buttonClose}
                    </Button>
                    <Button variant="primary" onClick={handleSurveyStatusChange}>
                        {text.statusChange.buttonApprove}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default AdminSurveyCard;

AdminSurveyCard.propTypes = {
    history: HistoryPropType.isRequired,
    numberOfVotes: PropTypes.number,
    buttonLabel: PropTypes.string.isRequired,
    buttonLink: PropTypes.string.isRequired,
    survey: SurveyPropType.isRequired,
    onChangeSurveyStatus: PropTypes.func.isRequired,
};

AdminSurveyCard.defaultProps = {
    numberOfVotes: 0,
};
