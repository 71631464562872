import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

function ExtraSettingTooltip({ id, text, onClickCallback, children }) {
    return (
        <OverlayTrigger
            key="bottom"
            placement="bottom"
            overlay={(
                <Tooltip id={`tooltip-extra-${id}`}>{text}</Tooltip>
            )}
        >
            <button
                type="button"
                className="no-style-button"
                onClick={onClickCallback}
            >
                {children}
            </button>
        </OverlayTrigger>
    );
}

export default ExtraSettingTooltip;
