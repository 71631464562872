import React from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import { HistoryPropType } from "../../../customPropTypes";
import SubjectVoteButtonContainer from "../../../containers/website/SubjectVoteButtonContainer";
import SVGxButton from "../../../media/svgs/common/x";
import SVGxButtonHover from "../../../media/svgs/common/x-hover";
// import { checkImageURL, checkVideoURL, checkAudioURL } from "../../helpers/files";

function ExhibitionSubject({
    history, subject, voteSubject, unvoteSubject,
}) {
    const onHideCallback = () => {
        history.push("/programi");
    };
    const [isCloseButtonHovering, setIsCloseButtonHovering] = React.useState(false);

    return (
        subject ? (
            <div>
                <Modal
                    show
                    onHide={onHideCallback}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    dialogClassName="custom-exhibition-subject-modal"
                >
                    <button
                        type="button"
                        href="#"
                        onClick={onHideCallback}
                        className="no-style-button custom-modal-close-button"
                        onMouseEnter={() => setIsCloseButtonHovering(true)}
                        onMouseLeave={() => setIsCloseButtonHovering(false)}
                    >
                        {isCloseButtonHovering ? <SVGxButtonHover /> : <SVGxButton />}
                    </button>
                    <h4 style={{ marginTop: "20px", marginBottom: "0", fontWeight: "400" }}>{subject.title}</h4>
                    <p style={{ marginTop: "20px", marginBottom: "20px" }}>{subject.description}</p>
                    <div>
                        <SubjectVoteButtonContainer
                            entryId={subject.id}
                            onVoteCallback={voteSubject}
                            onUnvoteCallback={unvoteSubject}
                        />
                    </div>
                </Modal>

            </div>
        ) : null
    );
}

export default ExhibitionSubject;

ExhibitionSubject.propTypes = {
    // subject: ExhibitionSubjectPropType,
    history: HistoryPropType.isRequired,
    voteSubject: PropTypes.func.isRequired,
    unvoteSubject: PropTypes.func.isRequired,
};

ExhibitionSubject.defaultProps = {
    // entry: null,
};
