import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Route } from "react-router";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
    HistoryPropType, MatchPropType, ExhibitionEntryPropType, ExtraSettingsPropType,
} from "../../../customPropTypes";
import ExhibitionEntryAddNew from "./ExhibitionEntryAddNew";
import SVGtitle from "../../../media/svgs/exhibition-entries/title";
import SVGredArrow from "../../../media/svgs/exhibition-entries/red-arrow";
import SVGblueArrow from "../../../media/svgs/exhibition-entries/blue-arrow";
import SVGbyellowDiagonalArrow from "../../../media/svgs/exhibition-entries/yellow-diagonal-arrow";
import SVGbgreenArrow1 from "../../../media/svgs/exhibition-entries/arrows/green-arrow-1";
import SVGbredArrow2 from "../../../media/svgs/exhibition-entries/arrows/red-arrow-2";
import Copy from "../../../content/WebsiteTextContent";
import ExhibitionAddNewButton from "../common/ExhibitionAddNewButton";
import ExhibitionEntryContainer from "../../../containers/website/ExhibitionEntryContainer";
import TextAccordion from "../TextAccordion";
import Filters from "../common/Filters";
import TwoColumnList from "./TwoColumnList";
import OneColumnList from "./OneColumnList";

function ExhibitionEntriesList({
    match, getExhibitionEntries, approvedEntries, onVoteEntry,
    getVotingData, onSubmitNew, onUpload, onDeleteFile, sortEntries, onUnvoteEntry,
    extraSettings, currentEntriesPage, archive,
}) {
    const { accordionData } = Copy.exhibitionEntriesPage;
    const [showAddModal, setShowAddModal] = React.useState(false);
    const [browserContentHeight, setBrowserContentHeight] = React.useState(0);

    React.useEffect(() => {
        getExhibitionEntries();
    }, [getExhibitionEntries]);

    React.useEffect(() => {
        const listOfIds = approvedEntries.map((entry) => entry.id);
        if (listOfIds.length > 0) {
            getVotingData(listOfIds);
        }
    }, [getVotingData, approvedEntries]);

    React.useEffect(() => {
        const contentHeight = document.getElementsByTagName("body")[0].clientHeight;
        setBrowserContentHeight(contentHeight);
    }, [browserContentHeight, getExhibitionEntries, getVotingData, approvedEntries]);

    const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);

    React.useEffect(() => {
        function handleResize() {
            setScreenWidth(window.innerWidth);
        }

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handleClose = () => setShowAddModal(false);
    const handleShow = () => setShowAddModal(true);

    let isPageActive;

    if (currentEntriesPage) {
        const pageActiveToDate = moment(currentEntriesPage.activeTo);
        isPageActive = moment().isBefore(pageActiveToDate);
    }

    let archivedContent;

    if (!isPageActive && approvedEntries.length > 0 && archive) {
        const pageId = approvedEntries[0].pageId;
        const content = archive.find((current) => current.id === pageId).contents;
        archivedContent = content[0];
    }

    return (
        <Container>
            {!extraSettings.hideBackgrounds && (
                <div className="custom-arrow-wrapper-red">
                    <SVGredArrow />
                </div>
            )}
            {!extraSettings.hideBackgrounds && browserContentHeight > 2000 && (
                <div className="custom-arrow-wrapper-blue">
                    <SVGblueArrow />
                </div>
            )}
            {!extraSettings.hideBackgrounds && browserContentHeight > 2500 && (
                <div className="custom-arrow-wrapper-yellow-diagonal">
                    <SVGbyellowDiagonalArrow />
                </div>
            )}
            {!extraSettings.hideBackgrounds && browserContentHeight > 3000 && (
                <div style={{ position: "absolute", top: "3850px", left: "350px" }}>
                    <SVGbgreenArrow1 />
                </div>
            )}
            {!extraSettings.hideBackgrounds && browserContentHeight > 3500 && (
                <div style={{ position: "absolute", top: "5050px", left: "60px" }}>
                    <SVGbredArrow2 />
                </div>
            )}
            <Row>
                <SVGtitle />
            </Row>
            <Row className="custom-exhibition-intro-text">
                <Col sm="12" md="12" lg="7">
                    {currentEntriesPage && <p>{currentEntriesPage.contents.find((content) => content.title === "Main page text").text}</p>}
                    {archivedContent && <p>{archivedContent.text}</p>}
                </Col>
                <Col sm="12" md="12" lg="5">
                    <TextAccordion data={accordionData[0]} />
                </Col>
            </Row>
            <Row className="custom-entries-accordion-row">
                <Col sm="12" md="12" lg="6">
                    <div className="custom-entries-accordion-1">
                        <TextAccordion data={accordionData[1]} />
                    </div>
                    <div className="custom-entries-accordion-2">
                        <TextAccordion data={accordionData[2]} />
                    </div>
                </Col>
                <Col sm="12" md="12" lg="6">
                    <TextAccordion data={accordionData[3]} />
                    <div className="custom-entries-accordion-3">
                        <TextAccordion data={accordionData[4]} />
                    </div>
                </Col>
            </Row>
            {
                isPageActive && <Row><ExhibitionAddNewButton onClickCallback={handleShow} /></Row>
            }
            <Filters sortFn={sortEntries} />
            {screenWidth > 991 ? (
                <TwoColumnList
                    items={approvedEntries}
                    onVoteEntry={onVoteEntry}
                    onUnvoteEntry={onUnvoteEntry}
                    handleShow={handleShow}
                    isPageActive={isPageActive}
                />
            ) : (
                <OneColumnList
                    items={approvedEntries}
                    onVoteEntry={onVoteEntry}
                    onUnvoteEntry={onUnvoteEntry}
                    handleShow={handleShow}
                    isPageActive={isPageActive}
                />
            )}
            { isPageActive && (
                <ExhibitionEntryAddNew
                    show={showAddModal}
                    onHideCallback={handleClose}
                    onSubmit={onSubmitNew}
                    onUpload={onUpload}
                    onDeleteFile={onDeleteFile}
                />
            )}
            <Route
                exact
                path={`${match.path}/prijedlog:id`}
                render={(props) => <ExhibitionEntryContainer history={props.history} match={props.match} />}
            />
        </Container>
    );
}

export default ExhibitionEntriesList;

ExhibitionEntriesList.propTypes = {
    history: HistoryPropType.isRequired,
    match: MatchPropType.isRequired,
    getExhibitionEntries: PropTypes.func.isRequired,
    approvedEntries: PropTypes.arrayOf(ExhibitionEntryPropType),
    onVoteEntry: PropTypes.func.isRequired,
    getVotingData: PropTypes.func.isRequired,
    votingData: PropTypes.shape({}),
    onSubmitNew: PropTypes.func.isRequired,
    onUpload: PropTypes.func.isRequired,
    onDeleteFile: PropTypes.func.isRequired,
    sortEntries: PropTypes.func.isRequired,
    onUnvoteEntry: PropTypes.func.isRequired,
    extraSettings: ExtraSettingsPropType.isRequired,
};

ExhibitionEntriesList.defaultProps = {
    approvedEntries: [],
    votingData: {},
};
