/* eslint max-len: 0 */
import React from "react";

function SVG() {
    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="602.678px"
            height="449.329px"
            viewBox="-55 800.562 602.678 449.329"
            enableBackground="new -55 800.562 602.678 449.329"
        >
            <g>
                <defs>
                    <rect id="SVGID_1_" x="-55" y="800.562" width="602.678" height="449.328" />
                </defs>
                <path fill="#4F78BA" d="M496.423,1239.083c42.344-23.252,65.241-82.973,41.983-125.312 c-11.131-20.266-29.121-34.352-49.492-41.004c-19.832-7.65-80.494-30.799-127.101-45.957 C257.177,992.774,42.531,896.608,92.948,917.019c54.423,22.037,137.935,1.965,137.935,1.965c9.586-1.66,17.411-3.516,25.688-8.059 c28.466-15.637,38.874-51.389,23.249-79.855c-15.638-28.467-51.396-38.869-79.864-23.235c-5.408,2.97-10.154,6.665-14.202,10.897 c-16.45,13.123-62.844,47.951-103.3,57.756C30.899,888.995-55,864.251-55,864.251s64.687,67.68,79.86,110.971 c15.167,43.266-2.983,127.6-2.983,127.6l0.302,0.025c-2.172,12.65-0.254,26.092,6.407,38.213 c15.632,28.473,51.38,38.877,79.851,23.238c23.676-13.002,34.842-39.916,28.939-64.988c-1.941-14.896-7.061-40.635-20.406-62.873 c-9.844-16.395-33.394-44.963-54.947-69.947c108.686,88.307,336.087,263.295,336.087,263.295l0.151-0.248 C425.095,1251.942,463.944,1256.925,496.423,1239.083" />
            </g>
        </svg>
    );
}

export default SVG;
