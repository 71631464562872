/* eslint max-len: 0 */
import React from "react";
import PropTypes from "prop-types";

const pinStyle = {
    cursor: "pointer",
    marginLeft: "-15px",
    marginTop: "-25px",
};

export const SVG_PIN_PATHS_COUNT = 8;

function CityPin({ onClick, pinSvgIndex, isPinActive }) {
    const SVG_PINS = [{
        inactive: <path pointerEvents="all" onClick={onClick} fill="#5077BA" d="M50.418,79.338l-1.302-24.336c-0.009-0.176-0.027-0.354-0.054-0.527c-0.012-0.078-0.03-0.156-0.047-0.234 c-0.019-0.092-0.034-0.188-0.058-0.279c-0.022-0.088-0.054-0.174-0.079-0.26c-0.025-0.082-0.047-0.162-0.076-0.24 c-0.03-0.088-0.067-0.166-0.102-0.252c-0.033-0.076-0.064-0.158-0.102-0.234c-0.036-0.076-0.079-0.15-0.119-0.225 c-0.044-0.08-0.084-0.16-0.132-0.238c-0.042-0.07-0.091-0.139-0.137-0.209c-0.051-0.074-0.101-0.152-0.155-0.225 C48,52,47.937,51.93,47.874,51.857c-0.051-0.061-0.099-0.125-0.152-0.184c-0.119-0.131-0.244-0.256-0.376-0.373 c-0.131-0.119-0.268-0.23-0.41-0.334c-0.064-0.047-0.133-0.089-0.199-0.133c-0.077-0.053-0.155-0.108-0.238-0.156 c-0.078-0.048-0.161-0.088-0.242-0.13c-0.073-0.038-0.146-0.081-0.221-0.116c-0.084-0.037-0.167-0.07-0.251-0.104 c-0.077-0.031-0.155-0.065-0.235-0.095c-0.081-0.027-0.164-0.051-0.245-0.074c-0.087-0.027-0.173-0.054-0.26-0.076 c-0.081-0.021-0.164-0.033-0.246-0.049c-0.09-0.018-0.178-0.037-0.269-0.051c-0.095-0.014-0.189-0.02-0.285-0.028 c-0.079-0.008-0.158-0.019-0.237-0.021c-0.178-0.008-0.353-0.01-0.53,0.001l-24.335,1.302c-2.957,0.158-5.226,2.684-5.067,5.639l0,0 c0.156,2.957,2.683,5.227,5.64,5.066l11.409-0.607L4.842,90.586c-1.979,2.201-1.798,5.592,0.403,7.572 c2.203,1.979,5.595,1.797,7.572-0.406L39.1,68.5l0.61,11.41c0.158,2.957,2.683,5.227,5.642,5.068l0,0 C48.307,84.82,50.576,82.295,50.418,79.338z" />,
        active: <path d="M50.418,79.338l-1.302-24.336c-0.009-0.176-0.027-0.354-0.054-0.527c-0.012-0.078-0.03-0.156-0.047-0.234 c-0.019-0.092-0.034-0.188-0.058-0.279c-0.022-0.088-0.054-0.174-0.079-0.26c-0.025-0.082-0.047-0.162-0.076-0.24 c-0.03-0.088-0.067-0.166-0.102-0.252c-0.033-0.076-0.064-0.158-0.102-0.234c-0.036-0.076-0.079-0.15-0.119-0.225 c-0.044-0.08-0.084-0.16-0.132-0.238c-0.042-0.07-0.091-0.139-0.137-0.209c-0.051-0.074-0.101-0.152-0.155-0.225 C48,52,47.937,51.93,47.874,51.857c-0.051-0.061-0.099-0.125-0.152-0.184c-0.119-0.131-0.244-0.256-0.376-0.373 c-0.131-0.119-0.268-0.23-0.41-0.334c-0.064-0.047-0.133-0.089-0.199-0.133c-0.077-0.053-0.155-0.108-0.238-0.156 c-0.078-0.048-0.161-0.088-0.242-0.13c-0.073-0.038-0.146-0.081-0.221-0.116c-0.084-0.037-0.167-0.07-0.251-0.104 c-0.077-0.031-0.155-0.065-0.235-0.095c-0.081-0.027-0.164-0.051-0.245-0.074c-0.087-0.027-0.173-0.054-0.26-0.076 c-0.081-0.021-0.164-0.033-0.246-0.049c-0.09-0.018-0.178-0.037-0.269-0.051c-0.095-0.014-0.189-0.02-0.285-0.028 c-0.079-0.008-0.158-0.019-0.237-0.021c-0.178-0.008-0.353-0.01-0.53,0.001l-24.335,1.302c-2.957,0.158-5.226,2.684-5.067,5.639l0,0 c0.156,2.957,2.683,5.227,5.64,5.066l11.409-0.607L4.842,90.586c-1.979,2.201-1.798,5.592,0.403,7.572 c2.203,1.979,5.595,1.797,7.572-0.406L39.1,68.5l0.61,11.41c0.158,2.957,2.683,5.227,5.642,5.068l0,0 C48.307,84.82,50.576,82.295,50.418,79.338z" />,
    }, {
        inactive: <polygon pointerEvents="all" onClick={onClick} fill="#6EBF62" points="85.887,0.559 56.754,29.691 52.024,24.962 49,51 75.209,48.146 70.65,43.589 99.783,14.455 " />,
        active: <polygon points="85.887,0.559 56.754,29.691 52.024,24.962 49,51 75.209,48.146 70.65,43.589 99.783,14.455 " />,
    }, {
        inactive: <path pointerEvents="all" onClick={onClick} fill="#5C72B4" d="M21.022,29.436l12.42,12.42l-5.374-0.575l-0.186,9.198L49,50.904l-0.428-21.115l-9.199,0.188l0.577,5.373 L27.528,22.93c-2.197-2.197-2.197-5.771,0-7.968l6.206-6.206L27.229,2.25l-6.206,6.206C20.87,8.607,20.725,8.764,20.58,8.92 L7.015,22.486c-0.157,0.145-0.313,0.291-0.466,0.443l-6.205,6.205l6.506,6.506l6.205-6.205 C15.251,27.238,18.825,27.238,21.022,29.436" />,
        active: <path d="M21.022,29.436l12.42,12.42l-5.374-0.575l-0.186,9.198L49,50.904l-0.428-21.115l-9.199,0.188l0.577,5.373L27.528,22.93 c-2.197-2.197-2.197-5.771,0-7.968l6.206-6.206L27.229,2.25l-6.206,6.206C20.87,8.607,20.725,8.764,20.58,8.92L7.015,22.486 c-0.157,0.145-0.313,0.291-0.466,0.443l-6.205,6.205l6.506,6.506l6.205-6.205C15.251,27.238,18.825,27.238,21.022,29.436" />,
    }, {
        inactive: <polygon pointerEvents="all" onClick={onClick} fill="#E5002E" points="48.97,51 16.501,55.478 22.646,60.976 5.206,99.946 41.989,78.276 48.137,83.771 " />,
        active: <polygon points="48.97,51 16.501,55.478 22.646,60.976 5.206,99.946 41.989,78.276 48.137,83.771 " />,
    }, {
        inactive: <path pointerEvents="all" onClick={onClick} fill="#E5002E" d="M98.56,8.938c-1.051-4.564-6.021-8.281-10.587-7.227c-2.187,0.5-3.978,1.807-5.136,3.531 c-1.238,1.65-5.002,6.699-7.648,10.648c-5.943,8.871-20.593,26.363-17.354,22.193c3.495-4.492,3.829-12.822,3.829-12.822l0,0 c0.098-0.939,0.131-1.719-0.074-2.607c-0.705-3.07-3.768-4.988-6.836-4.281c-3.073,0.707-4.986,3.77-4.283,6.84 c0.135,0.582,0.355,1.121,0.645,1.613c0.789,1.881,2.814,7.133,2.659,11.166C53.578,43.137,49,50.5,49,50.5s8.025-4.25,12.468-4.52 c4.44-0.275,11.829,3.648,11.829,3.648l0.013-0.027c1.121,0.537,2.428,0.713,3.732,0.414c3.072-0.707,4.988-3.77,4.283-6.842 c-0.588-2.553-2.809-4.305-5.302-4.416c-1.444-0.213-3.981-0.418-6.406,0.242c-1.794,0.484-5.083,1.928-7.983,3.277 c11.115-7.809,33.459-24.408,33.459-24.408l-0.018-0.02C97.874,15.934,99.366,12.441,98.56,8.938z" />,
        active: <path d="M98.56,9.438c-1.051-4.564-6.021-8.281-10.587-7.227c-2.187,0.5-3.978,1.807-5.136,3.531 c-1.238,1.65-5.002,6.699-7.648,10.648c-5.943,8.871-20.593,26.363-17.354,22.193c3.495-4.492,3.829-12.822,3.829-12.822l0,0 c0.098-0.939,0.131-1.719-0.074-2.607c-0.705-3.07-3.768-4.988-6.836-4.281c-3.073,0.707-4.986,3.77-4.283,6.84 c0.135,0.582,0.355,1.121,0.645,1.613c0.789,1.881,2.814,7.133,2.659,11.166C53.578,43.637,49,51,49,51s8.025-4.25,12.468-4.52 c4.44-0.275,11.829,3.648,11.829,3.648l0.013-0.027c1.121,0.537,2.428,0.713,3.732,0.414c3.072-0.707,4.988-3.77,4.283-6.842 c-0.588-2.553-2.809-4.305-5.302-4.416c-1.444-0.213-3.981-0.418-6.406,0.242c-1.794,0.484-5.083,1.928-7.983,3.277 c11.115-7.809,33.459-24.408,33.459-24.408l-0.018-0.02C97.874,16.434,99.366,12.941,98.56,9.438z" />,
    }, {
        inactive: <path pointerEvents="all" onClick={onClick} fill="#FFDD15" d="M77.959,72.109c7.309,6.85,14.202,12.731,21.492,19.562c-2.138,2.304-4.242,4.567-6.433,6.925 c-7.295-6.829-14.228-12.739-21.521-19.564c-2.981,4.531-5.895,8.962-8.955,13.614C58.092,78.962,53.459,64.711,49,51 c13.716,3.334,27.572,7.466,41.475,10.844C86.226,65.328,82.19,68.64,77.959,72.109z" />,
        active: <path d="M77.959,72.109c7.309,6.85,14.202,12.731,21.492,19.562c-2.138,2.304-4.242,4.567-6.433,6.925 c-7.295-6.829-14.228-12.739-21.521-19.564c-2.981,4.531-5.895,8.962-8.955,13.614C58.092,78.962,53.459,64.711,49,51 c13.716,3.334,27.572,7.466,41.475,10.844C86.226,65.328,82.19,68.64,77.959,72.109z" />,
    }, {
        inactive: <path pointerEvents="all" onClick={onClick} fill="#FFDC00" d="M33.315,36.303l-8.975,8.973L50.5,50.527l-5.442-25.998l-8.987,8.986c-0.914-1.523-1.331-2.986-1.469-4.918 c-0.339-3.701,1.554-8.254,3.972-11.232L22.516,1.52c-4.151,5.475-5.812,12.029-2.973,18.42c-6.208-2.879-13.451-1.197-18.511,3.064 l15.952,15.951c3.106-2.504,8.808-4.539,12.728-3.924C31.036,35.244,32.101,35.574,33.315,36.303" />,
        active: <path d="M33.315,36.303l-8.975,8.973L50.5,50.527l-5.442-25.998l-8.987,8.986c-0.914-1.523-1.331-2.986-1.469-4.918 c-0.339-3.701,1.554-8.254,3.972-11.232L22.516,1.52c-4.151,5.475-5.812,12.029-2.973,18.42c-6.208-2.879-13.451-1.197-18.511,3.064 l15.952,15.951c3.106-2.504,8.808-4.539,12.728-3.924C31.036,35.244,32.101,35.574,33.315,36.303" />,
    }, {
        inactive: <path pointerEvents="all" onClick={onClick} fill="#6EBF62" d="M0.314,91.966c1.051,4.564,6.021,8.281,10.587,7.227c2.187-0.5,3.978-1.807,5.136-3.531 c1.238-1.65,5.002-6.699,7.648-10.648c5.943-8.871,20.593-26.363,17.354-22.193c-3.495,4.492-3.829,12.822-3.829,12.822l0,0 c-0.098,0.939-0.131,1.719,0.074,2.607c0.705,3.07,3.768,4.988,6.836,4.281c3.073-0.707,4.986-3.77,4.283-6.84 c-0.135-0.582-0.355-1.121-0.645-1.613c-0.789-1.881-2.814-7.133-2.659-11.166c0.195-5.145,4.773-12.508,4.773-12.508 s-8.025,4.25-12.468,4.52c-4.44,0.275-11.829-3.648-11.829-3.648l-0.013,0.027c-1.121-0.537-2.428-0.713-3.732-0.414 c-3.072,0.707-4.988,3.77-4.283,6.842c0.588,2.553,2.809,4.305,5.302,4.416c1.444,0.213,3.981,0.418,6.406-0.242 c1.794-0.484,5.083-1.928,7.983-3.277C26.125,66.435,3.781,83.034,3.781,83.034l0.018,0.02C1,84.97-0.492,88.462,0.314,91.966z" />,
        active: <path d="M0.314,91.966c1.051,4.564,6.021,8.281,10.587,7.227c2.187-0.5,3.978-1.807,5.136-3.531 c1.238-1.65,5.002-6.699,7.648-10.648c5.943-8.871,20.593-26.363,17.354-22.193c-3.495,4.492-3.829,12.822-3.829,12.822l0,0 c-0.098,0.939-0.131,1.719,0.074,2.607c0.705,3.07,3.768,4.988,6.836,4.281c3.073-0.707,4.986-3.77,4.283-6.84 c-0.135-0.582-0.355-1.121-0.645-1.613c-0.789-1.881-2.814-7.133-2.659-11.166c0.195-5.145,4.773-12.508,4.773-12.508 s-8.025,4.25-12.468,4.52c-4.44,0.275-11.829-3.648-11.829-3.648l-0.013,0.027c-1.121-0.537-2.428-0.713-3.732-0.414 c-3.072,0.707-4.988,3.77-4.283,6.842c0.588,2.553,2.809,4.305,5.302,4.416c1.444,0.213,3.981,0.418,6.406-0.242 c1.794-0.484,5.083-1.928,7.983-3.277C26.125,66.435,3.781,83.034,3.781,83.034l0.018,0.02C1,84.97-0.492,88.462,0.314,91.966z" />,
    }];

    return (
        <span>
            <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="50px"
                height="50px"
                viewBox="0 0 100 100"
                enableBackground="new 0 0 100 100"
                style={pinStyle}
                pointerEvents="none"
            >
                {isPinActive ? SVG_PINS[pinSvgIndex].active : SVG_PINS[pinSvgIndex].inactive}
            </svg>
        </span>
    );
}

export default CityPin;

CityPin.propTypes = {
    onClick: PropTypes.func.isRequired,
    pinSvgIndex: PropTypes.number,
    isPinActive: PropTypes.bool.isRequired,
};

CityPin.defaultProps = {
    pinSvgIndex: 0,
};
