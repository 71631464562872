import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import SVGinfoIcon from "../../media/svgs/common/info-icon";

function FormTooltip({ id, text, svgStyle }) {
    return (
        <OverlayTrigger
            key="bottom"
            placement="bottom"
            overlay={(
                <Tooltip id={`tooltip-bottom-${id}`}>{text}</Tooltip>
            )}
        >
            <div className="custom-form-info-button" style={svgStyle}><SVGinfoIcon /></div>
        </OverlayTrigger>
    );
}

export default FormTooltip;
