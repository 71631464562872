import * as Yup from "yup";
import Content from "../content/AdminTextContent";

const validationText = Content.login.validation;

const validationSchemas = {
    adminLoginForm: Yup.object().shape({
        username: Yup.string()
            .min(2, validationText.tooShort)
            .max(70, validationText.tooLong)
            .required(validationText.required),
        password: Yup.string()
            .min(4, validationText.tooShort)
            .required(validationText.required),
    }),
    addNewSurveyForm: Yup.object().shape({
        title: Yup.string()
            .min(3, validationText.tooShort)
            .max(30, validationText.tooLong)
            .required(validationText.required),
        startDate: Yup.date().required,
        endDate: Yup.date().required,
        questions: Yup.array(Yup.object().shape({
            text: Yup.string().required,
            answers: Yup.array(Yup.object().shape({
                text: Yup.string().required,
                type: Yup.string().required,
            })),
        })),
    }),
    addNewMapPin: Yup.object().shape({
        title: Yup.string()
            .trim()
            .max(40, validationText.tooLong)
            .required(validationText.required),
        description: Yup.string()
            .trim()
            .max(400, validationText.tooLong)
            .required(validationText.required),
        contentUrl: Yup.string()
            .required(validationText.required),
        mediaOwningAgree: Yup.bool()
            .test("mediaOwningAgree", "Morate potvrditi.", (value) => value === true)
            .required(
                "Morate potvrditi."
            ),
    }),
    addNewExhibitionEntry: Yup.object().shape({
        title: Yup.string()
            .trim()
            .max(40, validationText.tooLong)
            .required(validationText.required),
        description: Yup.string()
            .trim()
            .max(400, validationText.tooLong)
            .required(validationText.required),
        userName: Yup.string()
            .trim()
            .max(80, validationText.tooLong)
            .required(validationText.required),
        userEmail: Yup.string().email("Email mora biti ispravnog formata.")
            .trim()
            .required(validationText.required)
            .max(80, validationText.tooLong),
        contentUrl: Yup.string()
            .required(validationText.required),
        dataProcessingEmailAgree: Yup.bool()
            .test("dataProcessingEmailAgree", "Morate se složiti da Vas kontaktiramo.", (value) => value === true)
            .required(
                "Morate se složiti da Vas kontaktiramo."
            ),
        mediaOwningAgree: Yup.bool()
            .test("mediaOwningAgree", "Morate potvrditi.", (value) => value === true)
            .required(
                "Morate potvrditi."
            ),
    }),
    addNewExhibitionSubject: Yup.object().shape({
        title: Yup.string()
            .trim()
            .max(40, validationText.tooLong)
            .required(validationText.required),
        description: Yup.string()
            .trim()
            .max(400, validationText.tooLong)
            .required(validationText.required),
        userName: Yup.string()
            .trim()
            .max(80, validationText.tooLong)
            .required(validationText.required),
        userEmail: Yup.string().email("Email mora biti ispravnog formata.")
            .trim()
            .required(validationText.required)
            .max(80, validationText.tooLong),
        dataProcessingEmailAgree: Yup.bool()
            .test("dataProcessingEmailAgree", "Morate se složiti da Vas kontaktiramo.", (value) => value === true)
            .required(
                "Morate se složiti da Vas kontaktiramo."
            ),
    }),
    addNewExhibitionEntriesPage: Yup.object().shape({
        alias: Yup.string()
            .trim()
            .min(2, validationText.tooShort)
            .max(10, validationText.tooLong)
            .required(validationText.required),
        content: Yup.array(Yup.object().shape({
            title: Yup.string()
                .min(5, validationText.tooShort)
                .max(20, validationText.tooLong)
                .required(validationText.required),
            text: Yup.string()
                .min(10, validationText.tooShort)
                .max(1000, validationText.tooLong)
                .required(validationText.required),
            tag: Yup.string()
                .min(0, validationText.tooShort)
                .max(20, validationText.tooLong),
        })),
    }),
};

export default validationSchemas;
