import React from "react";
import PropTypes from "prop-types";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import { SurveyPropType } from "../../../customPropTypes";
import Survey from "./Survey";
import SVGarrowDown from "../../../media/svgs/common/arrow-down";
import SVGarrowUp from "../../../media/svgs/common/arrow-up";

function SurveyAccordion({ survey, surveyVoting, surveyIndex }) {
    const [surveyCollapsed, setSurveyCollapsed] = React.useState(false);
    const [currentQuestionOrder, setCurrentQuestionOrder] = React.useState(1);

    function hasVoted() {
        return surveyVoting[survey.id] === "yes";
    }

    return (
        <Accordion className="custom-survey-accordion">
            <Card>
                <Accordion.Toggle
                    as={Card.Header}
                    eventKey="1"
                    onClick={() => setSurveyCollapsed(!surveyCollapsed)}
                >
                    {surveyCollapsed && hasVoted() ? `${currentQuestionOrder}/${survey.questions.length}` : survey.title}
                    {surveyCollapsed ? <SVGarrowUp /> : <SVGarrowDown />}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="1">
                    <Card.Body>
                        <Survey
                            surveyIndex={surveyIndex}
                            data={survey}
                            canVoteSurvey={surveyVoting[survey.id]}
                            onNextQuestionClicked={
                                () => setCurrentQuestionOrder(currentQuestionOrder + 1)
                            }
                            onPreviousQuestionClicked={
                                () => setCurrentQuestionOrder(currentQuestionOrder - 1)
                            }
                        />
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    );
}

export default SurveyAccordion;

SurveyAccordion.propTypes = {
    survey: SurveyPropType.isRequired,
    surveyVoting: PropTypes.shape({}).isRequired,
};
