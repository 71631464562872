let backendHost;

const hostname = window && window.location && window.location.hostname;


if (hostname.includes("muzejbuducnosti.eu")) {
    backendHost = "https://muzejbuducnosti.eu";
} else {
    backendHost = process.env.REACT_APP_BACKEND_HOST || "https://localhost:5001";
}

const API_ROOT = `${backendHost}/api`;

export default API_ROOT;
