import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router";
import { ConnectedRouter } from "connected-react-router";
import ReactGA from "react-ga";
import configureStore, { history } from "./store";
import "./index.css";
import AdminAppContainer from "./containers/admin/AdminAppContainer";
import AppContainer from "./containers/website/AppContainer";
import * as serviceWorker from "./serviceWorker";
import "@fortawesome/fontawesome-free/css/all.css";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import "./css/custom-bootstrap.scss";
import "react-bootstrap-toggle/dist/bootstrap2-toggle.css";
import "./css/custom-admin.scss";
import "./css/custom-website.scss";
import "react-datepicker/dist/react-datepicker.css";
import { GA_ID } from "./helpers/constants";

ReactGA.initialize(GA_ID);
ReactGA.pageview(window.location.pathname + window.location.search);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

ReactDOM.render(
    <Provider store={configureStore()}>
        <ConnectedRouter history={history}>
            <Switch>
                <Route path="/admin" component={AdminAppContainer} />
                <Route
                    path="/"
                    render={(props) => <AppContainer history={props.history} pathname={history.location.pathname} />}
                />
            </Switch>
        </ConnectedRouter>
    </Provider>,
    document.getElementById("root")
);
// serviceWorker.register();
