import React from "react";

function SVG() {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="35px" height="35px" viewBox="0 0 35 35" enableBackground="new 0 0 35 35">
            <g>
                <path d="M17.5,35C27.166,35,35,27.165,35,17.5S27.166,0,17.5,0C7.836,0,0,7.835,0,17.5S7.836,35,17.5,35"/>
                <g>
                    <path fill="#FFFFFF" d="M15.197,9.379c0-1.193,1-1.926,2.303-1.926c1.301,0,2.303,0.732,2.303,1.926v0.057 c0,1.191-1.002,1.951-2.303,1.949c-1.303,0-2.303-0.758-2.303-1.949V9.379z M15.439,14.748c0-1.139,0.922-2.059,2.061-2.059 s2.061,0.92,2.061,2.061v10.734c0,1.139-0.922,2.062-2.061,2.062s-2.061-0.924-2.061-2.062V14.748z" />
                </g>
                <path d="M17.5,35C27.166,35,35,27.165,35,17.5S27.166,0,17.5,0C7.836,0,0,7.835,0,17.5S7.836,35,17.5,35" />
                <g>
                    <path fill="#FFFFFF" d="M15.197,9.379c0-1.193,1-1.926,2.303-1.926c1.301,0,2.303,0.732,2.303,1.926v0.057 c0,1.191-1.002,1.951-2.303,1.949c-1.303,0-2.303-0.758-2.303-1.949V9.379z M15.439,14.748c0-1.139,0.922-2.059,2.061-2.059 s2.061,0.92,2.061,2.061v10.734c0,1.139-0.922,2.062-2.061,2.062s-2.061-0.924-2.061-2.062V14.748z" />
                </g>
            </g>
        </svg>
    );
}

export default SVG;
