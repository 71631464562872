import PropTypes from "prop-types";

export const SurveyPropType = PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    questions: PropTypes.arrayOf(PropTypes.shape({
        text: PropTypes.string,
        answers: PropTypes.arrayOf(PropTypes.shape({
            text: PropTypes.string,
            votes: PropTypes.number,
            type: PropTypes.number,
        })),
    })),
});

export const SurveyListPropType = PropTypes.arrayOf(SurveyPropType);

export const HistoryPropType = PropTypes.shape({
    push: PropTypes.func,
});

export const MatchPropType = PropTypes.shape({
    path: PropTypes.string,
});

export const ExhibitionEntryPropType = PropTypes.shape({
    currentEntryNumber: PropTypes.number,
    date: PropTypes.string,
    description: PropTypes.string,
    contentUrl: PropTypes.string,
    title: PropTypes.string,
    userEmail: PropTypes.string,
    userName: PropTypes.string,
    votes: PropTypes.number,
});

export const ExhibitionSubjectPropType = PropTypes.shape({
    created: PropTypes.string,
    description: PropTypes.string,
    id: PropTypes.number,
    status: PropTypes.number,
    votes: PropTypes.number,
});

export const ExhibitionEntryListPropType = PropTypes.shape({
    new: PropTypes.arrayOf(ExhibitionEntryPropType),
    approved: PropTypes.arrayOf(ExhibitionEntryPropType),
    rejected: PropTypes.arrayOf(ExhibitionEntryPropType),
});

export const ExhibitionSubjectListPropType = PropTypes.shape({
    new: PropTypes.arrayOf(ExhibitionSubjectPropType),
    approved: PropTypes.arrayOf(ExhibitionSubjectPropType),
    rejected: PropTypes.arrayOf(ExhibitionSubjectPropType),
});

export const MapPinPropType = PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    pinSvgIndex: PropTypes.number,
    pinInfos: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        description: PropTypes.string,
        imageUrl: PropTypes.string,
    })),
});

export const MapPinListPropType = PropTypes.arrayOf(MapPinPropType);

export const SomethingElseAnswersPropType = PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    votes: PropTypes.number,
}));

export const ExtraSettingsPropType = PropTypes.shape({
    hideBackgrounds: PropTypes.bool,
    increaseFontSize: PropTypes.bool,
    cookiesAccepted: PropTypes.bool,
});

export const FormStatusPropType = PropTypes.shape({
    type: PropTypes.string,
    message: PropTypes.string,
});

export const PagePropType = PropTypes.shape({
    activeFrom: PropTypes.string,
    activeTo: PropTypes.string,
    alias: PropTypes.string,
    contents: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        text: PropTypes.string,
        tag: PropTypes.string,
    })),
    exhibitionEntries: PropTypes.arrayOf(ExhibitionEntryPropType),
    exhibitionSubjects: PropTypes.arrayOf(ExhibitionEntryPropType),
});

export const PagesPropType = PropTypes.shape({
    archived: PropTypes.arrayOf(PropTypes.shape(PagePropType)),
    current: PagePropType,
    planned: PropTypes.arrayOf(PagePropType),
});
