import React from "react";
import PropTypes from "prop-types";
import uuidv1 from "uuid/v1";
import Moment from "moment";
import { Link } from "react-router-dom";
import { extendMoment } from "moment-range";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { SurveyListPropType, ExtraSettingsPropType, PagePropType } from "../../customPropTypes";
import { SURVEY_STATUS } from "../../helpers/constants";
import SVGtitle from "../../media/svgs/homepage/main-title";
import SVGyellowArrow from "../../media/svgs/homepage/yellow-arrow";
import SVGaboutProject from "../../media/svgs/homepage/about-the-project";
import SVGillustration from "../../media/svgs/homepage/illustration";
import InfoAccordion from "./InfoAccordion";
import SVGgreenArrow from "../../media/svgs/homepage/green-arrow";
import SVGredBottomArrow from "../../media/svgs/homepage/red-bottom-arrow";
import GMVtext from "./GMVtext";
import SurveyAccordion from "./survey/SurveyAccordion";
import Content from "../../content/WebsiteTextContent";

const moment = extendMoment(Moment);
const textContent = Content.homepage;

function Homepage({
    surveys, surveyVoting, getSurveys, extraSettings, archiveEntriesPages, archiveSubjectsPages,
}) {
    const activeSurveys = surveys.reduce((accum, currentSurvey) => {
        const start = currentSurvey.startDate;
        const end = currentSurvey.endDate;
        const range = moment.range(start, end);
        const today = new Date();

        if (currentSurvey.status === SURVEY_STATUS.ACTIVE && range.contains(today)) {
            return [...accum, currentSurvey];
        }
        return accum;
    }, []);

    React.useEffect(() => {
        getSurveys();
    }, [getSurveys]);

    const renderSectionIntro = (content, link) => (
        <div>
            <h4>{content.title}</h4>
            <p>
                {content.text}
                <br />
                <Link to={link} className="custom-link">
                    {content.linkText}
                    &gt;
                </Link>
            </p>
        </div>
    );

    return (
        <Container className="custom-homepage">
            {!extraSettings.hideBackgrounds && (
                <div>
                    <div className="custom-arrow-wrapper-yellow">
                        <SVGyellowArrow />
                    </div>
                    <div className="custom-arrow-wrapper-green">
                        <SVGgreenArrow />
                    </div>
                    <div className="custom-arrow-wrapper-red-bottom">
                        <SVGredBottomArrow />
                    </div>
                </div>
            )}
            <Row>
                <Col lg="6" style={{ marginTop: "20px" }} className="custom-homepage-title-wrapper">
                    <SVGtitle />
                </Col>
                <Col lg={{ span: 4 }} sm="12" className="custom-homepage-about-the-platform">
                    <h4>{textContent.aboutPlatformTitle}</h4>
                    <p>{textContent.aboutPlatformText}</p>
                </Col>
            </Row>
            <Row>
                <Col md="12" lg="5" className="custom-homepage-survey-intro">
                    <div>
                        <h4>{textContent.survey.title}</h4>
                        <p>{textContent.survey.text}</p>
                        {activeSurveys.length > 0 && activeSurveys.map((survey, index) => (
                            <SurveyAccordion
                                key={uuidv1()}
                                surveyIndex={index}
                                surveyVoting={surveyVoting}
                                survey={survey}
                            />
                        ))}
                    </div>
                </Col>
                <Col md="12" lg={{ span: 5, offset: 2 }} className="custom-homepage-suggest-intro">
                    {renderSectionIntro(textContent.exhibitionEntries, "/prijedlozi/")}
                </Col>
            </Row>
            <Row className="custom-homepage-suggest-intro">
                <Col md="12" lg="6">
                    {renderSectionIntro(textContent.map, "/dozivljaji/")}
                </Col>
                <Col md="12" lg="4" className="custom-homepage-suggest-subject">
                    {renderSectionIntro(textContent.subjects, "/programi/")}
                </Col>
            </Row>
            <Row className="custom-homepage-second-title-wrapper">
                <SVGaboutProject />
            </Row>
            <Row>
                <Col lg="6">
                    <div className="custom-homepage-illustration-wrapper"><SVGillustration /></div>
                    <p className="custom-homepage-p-type1">
                        <span style={{ fontWeight: "500" }}>{textContent.aboutCivilCouncil.titleBold}</span>
                        <br />
                        <br />
                        {textContent.aboutCivilCouncil.text}
                        <br />
                        <br />
                        <span>Arhiva predloženih predmeta:</span>
                        <br />
                        {archiveEntriesPages && archiveEntriesPages.map((archive) => (
                            <Link
                                to={`/arhiva/predmeti/${archive.alias.replace(/ /g, "")}`}
                                className="custom-archive-link"
                            >
                                {archive.alias}
                            </Link>
                        ))}
                        <br />
                        <br />
                        <span>Arhiva predloženih programa:</span>
                        <br />
                        {archiveSubjectsPages && archiveSubjectsPages.map((archive) => (
                            <Link
                                to={`/arhiva/programi/${archive.alias.replace(/ /g, "")}`}
                                className="custom-archive-link"
                            >
                                {archive.alias}
                            </Link>
                        ))}
                    </p>
                    <h4 className="custom-homepage-heading-type1">{textContent.projectResults.title}</h4>
                    <p className="custom-homepage-p-type2">{textContent.projectResults.text.point1}</p>
                    <p className="custom-homepage-p-type2">{textContent.projectResults.text.point2}</p>
                    <p className="custom-homepage-p-type2">{textContent.projectResults.text.point3}</p>
                </Col>
                <Col lg="6" className="custom-homepage-column">
                    <h4>{textContent.aboutProjectTitle}</h4>
                    <p className="custom-homepage-p-wider">{textContent.aboutProjectText}</p>
                    <GMVtext />
                    <InfoAccordion />
                </Col>
            </Row>
        </Container>
    );
}

export default Homepage;

Homepage.propTypes = {
    surveys: SurveyListPropType,
    surveyVoting: PropTypes.shape({}).isRequired,
    getSurveys: PropTypes.func.isRequired,
    extraSettings: ExtraSettingsPropType.isRequired,
    archiveEntriesPages: PropTypes.arrayOf(PagePropType),
    archiveSubjectsPages: PropTypes.arrayOf(PagePropType),
};

Homepage.defaultProps = {
    surveys: [],
    archiveEntriesPages: [],
    archiveSubjectsPages: [],
};
