import React from "react";
import PropTypes from "prop-types";

function VotesNumber({ votesData, id }) {
    const entryVotes = votesData[id];

    return (
        <span>{`Broj glasova: ${entryVotes}`}</span>
    );
}

export default VotesNumber;

VotesNumber.propTypes = {
    votesData: PropTypes.shape().isRequired,
    id: PropTypes.number.isRequired,
};
