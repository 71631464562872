/* eslint max-len: 0 */

const Content = {
    exhibitionEntriesPage: {
        accordionData: [{
            title: "Koji predmeti mogu činiti Civilnu muzejsku zbirku?",
            text: ["1. Predlagati možete materijalne (fizičke) predmete koji su u vašem vlasništvu i koje ste voljni DONIRATI muzeju.", "2. Predlagati možete predmete nematerijalne naravi (primjerice pjesmu za koju smatrate da je obilježila vašu generaciju ili recept za jelo koje vas podsjeća na djetinjstvo). Ako niste autor niti vlasnik prijedloga kojeg podnosite, Muzej će u skladu sa Zakonom o autorskim i srodnim pravima provjeriti mogućnost njegova korištenja.", "3. Predlagati možete predmete iz vašeg neposrednog okruženja za koje smatrate da:", "a) najbolje karakteriziraju vrijeme i prostor u kojem živimo", "b) utjelovljuju specifično iskustvo, sjećanje ili doživljaj vaše svakodnevice", "c) će  biti korisne budućim generacijama u razumijevanju života danas", "d) imaju potencijal najaviti promjene koje nas čekaju u budućnosti."],
        }, {
            title: "Kako predložiti predmet za Civilnu muzejsku zbirku?",
            text: ["1 | Objasniti (rečenicom ili dvije) koji predmet predlažete za Civilnu muzejsku zbirku i zašto?", "2 | Učitati fotografiju, video ili audio zapis vašeg prijedloga. Primjerice, ako predlažete da dio Civilne muzejske zbirke budu vaše stare tenisice “Starke” trebate nam poslati njihovu fotografiju. Ako predlažete da to bude, primjerice, plaža Sablićevo na Pećinama, jer smatrate da bi tijekom idućih 50ak godina mogla poprimiti sasvim drugačiji izgled, pošaljite nam fotografiju ili video kako Sablićevo danas izgleda. Ako predlažete da u zbirku uđu sjećanja vašeg oca ili djeda koji je radio, primjerice, u tvornici Rikard Benčić, pošaljite nam kratak audio snimak razgovora s njime…"],
        }, {
            title: "Što s predmetima nakon odabira?",
            text: ["Pet novih predmeta odabranih za Civilnu muzejsku zbirku bit će svake godine predstavljeni javnosti putem izložbe. Potom ulaze u fundus Muzeja, što znači da se, poput ostalih muzejskih predmeta, inventiraju (obrađuju), interpretiraju te čuvaju za buduće generacije."],
        }, {
            title: "Kako se među prijedlozima odabire 5 predmeta za Civilnu muzejsku zbirku?",
            text: ["Osim što ste pozvani predlagati predmete za Civilnu muzejsku zbirku, pozvani ste i podržati druge prijedloge u vidu oznake “sviđa mi se”. Pet prijedloga s najviše oznaka “sviđa mi se” postat će dijelom zbirke.", "Postupak predlaganja i odabira ponavlja se svake godine iznova.", "Zašto se radi odabir? Zašto svi predloženi predmeti ne mogu postati dijelom zbirke? Muzej raspolaže ograničenim prostorima (depoima) u kojima se predmeti čuvaju prema pravilima muzejske struke pa ih je potrebno koristiti racionalno."],
        }, {
            title: "Predomislili ste se? Kako povući svoj prijedlog?",
            text: ["Kontaktirajte nas na muzejbuducnosti@gmail.com i Vaš će prijedlog biti uklonjen."],
        }],
    },
    exhibitionSubjectsPage: {
        // intro3: "Osim predlaganja, oznakom sviđa mi se možete podržati i prijedloge drugih korisnika. Prijedlozi s najviše oznaka sviđa mi se predstavljat će vrijedne smjernice Građanskom muzejskom vijeću u kreiranju muzejskih sadržaja po vašoj mjeri.",
        accordion: {
            title: "Napomena",
            text: "Prilikom predlaganja programa molimo vas da imate razumijevanja za ograničene financijske i produkcijske mogućnosti Muzeja. Možda vam nećemo moći dovesti, primjerice, Picassa ili rekonstruirati mletačku galiju u punoj veličini, ali nam ništa ne brani da o njima zajedno učimo i istražujemo kroz posebna predavanja, radionica ili okrugle stolove.",
        },
    },
    homepage: {
        aboutPlatformTitle: "O platformi",
        aboutPlatformText: "Digitalna platforma za uključivanje zajednice predstavlja temeljni komunikacijski kanal između Građanskog muzejskog vijeća i lokalne zajednice. Pozvani ste, putem digitalne platforme, izraziti svoje mišljenje i stavove te podijeliti svoje ideje, iskustva i želje vezane uz djelatnost Muzeja. Uz vašu pomoć, Građansko muzejsko vijeće stvarat će muzej po mjeri zajednice.",
        survey: {
            title: "Podijeli mišljenje",
            text: "Voliš svoje mišljenje podijeliti s drugima? Podijeli ga s nama! Ispuni anketu i unaprijedi izložbu!",
        },
        exhibitionEntries: {
            title: "Predloži predmet",
            text: "Kako želimo da nas buduće generacije pamte? Predloži predmet za muzejsku zbirku koju stvaraju građani!",
            linkText: "Predloži predmet ",
        },
        map: {
            title: "Podijeli doživljaj",
            text: "Nešto iz okruženja privuklo je vašu pažnju? Nadahnulo vas, iznenadilo ili zabavilo? Označite svoju lokaciju i podijelite doživljaj.",
            linkText: "Podijeli doživljaj ",
        },
        subjects: {
            title: "Predloži program",
            text: "Želiš doživjeti i naučiti nešto novo? Predloži temu za program, radionicu ili predavanje. Usmjeri program muzeja.",
            linkText: "Predloži program ",
        },
        aboutProjectTitle: "Građansko muzejsko vijeće",
        aboutProjectText: "Građansko muzejsko vijeće je neformalno tijelo Pomorskog i povijesnog muzeja Hrvatskog primorja Rijeka koje čine predstavnici organizacija civilnog društva i Muzeja. Vijeće doprinosi demokratizaciji i otvaranju muzeja prema zajednici, potičući sudjelovanje građana na području PGŽ-a, kroz predlaganje, osmišljavanje i provedbu kulturnih programa Muzeja, u skladu s interesima i potrebama lokalne zajednice.",
        aboutCivilCouncil: {
            titleBold: "Građansko muzejsko vijeće osnovano je u sklopu projekta \"Muzej budućnosti - Građansko muzejsko vijeće kao model sudioničkog upravljanja\"",
            text: "Projekt provodi Pomorski i povijesni muzej Hrvatskog primorja Rijeka u suradnji s pet partnera, Udrugom Žmergo, Kreativnim kolektivom Kombinat, Centrom za poticanje darovitosti, Udrugom slijepih Primorsko-goranske županije te Maticom umirovljenika Grada Rijeke. Projekt se provodi s ciljem poticanja zaposlenika Muzeja, jedinica lokalne i regionalne samouprave te organizacija civilnog društva na suradnju, uzajamno jačanje kapaciteta, umrežavanje te razvijanje inovativnog modela sudioničkog upravljanja u kulturi.",
        },
        projectResults: {
            title: "Rezultati projekta:",
            text: {
                point1: "1 | Formirano Građansko muzejsko vijeće koje će omogućiti uključivanje zajednice u rad Muzeja te uspostavu modela suradnje između zaposlenika ustanova u kulturi i civilnog društva.",
                point2: "2 | Provedena edukacija za jačanje kapaciteta u području sudioničkog upravljanja namijenjenih civilnom sektoru te zaposlenicima civilnog i javnog sektora.",
                point3: "3 | Provedena promotivna kampanja kojoj je cilj jačanje svijesti o važnosti uključivanja zajednice u kreiranju kulturnih sadržaja namijenjenih istoj.",
            },
        },
    },
};

export default Content;
