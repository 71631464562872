/* eslint max-len: 0 */
import React from "react";
import PropTypes from "prop-types";
import MapGL, {
    Marker, Popup, NavigationControl, GeolocateControl,
} from "react-map-gl";
import Pin from "../../website/map/Pin";
import AdminPinInfo from "./AdminPinInfo";
import AdminSidebar from "../AdminSidebar";
import { HistoryPropType } from "../../../customPropTypes";

const TOKEN = "pk.eyJ1IjoiYW5kcmVhc3ZhZ2xpYyIsImEiOiJjazF3NHBxc3kwMTB5M2hwaXJia2Rzd21nIn0.eH2gEkuXqqsNlhdOD62b-Q"; // Set your mapbox token here
const navStyle = {
    position: "absolute",
    bottom: 36,
    right: 0,
};
const initialViewport = {
    width: "110%",
    height: 600,
    latitude: 45.367588,
    longitude: 14.614826,
    zoom: 8,
};
const geolocateStyle = {
    position: "absolute",
    borderRadius: "50%",
    boxShadow: "none",
    bottom: 120,
    right: 0,
};

function InteractiveMap({ history, customPins, getAllMapPins, deletePinById }) {
    const [viewport, setViewport] = React.useState(initialViewport);
    const [popupInfo, setPopupInfo] = React.useState(null);
    const [pinIdActive, setPinIdActive] = React.useState(null);

    const isPinActive = (id) => pinIdActive === id;

    const updateViewport = (newViewport) => {
        const updatedViewport = { ...viewport, ...newViewport, width: "100%" };
        setViewport(updatedViewport);
    };
    const renderCityMarker = (pin, index) => {
        return (
            <Marker key={`marker-${index}`} longitude={pin.longitude} latitude={pin.latitude}>
                <Pin
                    onClick={() => {
                        setPopupInfo(pin);
                        setPinIdActive(index);
                    }}
                    pinSvgIndex={pin.pinSvgIndex}
                    isPinActive={isPinActive(index)}
                />
            </Marker>
        );
    };
    const renderPopup = () => {
        return (
            popupInfo && (
                <div className="test-custom-map-popup">
                    <Popup
                        tipSize={5}
                        anchor="top"
                        longitude={popupInfo.longitude}
                        latitude={popupInfo.latitude}
                        closeOnClick={false}
                        onClose={() => {
                            setPopupInfo(null);
                            setPinIdActive(null);
                        }}
                    >
                        <AdminPinInfo history={history} info={popupInfo.pinInfos} deletePinById={deletePinById} />
                    </Popup>

                </div>
            )
        );
    };

    React.useEffect(() => {
        getAllMapPins();
    }, [getAllMapPins]);

    React.useEffect(() => {
        setPopupInfo(null);
        setPinIdActive(null);
    }, [customPins]);

    return (
        <div>
            <AdminSidebar history={history} />
            <div style={{ position: "relative" }}>
                <MapGL
                    {...viewport}
                    mapStyle="mapbox://styles/mapbox/light-v10"
                    onViewportChange={updateViewport}
                    mapboxApiAccessToken={TOKEN}
                >
                    {customPins.map(renderCityMarker)}
                    <div className="nav" style={navStyle}>
                        <NavigationControl showCompass={false} />
                    </div>
                    <GeolocateControl
                        positionOptions={{ enableHighAccuracy: true }}
                        trackUserLocation
                        style={geolocateStyle}
                    />
                    {renderPopup()}
                </MapGL>
            </div>
        </div>
    );
}

export default InteractiveMap;

InteractiveMap.propTypes = {
    getAllMapPins: PropTypes.func.isRequired,
    history: HistoryPropType.isRequired,
};
