import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { withFormik } from "formik";
import validationSchemas from "../../../helpers/validation";
import { extractFileName } from "../../../helpers/files";
import CustomFormLabel from "../../common/CustomFormLabel";
import ButtonRemove from "../../common/ButtonRemove";
import FormTooltip from "../FormTooltip";
import SendFormButton from "../SendFormButton";
import GiveUpButton from "../GiveUpButton";
import { FormStatusPropType } from "../../../customPropTypes";

export function ExhibitionEntryAddNewInner({
    show, onHideCallback, handleSubmit, touched,
    handleChange, values, errors, status, setFieldValue, handleBlur, onUpload, setStatus, handleReset,
    onDeleteFile,
}) {
    const [uploadedSuccessfully, setUploadedSuccessfully] = React.useState(false);
    const [contentUrl, setContentUrl] = React.useState(null);
    const [fileType, setFileType] = React.useState("");
    const [uploadErrorMessage, setUploadErrorMessage] = React.useState(null);

    const onDropRejected = useCallback(() => {
        setUploadErrorMessage("Vaša datoteka je prevelika.");
    }, []);

    const onDropAccepted = useCallback((acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            setUploadedSuccessfully(true);
            setUploadErrorMessage(null);

            const acceptedFile = acceptedFiles[0];
            switch (acceptedFile.type) {
            // fulfill with all accepted types
            case "image/png":
                setFileType("image");
                break;
            case "video/mp4":
                setFileType("video");
                break;
            case "video/quicktime":
                setFileType("video");
                break;
            case "image/jpeg":
                setFileType("image");
                break;
            case "audio/mp3":
                setFileType("audio");
                break;
            case "audio/wav":
                setFileType("audio");
                break;
            case "audio/ogg":
                setFileType("audio");
                break;
            default:
                break;
            }

            const successCallback = (response) => {
                setContentUrl(response.url);
                setFieldValue("contentUrl", response.url);
            };

            const failureCallback = () => {
                // acceptedFiles.length = 0;
                acceptedFiles.splice(0, acceptedFiles.length);
                setContentUrl(null);
                setFieldValue("contentUrl", "");
                // debugger;
            };

            onUpload(acceptedFile, successCallback, setStatus, failureCallback);
        }
    }, [setFieldValue, onUpload, setStatus]);

    const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({
        onDropAccepted, onDropRejected, accept: "image/*, video/*, audio/*", maxSize: 10000000,
    });

    const showPreview = () => {
        if (fileType === "image") {
            return (
                <img src={contentUrl} alt="" className="upload-preview-image" />
            );
        }
        if (fileType === "video") {
            return (
                <video width="320" height="240" controls>
                    <source src={contentUrl} type="video/mp4" />
                    <source src={contentUrl} type="video/webm" />
                    <track default kind="captions" />
                </video>
            );
        }
        if (fileType === "audio") {
            return (
                <audio controls>
                    <source src={contentUrl} />
                    <track default kind="captions" />
                    Your browser does not support the audio element.
                </audio>
            );
        }

        return <div>Preview datotete nemoguć.</div>;
    };

    // const dragDropClassName = errors.contentUrl ? "drag-drop drag-drop-error" : "drag-drop";
    const dragDropClassName = "drag-drop";
    const isSubmitButtonDisabled = () => ((Object.keys(touched).length === 0) ||
        (Object.keys(errors).length !== 0) ||
        (status && status.type === "error"));

    const removeAllFiles = () => {
        if (contentUrl) {
            const fileName = extractFileName(contentUrl);

            acceptedFiles.length = 0;
            acceptedFiles.splice(0, acceptedFiles.length);
            onDeleteFile(fileName);
            setContentUrl(null);
            setFieldValue("contentUrl", "");
        }
    };

    const onHideForm = () => {
        onHideCallback();
        handleReset();
        if (contentUrl) {
            acceptedFiles.length = 0;
            acceptedFiles.splice(0, acceptedFiles.length);
            setContentUrl(null);
            setFieldValue("contentUrl", "");
        }
    };

    const modalTitle = (status && status.type === "success") ? "Vaš prijedlog je poslan!" : "Predlažem";

    return (
        <Modal show={show} centered onHide={onHideForm} size="lg" dialogClassName="custom-entry-addnew-modal">
            <Modal.Header closeButton>
                <Modal.Title>{modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {status && status.type === "success" ?
                    (
                        <div className="custom-add-entry-success">
                            <div>
                                Hvala na sudjelovanju!
                            </div>
                        </div>
                    ) :
                    (
                        <Form onSubmit={handleSubmit}>
                            <Form.Group as={Row} controlId="formTitle">
                                <CustomFormLabel text="Naslov prijedloga" disclaimer="40 maks. broj znakova" />
                                <Col sm="12" md="9">
                                    <Form.Control
                                        type="text"
                                        name="title"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.title}
                                        isInvalid={touched.title && !!errors.title}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.title}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formDescription">
                                <CustomFormLabel text="Objašnjenje prijedloga" disclaimer="400 maks. broj znakova" customStyle={{ top: "-20px" }} />
                                <Col sm="12" md="9">
                                    <Form.Control
                                        as="textarea"
                                        type="text"
                                        name="description"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.description}
                                        isInvalid={touched.description && !!errors.description}
                                        rows="5"
                                    />
                                    <FormTooltip id="description" text="Objasniti zašto predmet predlažete za Civilnu muzejsku zbirku." svgStyle={{ top: "-15px" }} />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.description}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formUserName">
                                <CustomFormLabel text="Vaše ime" />
                                <Col sm="12" md="9">
                                    <Form.Control
                                        type="text"
                                        name="userName"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.userName}
                                        isInvalid={touched.userName && !!errors.userName}
                                    />
                                    <FormTooltip id="username" text="Predlaganje predmeta je anonimno. Vaše ime neće se javno prikazivati uz prijedlog kojeg podnosite." />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.userName}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formUserEmail">
                                <CustomFormLabel text="Vaš e-mail" />
                                <Col sm="12" md="9">
                                    <Form.Control
                                        type="text"
                                        name="userEmail"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.userEmail}
                                        isInvalid={touched.userEmail && !!errors.userEmail}
                                    />
                                    <FormTooltip id="email" text="Vaše osobne podatke prikupljamo isključivo u svrhu stupanja u kontakt s Vama u slučaju da Vaš prijedlog bude među odabranima." />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.userEmail}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formDataProcessingEmail" className="custom-form-checkbox-wrapper">
                                <Form.Check
                                    label="Pristajem da me PPMHP po potrebi kontaktira na uneseni email."
                                    type="checkbox"
                                    id="data-processing-email-checkbox"
                                    name="dataProcessingEmailAgree"
                                    value={values.dataProcessingEmailAgree}
                                    onChange={handleChange}
                                    isInvalid={touched.dataProcessingEmailAgree && !!errors.dataProcessingEmailAgree}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.dataProcessingEmailAgree}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <CustomFormLabel text="Datoteka" disclaimer="Maks. veličina datoteke: 5MB slika / 7MB zvuk / 10MB video" customStyle={{ alignSelf: "flex-start", marginTop: "-15px" }} />
                                <Col sm="12" md="9">
                                    {uploadedSuccessfully && acceptedFiles.length > 0 && contentUrl && (
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            {showPreview()}
                                            <ButtonRemove onClickCallback={removeAllFiles} />
                                        </div>
                                    )}
                                    {(acceptedFiles.length === 0 || (status && status.type === "error")) && (
                                        <div {...getRootProps()}>
                                            <input id="test-upload" {...getInputProps()} />
                                            {
                                                isDragActive ?
                                                    <p className={dragDropClassName}>POVUCI-SPUSTI OPCIJA / UPLOAD DATOTEKE</p> :
                                                    <p className={dragDropClassName}>POVUCI-SPUSTI OPCIJA / UPLOAD DATOTEKE</p>
                                            }
                                            {/* <div className="custom-error-message">
                                                {errors.contentUrl}
                                            </div> */}
                                        </div>
                                    )}
                                    {uploadErrorMessage && <em className="text-danger">{uploadErrorMessage}</em>}
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formMediaOwning" style={{ marginTop: "20px", marginBottom: "50px" }}>
                                <Form.Check
                                    label="Autor sam fotografije/video ili audio snimke, ili imam izričito dopuštenje od autora (onoga tko posjeduje autorska prava) za njihovo korištenje."
                                    type="checkbox"
                                    id="media-owning-checkbox"
                                    name="mediaOwningAgree"
                                    value={values.mediaOwningAgree}
                                    onChange={handleChange}
                                    isInvalid={touched.mediaOwningAgree && !!errors.mediaOwningAgree}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.dataProcessingEmailAgree}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <div className="admin-error-message">
                                {(status && status.type) === "error" && status.message}
                            </div>
                            <div className="custom-form-action-buttons">
                                <SendFormButton disabled={isSubmitButtonDisabled()} />
                                <GiveUpButton onClickCallback={onHideForm} />
                            </div>
                        </Form>
                    )}
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>
    );
}

export const handleSubmit = (values, { props, setStatus }) => {
    props.onSubmit(values, setStatus);
};

export default withFormik({
    mapPropsToValues: () => ({
        title: "",
        description: "",
        userName: "",
        userEmail: "",
        contentUrl: "",
        dataProcessingEmailAgree: false,
        mediaOwningAgree: false,
    }),
    validationSchema: validationSchemas.addNewExhibitionEntry,
    handleSubmit,
})(ExhibitionEntryAddNewInner);

ExhibitionEntryAddNewInner.propTypes = {
    show: PropTypes.bool.isRequired,
    onHideCallback: PropTypes.func.isRequired,
    values: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        userName: PropTypes.string,
        userEmail: PropTypes.string,
        contentUrl: PropTypes.string,
        exhibitionYear: PropTypes.number,
        dataProcessingEmailAgree: PropTypes.bool,
        mediaOwningAgree: PropTypes.bool,
    }).isRequired,
    errors: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        userName: PropTypes.string,
        userEmail: PropTypes.string,
        dataProcessingEmailAgree: PropTypes.string,
        mediaOwningAgree: PropTypes.string,
        // contentUrl: PropTypes.string,
    }).isRequired,
    touched: PropTypes.shape({
        title: PropTypes.bool,
        description: PropTypes.bool,
        userName: PropTypes.bool,
        userEmail: PropTypes.bool,
        contentUrl: PropTypes.bool,
        dataProcessingEmailAgree: PropTypes.bool,
        mediaOwningAgree: PropTypes.bool,
    }).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleReset: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    status: FormStatusPropType,
    handleBlur: PropTypes.func.isRequired,
    onUpload: PropTypes.func.isRequired,
    setStatus: PropTypes.func.isRequired,
    onDeleteFile: PropTypes.func.isRequired,
};

ExhibitionEntryAddNewInner.defaultProps = {
    status: null,
};
