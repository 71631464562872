import { connect } from "react-redux";
import EditPage from "../../../../components/admin/pages/subjects/EditPage";
import { pages, exhibitionSubject } from "../../../../services/requests";
import Copy from "../../../../content/AdminTextContent";

const mapStateToProps = (state, ownProps) => {
    const pageId = parseInt(ownProps.match.params.pageId, 10);

    const subjectsPages = state.exhibitionSubjects.pages;
    const allPages = Object.keys(subjectsPages).length > 1 ?
        [subjectsPages.current, ...subjectsPages.archived, ...subjectsPages.planned] :
        [];

    const pageEdited = allPages.length > 0 ? allPages.find((p) => p.id === pageId) : null;

    return {
        pageId,
        pageEdited,
        text: Copy.pages.exhibitionSubjects.edit,
    };
};

const mapDispatchToProps = (dispatch) => ({
    editPage: (values, pageId, setStatus) => dispatch(pages.updateSubjects(values, pageId, setStatus)),
    getPages: () => dispatch(exhibitionSubject.getPages()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditPage);
