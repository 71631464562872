import { connect } from "react-redux";
import { exhibitionEntry, pages } from "../../../../services/requests";
import PlannedEntriesPages from "../../../../components/admin/pages/entries/Planned";

const mapStateToProps = (state) => ({
    pages: state.exhibitionEntries.pages.planned,
});

const mapDispatchToProps = (dispatch) => ({
    getExhibitionEntryPages: () => dispatch(exhibitionEntry.getPages()),
    onPageDeleteClicked: (pageId) => dispatch(pages.delete(pageId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlannedEntriesPages);
