/* eslint max-len: 0 */
import React from "react";
import PropTypes from "prop-types";
import MapGL, {
    Marker, NavigationControl, GeolocateControl,
} from "react-map-gl";
import Pin from "./Pin";

const TOKEN = "pk.eyJ1IjoiYW5kcmVhc3ZhZ2xpYyIsImEiOiJjazF3NHBxc3kwMTB5M2hwaXJia2Rzd21nIn0.eH2gEkuXqqsNlhdOD62b-Q"; // Set your mapbox token here
const navStyle = {
    top: 36,
    position: "absolute",
    left: 0,
    padding: "10px",
};

const geolocateStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    margin: 10,
    borderRadius: 0,
    boxShadow: "0 0 0 2px rgba(0, 0, 0, 1)",
};

export const RIJEKA_CENTER_LOCATION = {
    latitude: 45.327419,
    longitude: 14.443240,
};

function InteractiveMap({ onMarkerChanged }) {
    const [marker, setMarker] = React.useState({ latitude: RIJEKA_CENTER_LOCATION.latitude, longitude: RIJEKA_CENTER_LOCATION.longitude });

    const initialViewport = {
        width: "100%",
        height: 200,
        latitude: marker.latitude,
        longitude: marker.longitude,
        zoom: 12,
    };

    const [viewport, setViewport] = React.useState(initialViewport);

    React.useEffect(() => {
        navigator.geolocation.getCurrentPosition((position) => {
            setMarker({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
            });
            setViewport({
                ...viewport,
                zoom: 16,
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
            });

            onMarkerChanged(position.coords.latitude, position.coords.longitude);
        });
    }, [viewport]);

    const updateViewport = (newViewport) => setViewport({
        ...viewport,
        ...newViewport,
        width: viewport.width,
        height: viewport.height,
    });

    const onMarkerDragEnd = (event) => {
        const latitude = event.lngLat[1];
        const longitude = event.lngLat[0];

        setMarker({ longitude, latitude });
        onMarkerChanged(latitude, longitude);
    };

    return (
        <MapGL
            {...viewport}
            mapStyle="mapbox://styles/mapbox/light-v10"
            onViewportChange={updateViewport}
            mapboxApiAccessToken={TOKEN}
        >
            <div className="nav" style={navStyle}>
                <NavigationControl showCompass={false} />
            </div>
            <GeolocateControl
                positionOptions={{ enableHighAccuracy: true }}
                trackUserLocation
                style={geolocateStyle}
            />
            <Marker
                longitude={marker.longitude}
                latitude={marker.latitude}
                offsetTop={-20}
                offsetLeft={-10}
                draggable
                onDragEnd={onMarkerDragEnd}
            >
                <Pin size={20} />
            </Marker>
        </MapGL>
    );
}

export default InteractiveMap;

InteractiveMap.propTypes = {
    onMarkerChanged: PropTypes.func.isRequired,
};
