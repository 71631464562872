/* eslint max-len: 0 */
import React from "react";
import PropTypes from "prop-types";

const ORIGINAL_WIDTH = 240.34;
const ORIGINAL_HEIGHT = 62.392;

function SVG({ scale }) {
    const width = ORIGINAL_WIDTH * scale;
    const height = ORIGINAL_HEIGHT * scale;
    const widthString = `${width}px`;
    const heightString = `${height}px`;

    return (
        <svg
            version="1.0"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width={widthString}
            height={heightString}
            viewBox={`0 0 ${ORIGINAL_WIDTH} ${ORIGINAL_HEIGHT}`}
            enableBackground={`new 0 0 ${ORIGINAL_WIDTH} ${ORIGINAL_HEIGHT}`}
        >
            <switch>
                <g>
                    <g>
                        <g>
                            <defs>
                                <rect id="SVGID_1_" x="26.787" y="13.982" width="28.913" height="20.949" />
                            </defs>
                            <clipPath id="SVGID_2_">
                                <use overflow="visible" />
                            </clipPath>
                            <rect x="23.64" y="-9.334" clipPath="url(#SVGID_2_)" fill="none" stroke="#0C0506" strokeWidth="0.13" width="66.32" height="37.206" />
                        </g>
                        <rect x="26.786" y="13.982" fill="#FFFFFF" width="28.913" height="20.951" />
                        <rect x="26.786" y="13.982" fill="#183C8C" width="28.913" height="20.951" />
                        <polygon fill="#FFEA00" points="41.345,18.012 40.654,18.487 40.919,17.71 40.21,17.21 41.089,17.21 41.37,16.387 41.624,17.21 42.501,17.21 41.78,17.711 42.018,18.494" />
                        <polygon fill="#FFEA00" points="41.345,31.969 40.654,32.443 40.919,31.665 40.21,31.165 41.089,31.165 41.37,30.341 41.624,31.165 42.501,31.165 41.78,31.667 42.018,32.448" />
                        <polygon fill="#FFEA00" points="34.392,24.981 33.702,25.455 33.967,24.679 33.26,24.177 34.138,24.177 34.417,23.353 34.673,24.177 35.548,24.177 34.829,24.679 35.065,25.46" />
                        <polygon fill="#FFEA00" points="35.381,21.427 34.692,21.901 34.956,21.125 34.247,20.624 35.127,20.624 35.405,19.8 35.661,20.624 36.537,20.624 35.817,21.126 36.055,21.907" />
                        <polygon fill="#FFEA00" points="37.839,18.926 37.148,19.401 37.413,18.625 36.704,18.125 37.583,18.125 37.863,17.299 38.117,18.125 38.993,18.125 38.274,18.627 38.511,19.407" />
                        <polygon fill="#FFEA00" points="44.729,18.926 44.041,19.401 44.306,18.625 43.597,18.125 44.476,18.125 44.755,17.299 45.01,18.125 45.886,18.125 45.166,18.627 45.403,19.407" />
                        <polygon fill="#FFEA00" points="47.247,21.427 46.558,21.901 46.822,21.125 46.114,20.624 46.992,20.624 47.271,19.8 47.528,20.624 48.403,20.624 47.685,21.126 47.921,21.907" />
                        <polygon fill="#FFEA00" points="35.351,28.444 34.661,28.918 34.926,28.142 34.218,27.642 35.096,27.642 35.376,26.817 35.631,27.642 36.507,27.642 35.786,28.143 36.024,28.924" />
                        <polygon fill="#FFEA00" points="37.809,31.035 37.12,31.509 37.383,30.733 36.676,30.233 37.554,30.233 37.834,29.407 38.088,30.233 38.964,30.233 38.245,30.736 38.483,31.515" />
                        <polygon fill="#FFEA00" points="44.79,31.035 44.102,31.509 44.366,30.733 43.657,30.233 44.534,30.233 44.816,29.407 45.07,30.233 45.946,30.233 45.227,30.736 45.464,31.515" />
                        <polygon fill="#FFEA00" points="47.276,28.444 46.589,28.918 46.853,28.142 46.146,27.642 47.022,27.642 47.303,26.817 47.558,27.642 48.433,27.642 47.714,28.143 47.952,28.924" />
                        <polygon fill="#FFEA00" points="48.207,24.981 47.519,25.455 47.782,24.679 47.073,24.177 47.952,24.177 48.231,23.353 48.487,24.177 49.362,24.177 48.644,24.679 48.88,25.46" />
                        <rect x="26.448" y="13.644" fill="none" stroke="#FFFFFF" strokeWidth="0.599" width="29.591" height="21.635" />
                        <path fill="#E50004" d="M202.077,21.714h4.933v4.932h-4.933V21.714z M202.552,22.182h3.994v3.973l-0.499-3.444L202.552,22.182z" />
                        <path fill="#23308D" d="M210.802,38.655v-0.14c0-0.037-0.017-0.053-0.053-0.053h-0.015c-0.121,0.01-0.364,0.026-0.558,0.026 c-0.33,0-0.588-0.198-0.588-0.768v-0.469c0-0.574,0.258-0.767,0.588-0.767c0.197,0,0.447,0.021,0.568,0.03h0.01 c0.033,0,0.041-0.016,0.041-0.037v-0.172c0-0.029-0.024-0.041-0.055-0.044c-0.148-0.023-0.355-0.054-0.568-0.054 c-0.414,0-0.891,0.215-0.891,1.043v0.469c0,0.822,0.477,1.044,0.891,1.044c0.287,0,0.42-0.016,0.562-0.043 C210.771,38.715,210.802,38.695,210.802,38.655 M210.785,35.557c0-0.013-0.01-0.022-0.03-0.022h-0.183 c-0.037,0-0.047,0.019-0.077,0.053l-0.277,0.324l-0.28-0.324c-0.03-0.034-0.04-0.053-0.078-0.053h-0.183 c-0.02,0-0.029,0.01-0.029,0.022c0,0.011,0.003,0.023,0.013,0.033l0.331,0.42c0.031,0.036,0.041,0.05,0.081,0.05h0.287 c0.041,0,0.054-0.014,0.085-0.05l0.327-0.42C210.781,35.581,210.785,35.568,210.785,35.557" />
                        <path fill="#23308D" d="M211.524,38.674v-2.378c0-0.036-0.026-0.063-0.061-0.063h-0.186c-0.037,0-0.062,0.029-0.062,0.063v2.378 c0,0.038,0.027,0.068,0.062,0.068h0.186C211.501,38.743,211.524,38.708,211.524,38.674" />
                        <path fill="#23308D" d="M213.854,38.644v-2.347c0-0.034-0.023-0.065-0.061-0.065h-0.179c-0.034,0-0.062,0.031-0.062,0.065v1.975 c0,0.004,0,0.011-0.002,0.011l-0.008-0.011l-1.037-1.972c-0.027-0.05-0.043-0.068-0.138-0.068h-0.187 c-0.053,0-0.097,0.041-0.097,0.095v2.375c0,0.033,0.026,0.04,0.06,0.04h0.177c0.032,0,0.06-0.029,0.06-0.063v-1.973 c0-0.007,0-0.011,0.004-0.011s0.008,0.004,0.01,0.011l1.045,1.982c0.016,0.034,0.053,0.054,0.107,0.054h0.209 C213.812,38.742,213.854,38.698,213.854,38.644" />
                        <path fill="#23308D" d="M216.023,38.708c0-0.013-0.007-0.03-0.017-0.04l-1.018-1.216l1.018-1.148 c0.01-0.01,0.013-0.022,0.013-0.033c0-0.021-0.013-0.037-0.048-0.037h-0.242c-0.034,0-0.047,0.01-0.066,0.03l-0.974,1.125v-1.092 c0-0.037-0.031-0.063-0.063-0.063h-0.18c-0.038,0-0.064,0.03-0.064,0.063v2.378c0,0.037,0.026,0.068,0.061,0.068h0.186 c0.037,0,0.062-0.035,0.062-0.068v-1.125l0.952,1.158c0.021,0.025,0.044,0.035,0.074,0.035h0.264 C216.009,38.743,216.023,38.725,216.023,38.708" />
                        <path fill="#23308D" d="M218.04,37.706V37.27c0-0.75-0.383-1.061-0.912-1.061c-0.53,0-0.915,0.311-0.915,1.061v0.436 c0,0.749,0.385,1.061,0.915,1.061C217.657,38.766,218.04,38.455,218.04,37.706 M217.729,37.706c0,0.567-0.234,0.783-0.602,0.783 c-0.372,0-0.604-0.216-0.604-0.783V37.27c0-0.567,0.235-0.784,0.604-0.784c0.372,0,0.602,0.217,0.602,0.784V37.706z" />
                        <path fill="#23308D" d="M220.222,36.291c0-0.034-0.024-0.059-0.057-0.059h-0.201c-0.026,0-0.049,0.024-0.057,0.052l-0.592,2.15 c-0.012,0.048-0.03,0.071-0.062,0.071c-0.037,0-0.055-0.023-0.068-0.071l-0.588-2.15c-0.006-0.027-0.033-0.052-0.061-0.052 h-0.195c-0.034,0-0.062,0.024-0.062,0.059c0,0.007,0,0.013,0.004,0.02l0.624,2.199c0.061,0.213,0.221,0.256,0.346,0.256 c0.127,0,0.282-0.043,0.344-0.256l0.621-2.199C220.222,36.303,220.222,36.297,220.222,36.291" />
                        <path fill="#23308D" d="M220.94,38.674v-2.378c0-0.036-0.027-0.063-0.061-0.063h-0.186c-0.037,0-0.061,0.029-0.061,0.063v2.378 c0,0.038,0.027,0.068,0.061,0.068h0.186C220.917,38.743,220.94,38.708,220.94,38.674" />
                        <path fill="#23308D" d="M223.112,36.442v-0.148c0-0.037-0.027-0.062-0.064-0.062h-1.625c-0.037,0-0.066,0.028-0.066,0.062v0.148 c0,0.038,0.029,0.054,0.066,0.054h0.66v2.179c0,0.038,0.026,0.068,0.061,0.068h0.186c0.036,0,0.061-0.034,0.061-0.068v-2.179 h0.658C223.085,36.496,223.112,36.475,223.112,36.442" />
                        <path fill="#23308D" d="M223.835,38.674v-2.378c0-0.036-0.027-0.063-0.061-0.063h-0.186c-0.037,0-0.062,0.029-0.062,0.063v2.378 c0,0.038,0.026,0.068,0.062,0.068h0.186C223.812,38.743,223.835,38.708,223.835,38.674" />
                        <path fill="#23308D" d="M208.854,37.799v-1.503c0-0.037-0.034-0.063-0.071-0.063h-0.439c-0.036,0-0.066,0.026-0.066,0.063v1.503 c0,0.346-0.095,0.49-0.398,0.49c-0.305,0-0.396-0.145-0.396-0.49v-1.503c0-0.037-0.029-0.063-0.066-0.063h-0.442 c-0.037,0-0.068,0.026-0.068,0.063v1.503c0,0.771,0.437,0.974,0.974,0.974S208.854,38.571,208.854,37.799" />
                        <path fill="#23308D" d="M208.356,42.923v-0.315c0-0.036-0.029-0.067-0.066-0.067h-0.582c-0.158,0-0.217-0.054-0.217-0.229v-1.745 c0-0.038-0.029-0.068-0.066-0.068h-0.443c-0.037,0-0.066,0.03-0.066,0.068v1.745c0,0.615,0.389,0.727,0.824,0.727 c0.178,0,0.438-0.02,0.551-0.044C208.337,42.984,208.356,42.967,208.356,42.923" />
                        <path fill="#23308D" d="M209.245,43.028v-2.466c0-0.037-0.031-0.064-0.066-0.064h-0.443c-0.037,0-0.067,0.027-0.067,0.064v2.466 c0,0.084-0.011,0.121-0.088,0.121h-0.169c-0.023,0-0.037,0.023-0.037,0.047v0.345c0,0.031,0.02,0.055,0.051,0.058 c0.092,0.011,0.139,0.014,0.217,0.014C208.981,43.612,209.245,43.487,209.245,43.028" />
                        <path fill="#23308D" d="M211.464,42.095v-1.533c0-0.037-0.031-0.064-0.064-0.064h-0.182c-0.037,0-0.064,0.03-0.064,0.064v1.533 c0,0.44-0.135,0.672-0.555,0.672c-0.418,0-0.553-0.237-0.553-0.672v-1.533c0-0.037-0.031-0.064-0.064-0.064H209.8 c-0.037,0-0.064,0.03-0.064,0.064v1.533c0,0.645,0.316,0.935,0.863,0.935S211.464,42.74,211.464,42.095" />
                        <path fill="#23308D" d="M213.689,41.98v-0.456c0-0.729-0.397-1.05-1.022-1.05c-0.258,0-0.518,0.018-0.615,0.033 c-0.045,0.008-0.071,0.038-0.071,0.086v2.32c0,0.049,0.026,0.077,0.071,0.084c0.098,0.017,0.357,0.033,0.615,0.033 C213.292,43.031,213.689,42.705,213.689,41.98 M213.382,41.98c0,0.608-0.311,0.787-0.715,0.787c-0.16,0-0.268,0-0.375-0.007 v-2.013c0.111-0.006,0.215-0.011,0.375-0.011c0.404,0,0.715,0.176,0.715,0.787V41.98z" />
                        <path fill="#23308D" d="M215.571,42.356c0-0.271-0.139-0.456-0.53-0.689l-0.312-0.186c-0.256-0.155-0.367-0.25-0.367-0.409 c0-0.246,0.147-0.32,0.496-0.32c0.195,0,0.432,0.009,0.58,0.019h0.008c0.033,0,0.057-0.019,0.057-0.053v-0.145 c0-0.034-0.023-0.051-0.057-0.059c-0.129-0.02-0.389-0.04-0.588-0.04c-0.515,0-0.808,0.189-0.808,0.598 c0,0.271,0.155,0.442,0.466,0.632l0.328,0.199c0.307,0.189,0.405,0.283,0.405,0.453c0,0.235-0.108,0.398-0.515,0.398 c-0.154,0-0.4-0.007-0.557-0.021h-0.01c-0.029,0-0.057,0.017-0.057,0.054v0.136c0,0.036,0.007,0.05,0.063,0.061 c0.149,0.023,0.324,0.047,0.56,0.047C215.401,43.031,215.571,42.679,215.571,42.356" />
                        <path fill="#23308D" d="M217.645,42.973c0-0.014-0.007-0.03-0.017-0.041l-1.017-1.215l1.017-1.148 c0.01-0.01,0.014-0.023,0.014-0.033c0-0.021-0.014-0.038-0.049-0.038h-0.242c-0.034,0-0.047,0.01-0.067,0.03l-0.974,1.125v-1.091 c0-0.036-0.029-0.064-0.063-0.064h-0.179c-0.037,0-0.064,0.03-0.064,0.064v2.378c0,0.037,0.027,0.067,0.062,0.067h0.185 c0.037,0,0.061-0.034,0.061-0.067v-1.125l0.953,1.158c0.021,0.024,0.043,0.034,0.074,0.034h0.264 C217.632,43.007,217.645,42.991,217.645,42.973" />
                        <path fill="#23308D" d="M218.395,42.94v-2.378c0-0.036-0.027-0.064-0.062-0.064h-0.186c-0.036,0-0.061,0.03-0.061,0.064v2.378 c0,0.037,0.027,0.067,0.061,0.067h0.186C218.37,43.007,218.395,42.973,218.395,42.94" />
                        <path fill="#23308D" d="M208.765,45.577v-0.03c0-0.635-0.502-0.813-1.012-0.813c-0.243,0-0.514,0.017-0.686,0.044 c-0.104,0.016-0.152,0.047-0.152,0.162v2.269c0,0.037,0.029,0.065,0.068,0.065h0.436c0.035,0,0.066-0.03,0.066-0.068v-0.821 c0.084,0.005,0.192,0.012,0.274,0.012C208.263,46.395,208.765,46.208,208.765,45.577 M208.192,45.577 c0,0.23-0.133,0.341-0.433,0.341c-0.038,0-0.237-0.003-0.274-0.007v-0.688c0.041-0.005,0.227-0.011,0.274-0.011 c0.282,0,0.433,0.095,0.433,0.334V45.577z" />
                        <path fill="#23308D" d="M210.907,46.236V45.8c0-0.75-0.383-1.061-0.912-1.061c-0.53,0-0.915,0.311-0.915,1.061v0.436 c0,0.749,0.385,1.061,0.915,1.061C210.524,47.296,210.907,46.985,210.907,46.236 M210.596,46.236 c0,0.567-0.233,0.783-0.601,0.783c-0.371,0-0.604-0.216-0.604-0.783V45.8c0-0.567,0.235-0.784,0.604-0.784 c0.371,0,0.601,0.217,0.601,0.784V46.236z" />
                        <path fill="#23308D" d="M212.832,44.972v-0.148c0-0.037-0.027-0.062-0.064-0.062h-1.624c-0.037,0-0.068,0.028-0.068,0.062v0.148 c0,0.038,0.031,0.055,0.068,0.055h0.658v2.178c0,0.038,0.027,0.067,0.061,0.067h0.187c0.037,0,0.062-0.033,0.062-0.067v-2.178 h0.657C212.805,45.027,212.832,45.006,212.832,44.972" />
                        <path fill="#23308D" d="M214.534,47.231V47.06c0-0.024-0.014-0.042-0.037-0.042h-0.652c-0.358,0-0.416-0.094-0.416-0.35V46.1 h1.037c0.037,0,0.068-0.03,0.068-0.067v-0.127c0-0.038-0.031-0.068-0.068-0.068h-1.037v-0.47c0-0.256,0.058-0.353,0.416-0.353 h0.652c0.023,0,0.037-0.016,0.037-0.04v-0.169c0-0.02-0.014-0.044-0.033-0.047c-0.143-0.013-0.461-0.02-0.66-0.02 c-0.473,0-0.719,0.179-0.719,0.628v1.301c0,0.449,0.246,0.627,0.719,0.627c0.199,0,0.531-0.003,0.66-0.016 C214.521,47.276,214.534,47.252,214.534,47.231" />
                        <path fill="#23308D" d="M216.753,47.174v-2.347c0-0.034-0.023-0.065-0.061-0.065h-0.18c-0.033,0-0.061,0.031-0.061,0.065v1.975 c0,0.004,0,0.011-0.004,0.011l-0.006-0.011l-1.037-1.972c-0.027-0.05-0.045-0.068-0.139-0.068h-0.186 c-0.055,0-0.098,0.041-0.098,0.095v2.374c0,0.034,0.026,0.041,0.061,0.041h0.176c0.033,0,0.061-0.029,0.061-0.064v-1.973 c0-0.006,0-0.01,0.004-0.01c0.003,0,0.007,0.004,0.01,0.01l1.044,1.983c0.017,0.034,0.054,0.054,0.108,0.054h0.209 C216.709,47.272,216.753,47.228,216.753,47.174" />
                        <path fill="#23308D" d="M218.739,47.184v-0.139c0-0.036-0.018-0.053-0.055-0.053h-0.014c-0.121,0.01-0.364,0.025-0.557,0.025 c-0.332,0-0.588-0.197-0.588-0.767v-0.468c0-0.575,0.256-0.769,0.588-0.769c0.195,0,0.445,0.021,0.566,0.031h0.012 c0.032,0,0.039-0.016,0.039-0.036v-0.173c0-0.031-0.023-0.041-0.054-0.044c-0.149-0.023-0.354-0.054-0.567-0.054 c-0.416,0-0.892,0.216-0.892,1.044v0.468c0,0.822,0.476,1.044,0.892,1.044c0.287,0,0.418-0.016,0.561-0.043 C218.708,47.246,218.739,47.224,218.739,47.184" />
                        <path fill="#23308D" d="M219.462,47.205v-2.378c0-0.036-0.027-0.063-0.062-0.063h-0.185c-0.037,0-0.062,0.029-0.062,0.063v2.378 c0,0.038,0.026,0.068,0.062,0.068h0.185C219.438,47.273,219.462,47.239,219.462,47.205" />
                        <path fill="#23308D" d="M220.362,47.397v-2.57c0-0.033-0.029-0.063-0.063-0.063h-0.179c-0.034,0-0.064,0.03-0.064,0.063v2.57 c0,0.166-0.037,0.236-0.216,0.236h-0.132c-0.037,0-0.068,0.021-0.068,0.058v0.125c0,0.017,0.008,0.031,0.021,0.037 c0.02,0.01,0.104,0.027,0.179,0.027C220.211,47.88,220.362,47.722,220.362,47.397" />
                        <path fill="#23308D" d="M222.667,47.218c0-0.006,0-0.016-0.004-0.024l-0.625-2.201c-0.058-0.206-0.215-0.254-0.342-0.254 c-0.128,0-0.285,0.052-0.344,0.258l-0.625,2.197c-0.003,0.009-0.003,0.019-0.003,0.024c0,0.03,0.022,0.054,0.058,0.054h0.199 c0.027,0,0.055-0.023,0.061-0.05l0.217-0.778h0.874l0.22,0.778c0.007,0.026,0.027,0.05,0.057,0.05h0.2 C222.646,47.272,222.667,47.249,222.667,47.218 M222.068,46.178h-0.745l0.307-1.112c0.011-0.036,0.029-0.06,0.066-0.06 c0.034,0,0.055,0.023,0.065,0.06L222.068,46.178z" />
                        <path fill="#23308D" d="M224.379,47.218V47.06c0-0.024-0.013-0.042-0.037-0.042h-0.652c-0.358,0-0.415-0.094-0.415-0.35v-1.838 c0-0.037-0.031-0.068-0.068-0.068h-0.172c-0.037,0-0.067,0.031-0.067,0.068v1.838c0,0.557,0.358,0.627,0.72,0.627 c0.317,0,0.571-0.016,0.659-0.03C224.366,47.262,224.379,47.239,224.379,47.218" />
                        <path fill="#23308D" d="M225.044,47.205v-2.378c0-0.036-0.027-0.063-0.061-0.063h-0.186c-0.037,0-0.061,0.029-0.061,0.063v2.378 c0,0.038,0.025,0.068,0.061,0.068h0.186C225.021,47.273,225.044,47.239,225.044,47.205" />
                        <path fill="#23308D" d="M207.998,21.038c-0.955-0.375-2.401-0.852-4.349-1.084c1.36-3.537,2.229-6.426,7.947-3.062 C210.31,18.043,208.983,19.165,207.998,21.038 M207.708,20.397c-0.753-0.297-1.684-0.626-3.221-0.811 c1.074-2.79,1.548-5.116,6.061-2.463C209.53,18.033,208.487,18.918,207.708,20.397" />
                        <path fill="#23308D" d="M208.216,21.747c0.322,0.974,1,2.865,1.123,4.822c3.607-1.163,5.462-1.589,5.604-7.299 C213.72,20.494,210.842,21.707,208.216,21.747" />
                        <path fill="#23308D" d="M207.942,26.315c0.83,0.603,2.824,1.657,4.114,2.626c-0.284,2.944-1.673,4.63-7.155,3.033 C206.343,30.852,208.466,28.384,207.942,26.315 M208.362,27.18c0.688,0.5,1.965,1.187,3.033,1.989 c-0.281,2.299-1.244,3.836-5.507,2.559C207.084,30.8,208.796,28.895,208.362,27.18" />
                        <path fill="#23308D" d="M203.925,29.478c-2.028,0.762-5.549,3.145-9.824-0.642c1.054-0.338,2.025-0.315,2.721-0.868 C199.365,25.945,200.173,24.041,203.925,29.478 M202.921,29.262c-1.614,0.606-4.182,2.407-7.629-0.324 c0.838-0.269,1.61-0.25,2.164-0.689C199.481,26.639,199.936,24.935,202.921,29.262" />
                        <path fill="#23308D" d="M202.794,20.973c-0.463-1.233-0.932-1.751-1.574-3.431c-3.98,0.752-8.02,4.341-3.599,8.481 C199.381,22.676,200.717,21.514,202.794,20.973 M202.015,20.708c-0.35-0.931-0.701-1.319-1.186-2.586 c-3.001,0.564-6.514,3.786-3.182,6.907C198.974,22.506,200.45,21.116,202.015,20.708" />
                        <path fill="#FFEC00" d="M211.238,21.704c0,0,0.095,1.183,0.099,1.23c-0.043,0.021-1.213,0.561-1.213,0.561s1.252,0.3,1.299,0.312 c0.005,0.047,0.158,1.324,0.158,1.324s0.674-1.097,0.698-1.138c0.046,0.009,1.31,0.258,1.31,0.258s-0.836-0.979-0.867-1.015 c0.023-0.041,0.65-1.166,0.65-1.166s-1.189,0.493-1.233,0.511c-0.032-0.034-0.908-0.979-0.908-0.979L211.238,21.704z" />
                        <line fill="none" stroke="#23308D" strokeWidth="0.667" strokeMiterlimit="22.926" x1="204.607" y1="27.871" x2="204.607" y2="47.325" />
                        <path fill="#23308D" d="M202.354,36.528v-0.39c0-0.046-0.037-0.084-0.082-0.084h-0.852c-0.197,0-0.27-0.067-0.27-0.285v-0.443 h1.086c0.047,0,0.084-0.038,0.084-0.084v-0.398c0-0.046-0.037-0.084-0.084-0.084h-1.086v-0.381c0-0.218,0.072-0.285,0.27-0.285 h0.852c0.045,0,0.082-0.038,0.082-0.085v-0.388c0-0.055-0.024-0.076-0.082-0.084c-0.147-0.021-0.357-0.055-0.81-0.055 c-0.541,0-1.022,0.134-1.022,0.896v1.391c0,0.763,0.478,0.901,1.019,0.901c0.452,0,0.666-0.033,0.813-0.055 C202.33,36.608,202.354,36.583,202.354,36.528" />
                        <path fill="#23308D" d="M202.446,41.044c0-0.339-0.129-0.616-0.564-0.851l-0.537-0.289c-0.226-0.122-0.281-0.177-0.281-0.294 c0-0.149,0.088-0.222,0.328-0.222c0.284,0,0.771,0.038,0.889,0.046h0.008c0.055,0,0.07-0.033,0.07-0.071V38.94 c0-0.045-0.028-0.065-0.062-0.075c-0.158-0.042-0.582-0.092-0.888-0.092c-0.813,0-1.073,0.357-1.073,0.817 c0,0.306,0.105,0.569,0.495,0.8l0.578,0.345c0.218,0.129,0.276,0.196,0.276,0.318c0,0.201-0.084,0.293-0.373,0.293 c-0.164,0-0.625-0.034-0.795-0.05h-0.014c-0.042,0-0.074,0.021-0.074,0.079v0.41c0,0.047,0.04,0.071,0.082,0.084 c0.215,0.055,0.461,0.093,0.793,0.093C202.153,41.962,202.446,41.489,202.446,41.044" />
                        <path fill="#23308D" d="M202.354,44.597v-0.39c0-0.056-0.024-0.075-0.082-0.084c-0.147-0.021-0.357-0.055-0.81-0.055 c-0.541,0-1.022,0.135-1.022,0.897v2.166c0,0.046,0.037,0.084,0.084,0.084h0.545c0.046,0,0.082-0.038,0.082-0.084V45.91h1.086 c0.047,0,0.084-0.038,0.084-0.084v-0.398c0-0.046-0.037-0.084-0.084-0.084h-1.086v-0.377c0-0.218,0.072-0.285,0.27-0.285h0.852 C202.317,44.681,202.354,44.643,202.354,44.597" />
                        <rect x="93.524" y="37.251" transform="matrix(-0.9659 0.2589 -0.2589 -0.9659 201.9215 55.4788)" fill="#23308D" width="7.568" height="7.566" />
                        <rect x="86.15" y="33.297" transform="matrix(-0.9659 0.2588 -0.2588 -0.9659 183.9168 47.7161)" fill="#23308D" width="5.335" height="5.335" />
                        <polygon fill="#FFEC00" points="95.03,41.11 92.795,39.499 90.584,41.127 91.42,38.516 89.147,36.892 91.942,36.882 92.772,34.216 93.635,36.88 96.431,36.863 94.164,38.505" />
                        <polygon fill="none" stroke="#FFFFFF" strokeWidth="0.442" strokeMiterlimit="22.926" points="95.03,41.11 92.795,39.499 90.584,41.127 91.42,38.516 89.147,36.892 91.942,36.882 92.772,34.216 93.635,36.88 96.431,36.863 94.164,38.505" />
                        <rect x="112.897" y="16.598" fill="#E50004" width="3.58" height="3.58" />
                        <rect x="99.985" y="28.21" fill="#E50004" width="8.193" height="8.193" />
                        <polygon fill="#FFFFFF" points="100.775,28.991 107.409,28.991 107.409,35.586 106.579,29.869" />
                        <rect x="107.245" y="21.123" transform="matrix(0.9659 -0.2588 0.2588 0.9659 -2.5291 29.4039)" fill="#E50004" width="6.37" height="6.372" />
                        <polygon fill="#FFFFFF" points="113.582,26.135 108.6,27.47 107.272,22.515 109.046,26.644" />
                        <path fill="#23308D" d="M114.005,36.349v-0.523c0-0.061-0.051-0.11-0.111-0.11h-1.141c-0.264,0-0.358-0.091-0.358-0.383v-0.595 h1.454c0.062,0,0.111-0.051,0.111-0.113v-0.533c0-0.062-0.05-0.112-0.111-0.112h-1.454v-0.511c0-0.292,0.095-0.382,0.358-0.382 h1.141c0.061,0,0.111-0.051,0.111-0.112v-0.522c0-0.071-0.033-0.101-0.111-0.111c-0.197-0.028-0.479-0.073-1.084-0.073 c-0.725,0-1.37,0.179-1.37,1.201v1.864c0,1.022,0.64,1.207,1.364,1.207c0.606,0,0.893-0.045,1.09-0.072 C113.972,36.455,114.005,36.422,114.005,36.349" />
                        <path fill="#23308D" d="M117.987,34.922v-2.498c0-0.062-0.057-0.106-0.118-0.106h-0.729c-0.062,0-0.113,0.044-0.113,0.106v2.498 c0,0.573-0.157,0.814-0.662,0.814c-0.506,0-0.657-0.241-0.657-0.814v-2.498c0-0.062-0.051-0.106-0.112-0.106h-0.736 c-0.062,0-0.111,0.044-0.111,0.106v2.498c0,1.28,0.725,1.617,1.617,1.617S117.987,36.203,117.987,34.922" />
                        <path fill="#23308D" d="M122.03,36.422c0-0.017-0.006-0.039-0.016-0.063l-0.85-1.583c0.41-0.161,0.725-0.526,0.725-1.123v-0.071 c0-0.994-0.735-1.315-1.69-1.315c-0.404,0-0.882,0.034-1.14,0.073c-0.174,0.029-0.252,0.079-0.252,0.27v3.769 c0,0.062,0.049,0.113,0.112,0.113h0.729c0.062,0,0.111-0.052,0.111-0.113v-1.426c0.107,0,0.281,0.01,0.399,0.01l0.062-0.005 l0.752,1.421c0.028,0.056,0.051,0.113,0.113,0.113h0.858C122.009,36.491,122.03,36.461,122.03,36.422 M120.935,33.653 c0,0.383-0.185,0.533-0.774,0.533c-0.062,0-0.337-0.005-0.399-0.01v-1.078c0.068-0.006,0.36-0.018,0.438-0.018 c0.472,0,0.735,0.158,0.735,0.501V33.653z" />
                        <path fill="#23308D" d="M125.787,34.738V34.07c0-1.304-0.735-1.804-1.663-1.804c-0.926,0-1.662,0.5-1.662,1.804v0.668 c0,1.303,0.736,1.802,1.662,1.802C125.052,36.54,125.787,36.041,125.787,34.738 M124.821,34.738c0,0.674-0.225,0.96-0.697,0.96 c-0.471,0-0.689-0.286-0.689-0.96V34.07c0-0.675,0.219-0.961,0.689-0.961c0.473,0,0.697,0.286,0.697,0.961V34.738z" />
                        <path fill="#23308D" d="M129.604,33.67V33.62c0-1.055-0.836-1.354-1.684-1.354c-0.404,0-0.854,0.028-1.14,0.073 c-0.175,0.028-0.253,0.079-0.253,0.271v3.772c0,0.062,0.05,0.107,0.113,0.107h0.724c0.062,0,0.112-0.05,0.112-0.112v-1.365 c0.14,0.006,0.319,0.018,0.455,0.018C128.769,35.03,129.604,34.72,129.604,33.67 M128.651,33.67c0,0.382-0.219,0.567-0.719,0.567 c-0.062,0-0.394-0.006-0.455-0.011v-1.146c0.066-0.006,0.377-0.017,0.455-0.017c0.472,0,0.719,0.156,0.719,0.556V33.67z" />
                        <path fill="#23308D" d="M132.907,35.31c0-0.455-0.174-0.826-0.758-1.139l-0.719-0.389c-0.303-0.163-0.376-0.235-0.376-0.393 c0-0.202,0.118-0.298,0.437-0.298c0.383,0,1.035,0.051,1.191,0.062h0.012c0.072,0,0.096-0.045,0.096-0.096v-0.567 c0-0.061-0.041-0.09-0.084-0.101c-0.215-0.055-0.781-0.123-1.191-0.123c-1.089,0-1.438,0.477-1.438,1.094 c0,0.411,0.141,0.766,0.662,1.073l0.775,0.46c0.292,0.175,0.371,0.264,0.371,0.428c0,0.269-0.113,0.394-0.5,0.394 c-0.219,0-0.836-0.046-1.066-0.068h-0.017c-0.058,0-0.103,0.028-0.103,0.107v0.55c0,0.061,0.057,0.095,0.113,0.112 c0.286,0.073,0.617,0.124,1.061,0.124C132.515,36.541,132.907,35.906,132.907,35.31" />
                        <path fill="#23308D" d="M136.855,32.379c0-0.034-0.028-0.062-0.09-0.062h-0.859c-0.062,0-0.096,0.021-0.124,0.066l-1.234,1.825 v-1.785c0-0.062-0.051-0.106-0.112-0.106H133.7c-0.062,0-0.113,0.045-0.113,0.106v3.953c0,0.062,0.051,0.112,0.113,0.112h0.735 c0.062,0,0.112-0.05,0.112-0.112v-1.82l1.14,1.854c0.028,0.045,0.056,0.079,0.123,0.079h0.966c0.051,0,0.079-0.023,0.079-0.057 c0-0.017-0.006-0.039-0.022-0.062l-1.342-2.016l1.336-1.898C136.843,32.43,136.855,32.402,136.855,32.379" />
                        <path fill="#23308D" d="M138.405,36.377v-3.953c0-0.062-0.045-0.107-0.107-0.107h-0.752c-0.062,0-0.101,0.045-0.101,0.107v3.953 c0,0.062,0.039,0.112,0.101,0.112h0.752C138.36,36.49,138.405,36.439,138.405,36.377" />
                        <path fill="#23308D" d="M143.116,35.406c0-0.45-0.229-0.758-0.881-1.146l-0.518-0.309c-0.426-0.259-0.611-0.415-0.611-0.68 c0-0.409,0.247-0.533,0.826-0.533c0.324,0,0.718,0.017,0.966,0.034h0.011c0.057,0,0.096-0.034,0.096-0.091v-0.241 c0-0.057-0.039-0.084-0.096-0.096c-0.214-0.034-0.646-0.066-0.977-0.066c-0.854,0-1.344,0.313-1.344,0.993 c0,0.449,0.259,0.735,0.775,1.05l0.545,0.332c0.512,0.313,0.674,0.472,0.674,0.752c0,0.393-0.18,0.663-0.853,0.663 c-0.259,0-0.668-0.012-0.927-0.034h-0.018c-0.051,0-0.095,0.027-0.095,0.09v0.224c0,0.062,0.011,0.085,0.106,0.103 c0.246,0.039,0.539,0.078,0.933,0.078C142.835,36.529,143.116,35.945,143.116,35.406" />
                        <path fill="#23308D" d="M146.562,32.666v-0.247c0-0.062-0.045-0.101-0.105-0.101h-2.701c-0.062,0-0.113,0.044-0.113,0.101v0.247 c0,0.062,0.051,0.09,0.113,0.09h1.096v3.621c0,0.062,0.043,0.113,0.1,0.113h0.31c0.062,0,0.101-0.056,0.101-0.113v-3.621h1.096 C146.517,32.755,146.562,32.722,146.562,32.666" />
                        <path fill="#23308D" d="M150.097,36.417c0-0.017-0.006-0.034-0.016-0.051l-1.023-1.707c0.513-0.135,0.883-0.505,0.883-1.134 v-0.039c0-0.909-0.686-1.207-1.572-1.207c-0.326,0-0.747,0.016-0.988,0.045c-0.101,0.011-0.135,0.044-0.135,0.122v3.932 c0,0.062,0.044,0.112,0.102,0.112h0.303c0.062,0,0.102-0.057,0.102-0.112v-1.652c0.297,0.013,0.432,0.019,0.617,0.019h0.096 h0.051l1.005,1.673c0.045,0.073,0.077,0.073,0.146,0.073h0.359C150.069,36.49,150.097,36.456,150.097,36.417 M149.436,33.525 c0,0.606-0.378,0.78-1.067,0.78c-0.13,0-0.371-0.006-0.617-0.012v-1.538c0.135-0.017,0.442-0.017,0.617-0.017 c0.702,0,1.067,0.185,1.067,0.747V33.525z" />
                        <path fill="#23308D" d="M153.661,34.973v-2.549c0-0.062-0.051-0.107-0.107-0.107h-0.303c-0.062,0-0.106,0.052-0.106,0.107v2.549 c0,0.73-0.226,1.118-0.921,1.118c-0.696,0-0.922-0.394-0.922-1.118v-2.549c0-0.062-0.049-0.107-0.106-0.107h-0.303 c-0.062,0-0.107,0.052-0.107,0.107v2.549c0,1.073,0.528,1.557,1.438,1.557C153.132,36.53,153.661,36.046,153.661,34.973" />
                        <path fill="#23308D" d="M157.298,36.433c0-0.022-0.012-0.051-0.028-0.067l-1.69-2.021l1.69-1.909 c0.017-0.017,0.022-0.04,0.022-0.056c0-0.034-0.022-0.062-0.078-0.062h-0.405c-0.056,0-0.078,0.017-0.112,0.05l-1.617,1.87 v-1.813c0-0.062-0.051-0.106-0.107-0.106h-0.297c-0.062,0-0.106,0.05-0.106,0.106v3.953c0,0.062,0.045,0.112,0.101,0.112h0.31 c0.062,0,0.101-0.057,0.101-0.112v-1.87l1.584,1.926c0.033,0.039,0.072,0.057,0.123,0.057h0.438 C157.274,36.49,157.298,36.46,157.298,36.433" />
                        <path fill="#23308D" d="M160.625,32.666v-0.247c0-0.062-0.045-0.101-0.106-0.101h-2.701c-0.062,0-0.112,0.044-0.112,0.101v0.247 c0,0.062,0.05,0.09,0.112,0.09h1.095v3.621c0,0.062,0.045,0.113,0.101,0.113h0.309c0.062,0,0.102-0.056,0.102-0.113v-3.621h1.096 C160.58,32.755,160.625,32.722,160.625,32.666" />
                        <path fill="#23308D" d="M164.166,34.973v-2.549c0-0.062-0.052-0.107-0.107-0.107h-0.303c-0.062,0-0.106,0.052-0.106,0.107v2.549 c0,0.73-0.225,1.118-0.922,1.118c-0.695,0-0.92-0.394-0.92-1.118v-2.549c0-0.062-0.051-0.107-0.107-0.107h-0.303 c-0.062,0-0.106,0.052-0.106,0.107v2.549c0,1.073,0.526,1.557,1.437,1.557S164.166,36.046,164.166,34.973" />
                        <path fill="#23308D" d="M167.925,36.417c0-0.017-0.006-0.034-0.016-0.051l-1.023-1.707c0.512-0.135,0.883-0.505,0.883-1.134 v-0.039c0-0.909-0.686-1.207-1.572-1.207c-0.326,0-0.747,0.016-0.988,0.045c-0.101,0.011-0.135,0.044-0.135,0.122v3.932 c0,0.062,0.044,0.112,0.102,0.112h0.303c0.062,0,0.102-0.057,0.102-0.112v-1.652c0.297,0.013,0.432,0.019,0.617,0.019h0.096 h0.051l1.004,1.673c0.046,0.073,0.08,0.073,0.146,0.073h0.36C167.897,36.49,167.925,36.456,167.925,36.417 M167.263,33.525 c0,0.606-0.377,0.78-1.066,0.78c-0.13,0-0.371-0.006-0.617-0.012v-1.538c0.135-0.017,0.442-0.017,0.617-0.017 c0.702,0,1.066,0.185,1.066,0.747V33.525z" />
                        <path fill="#23308D" d="M171.573,36.327v-3.902c0-0.057-0.04-0.106-0.102-0.106h-0.298c-0.055,0-0.1,0.05-0.1,0.106v3.285 c0,0.005,0,0.016-0.007,0.016l-0.012-0.016l-1.724-3.279c-0.044-0.085-0.072-0.112-0.229-0.112h-0.309 c-0.09,0-0.163,0.066-0.163,0.156v3.947c0,0.057,0.045,0.068,0.101,0.068h0.293c0.056,0,0.101-0.05,0.101-0.107v-3.279 c0-0.011,0-0.017,0.006-0.017c0.005,0,0.011,0.006,0.017,0.017l1.734,3.297c0.028,0.056,0.09,0.09,0.18,0.09h0.349 C171.5,36.49,171.573,36.417,171.573,36.327" />
                        <path fill="#23308D" d="M173.064,36.377v-3.953c0-0.062-0.045-0.107-0.101-0.107h-0.309c-0.062,0-0.102,0.051-0.102,0.107v3.953 c0,0.062,0.045,0.112,0.102,0.112h0.309C173.026,36.49,173.064,36.433,173.064,36.377" />
                        <path fill="#23308D" d="M112.165,43.594v-3.953c0-0.062-0.045-0.106-0.101-0.106h-0.31c-0.061,0-0.101,0.051-0.101,0.106v3.953 c0,0.062,0.046,0.113,0.101,0.113h0.31C112.126,43.708,112.165,43.651,112.165,43.594" />
                        <path fill="#23308D" d="M115.052,43.594v-3.953c0-0.062-0.045-0.106-0.101-0.106h-0.31c-0.062,0-0.101,0.051-0.101,0.106v3.953 c0,0.062,0.045,0.113,0.101,0.113h0.31C115.013,43.708,115.052,43.651,115.052,43.594" />
                        <path fill="#23308D" d="M118.926,43.543v-3.901c0-0.058-0.038-0.107-0.101-0.107h-0.299c-0.055,0-0.101,0.05-0.101,0.107v3.285 c0,0.005,0,0.017-0.005,0.017l-0.011-0.017l-1.724-3.279c-0.045-0.084-0.074-0.113-0.23-0.113h-0.309 c-0.09,0-0.164,0.068-0.164,0.157v3.947c0,0.057,0.045,0.067,0.102,0.067h0.292c0.058,0,0.101-0.05,0.101-0.106v-3.279 c0-0.011,0-0.016,0.006-0.016s0.012,0.005,0.018,0.016l1.734,3.297c0.029,0.057,0.09,0.089,0.18,0.089h0.348 C118.853,43.707,118.926,43.634,118.926,43.543" />
                        <path fill="#23308D" d="M122.655,39.63c0-0.057-0.039-0.096-0.096-0.096h-0.332c-0.044,0-0.084,0.039-0.095,0.084l-0.983,3.577 c-0.021,0.079-0.05,0.118-0.106,0.118c-0.062,0-0.089-0.039-0.112-0.118l-0.977-3.577c-0.012-0.045-0.057-0.084-0.102-0.084 h-0.324c-0.058,0-0.103,0.039-0.103,0.096c0,0.012,0,0.022,0.006,0.034l1.039,3.655c0.101,0.354,0.365,0.427,0.572,0.427 c0.214,0,0.472-0.072,0.573-0.427l1.033-3.655C122.655,39.653,122.655,39.642,122.655,39.63" />
                        <path fill="#23308D" d="M125.484,43.639v-0.286c0-0.039-0.022-0.066-0.061-0.066h-1.085c-0.594,0-0.69-0.157-0.69-0.584v-0.944 h1.724c0.062,0,0.112-0.051,0.112-0.112v-0.213c0-0.062-0.05-0.113-0.112-0.113h-1.724v-0.779c0-0.427,0.097-0.584,0.69-0.584 h1.085c0.038,0,0.061-0.028,0.061-0.067v-0.281c0-0.033-0.022-0.073-0.056-0.078c-0.236-0.022-0.764-0.034-1.096-0.034 c-0.785,0-1.195,0.298-1.195,1.045v2.162c0,0.746,0.41,1.044,1.195,1.044c0.332,0,0.883-0.006,1.096-0.027 C125.462,43.713,125.484,43.674,125.484,43.639" />
                        <path fill="#23308D" d="M128.601,42.623c0-0.447-0.229-0.758-0.881-1.145l-0.517-0.31c-0.426-0.258-0.612-0.415-0.612-0.679 c0-0.41,0.248-0.534,0.826-0.534c0.325,0,0.719,0.018,0.965,0.034h0.012c0.057,0,0.096-0.034,0.096-0.089v-0.242 c0-0.056-0.039-0.084-0.096-0.095c-0.213-0.035-0.645-0.068-0.977-0.068c-0.854,0-1.343,0.315-1.343,0.994 c0,0.449,0.259,0.735,0.776,1.05l0.544,0.331c0.511,0.315,0.674,0.473,0.674,0.752c0,0.394-0.181,0.664-0.854,0.664 c-0.258,0-0.668-0.012-0.926-0.035h-0.017c-0.05,0-0.097,0.029-0.097,0.091v0.225c0,0.062,0.012,0.084,0.107,0.101 c0.247,0.039,0.539,0.078,0.932,0.078C128.32,43.746,128.601,43.162,128.601,42.623" />
                        <path fill="#23308D" d="M131.999,39.883v-0.247c0-0.062-0.045-0.101-0.106-0.101h-2.701c-0.062,0-0.112,0.044-0.112,0.101v0.247 c0,0.062,0.051,0.09,0.112,0.09h1.095v3.621c0,0.062,0.045,0.113,0.102,0.113h0.309c0.061,0,0.102-0.057,0.102-0.113v-3.621 h1.095C131.954,39.973,131.999,39.939,131.999,39.883" />
                        <path fill="#23308D" d="M133.2,43.594v-3.953c0-0.062-0.045-0.106-0.101-0.106h-0.31c-0.062,0-0.101,0.051-0.101,0.106v3.953 c0,0.062,0.045,0.113,0.101,0.113h0.31C133.161,43.708,133.2,43.651,133.2,43.594" />
                        <path fill="#23308D" d="M136.558,43.561v-0.229c0-0.062-0.027-0.09-0.09-0.09h-0.021c-0.202,0.016-0.607,0.045-0.927,0.045 c-0.55,0-0.976-0.332-0.976-1.275V41.23c0-0.954,0.426-1.273,0.976-1.273c0.325,0,0.741,0.034,0.944,0.05h0.016 c0.057,0,0.068-0.028,0.068-0.062V39.66c0-0.052-0.041-0.068-0.09-0.074c-0.248-0.039-0.59-0.09-0.945-0.09 c-0.689,0-1.481,0.359-1.481,1.734v0.781c0,1.365,0.792,1.735,1.481,1.735c0.479,0,0.697-0.027,0.934-0.072 C136.507,43.663,136.558,43.628,136.558,43.561" />
                        <path fill="#23308D" d="M137.76,43.594v-3.953c0-0.062-0.045-0.106-0.101-0.106h-0.31c-0.062,0-0.101,0.051-0.101,0.106v3.953 c0,0.062,0.045,0.113,0.101,0.113h0.31C137.721,43.708,137.76,43.651,137.76,43.594" />
                        <path fill="#23308D" d="M139.259,43.916v-4.274c0-0.056-0.051-0.106-0.107-0.106h-0.297c-0.056,0-0.106,0.051-0.106,0.106v4.274 c0,0.275-0.062,0.393-0.358,0.393h-0.22c-0.062,0-0.112,0.034-0.112,0.096v0.207c0,0.028,0.012,0.051,0.033,0.062 c0.034,0.017,0.174,0.044,0.299,0.044C139.007,44.717,139.259,44.455,139.259,43.916" />
                        <path fill="#23308D" d="M142.505,42.623c0-0.447-0.23-0.758-0.882-1.145l-0.517-0.31c-0.427-0.258-0.611-0.415-0.611-0.679 c0-0.41,0.246-0.534,0.824-0.534c0.326,0,0.72,0.018,0.967,0.034h0.011c0.058,0,0.095-0.034,0.095-0.089v-0.242 c0-0.056-0.037-0.084-0.095-0.095c-0.213-0.035-0.646-0.068-0.978-0.068c-0.853,0-1.342,0.315-1.342,0.994 c0,0.449,0.258,0.735,0.775,1.05l0.545,0.331c0.512,0.315,0.674,0.473,0.674,0.752c0,0.394-0.18,0.664-0.854,0.664 c-0.258,0-0.668-0.012-0.926-0.035h-0.019c-0.05,0-0.095,0.029-0.095,0.091v0.225c0,0.062,0.011,0.084,0.107,0.101 c0.246,0.039,0.539,0.078,0.932,0.078C142.224,43.746,142.505,43.162,142.505,42.623" />
                        <path fill="#23308D" d="M145.952,43.651c0-0.022-0.011-0.051-0.027-0.067l-1.691-2.021l1.691-1.909 c0.017-0.017,0.022-0.039,0.022-0.056c0-0.033-0.022-0.062-0.079-0.062h-0.404c-0.056,0-0.078,0.018-0.111,0.05l-1.618,1.871 v-1.813c0-0.062-0.051-0.107-0.106-0.107h-0.297c-0.062,0-0.107,0.05-0.107,0.107v3.953c0,0.062,0.045,0.111,0.101,0.111h0.31 c0.062,0,0.101-0.056,0.101-0.111v-1.87l1.584,1.926c0.034,0.039,0.073,0.056,0.123,0.056h0.438 C145.931,43.707,145.952,43.679,145.952,43.651" />
                        <path fill="#23308D" d="M147.199,43.594v-3.953c0-0.062-0.045-0.106-0.101-0.106h-0.31c-0.062,0-0.101,0.051-0.101,0.106v3.953 c0,0.062,0.045,0.113,0.101,0.113h0.31C147.159,43.708,147.199,43.651,147.199,43.594" />
                        <path fill="#23308D" d="M152.022,40.198v-0.523c0-0.071-0.034-0.1-0.111-0.11c-0.197-0.029-0.479-0.074-1.084-0.074 c-0.726,0-1.371,0.18-1.371,1.202v2.902c0,0.062,0.051,0.113,0.113,0.113h0.729c0.062,0,0.113-0.051,0.113-0.113v-1.64h1.455 c0.062,0,0.111-0.05,0.111-0.112V41.31c0-0.062-0.05-0.112-0.111-0.112h-1.455v-0.506c0-0.292,0.096-0.382,0.359-0.382h1.141 C151.972,40.31,152.022,40.258,152.022,40.198" />
                        <path fill="#23308D" d="M155.909,41.955v-0.668c0-1.303-0.736-1.803-1.664-1.803c-0.926,0-1.66,0.5-1.66,1.803v0.668 c0,1.304,0.734,1.804,1.66,1.804C155.173,43.758,155.909,43.258,155.909,41.955 M154.942,41.955c0,0.675-0.225,0.96-0.697,0.96 c-0.471,0-0.689-0.285-0.689-0.96v-0.668c0-0.674,0.219-0.96,0.689-0.96c0.473,0,0.697,0.286,0.697,0.96V41.955z" />
                        <path fill="#23308D" d="M159.906,43.494v-3.853c0-0.062-0.05-0.106-0.112-0.106h-0.662c-0.062,0-0.111,0.045-0.111,0.106v2.376 c0,0.039-0.019,0.05-0.029,0.05c-0.018,0-0.022-0.011-0.045-0.044l-1.331-2.313c-0.067-0.118-0.123-0.175-0.235-0.175h-0.568 c-0.117,0-0.213,0.096-0.213,0.214v3.846c0,0.062,0.051,0.113,0.112,0.113h0.663c0.062,0,0.111-0.051,0.111-0.113V41.32 c0-0.032,0.013-0.045,0.029-0.045s0.027,0.013,0.051,0.045l1.279,2.214c0.09,0.151,0.176,0.174,0.293,0.174h0.549 C159.806,43.708,159.906,43.612,159.906,43.494" />
                        <path fill="#23308D" d="M163.843,41.984v-0.725c0-1.186-0.674-1.775-1.791-1.775c-0.467,0-0.967,0.045-1.196,0.085 c-0.106,0.016-0.163,0.067-0.163,0.196v3.713c0,0.128,0.057,0.179,0.163,0.196c0.229,0.039,0.729,0.084,1.196,0.084 C163.169,43.758,163.843,43.167,163.843,41.984 M162.883,41.984c0,0.758-0.326,0.96-0.831,0.96c-0.141,0-0.32-0.006-0.399-0.012 V40.31c0.079-0.005,0.259-0.011,0.399-0.011c0.505,0,0.831,0.201,0.831,0.96V41.984z" />
                        <path fill="#23308D" d="M167.751,41.955v-0.668c0-1.303-0.736-1.803-1.662-1.803c-0.927,0-1.662,0.5-1.662,1.803v0.668 c0,1.304,0.735,1.804,1.662,1.804C167.015,43.758,167.751,43.258,167.751,41.955 M166.785,41.955c0,0.675-0.224,0.96-0.696,0.96 c-0.472,0-0.69-0.285-0.69-0.96v-0.668c0-0.674,0.219-0.96,0.69-0.96c0.473,0,0.696,0.286,0.696,0.96V41.955z" />
                        <path fill="#23308D" d="M171.608,39.625c0-0.057-0.039-0.091-0.095-0.091h-0.786c-0.057,0-0.096,0.044-0.107,0.102l-0.701,3.24 c-0.012,0.062-0.028,0.084-0.08,0.084c-0.051,0-0.066-0.022-0.078-0.084l-0.701-3.24c-0.012-0.058-0.057-0.102-0.113-0.102 h-0.779c-0.058,0-0.102,0.039-0.102,0.091c0,0.011,0.006,0.017,0.006,0.027l0.836,3.482c0.129,0.539,0.545,0.623,0.932,0.623 c0.389,0,0.799-0.084,0.928-0.623l0.836-3.482C171.603,39.642,171.608,39.63,171.608,39.625" />
                        <path fill="#23308D" d="M173.12,43.594v-3.953c0-0.062-0.045-0.106-0.107-0.106h-0.753c-0.062,0-0.101,0.045-0.101,0.106v3.953 c0,0.062,0.039,0.113,0.101,0.113h0.753C173.075,43.708,173.12,43.657,173.12,43.594" />
                        <polygon fill="#1B1C20" points="26.271,41.996 26.271,38.673 28.354,38.673 28.317,39.134 26.873,39.134 26.873,40.06 28.163,40.06 28.163,40.523 26.873,40.523 26.873,41.534 28.321,41.534 28.365,41.996" />
                        <path fill="#1B1C20" d="M30.302,42.014c-0.008-0.016-0.014-0.038-0.02-0.064c-0.008-0.028-0.014-0.057-0.019-0.088 c-0.005-0.029-0.009-0.061-0.013-0.092c-0.002-0.031-0.006-0.061-0.008-0.087c-0.066,0.061-0.133,0.112-0.199,0.156 c-0.067,0.045-0.135,0.081-0.201,0.11c-0.066,0.028-0.133,0.05-0.197,0.063s-0.129,0.021-0.191,0.021 c-0.109,0-0.203-0.015-0.277-0.042c-0.074-0.029-0.135-0.07-0.182-0.124c-0.047-0.056-0.078-0.123-0.1-0.204 c-0.02-0.081-0.029-0.175-0.029-0.28v-1.657l0.592-0.024v1.585c0,0.042,0.004,0.078,0.013,0.109s0.022,0.06,0.041,0.081 c0.019,0.023,0.043,0.04,0.073,0.053c0.031,0.011,0.066,0.017,0.109,0.017c0.073,0,0.153-0.021,0.242-0.064 c0.09-0.043,0.186-0.11,0.287-0.205v-1.546l0.593-0.029v2.283L30.302,42.014z" />
                        <path fill="#1B1C20" d="M32.737,40.208c-0.035-0.003-0.068-0.004-0.097-0.006C32.612,40.2,32.583,40.2,32.555,40.2 c-0.034,0-0.063,0.001-0.091,0.005c-0.026,0.003-0.053,0.011-0.076,0.021c-0.025,0.013-0.049,0.027-0.072,0.047 c-0.023,0.021-0.048,0.048-0.076,0.081c-0.027,0.034-0.057,0.076-0.09,0.125c-0.033,0.051-0.072,0.109-0.115,0.179v1.339h-0.582 v-2.277l0.463-0.039c0.008,0.019,0.017,0.045,0.022,0.081c0.008,0.035,0.015,0.076,0.019,0.121 c0.006,0.047,0.012,0.096,0.016,0.149c0.005,0.052,0.008,0.105,0.01,0.158c0.053-0.107,0.102-0.195,0.145-0.263 c0.043-0.065,0.086-0.118,0.127-0.155c0.043-0.035,0.086-0.061,0.129-0.072c0.043-0.014,0.092-0.02,0.145-0.02 c0.051,0,0.1,0.003,0.148,0.009c0.048,0.008,0.089,0.015,0.125,0.024L32.737,40.208z" />
                        <path fill="#1B1C20" d="M34.601,40.831c0-0.123-0.008-0.231-0.026-0.323c-0.019-0.093-0.046-0.17-0.085-0.23 c-0.04-0.062-0.09-0.108-0.152-0.14c-0.061-0.031-0.136-0.047-0.222-0.047c-0.08,0-0.151,0.016-0.214,0.04 c-0.063,0.032-0.117,0.08-0.16,0.145c-0.045,0.062-0.078,0.142-0.102,0.235c-0.023,0.096-0.035,0.204-0.035,0.32 c0,0.145,0.011,0.266,0.031,0.364c0.021,0.098,0.053,0.178,0.094,0.238c0.043,0.061,0.094,0.104,0.154,0.1 c0.062,0.024,0.133,0.038,0.213,0.038c0.086,0,0.161-0.017,0.225-0.051c0.063-0.033,0.115-0.083,0.158-0.14 c0.041-0.066,0.071-0.148,0.092-0.246C34.592,41.072,34.601,40.958,34.601,40.831 M35.208,40.80 c0,0.179-0.025,0.344-0.074,0.495s-0.123,0.281-0.22,0.39c-0.097,0.11-0.218,0.195-0.36,0.25 c-0.143,0.062-0.309,0.093-0.496,0.093c-0.356,0-0.621-0.095-0.798-0.282c-0.175-0.188-0.262-0.476-0.262-0.8 c0-0.184,0.026-0.35,0.079-0.5s0.129-0.281,0.229-0.388c0.1-0.108,0.221-0.191,0.362-0.25c0.142-0.06,0.302-0.089,0.481-0.08 c0.177,0,0.332,0.022,0.466,0.066c0.133,0.045,0.243,0.113,0.331,0.206c0.087,0.094,0.152,0.211,0.197,0.35 C35.187,40.435,35.208,40.604,35.208,40.801" />
                        <path fill="#1B1C20" d="M37.226,40.826c0-0.108-0.01-0.205-0.028-0.29c-0.021-0.086-0.049-0.158-0.086-0.217 c-0.038-0.06-0.086-0.104-0.144-0.136c-0.057-0.03-0.123-0.047-0.199-0.047c-0.045,0-0.088,0.005-0.131,0.018 c-0.041,0.012-0.08,0.026-0.119,0.046c-0.039,0.019-0.076,0.041-0.111,0.066c-0.036,0.026-0.07,0.054-0.102,0.082v1.186 c0.024,0.011,0.052,0.021,0.08,0.03c0.03,0.01,0.062,0.018,0.094,0.023c0.031,0.007,0.064,0.013,0.098,0.016 c0.034,0.006,0.068,0.007,0.1,0.007c0.178,0,0.314-0.064,0.408-0.192S37.226,41.091,37.226,40.826 M37.823,40.783 c0,0.11-0.01,0.218-0.027,0.323c-0.018,0.104-0.047,0.201-0.086,0.293c-0.037,0.091-0.088,0.173-0.148,0.249 c-0.061,0.074-0.133,0.138-0.217,0.191s-0.181,0.095-0.289,0.126c-0.109,0.029-0.232,0.044-0.369,0.044 c-0.03,0-0.064-0.001-0.1-0.002c-0.035-0.003-0.07-0.006-0.104-0.009c-0.034-0.004-0.066-0.01-0.097-0.017 c-0.031-0.009-0.058-0.016-0.08-0.025v0.987l-0.592,0.034v-3.26l0.512-0.039c0.014,0.036,0.025,0.079,0.035,0.129 c0.008,0.05,0.017,0.102,0.021,0.154c0.1-0.097,0.209-0.171,0.327-0.224c0.118-0.054,0.245-0.079,0.381-0.079 c0.127,0,0.241,0.021,0.343,0.063c0.104,0.043,0.191,0.109,0.264,0.201s0.129,0.207,0.168,0.35 C37.804,40.416,37.823,40.584,37.823,40.783" />
                        <path fill="#1B1C20" d="M39.948,41.316c0,0.101-0.02,0.195-0.058,0.283c-0.038,0.089-0.096,0.164-0.173,0.229 c-0.078,0.063-0.176,0.114-0.294,0.151c-0.119,0.037-0.259,0.056-0.42,0.056c-0.156,0-0.304-0.016-0.444-0.047 c-0.141-0.03-0.275-0.076-0.404-0.137l0.111-0.435c0.062,0.029,0.129,0.056,0.196,0.078c0.067,0.023,0.136,0.044,0.204,0.061 c0.068,0.016,0.135,0.028,0.199,0.037c0.065,0.009,0.125,0.013,0.181,0.013c0.063,0,0.116-0.006,0.161-0.017 c0.044-0.013,0.08-0.026,0.107-0.047c0.029-0.02,0.049-0.042,0.063-0.068c0.013-0.027,0.021-0.055,0.021-0.085 c0-0.029-0.004-0.057-0.013-0.082c-0.008-0.025-0.024-0.049-0.049-0.072c-0.024-0.024-0.062-0.045-0.108-0.067 c-0.047-0.021-0.109-0.045-0.188-0.067c-0.11-0.032-0.207-0.063-0.289-0.098c-0.082-0.031-0.152-0.065-0.211-0.101 s-0.105-0.071-0.145-0.108c-0.037-0.037-0.066-0.077-0.089-0.119c-0.021-0.042-0.036-0.089-0.044-0.137 c-0.009-0.048-0.013-0.1-0.013-0.154c0-0.108,0.022-0.207,0.063-0.296c0.043-0.09,0.104-0.164,0.18-0.229 c0.078-0.063,0.171-0.112,0.279-0.147c0.108-0.034,0.228-0.052,0.358-0.052c0.071,0,0.14,0.003,0.208,0.01 c0.066,0.007,0.132,0.017,0.197,0.029c0.064,0.016,0.129,0.032,0.191,0.053c0.062,0.021,0.125,0.048,0.188,0.077l-0.144,0.424 c-0.041-0.02-0.088-0.038-0.143-0.056c-0.053-0.018-0.109-0.032-0.166-0.045c-0.057-0.012-0.114-0.021-0.17-0.028 c-0.057-0.007-0.107-0.011-0.153-0.011c-0.06,0-0.11,0.005-0.151,0.017c-0.041,0.013-0.074,0.027-0.1,0.047 c-0.025,0.021-0.044,0.043-0.055,0.069c-0.012,0.026-0.018,0.055-0.018,0.085c0,0.035,0.006,0.067,0.015,0.095 c0.01,0.025,0.03,0.051,0.06,0.073c0.03,0.023,0.073,0.046,0.131,0.069c0.055,0.023,0.131,0.048,0.226,0.075 c0.141,0.044,0.256,0.088,0.346,0.133c0.091,0.047,0.162,0.096,0.214,0.15c0.053,0.054,0.088,0.111,0.108,0.176 S39.948,41.237,39.948,41.316" />
                        <polygon fill="#1B1C20" points="41.772,39.723 42.413,39.723 41.561,40.739 42.499,41.996 41.738,41.996 41.025,40.979 41.025,41.996 40.438,41.996 40.438,38.537 41.025,38.503 41.025,40.643" />
                        <path fill="#1B1C20" d="M43.648,41.082c-0.099,0.015-0.176,0.033-0.233,0.057c-0.057,0.021-0.096,0.05-0.117,0.08 c-0.022,0.031-0.033,0.068-0.033,0.113s0.006,0.086,0.02,0.123c0.012,0.037,0.031,0.068,0.057,0.094 c0.025,0.026,0.056,0.046,0.092,0.061c0.037,0.014,0.079,0.021,0.126,0.021s0.093-0.006,0.138-0.019 c0.047-0.014,0.09-0.03,0.129-0.05c0.043-0.022,0.079-0.046,0.113-0.072c0.034-0.027,0.062-0.054,0.088-0.081v-0.366 C43.874,41.052,43.747,41.066,43.648,41.082 M44.612,42.019c-0.083,0-0.156-0.007-0.22-0.02c-0.063-0.012-0.117-0.03-0.163-0.054 c-0.046-0.024-0.084-0.055-0.111-0.089c-0.029-0.035-0.051-0.075-0.063-0.121c-0.097,0.105-0.203,0.182-0.32,0.229 c-0.116,0.047-0.248,0.07-0.394,0.07c-0.086,0-0.168-0.015-0.245-0.045c-0.076-0.03-0.144-0.074-0.202-0.132 s-0.104-0.128-0.137-0.212c-0.034-0.084-0.051-0.182-0.051-0.291c0-0.055,0.006-0.105,0.02-0.154 c0.012-0.048,0.036-0.094,0.072-0.136c0.035-0.042,0.084-0.082,0.146-0.118c0.062-0.035,0.143-0.069,0.24-0.098 c0.098-0.027,0.216-0.054,0.355-0.075c0.139-0.022,0.301-0.04,0.49-0.054c0.004-0.06,0.004-0.114,0.004-0.168 c0.002-0.052,0.002-0.106,0.002-0.166c0-0.051-0.006-0.095-0.015-0.13c-0.01-0.037-0.024-0.065-0.044-0.087 s-0.045-0.038-0.076-0.048s-0.068-0.014-0.111-0.014c-0.062,0-0.125,0.005-0.19,0.018c-0.065,0.012-0.13,0.029-0.195,0.052 c-0.065,0.024-0.129,0.053-0.192,0.084c-0.062,0.033-0.121,0.07-0.178,0.112l-0.231-0.357c0.079-0.058,0.163-0.109,0.247-0.155 c0.082-0.045,0.171-0.083,0.262-0.113c0.092-0.031,0.188-0.054,0.291-0.07c0.102-0.016,0.21-0.022,0.327-0.022 c0.135,0,0.247,0.017,0.335,0.051c0.089,0.034,0.158,0.084,0.212,0.149c0.052,0.063,0.088,0.143,0.108,0.236 c0.021,0.095,0.033,0.199,0.033,0.319v0.944c0,0.042,0.002,0.076,0.008,0.102c0.006,0.026,0.019,0.047,0.041,0.063 c0.021,0.015,0.054,0.027,0.098,0.037c0.044,0.011,0.104,0.02,0.181,0.029l-0.096,0.428c-0.044,0.004-0.086,0.004-0.126,0.005 H44.612z" />
                        <path fill="#1B1C20" d="M47.696,42.014c-0.008-0.016-0.015-0.038-0.021-0.064c-0.007-0.028-0.012-0.057-0.018-0.088 c-0.004-0.029-0.008-0.061-0.012-0.092c-0.003-0.031-0.006-0.061-0.008-0.087c-0.066,0.061-0.133,0.112-0.199,0.156 c-0.068,0.045-0.135,0.081-0.201,0.11c-0.066,0.028-0.133,0.05-0.197,0.063c-0.065,0.014-0.129,0.021-0.192,0.021 c-0.11,0-0.202-0.015-0.276-0.042c-0.074-0.029-0.135-0.07-0.182-0.124c-0.047-0.056-0.08-0.123-0.1-0.204s-0.03-0.175-0.03-0.28 v-1.657l0.593-0.024v1.585c0,0.042,0.004,0.078,0.012,0.109s0.022,0.06,0.041,0.081c0.02,0.023,0.043,0.04,0.074,0.053 c0.029,0.011,0.066,0.017,0.11,0.017c0.071,0,0.151-0.021,0.242-0.064c0.089-0.043,0.185-0.11,0.286-0.205v-1.546l0.593-0.029 v2.283L47.696,42.014z" />
                        <path fill="#1B1C20" d="M50.213,41.996v-1.579c0-0.04-0.004-0.076-0.01-0.108c-0.007-0.031-0.021-0.06-0.036-0.083 c-0.018-0.022-0.04-0.04-0.07-0.052c-0.029-0.012-0.066-0.019-0.109-0.019c-0.039,0-0.078,0.008-0.123,0.021 c-0.043,0.014-0.088,0.032-0.137,0.057c-0.047,0.024-0.096,0.056-0.146,0.093c-0.05,0.036-0.099,0.078-0.147,0.125v1.546h-0.589 v-2.277l0.511-0.035c0.008,0.018,0.015,0.038,0.021,0.064c0.005,0.026,0.011,0.055,0.015,0.085 c0.006,0.031,0.01,0.064,0.014,0.098c0.004,0.035,0.007,0.07,0.01,0.104c0.135-0.122,0.271-0.216,0.408-0.277 s0.269-0.093,0.395-0.093c0.107,0,0.199,0.013,0.273,0.039s0.136,0.064,0.182,0.119c0.046,0.052,0.08,0.119,0.102,0.199 c0.021,0.08,0.031,0.175,0.031,0.282v1.691H50.213z" />
                        <path fill="#1B1C20" d="M51.427,39.728v2.268h0.578v-2.302L51.427,39.728z M51.413,39.275h0.592v-0.521h-0.592V39.275z" />
                        <path fill="#1B1C20" d="M52.636,38.754h0.593v0.521h-0.593V38.754z M53.165,42.504c-0.041,0.119-0.1,0.215-0.18,0.289 c-0.08,0.075-0.181,0.131-0.302,0.169c-0.123,0.038-0.267,0.064-0.433,0.078l-0.082-0.443c0.097-0.013,0.176-0.028,0.238-0.048 c0.06-0.019,0.107-0.044,0.142-0.073c0.032-0.029,0.056-0.065,0.069-0.109c0.012-0.043,0.018-0.097,0.018-0.159v-2.48 l0.588-0.033v2.368C53.224,42.239,53.204,42.385,53.165,42.504" />
                        <path fill="#1B1C20" d="M54.651,41.082c-0.098,0.015-0.177,0.033-0.232,0.057c-0.057,0.021-0.098,0.05-0.119,0.08 c-0.021,0.031-0.033,0.068-0.033,0.113s0.008,0.086,0.02,0.123c0.014,0.037,0.033,0.068,0.058,0.094 c0.024,0.026,0.056,0.046,0.092,0.061c0.036,0.014,0.078,0.021,0.125,0.021c0.048,0,0.095-0.006,0.14-0.019 c0.045-0.014,0.088-0.03,0.129-0.05c0.041-0.022,0.078-0.046,0.113-0.072c0.033-0.027,0.062-0.054,0.086-0.081v-0.366 C54.876,41.052,54.751,41.066,54.651,41.082 M55.616,42.019c-0.084,0-0.156-0.007-0.221-0.02 c-0.062-0.012-0.117-0.03-0.164-0.054c-0.046-0.024-0.082-0.055-0.111-0.089c-0.028-0.035-0.049-0.075-0.062-0.121 c-0.097,0.105-0.203,0.182-0.32,0.229s-0.248,0.07-0.393,0.07c-0.087,0-0.169-0.015-0.246-0.045s-0.145-0.074-0.202-0.132 s-0.104-0.128-0.137-0.212c-0.034-0.084-0.052-0.182-0.052-0.291c0-0.055,0.008-0.105,0.021-0.154 c0.013-0.048,0.036-0.094,0.072-0.136c0.035-0.042,0.084-0.082,0.146-0.118c0.063-0.035,0.143-0.069,0.241-0.098 c0.097-0.027,0.216-0.054,0.354-0.075c0.139-0.022,0.303-0.04,0.492-0.054c0.002-0.06,0.004-0.114,0.004-0.168 c0-0.052,0-0.106,0-0.166c0-0.051-0.004-0.095-0.014-0.13c-0.01-0.037-0.023-0.065-0.044-0.087s-0.046-0.038-0.077-0.048 s-0.067-0.014-0.111-0.014c-0.061,0-0.125,0.005-0.189,0.018c-0.064,0.012-0.131,0.029-0.195,0.052 c-0.065,0.024-0.129,0.053-0.191,0.084c-0.062,0.033-0.122,0.07-0.18,0.112l-0.231-0.357c0.081-0.058,0.164-0.109,0.248-0.155 c0.083-0.045,0.171-0.083,0.262-0.113c0.091-0.031,0.188-0.054,0.29-0.07c0.102-0.016,0.211-0.022,0.328-0.022 c0.135,0,0.246,0.017,0.335,0.051s0.159,0.084,0.211,0.149c0.052,0.063,0.089,0.143,0.11,0.236 c0.021,0.095,0.031,0.199,0.031,0.319v0.944c0,0.042,0.002,0.076,0.009,0.102c0.006,0.026,0.019,0.047,0.041,0.063 c0.021,0.015,0.054,0.027,0.098,0.037c0.044,0.011,0.104,0.02,0.181,0.029l-0.096,0.428c-0.045,0.004-0.086,0.004-0.126,0.005 H55.616z" />
                        <path fill="#1B1C20" d="M16.452,45.144c0-0.107,0.02-0.207,0.057-0.295s0.093-0.168,0.168-0.24 c0.074-0.072,0.168-0.139,0.279-0.198c0.112-0.061,0.244-0.115,0.393-0.167l0.18,0.347c-0.105,0.048-0.189,0.091-0.256,0.13 c-0.066,0.038-0.117,0.075-0.154,0.11c-0.039,0.035-0.063,0.071-0.076,0.108c-0.014,0.036-0.021,0.077-0.021,0.123 c0,0.029,0.004,0.068,0.013,0.119c0.008,0.05,0.018,0.105,0.03,0.165s0.026,0.119,0.042,0.182 c0.016,0.062,0.033,0.118,0.049,0.171l-0.49,0.087c-0.031-0.037-0.061-0.08-0.086-0.131c-0.026-0.051-0.049-0.104-0.067-0.161 c-0.019-0.058-0.034-0.116-0.044-0.176C16.458,45.257,16.452,45.2,16.452,45.144 M15.296,45.144c0-0.107,0.02-0.207,0.057-0.295 s0.094-0.168,0.168-0.24c0.075-0.072,0.169-0.139,0.281-0.198c0.111-0.061,0.242-0.115,0.391-0.167l0.18,0.347 c-0.105,0.048-0.19,0.091-0.256,0.13c-0.065,0.038-0.115,0.075-0.152,0.11c-0.036,0.035-0.061,0.071-0.073,0.108 c-0.013,0.036-0.021,0.077-0.021,0.123c0,0.029,0.004,0.068,0.012,0.119c0.007,0.05,0.016,0.105,0.027,0.165 s0.025,0.119,0.041,0.182c0.014,0.062,0.029,0.118,0.045,0.171l-0.485,0.087c-0.033-0.037-0.063-0.08-0.089-0.131 c-0.026-0.051-0.049-0.104-0.067-0.161c-0.019-0.058-0.032-0.116-0.042-0.176C15.302,45.257,15.296,45.2,15.296,45.144" />
                        <polygon fill="#1B1C20" points="20.127,44.754 18.47,47.239 20.079,47.239 20.05,47.702 17.806,47.702 17.763,47.263 19.347,44.841 17.854,44.841 17.888,44.379 20.103,44.379" />
                        <path fill="#1B1C20" d="M21.263,46.788c-0.099,0.016-0.177,0.033-0.233,0.057c-0.058,0.021-0.097,0.049-0.118,0.08 c-0.021,0.032-0.033,0.069-0.033,0.113c0,0.046,0.006,0.087,0.02,0.124c0.014,0.035,0.031,0.067,0.058,0.094 c0.024,0.024,0.056,0.046,0.092,0.059c0.036,0.016,0.078,0.022,0.125,0.022c0.048,0,0.095-0.007,0.14-0.02 s0.088-0.029,0.129-0.051c0.041-0.021,0.078-0.044,0.111-0.072c0.034-0.025,0.064-0.053,0.088-0.081v-0.364 C21.487,46.759,21.361,46.772,21.263,46.788 M22.228,47.725c-0.084,0-0.156-0.006-0.221-0.019 c-0.062-0.014-0.117-0.03-0.164-0.056c-0.046-0.022-0.082-0.053-0.111-0.088c-0.028-0.035-0.049-0.076-0.063-0.121 c-0.096,0.105-0.202,0.183-0.319,0.229c-0.117,0.047-0.248,0.069-0.393,0.069c-0.087,0-0.169-0.015-0.246-0.044 c-0.076-0.029-0.145-0.073-0.201-0.131c-0.059-0.058-0.104-0.129-0.139-0.213c-0.033-0.085-0.05-0.181-0.05-0.291 c0-0.055,0.006-0.104,0.019-0.154c0.014-0.049,0.037-0.094,0.072-0.136c0.035-0.043,0.085-0.083,0.146-0.119 c0.062-0.034,0.143-0.067,0.24-0.097c0.098-0.028,0.217-0.053,0.356-0.075c0.138-0.021,0.302-0.04,0.491-0.054 c0.002-0.059,0.004-0.115,0.004-0.167c0-0.053,0-0.108,0-0.166c0-0.052-0.004-0.096-0.014-0.131 c-0.01-0.036-0.024-0.065-0.045-0.086c-0.02-0.022-0.045-0.038-0.076-0.048c-0.031-0.011-0.067-0.015-0.11-0.015 c-0.062,0-0.125,0.005-0.19,0.018c-0.066,0.013-0.131,0.029-0.195,0.053c-0.065,0.023-0.129,0.051-0.192,0.084 c-0.062,0.032-0.122,0.07-0.179,0.111l-0.231-0.356c0.081-0.059,0.163-0.111,0.247-0.157c0.084-0.044,0.172-0.082,0.264-0.111 c0.09-0.031,0.188-0.055,0.289-0.07s0.211-0.024,0.328-0.024c0.135,0,0.246,0.019,0.335,0.053 c0.089,0.033,0.159,0.084,0.212,0.147c0.051,0.064,0.088,0.145,0.108,0.238c0.022,0.093,0.032,0.199,0.032,0.319v0.943 c0,0.042,0.002,0.076,0.009,0.103c0.005,0.025,0.019,0.047,0.04,0.062c0.021,0.016,0.055,0.029,0.098,0.039 c0.044,0.009,0.105,0.019,0.182,0.029l-0.097,0.428c-0.044,0.002-0.086,0.003-0.125,0.003 C22.3,47.725,22.261,47.725,22.228,47.725" />
                        <path fill="#1B1C20" d="M22.93,44.46h0.593v0.52H22.93V44.46z M23.458,48.21c-0.038,0.119-0.098,0.215-0.178,0.29 c-0.08,0.073-0.182,0.13-0.303,0.169c-0.122,0.038-0.266,0.063-0.433,0.078l-0.082-0.444c0.098-0.013,0.177-0.029,0.237-0.047 c0.062-0.02,0.108-0.044,0.143-0.074c0.033-0.029,0.057-0.066,0.068-0.11c0.013-0.043,0.019-0.096,0.019-0.158v-2.48l0.588-0.034 v2.369C23.518,47.944,23.497,48.091,23.458,48.21" />
                        <path fill="#1B1C20" d="M25.478,46.331c0-0.182-0.031-0.314-0.095-0.398c-0.063-0.085-0.155-0.127-0.276-0.127 c-0.066,0-0.127,0.011-0.182,0.033c-0.055,0.021-0.104,0.054-0.145,0.098c-0.04,0.043-0.074,0.097-0.102,0.164 c-0.025,0.065-0.044,0.142-0.054,0.23H25.478z M25.832,47.579c-0.076,0.033-0.156,0.062-0.239,0.086 c-0.082,0.022-0.167,0.041-0.255,0.054c-0.087,0.011-0.175,0.017-0.265,0.017c-0.165,0-0.312-0.019-0.442-0.059 c-0.129-0.041-0.237-0.104-0.327-0.19s-0.158-0.197-0.205-0.334c-0.048-0.136-0.07-0.3-0.07-0.491 c0-0.195,0.025-0.372,0.076-0.53c0.049-0.159,0.123-0.294,0.222-0.406c0.097-0.111,0.216-0.197,0.356-0.257 c0.143-0.062,0.305-0.091,0.485-0.091c0.155,0,0.285,0.023,0.392,0.072s0.191,0.117,0.258,0.209 c0.065,0.091,0.113,0.2,0.142,0.329s0.043,0.273,0.043,0.434c0,0.031,0,0.068-0.002,0.108c-0.001,0.04-0.005,0.091-0.013,0.152 h-1.383c0,0.101,0.012,0.191,0.033,0.268c0.021,0.077,0.053,0.142,0.098,0.191c0.045,0.051,0.102,0.089,0.17,0.114 c0.07,0.025,0.152,0.039,0.25,0.039c0.06,0,0.121-0.007,0.187-0.019c0.065-0.011,0.13-0.025,0.194-0.046 c0.064-0.02,0.128-0.043,0.191-0.069c0.062-0.025,0.121-0.054,0.178-0.084l0.141,0.39C25.979,47.507,25.909,47.546,25.832,47.579 " />
                        <path fill="#1B1C20" d="M27.93,45.877c-0.112-0.049-0.236-0.073-0.372-0.073c-0.081,0-0.154,0.014-0.221,0.043 s-0.123,0.074-0.17,0.136c-0.047,0.062-0.084,0.144-0.108,0.243c-0.026,0.1-0.039,0.221-0.039,0.363 c0,0.133,0.01,0.245,0.03,0.334c0.021,0.089,0.051,0.161,0.09,0.215c0.04,0.054,0.088,0.093,0.145,0.116 c0.059,0.022,0.123,0.034,0.197,0.034c0.044,0,0.088-0.006,0.131-0.017c0.042-0.011,0.083-0.023,0.122-0.041 c0.038-0.016,0.074-0.035,0.106-0.055c0.035-0.021,0.063-0.042,0.089-0.063V45.877z M27.991,47.725 c-0.005-0.016-0.011-0.035-0.017-0.056c-0.005-0.022-0.011-0.046-0.015-0.071s-0.006-0.051-0.008-0.078 c-0.002-0.026-0.004-0.053-0.004-0.078c-0.044,0.042-0.09,0.081-0.138,0.117c-0.05,0.035-0.103,0.066-0.157,0.092 c-0.055,0.026-0.113,0.047-0.175,0.062c-0.062,0.015-0.127,0.021-0.194,0.021c-0.16,0-0.293-0.023-0.404-0.075 c-0.109-0.052-0.198-0.123-0.266-0.22c-0.068-0.095-0.117-0.212-0.148-0.348c-0.029-0.138-0.043-0.292-0.043-0.464 c0-0.201,0.024-0.379,0.076-0.534c0.053-0.155,0.125-0.285,0.219-0.392c0.096-0.105,0.209-0.186,0.341-0.24 c0.133-0.056,0.28-0.081,0.44-0.081c0.086,0,0.164,0.005,0.233,0.016c0.07,0.01,0.136,0.028,0.197,0.056v-1.209l0.587-0.039 v3.479L27.991,47.725z" />
                        <path fill="#1B1C20" d="M30.524,47.702v-1.58c0-0.039-0.004-0.074-0.01-0.106c-0.008-0.033-0.021-0.061-0.037-0.083 c-0.016-0.023-0.039-0.04-0.069-0.052c-0.028-0.014-0.065-0.02-0.11-0.02c-0.037,0-0.078,0.007-0.121,0.021 c-0.045,0.013-0.09,0.031-0.138,0.057c-0.047,0.025-0.097,0.056-0.146,0.093c-0.051,0.036-0.1,0.078-0.149,0.124v1.547h-0.587 v-2.277l0.511-0.034c0.007,0.016,0.013,0.038,0.019,0.063c0.007,0.026,0.013,0.055,0.017,0.085 c0.005,0.03,0.009,0.064,0.013,0.099s0.008,0.069,0.01,0.104c0.136-0.123,0.271-0.216,0.408-0.276 c0.137-0.062,0.27-0.094,0.396-0.094c0.106,0,0.198,0.014,0.272,0.039c0.074,0.026,0.135,0.065,0.182,0.118s0.08,0.119,0.102,0.2 c0.021,0.08,0.031,0.175,0.031,0.282v1.691H30.524z" />
                        <path fill="#1B1C20" d="M33.212,46.537c0-0.122-0.01-0.231-0.027-0.323c-0.018-0.094-0.046-0.169-0.085-0.232 c-0.04-0.062-0.09-0.107-0.151-0.14c-0.062-0.031-0.136-0.046-0.224-0.046c-0.078,0-0.149,0.016-0.213,0.048 c-0.063,0.031-0.116,0.079-0.16,0.143s-0.078,0.144-0.103,0.237c-0.022,0.095-0.034,0.204-0.034,0.327 c0,0.144,0.011,0.266,0.032,0.364c0.021,0.098,0.052,0.178,0.094,0.238s0.093,0.104,0.154,0.129 c0.061,0.025,0.131,0.038,0.211,0.038c0.088,0,0.162-0.016,0.227-0.05c0.062-0.034,0.115-0.083,0.157-0.15 c0.042-0.065,0.071-0.147,0.092-0.244C33.202,46.777,33.212,46.665,33.212,46.537 M33.818,46.507c0,0.18-0.024,0.345-0.073,0.495 c-0.051,0.151-0.123,0.281-0.221,0.391c-0.097,0.109-0.217,0.195-0.359,0.255c-0.144,0.062-0.31,0.092-0.498,0.092 c-0.355,0-0.621-0.094-0.797-0.281c-0.174-0.188-0.262-0.476-0.262-0.859c0-0.184,0.026-0.351,0.079-0.501 c0.054-0.151,0.129-0.28,0.228-0.388c0.101-0.107,0.222-0.191,0.363-0.25c0.143-0.06,0.303-0.089,0.481-0.089 c0.178,0,0.333,0.022,0.466,0.066c0.133,0.045,0.243,0.114,0.331,0.207c0.088,0.092,0.153,0.211,0.196,0.354 C33.798,46.142,33.818,46.311,33.818,46.507" />
                        <path fill="#1B1C20" d="M36.661,45.877c-0.112-0.049-0.235-0.073-0.373-0.073c-0.08,0-0.153,0.014-0.219,0.043 c-0.066,0.029-0.125,0.074-0.17,0.136c-0.048,0.062-0.084,0.144-0.109,0.243c-0.026,0.1-0.039,0.221-0.039,0.363 c0,0.133,0.01,0.245,0.031,0.334c0.021,0.089,0.051,0.161,0.09,0.215s0.088,0.093,0.145,0.116 c0.057,0.022,0.122,0.034,0.196,0.034c0.044,0,0.088-0.006,0.131-0.017s0.083-0.023,0.122-0.041 c0.038-0.016,0.074-0.035,0.107-0.055c0.033-0.021,0.062-0.042,0.088-0.063V45.877z M36.723,47.725 c-0.005-0.016-0.011-0.035-0.017-0.056c-0.006-0.022-0.01-0.046-0.014-0.071s-0.008-0.051-0.01-0.078 c-0.002-0.026-0.003-0.053-0.003-0.078c-0.043,0.042-0.089,0.081-0.14,0.117c-0.049,0.035-0.101,0.066-0.155,0.092 c-0.056,0.026-0.114,0.047-0.175,0.062c-0.062,0.015-0.127,0.021-0.195,0.021c-0.159,0-0.293-0.023-0.402-0.075 c-0.111-0.052-0.199-0.123-0.267-0.22c-0.067-0.095-0.117-0.212-0.147-0.348c-0.029-0.138-0.045-0.292-0.045-0.464 c0-0.201,0.026-0.379,0.077-0.534c0.052-0.155,0.125-0.285,0.22-0.392c0.094-0.105,0.207-0.186,0.34-0.24 c0.133-0.056,0.279-0.081,0.441-0.081c0.086,0,0.163,0.005,0.232,0.016c0.07,0.01,0.136,0.028,0.197,0.056v-1.209l0.586-0.039 v3.479L36.723,47.725z" />
                        <path fill="#1B1C20" d="M39.362,46.537c0-0.122-0.01-0.231-0.027-0.323c-0.018-0.094-0.046-0.169-0.085-0.232 c-0.04-0.062-0.09-0.107-0.151-0.14c-0.062-0.031-0.136-0.046-0.224-0.046c-0.078,0-0.149,0.016-0.213,0.048 c-0.063,0.031-0.116,0.079-0.16,0.143s-0.078,0.144-0.103,0.237c-0.022,0.095-0.034,0.204-0.034,0.327 c0,0.144,0.011,0.266,0.032,0.364c0.021,0.098,0.052,0.178,0.094,0.238s0.093,0.104,0.154,0.129 c0.061,0.025,0.131,0.038,0.211,0.038c0.088,0,0.162-0.016,0.227-0.05c0.062-0.034,0.115-0.083,0.157-0.15 c0.042-0.065,0.071-0.147,0.092-0.244C39.353,46.777,39.362,46.665,39.362,46.537 M39.97,46.507c0,0.18-0.025,0.345-0.074,0.495 c-0.051,0.151-0.123,0.281-0.221,0.391c-0.097,0.109-0.217,0.195-0.359,0.255c-0.144,0.062-0.31,0.092-0.498,0.092 c-0.355,0-0.621-0.094-0.795-0.281c-0.176-0.188-0.264-0.476-0.264-0.859c0-0.184,0.026-0.351,0.079-0.501 c0.054-0.151,0.129-0.28,0.228-0.388c0.101-0.107,0.222-0.191,0.363-0.25c0.143-0.06,0.303-0.089,0.481-0.089 c0.178,0,0.333,0.022,0.466,0.066c0.133,0.045,0.243,0.114,0.331,0.207c0.088,0.092,0.153,0.211,0.196,0.354 C39.948,46.142,39.97,46.311,39.97,46.507" />
                        <path fill="#1B1C20" d="M42.604,44.696c-0.025-0.006-0.054-0.012-0.084-0.017c-0.029-0.006-0.061-0.011-0.09-0.016 c-0.031-0.006-0.061-0.009-0.089-0.011c-0.028-0.003-0.053-0.005-0.075-0.005c-0.041,0-0.074,0.003-0.102,0.012 c-0.027,0.008-0.048,0.02-0.062,0.037c-0.017,0.02-0.027,0.042-0.033,0.073c-0.008,0.03-0.01,0.068-0.01,0.114v0.545h0.481 l-0.033,0.423H42.06v1.851h-0.584v-1.851l-0.293-0.019l0.034-0.396l0.259-0.009v-0.473c0-0.128,0.014-0.24,0.041-0.334 c0.025-0.096,0.066-0.174,0.123-0.235c0.055-0.062,0.127-0.107,0.215-0.138c0.088-0.029,0.192-0.045,0.315-0.045 c0.05,0,0.099,0.003,0.146,0.008c0.05,0.004,0.096,0.01,0.141,0.017c0.045,0.009,0.087,0.018,0.126,0.027 c0.04,0.009,0.075,0.02,0.108,0.031L42.604,44.696z" />
                        <path fill="#1B1C20" d="M44.429,46.537c0-0.122-0.008-0.231-0.027-0.323c-0.018-0.094-0.047-0.169-0.085-0.232 c-0.04-0.062-0.091-0.107-0.151-0.14c-0.062-0.031-0.136-0.046-0.223-0.046c-0.08,0-0.15,0.016-0.215,0.048 c-0.062,0.031-0.115,0.079-0.159,0.143s-0.078,0.144-0.101,0.237c-0.024,0.095-0.035,0.204-0.035,0.327 c0,0.144,0.01,0.266,0.031,0.364c0.021,0.098,0.051,0.178,0.094,0.238c0.041,0.061,0.093,0.104,0.154,0.129 s0.131,0.038,0.211,0.038c0.088,0,0.162-0.016,0.227-0.05c0.062-0.034,0.115-0.083,0.156-0.15 c0.043-0.065,0.074-0.147,0.094-0.244C44.418,46.777,44.429,46.665,44.429,46.537 M45.035,46.507c0,0.18-0.024,0.345-0.073,0.495 c-0.05,0.151-0.123,0.281-0.221,0.391s-0.217,0.195-0.359,0.255c-0.144,0.062-0.31,0.092-0.498,0.092 c-0.355,0-0.621-0.094-0.797-0.281c-0.174-0.188-0.262-0.476-0.262-0.859c0-0.184,0.025-0.351,0.079-0.501 c0.053-0.151,0.129-0.28,0.229-0.388c0.099-0.107,0.219-0.191,0.361-0.25c0.143-0.06,0.303-0.089,0.48-0.089 c0.179,0,0.334,0.022,0.467,0.066c0.133,0.045,0.242,0.114,0.331,0.207c0.088,0.092,0.153,0.211,0.196,0.354 C45.013,46.142,45.035,46.311,45.035,46.507" />
                        <path fill="#1B1C20" d="M46.908,47.702v-1.58c0-0.039-0.004-0.074-0.01-0.106c-0.007-0.033-0.021-0.061-0.036-0.083 c-0.018-0.023-0.04-0.04-0.07-0.052c-0.029-0.014-0.066-0.02-0.109-0.02c-0.039,0-0.078,0.007-0.123,0.021 c-0.043,0.013-0.088,0.031-0.137,0.057c-0.047,0.025-0.096,0.056-0.146,0.093c-0.05,0.036-0.099,0.078-0.147,0.124v1.547h-0.589 v-2.277l0.511-0.034c0.008,0.016,0.015,0.038,0.021,0.063c0.005,0.026,0.011,0.055,0.015,0.085 c0.006,0.03,0.01,0.064,0.014,0.099s0.007,0.069,0.01,0.104c0.135-0.123,0.271-0.216,0.408-0.276 c0.137-0.062,0.268-0.094,0.395-0.094c0.107,0,0.198,0.014,0.273,0.039c0.074,0.026,0.136,0.065,0.182,0.118 s0.08,0.119,0.102,0.2c0.021,0.08,0.031,0.175,0.031,0.282v1.691H46.908z" />
                        <path fill="#1B1C20" d="M49.5,45.877c-0.112-0.049-0.235-0.073-0.372-0.073c-0.081,0-0.154,0.014-0.22,0.043 c-0.067,0.029-0.123,0.074-0.171,0.136c-0.047,0.062-0.084,0.144-0.108,0.243c-0.026,0.1-0.039,0.221-0.039,0.363 c0,0.133,0.011,0.245,0.031,0.334s0.05,0.161,0.09,0.215c0.039,0.054,0.087,0.093,0.144,0.116 c0.059,0.022,0.123,0.034,0.197,0.034c0.045,0,0.088-0.006,0.131-0.017s0.084-0.023,0.123-0.041 c0.037-0.016,0.073-0.035,0.106-0.055c0.034-0.021,0.062-0.042,0.088-0.063V45.877z M49.562,47.725 c-0.004-0.016-0.011-0.035-0.017-0.056c-0.005-0.022-0.01-0.046-0.015-0.071c-0.004-0.025-0.006-0.051-0.008-0.078 c-0.002-0.026-0.004-0.053-0.004-0.078c-0.043,0.042-0.09,0.081-0.138,0.117c-0.05,0.035-0.103,0.066-0.157,0.092 c-0.055,0.026-0.113,0.047-0.175,0.062c-0.062,0.015-0.127,0.021-0.194,0.021c-0.16,0-0.295-0.023-0.403-0.075 c-0.11-0.052-0.199-0.123-0.267-0.22c-0.067-0.095-0.117-0.212-0.147-0.348c-0.03-0.138-0.045-0.292-0.045-0.464 c0-0.201,0.026-0.379,0.077-0.534c0.053-0.155,0.125-0.285,0.22-0.392c0.095-0.105,0.208-0.186,0.34-0.24 c0.134-0.056,0.28-0.081,0.44-0.081c0.086,0,0.164,0.005,0.234,0.016c0.069,0.01,0.135,0.028,0.196,0.056v-1.209l0.587-0.039 v3.479L49.562,47.725z" />
                        <path fill="#1B1C20" d="M52.202,46.537c0-0.122-0.01-0.231-0.027-0.323c-0.018-0.094-0.047-0.169-0.086-0.232 c-0.039-0.062-0.09-0.107-0.151-0.14c-0.062-0.031-0.136-0.046-0.223-0.046c-0.079,0-0.15,0.016-0.214,0.048 c-0.062,0.031-0.116,0.079-0.16,0.143c-0.045,0.063-0.077,0.144-0.102,0.237c-0.022,0.095-0.035,0.204-0.035,0.327 c0,0.144,0.011,0.266,0.031,0.364c0.022,0.098,0.053,0.178,0.094,0.238c0.043,0.061,0.095,0.104,0.155,0.129 c0.062,0.025,0.132,0.038,0.212,0.038c0.086,0,0.162-0.016,0.225-0.05c0.064-0.034,0.117-0.083,0.158-0.15 c0.043-0.065,0.072-0.147,0.092-0.244C52.191,46.777,52.202,46.665,52.202,46.537 M52.809,46.507c0,0.18-0.024,0.345-0.074,0.495 c-0.05,0.151-0.122,0.281-0.22,0.391s-0.217,0.195-0.361,0.255c-0.142,0.062-0.308,0.092-0.496,0.092 c-0.355,0-0.621-0.094-0.797-0.281c-0.175-0.188-0.263-0.476-0.263-0.859c0-0.184,0.026-0.351,0.08-0.501 c0.053-0.151,0.129-0.28,0.228-0.388c0.1-0.107,0.221-0.191,0.362-0.25c0.142-0.06,0.302-0.089,0.481-0.089 c0.178,0,0.334,0.022,0.466,0.066c0.133,0.045,0.243,0.114,0.331,0.207c0.088,0.092,0.154,0.211,0.197,0.354 C52.786,46.142,52.809,46.311,52.809,46.507" />
                        <path fill="#1B1C20" d="M54.108,46.854c0.018,0.048,0.029,0.092,0.035,0.131s0.008,0.074,0.008,0.105 c0-0.031,0.004-0.068,0.012-0.108c0.007-0.042,0.018-0.084,0.033-0.128l0.529-1.425h0.539l-0.896,2.272h-0.524l-0.839-2.272 h0.612L54.108,46.854z" />
                        <path fill="#1B1C20" d="M56.381,46.788c-0.099,0.016-0.177,0.033-0.234,0.057c-0.057,0.021-0.096,0.049-0.118,0.08 c-0.021,0.032-0.033,0.069-0.033,0.113c0,0.046,0.006,0.087,0.02,0.124c0.014,0.035,0.032,0.067,0.059,0.094 c0.024,0.024,0.055,0.046,0.092,0.059c0.035,0.016,0.078,0.022,0.125,0.022s0.094-0.007,0.139-0.02s0.088-0.029,0.129-0.051 c0.041-0.021,0.079-0.044,0.112-0.072c0.034-0.025,0.063-0.053,0.088-0.081v-0.364C56.604,46.759,56.479,46.772,56.381,46.788 M57.345,47.725c-0.083,0-0.156-0.006-0.221-0.019c-0.062-0.014-0.117-0.03-0.163-0.056c-0.046-0.022-0.083-0.053-0.112-0.088 s-0.049-0.076-0.062-0.121c-0.097,0.105-0.203,0.183-0.319,0.229c-0.118,0.047-0.248,0.069-0.394,0.069 c-0.086,0-0.168-0.015-0.246-0.044c-0.076-0.029-0.144-0.073-0.201-0.131c-0.059-0.058-0.104-0.129-0.139-0.213 c-0.033-0.085-0.05-0.181-0.05-0.291c0-0.055,0.007-0.104,0.019-0.154c0.013-0.049,0.037-0.094,0.072-0.136 c0.035-0.043,0.086-0.083,0.147-0.119c0.062-0.034,0.142-0.067,0.239-0.097c0.099-0.028,0.218-0.053,0.355-0.075 c0.139-0.021,0.303-0.04,0.492-0.054c0.002-0.059,0.004-0.115,0.004-0.167c0-0.053,0-0.108,0-0.166 c0-0.052-0.004-0.096-0.014-0.131c-0.01-0.036-0.024-0.065-0.045-0.086c-0.02-0.022-0.045-0.038-0.075-0.048 c-0.031-0.011-0.068-0.015-0.111-0.015c-0.062,0-0.125,0.005-0.19,0.018s-0.131,0.029-0.195,0.053s-0.129,0.051-0.191,0.084 c-0.062,0.032-0.123,0.07-0.18,0.111l-0.23-0.356c0.08-0.059,0.162-0.111,0.246-0.157c0.084-0.044,0.172-0.082,0.264-0.111 c0.091-0.031,0.188-0.055,0.289-0.07c0.103-0.016,0.212-0.024,0.328-0.024c0.135,0,0.246,0.019,0.334,0.053 c0.09,0.033,0.16,0.084,0.213,0.147c0.052,0.064,0.088,0.145,0.109,0.238c0.021,0.093,0.031,0.199,0.031,0.319v0.943 c0,0.042,0.003,0.076,0.01,0.103c0.004,0.025,0.018,0.047,0.039,0.062c0.022,0.016,0.055,0.029,0.098,0.039 c0.045,0.009,0.105,0.019,0.183,0.029l-0.097,0.428c-0.045,0.002-0.086,0.003-0.125,0.003 C57.417,47.725,57.379,47.725,57.345,47.725" />
                        <polygon fill="#1B1C20" points="59.017,47.702 59.017,44.379 61.101,44.379 61.062,44.841 59.618,44.841 59.618,45.766 60.908,45.766 60.908,46.229 59.618,46.229 59.618,47.239 61.067,47.239 61.11,47.702" />
                        <path fill="#1B1C20" d="M64.167,46.941c-0.055,0.166-0.139,0.307-0.253,0.424c-0.112,0.118-0.254,0.209-0.425,0.274 c-0.172,0.064-0.371,0.096-0.602,0.096s-0.427-0.026-0.589-0.079c-0.161-0.054-0.295-0.134-0.397-0.242 c-0.103-0.107-0.178-0.244-0.225-0.407s-0.07-0.355-0.07-0.575v-2.053h0.606v2.053c0,0.142,0.013,0.264,0.038,0.367 c0.025,0.103,0.064,0.188,0.122,0.255c0.056,0.066,0.13,0.115,0.222,0.147c0.09,0.032,0.2,0.049,0.331,0.049 c0.14,0,0.257-0.019,0.353-0.055c0.095-0.036,0.172-0.09,0.23-0.161s0.1-0.159,0.125-0.264c0.026-0.106,0.038-0.229,0.038-0.369 v-2.022h0.579v2.014C64.251,46.593,64.224,46.775,64.167,46.941" />
                        <path fill="#1B1C20" d="M66.923,44.889c0,0.11-0.019,0.209-0.056,0.297c-0.038,0.088-0.095,0.169-0.17,0.242 s-0.169,0.139-0.281,0.199s-0.244,0.118-0.394,0.172l-0.172-0.352c0.069-0.032,0.129-0.062,0.182-0.09 c0.052-0.026,0.096-0.053,0.133-0.078c0.038-0.023,0.068-0.049,0.092-0.071c0.024-0.024,0.043-0.048,0.057-0.071 c0.014-0.025,0.023-0.049,0.029-0.075s0.008-0.055,0.008-0.086c0-0.028-0.004-0.069-0.01-0.12 c-0.008-0.05-0.018-0.104-0.029-0.164s-0.026-0.12-0.043-0.181c-0.015-0.062-0.031-0.119-0.049-0.171l0.487-0.087 c0.033,0.036,0.063,0.079,0.09,0.129c0.026,0.051,0.05,0.104,0.067,0.16c0.02,0.057,0.033,0.114,0.044,0.174 C66.918,44.776,66.923,44.833,66.923,44.889 M65.759,44.889c0,0.11-0.019,0.209-0.057,0.297 c-0.037,0.088-0.094,0.169-0.168,0.242c-0.075,0.073-0.168,0.139-0.28,0.199c-0.11,0.061-0.241,0.118-0.392,0.172l-0.174-0.352 c0.07-0.032,0.132-0.062,0.183-0.09c0.054-0.026,0.097-0.053,0.134-0.078c0.037-0.023,0.068-0.049,0.092-0.071 c0.024-0.024,0.043-0.048,0.057-0.071c0.014-0.025,0.023-0.049,0.029-0.075c0.004-0.026,0.008-0.055,0.008-0.086 c0-0.028-0.004-0.069-0.012-0.12c-0.008-0.05-0.018-0.104-0.031-0.164c-0.012-0.06-0.025-0.12-0.041-0.181 c-0.016-0.062-0.031-0.119-0.047-0.171l0.487-0.087c0.031,0.036,0.062,0.079,0.087,0.129c0.026,0.051,0.049,0.104,0.067,0.16 s0.032,0.114,0.042,0.174S65.759,44.833,65.759,44.889" />
                    </g>
                </g>
            </switch>
        </svg>
    );
}

export default SVG;

SVG.propTypes = {
    scale: PropTypes.number,
};

SVG.defaultProps = {
    scale: 2,
};
