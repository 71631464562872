const defaultState = {
    hideBackgrounds: false,
    increaseFontSize: false,
    cookiesAccepted: false,
};

export default (state = defaultState, action) => {
    switch (action.type) {
    case "HIDE_BACKGROUNDS":
        return { ...state, hideBackgrounds: action.payload };
    case "INCREASE_FONT_SIZE":
        return { ...state, increaseFontSize: action.payload };
    case "ACCEPT_COOKIES":
        return { ...state, cookiesAccepted: true };
    default:
        return state;
    }
};
