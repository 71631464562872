import React from "react";

function SVG() {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="11.546px" height="12.305px" viewBox="0 0 11.546 12.305" enableBackground="new 0 0 11.546 12.305">
            <polygon fill="#1B1C20" points="5.773,12.305 11.546,6.533 11.04,6.004 6.14,10.949 6.14,0 5.427,0 5.427,10.949 0.505,6.027 0,6.533 " />
        </svg>
    );
}

export default SVG;
