import { connect } from "react-redux";
import VotesNumber from "../../components/website/common/VotesNumber";

const mapStateToProps = (state) => ({
    votesData: state.exhibitionSubjectVotes,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(VotesNumber);
