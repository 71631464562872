import { connect } from "react-redux";
import EntryVoteButton from "../../components/website/common/EntryVoteButton";

const mapStateToProps = (state) => ({
    votingData: state.voting.exhibitionEntries,
    extraSettings: state.extraSettings,
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(EntryVoteButton);
