/* eslint max-len: 0 */
import React from "react";

function SVG() {
    const scale = 1.3;
    const width = 947.804 * scale;
    const height = 947.854 * scale;
    const widthString = `${width}px`;
    const heightString = `${height}px`;

    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width={widthString}
            height={heightString}
            viewBox="680 50.036 947.804 947.854"
            enableBackground="new 680 50.036 947.804 947.854"
        >
            <g>
                <defs>
                    <rect id="SVGID_3_" x="680" y="50.036" width="947.804" height="947.854" />
                </defs>
                <path fill="#FFDE14" d="M1209.573,595.062l241.948-241.949l-11.2,104.683l179.196,3.602l8.288-411.36 l-411.337,8.335l3.652,179.201l104.675-11.216l-241.962,241.964c-42.791,42.791-112.412,42.791-155.201,0.002L806.739,347.43 L680.001,474.168l120.893,120.894c2.961,2.961,5.997,5.801,9.054,8.605l264.277,264.277c2.805,3.057,5.645,6.092,8.604,9.053 l120.893,120.893l126.738-126.738l-120.892-120.893C1166.781,707.472,1166.781,637.853,1209.573,595.062" />
            </g>
        </svg>
    );
}

export default SVG;
