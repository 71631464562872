const EXTRA_SETTINGS_HIDE_BACKGROUNDS = "extra_hide_backgrounds";
const EXTRA_SETTINGS_INCREASED_FONTSIZE = "extra_increase_fontsize";
const EXTRA_SETTINGS_COOKIES = "extra_accept_cookies";

export function storeHideBackgrounds() {
    localStorage.setItem(EXTRA_SETTINGS_HIDE_BACKGROUNDS, "yes");
}

export function storeShowBackgrounds() {
    localStorage.setItem(EXTRA_SETTINGS_HIDE_BACKGROUNDS, "no");
}

export function getExtraSettingsBackgroundsData() {
    return localStorage.getItem(EXTRA_SETTINGS_HIDE_BACKGROUNDS) || "no";
}

export function storeIncreaseFontSize() {
    localStorage.setItem(EXTRA_SETTINGS_INCREASED_FONTSIZE, "yes");
}

export function storeResetFontSize() {
    localStorage.setItem(EXTRA_SETTINGS_INCREASED_FONTSIZE, "no");
}

export function getExtraSettingsFontsizeData() {
    return localStorage.getItem(EXTRA_SETTINGS_INCREASED_FONTSIZE) || "no";
}

export function acceptCookiesUsage() {
    localStorage.setItem(EXTRA_SETTINGS_COOKIES, "yes");
}

export function getExtraSettingsCookies() {
    return localStorage.getItem(EXTRA_SETTINGS_COOKIES) || "no";
}
