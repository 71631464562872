import React from "react";
import PropTypes from "prop-types";
import Navbar from "react-bootstrap/Navbar";
import EUlenta from "../../../media/svgs/common/eu-lenta";
import ExtraSettingTooltip from "./ExtraSettingTooltip";
import SVGmuseumLogo from "../../../media/svgs/common/museum-logo";
import SVGnormalFontSize from "../../../media/svgs/common/normal-font-size";
import SVGincreasedFontSize from "../../../media/svgs/common/increased-font-size";
import SVGbiggerContrast from "../../../media/svgs/common/bigger-contrast";
import SVGlessContrast from "../../../media/svgs/common/less-contrast";

function TopBar({
    hideBackgrounds, showBackgrounds, increaseFontSize, resetFontSize, navWrapperClassName,
}) {
    return (
        <div className={navWrapperClassName}>
            <Navbar bg="transparent" expand="lg">
                <div className="custom-topbar-wrapper">
                    <div className="custom-topbar-extra-settings">
                        <div className="custom-group">
                            <ExtraSettingTooltip
                                id="reset-font-size"
                                text="Promijeni veličinu teksta"
                                onClickCallback={resetFontSize}
                            >
                                <SVGnormalFontSize />
                            </ExtraSettingTooltip>
                            <ExtraSettingTooltip
                                id="increase-font-size"
                                text="Promijeni veličinu teksta"
                                onClickCallback={increaseFontSize}
                            >
                                <SVGincreasedFontSize />
                            </ExtraSettingTooltip>
                        </div>
                        <div className="custom-group">
                            <ExtraSettingTooltip
                                id="hide-backgrounds"
                                text="Promijeni boju podloge"
                                onClickCallback={hideBackgrounds}
                            >
                                <SVGbiggerContrast />
                            </ExtraSettingTooltip>
                            <ExtraSettingTooltip
                                id="show-backgrounds"
                                text="Promijeni boju podloge"
                                onClickCallback={showBackgrounds}
                            >
                                <SVGlessContrast />
                            </ExtraSettingTooltip>
                        </div>
                    </div>
                    <div className="custom-museum-logo-wrapper">
                        <SVGmuseumLogo />
                    </div>
                    <div className="custom-eu-badges-wrapper">
                        <EUlenta />
                    </div>
                </div>
            </Navbar>
        </div>
    );
}

export default TopBar;

TopBar.propTypes = {
    navWrapperClassName: PropTypes.string.isRequired,
    hideBackgrounds: PropTypes.func.isRequired,
    showBackgrounds: PropTypes.func.isRequired,
    increaseFontSize: PropTypes.func.isRequired,
    resetFontSize: PropTypes.func.isRequired,
};
