import React from "react";

function CookiesPopup({ getCookiesAcceptanceData, acceptCookies, extraSettings }) {
    React.useEffect(() => {
        getCookiesAcceptanceData();
    }, [getCookiesAcceptanceData]);

    return (
        !extraSettings.cookiesAccepted && (
            <div className="custom-cookies-popup">
                <p>
                    Muzejbuducnosti.eu koristi kolačiće za pružanje boljeg korisničkog iskustva i funkcionalnosti. Cookie postavke mogu se kontrolirati i konfigurirati u Vašem web pregledniku. Nastavkom pregleda web stranica muzejbuducnosti.eu slažete se s korištenjem kolačića.
                </p>
                <button
                    type="button"
                    href="#"
                    onClick={() => { acceptCookies(); }}
                    className="no-style-button no-style-button-white"
                >
                    Zatvori
                </button>
            </div>
        )
    );
}

export default CookiesPopup;
