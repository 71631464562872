import { getToken } from "../services/auth";
import API_ROOT from "../services/api-config";

export const BASIC_HEADERS = {
    Accept: "application/json",
    "Content-Type": "application/json",
};

export const AUTH_HEADERS = {
    ...BASIC_HEADERS,
    Authorization: `Bearer ${getToken()}`,
};

export const ENDPOINTS = {
    exhibtionEntries: {
        all: `${API_ROOT}/ExhibitionEntrys/`,
        one: `${API_ROOT}/ExhibitionEntry/`,
    },
    login: `${API_ROOT}/Authenticate/login/`,
    surveys: {
        one: `${API_ROOT}/Survey/`,
        all: `${API_ROOT}/Surveys/`,
    },
    map: `${API_ROOT}/Map/`,
    exhibitionSubjects: {
        all: `${API_ROOT}/ExhibitionSubjects/`,
        one: `${API_ROOT}/ExhibitionSubject/`,
    },
    pages: `${API_ROOT}/Page/`,
};

export const GA_ID = "UA-149413301-1";

export const PUBLIC_DOMAIN = "https://muzejbuducnosti.eu/";
export const PUBLIC_DOMAIN_INSECURE = "http://muzejbuducnosti.eu/";

export const EXHIBITION_ENTRY_STATUS = {
    NEW: 1,
    APPROVED: 2,
    REJECTED: 3,
};

export const EXHIBITION_SUBJECT_STATUS = {
    NEW: 1,
    APPROVED: 2,
    REJECTED: 3,
};

export const ANSWER_TYPES = {
    NONE: 0,
    PREDEFINED: 1,
    CUSTOM: 2,
};

export const SURVEY_STATUS = {
    NONE: 0,
    ACTIVE: 1,
    INACTIVE: 2,
};
