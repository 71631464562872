import { connect } from "react-redux";
import ExhibitionSubjectsList from "../../components/website/exhibition-subjects/ExhibitionSubjectsList";
import { exhibitionSubject, getExhibitionSubjectsVotingData } from "../../services/requests";

const mapStateToProps = (state) => ({
    currentSubjectPage: state.exhibitionSubjects.pages.current,
    // archive: state.exhibitionSubjects.pages.archived,
});

const mapDispatchToProps = (dispatch) => ({
    getVotingData: (listOfIds) => dispatch(getExhibitionSubjectsVotingData(listOfIds)),
    getExhibitionSubjects: () => dispatch(exhibitionSubject.getAllApproved()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExhibitionSubjectsList);
