import React from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";

function ButtonAdd({ onClickCallback }) {
    return (
        <Col sm="1" className="text-left">
            <Button
                variant="primary"
                onClick={onClickCallback}
            >
                +
            </Button>
        </Col>
    );
}

export default ButtonAdd;

ButtonAdd.propTypes = {
    onClickCallback: PropTypes.func,
};

ButtonAdd.defaultProps = {
    onClickCallback: () => {},
};
