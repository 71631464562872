/* eslint max-len: 0 */
import React from "react";
import PropTypes from "prop-types";
import MapGL, {
    Marker, Popup, NavigationControl, GeolocateControl,
} from "react-map-gl";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CityPin from "./CityPin";
import CityInfo from "./CityInfo";
import InfoAccordion from "./InfoAccordion";
import NewMapPinPopup from "./NewMapPinPopup";
import BgGreenArrowBackground from "../../../media/svgs/map/bg-green-arrow";
import SVGHeading from "../../../media/svgs/map/share-experience-heading";
import { ExtraSettingsPropType, MapPinListPropType } from "../../../customPropTypes";
import ShareExperienceButton from "./ShareExperienceButton";

const TOKEN = "pk.eyJ1IjoiYW5kcmVhc3ZhZ2xpYyIsImEiOiJjazF3NHBxc3kwMTB5M2hwaXJia2Rzd21nIn0.eH2gEkuXqqsNlhdOD62b-Q"; // Set your mapbox token here
const navStyle = {
    position: "absolute",
    bottom: 36,
    right: 0,
};
const initialViewport = {
    width: "110%",
    height: 600,
    latitude: 45.367588,
    longitude: 14.614826,
    zoom: 8,
};
const geolocateStyle = {
    position: "absolute",
    borderRadius: "50%",
    boxShadow: "none",
    bottom: 120,
    right: 0,
};

function InteractiveMap({
    customPins, extraSettings, onUpload, onDeleteFile, onSubmitNew, getAllMapPins,
}) {
    const [viewport, setViewport] = React.useState(initialViewport);
    const [popupInfo, setPopupInfo] = React.useState(null);
    const [pinIdActive, setPinIdActive] = React.useState(null);

    const isPinActive = (id) => pinIdActive === id;

    const [showAddModal, setShowAddModal] = React.useState(false);

    const handleClose = () => setShowAddModal(false);
    const handleShow = () => setShowAddModal(true);

    const updateViewport = (newViewport) => {
        const updatedViewport = { ...viewport, ...newViewport, width: "100%" };
        setViewport(updatedViewport);
    };
    const renderCityMarker = (pin, index) => {
        return (
            <Marker key={`marker-${index}`} longitude={pin.longitude} latitude={pin.latitude}>
                <CityPin
                    onClick={() => {
                        setPopupInfo(pin);
                        setPinIdActive(index);
                    }}
                    pinSvgIndex={pin.pinSvgIndex}
                    isPinActive={isPinActive(index)}
                />
            </Marker>
        );
    };
    const renderPopup = () => {
        return (
            popupInfo && (
                <div className="test-custom-map-popup">
                    <Popup
                        tipSize={5}
                        anchor="top"
                        longitude={popupInfo.longitude}
                        latitude={popupInfo.latitude}
                        closeOnClick={false}
                        onClose={() => {
                            setPopupInfo(null);
                            setPinIdActive(null);
                        }}
                    >
                        <CityInfo info={popupInfo.pinInfos} />
                    </Popup>

                </div>
            )
        );
    };

    React.useEffect(() => {
        getAllMapPins();
    }, [getAllMapPins]);

    React.useEffect(() => {
        setPopupInfo(null);
        setPinIdActive(null);
    }, [customPins]);

    return (
        <div style={{ marginBottom: "-200px" }}>
            {!extraSettings.hideBackgrounds && (
                <div className="custom-mappage-arrow-wrapper">
                    <BgGreenArrowBackground />
                </div>
            )}
            <Row>
                <SVGHeading />
            </Row>
            <Row className="custom-map-intro-text">
                <Col sm="12" lg="5">
                    <p style={{ marginTop: "50px" }}>Sigurno ste barem jednom doživjeli trenutak u kojem vam je neki prizor iz neposrednog okruženja zadržao pažnju i nakratko zaustavio misli. Krajičkom oka uhvaćeni detalj pročelja zgrade, barke na vezu, klupice na neočekivanom mjestu, uspio vas je nadahnuti, podsjetiti na nešto, ispuniti osjećajem ugode. Iako ste se ubrzo opet našli u vrtlogu misli, ostala je slatka spoznaja da ste na trenutak doživjeli nešto vrijedno.</p>
                    <p style={{ marginBottom: "0" }}>Ova je karta posvećena vašim osobnim prostornim “otkrićima” - vašim magičnim mjestima s prostora Primorsko-goranske županije.</p>
                </Col>
                <Col sm="12" lg={{ span: 5, offset: 1 }}>
                    <InfoAccordion />
                </Col>
            </Row>
            <Row>
                <ShareExperienceButton onClickCallback={handleShow} />
            </Row>
            <Row>
                <div style={{ position: "relative", width: "100%" }}>
                    <MapGL
                        {...viewport}
                        mapStyle="mapbox://styles/mapbox/light-v10"
                        onViewportChange={updateViewport}
                        mapboxApiAccessToken={TOKEN}
                    >
                        {customPins.map(renderCityMarker)}
                        <div className="nav" style={navStyle}>
                            <NavigationControl showCompass={false} />
                        </div>
                        <GeolocateControl
                            positionOptions={{ enableHighAccuracy: true }}
                            trackUserLocation
                            style={geolocateStyle}
                        />
                        {renderPopup()}
                    </MapGL>
                </div>
            </Row>
            <NewMapPinPopup
                show={showAddModal}
                onHideCallback={handleClose}
                onSubmit={onSubmitNew}
                onUpload={onUpload}
                onDeleteFile={onDeleteFile}
            />
        </div>
    );
}

export default InteractiveMap;

InteractiveMap.propTypes = {
    extraSettings: ExtraSettingsPropType.isRequired,
    onUpload: PropTypes.func.isRequired,
    onDeleteFile: PropTypes.func.isRequired,
    getAllMapPins: PropTypes.func.isRequired,
    customPins: MapPinListPropType.isRequired,
    onSubmitNew: PropTypes.func.isRequired,
};
