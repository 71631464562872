export function checkImageURL(url) {
    const urlLowerCase = url.toLowerCase();
    return (urlLowerCase.match(/\.(jpeg|jpg|gif|png)$/) != null);
}

export function checkVideoURL(url) {
    const urlLowerCase = url.toLowerCase();
    return (urlLowerCase.match(/\.(mp4|m4a|m4v|f4v|f4a|m4b|m4r|f4b|mov|ogg|oga|ogv|ogx|wmv|wma|3gp|3gp2|3g2|3gpp|3gpp2)$/) != null);
}

export function checkAudioURL(url) {
    const urlLowerCase = url.toLowerCase();
    return (urlLowerCase.match(/\.(mp3|wav)$/) != null);
}


export function extractFileName(contentUrl) {
    if (contentUrl && contentUrl.includes("/Images/")) {
        return contentUrl.split("/Images/")[1];
    }
    if (contentUrl && contentUrl.includes("/Videos/")) {
        return contentUrl.split("/Videos/")[1];
    }
    if (contentUrl && contentUrl.includes("/Audios/")) {
        return contentUrl.split("/Audios/")[1];
    }

    return null;
}
