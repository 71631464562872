const mapPinsActions = {
    fetchMapPinsSuccess: (pins) => (dispatch) => {
        dispatch({
            type: "FETCH_MAP_PINS",
            payload: pins,
        });
    },
    assignRandomPinArrows: () => (dispatch) => {
        dispatch({
            type: "ASSIGN_RANDOM_PIN_ARROWS",
        });
    },
    deletePinById: (id) => (dispatch) => {
        dispatch({
            type: "DELETE_PIN_BY_ID",
            payload: id,
        });
    },
    addPin: (pin) => (dispatch) => {
        // debugger;
        // dispatch({
        //     type: "ADD_NEW_PIN",
        //     payload: pin,
        // });
    },
};

export default mapPinsActions;
