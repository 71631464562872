import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router";
import {
    ExhibitionEntryPropType, HistoryPropType, MatchPropType, ExtraSettingsPropType, MapPinListPropType,
    ExhibitionSubjectPropType,
} from "../../customPropTypes";
import TopBarContainer from "../../containers/website/TopBarContainer";
import SideBar from "./common/SideBar";
import LogoLink from "./common/LogoLink";
import HomepageContainer from "../../containers/website/HomepageContainer";
import ExhibitionEntriesListContainer from "../../containers/website/ExhibitionEntriesListContainer";
import ExhibitionSubjectsListContainer from "../../containers/website/ExhibitionSubjectsListContainer";
import { exhibitionEntry, map } from "../../services/requests";
import CookiesPopupContainer from "../../containers/website/CookiesPopupContainer";
import InteractiveMap from "./map/InteractiveMap";
import ExhibitionEntriesArchiveContainer from "../../containers/website/ExhibitionEntriesArchiveContainer";
import ExhibitionSubjectsArchiveContainer from "../../containers/website/ExhibitionSubjectsArchiveContainer";

function App({
    pathname,
    approvedEntries, voteEntry, addNewEntry, getExhibitionEntryPages,
    sortEntries, unvoteEntry,
    extraSettings, getExtraSettingsBackgroundsData, getFontSizeData,
    addNewMapPin, customPins, getAllMapPins,
    voteSubject, unvoteSubject, addNewSubject, approvedSubjects, sortSubjects,
    getExhibitionSubjectPages,
}) {
    React.useEffect(() => {
        getExtraSettingsBackgroundsData();
    }, [getExtraSettingsBackgroundsData]);

    React.useEffect(() => {
        getFontSizeData();
    }, [getFontSizeData]);

    React.useEffect(() => {
        getExhibitionEntryPages();
    }, [getExhibitionEntryPages]);

    React.useEffect(() => {
        getExhibitionSubjectPages();
    }, [getExhibitionSubjectPages]);

    const increaseFontSize = extraSettings && extraSettings.increaseFontSize;
    let pageWrapperClassName = increaseFontSize ?
        "custom-page-wrapper increased-font-size" : "custom-page-wrapper";
    let navWrapperClassName = "custom-nav-wrapper";
    const appWrapperClassName = increaseFontSize ? "increased-font-size" : "";

    if (pathname.includes("arhiva")) {
        pageWrapperClassName = pageWrapperClassName.concat(" archive");
        navWrapperClassName = navWrapperClassName.concat(" archive");
    }

    return (
        <div className={appWrapperClassName}>
            <TopBarContainer navWrapperClassName={navWrapperClassName} />
            <SideBar />
            <LogoLink />
            <div className={pageWrapperClassName}>
                <Route exact path="/" render={() => <Redirect to="/o-projektu" />} />
                <Route
                    path="/o-projektu"
                    render={() => <HomepageContainer extraSettings={extraSettings} />}
                />
                <Route
                    path="/prijedlozi"
                    render={(props) => (
                        <ExhibitionEntriesListContainer
                            history={props.history}
                            match={props.match}
                            approvedEntries={approvedEntries}
                            onVoteEntry={voteEntry}
                            onUnvoteEntry={unvoteEntry}
                            onSubmitNew={addNewEntry}
                            onUpload={exhibitionEntry.upload}
                            onDeleteFile={exhibitionEntry.deleteContent}
                            sortEntries={sortEntries}
                            extraSettings={extraSettings}
                        />
                    )}
                />
                <Route
                    path="/programi"
                    render={(props) => (
                        <ExhibitionSubjectsListContainer
                            history={props.history}
                            match={props.match}
                            approvedSubjects={approvedSubjects}
                            onVoteSubject={voteSubject}
                            onUnvoteSubject={unvoteSubject}
                            onSubmitNew={addNewSubject}
                            sortSubjects={sortSubjects}
                            extraSettings={extraSettings}
                        />
                    )}
                />
                <Route
                    path="/dozivljaji"
                    render={() => (
                        <InteractiveMap
                            customPins={customPins}
                            extraSettings={extraSettings}
                            onUpload={map.upload}
                            onDeleteFile={map.deleteContent}
                            onSubmitNew={addNewMapPin}
                            sortEntries={sortEntries}
                            getAllMapPins={getAllMapPins}
                        />
                    )}
                />
                <Route exact path="/arhiva" render={() => <Redirect to="/o-projektu" />} />
                <Route exact path="/arhiva/predmeti" render={() => <Redirect to="/o-projektu" />} />
                <Route
                    exact
                    path="/arhiva/predmeti/:alias"
                    render={
                        (props) => (
                            <ExhibitionEntriesArchiveContainer
                                history={props.history}
                                match={props.match}
                                approvedEntries={approvedEntries}
                            />
                        )}
                />
                <Route
                    exact
                    path="/arhiva/programi/:alias"
                    render={
                        (props) => (
                            <ExhibitionSubjectsArchiveContainer
                                history={props.history}
                                match={props.match}
                                approvedSubjects={approvedSubjects}
                            />
                        )}
                />
            </div>
            <CookiesPopupContainer />
        </div>
    );
}

export default App;

App.propTypes = {
    approvedEntries: PropTypes.arrayOf(ExhibitionEntryPropType),
    history: HistoryPropType.isRequired,
    match: MatchPropType.isRequired,
    voteEntry: PropTypes.func.isRequired,
    addNewEntry: PropTypes.func.isRequired,
    sortEntries: PropTypes.func.isRequired,
    unvoteEntry: PropTypes.func.isRequired,
    addNewMapPin: PropTypes.func.isRequired,
    voteSubject: PropTypes.func.isRequired,
    unvoteSubject: PropTypes.func.isRequired,
    addNewSubject: PropTypes.func.isRequired,
    getAllMapPins: PropTypes.func.isRequired,
    pathname: PropTypes.string.isRequired,
    getExhibitionEntryPages: PropTypes.func.isRequired,
    extraSettings: ExtraSettingsPropType.isRequired,
    getExtraSettingsBackgroundsData: PropTypes.func.isRequired,
    getFontSizeData: PropTypes.func.isRequired,
    customPins: MapPinListPropType.isRequired,
    approvedSubjects: PropTypes.arrayOf(ExhibitionSubjectPropType).isRequired,
    sortSubjects: PropTypes.func.isRequired,
    getExhibitionSubjectPages: PropTypes.func.isRequired,
};

App.defaultProps = {
    approvedEntries: [],
};
