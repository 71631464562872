import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Logo from "../../../media/svgs/common/logo";

function LogoLink({ customClassName }) {
    const cName = `project-logo ${customClassName}`;

    return (
        <div className={cName}>
            <Link to="/"><Logo /></Link>
        </div>
    );
}

export default LogoLink;

LogoLink.propTypes = {
    customClassName: PropTypes.string,
};

LogoLink.defaultProps = {
    customClassName: "custom-logo-wrapper",
};
