import React from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function RejectButton({
    onRejectCallback, modalTitle, modalText, buttonText, executeButtonLabel,
}) {
    const [showModal, setShowModal] = React.useState(false);

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    return (
        <div>
            <Button
                variant="danger"
                id="exhibition-entry-button-reject"
                onClick={handleShow}
            >
                {buttonText}
            </Button>
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modalText}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Odustani
                    </Button>
                    <Button variant="primary" onClick={onRejectCallback}>
                        {executeButtonLabel}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default RejectButton;

RejectButton.propTypes = {
    onRejectCallback: PropTypes.func.isRequired,
    modalTitle: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    modalText: PropTypes.string.isRequired,
    executeButtonLabel: PropTypes.string.isRequired,
};
