import { connect } from "react-redux";
import { exhibitionSubject, pages } from "../../../../services/requests";
import PlannedSubjectsPage from "../../../../components/admin/pages/subjects/Planned";

const mapStateToProps = (state) => ({
    pages: state.exhibitionSubjects.pages.planned,
});

const mapDispatchToProps = (dispatch) => ({
    getExhibitionSubjectPages: () => dispatch(exhibitionSubject.getPages()),
    onPageDeleteClicked: (pageId) => dispatch(pages.delete(pageId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlannedSubjectsPage);
