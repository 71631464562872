import React from "react";
import PropTypes from "prop-types";
import { HistoryPropType, PagePropType } from "../../../../customPropTypes";
import PageList from "../common/PageList";

function ArchivedEntriesPages({ history, pages, getExhibitionEntryPages }) {
    React.useEffect(() => {
        getExhibitionEntryPages();
    }, [getExhibitionEntryPages]);

    return (
        <PageList
            history={history}
            pages={pages}
            title="Pregled arhiviranih natječaja za prikupljanje predmeta"
            seeUserEntries
            baseLink="/admin/natjecaji/predmeti"
        />
    );
}

export default ArchivedEntriesPages;

ArchivedEntriesPages.propTypes = {
    history: HistoryPropType.isRequired,
    pages: PropTypes.arrayOf(PagePropType).isRequired,
    getExhibitionEntryPages: PropTypes.func.isRequired,
};
