import { withFormik } from "formik";
import validationSchemas from "../../../../helpers/validation";
import CreateEditFormInner from "../common/CreateEditFormInner";

const handleSubmit = (values, { props, setStatus }) => {
    props.onSubmit(values, setStatus);
};

export default withFormik({
    mapPropsToValues: () => ({
        type: 2,
        alias: "",
        activeFrom: new Date(),
        activeTo: new Date(),
        content: [{
            title: "Main page text",
            text: "",
            tag: "",
        }],
    }),
    validationSchema: validationSchemas.addNewExhibitionEntriesPage,
    handleSubmit,
})(CreateEditFormInner);
