import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import { HistoryPropType, PagesPropType } from "../../../../customPropTypes";
import AdminSidebar from "../../AdminSidebar";
import CroatianDateFormat from "../../../common/CroatianDateFormat";
import GoBackButton from "../../common/GoBackButton";

export const POSSIBLE_ACTIVE_PAGES = {
    ENTRIES: "ENTRIES",
    SUBJECTS: "SUBJECTS",
};

const copy = {
    ENTRIES: {
        heading: "Aktualni natječaj za predmete:",
        editLink: "/admin/natjecaji/predmeti/edit/",
        seeItemsLink: "/admin/predmeti",
        plannedPages: {
            heading: "Pregledaj ili editiraj buduće natječaje za odabir muzejskih predmeta",
            link: "/admin/natjecaji/predmeti/buduci",
        },
        archivedPages: {
            heading: "Pregledaj arhivirane natječaje za odabir muzejskih predmeta",
            link: "/admin/natjecaji/predmeti/arhivirani",
        },
    },
    SUBJECTS: {
        heading: "Aktualni natječaj za programe:",
        editLink: "/admin/natjecaji/programi/edit/",
        seeItemsLink: "/admin/programi",
        plannedPages: {
            heading: "Pregledaj ili editiraj buduće natječaje za odabir muzejskih programa",
            link: "/admin/natjecaji/programi/buduci/",
        },
        archivedPages: {
            heading: "Pregledaj arhivirane natječaje za odabir muzejskih programa",
            link: "/admin/natjecaji/programi/arhivirani",
        },
    },
};

function PagesOverview({ history, pages, activePage }) {
    const currentPage = pages.current;
    const activePageEntries = activePage === POSSIBLE_ACTIVE_PAGES.ENTRIES;
    const activePageSubjects = activePage === POSSIBLE_ACTIVE_PAGES.SUBJECTS;
    const activePageCopy = copy[activePage];

    return (
        <div>
            <AdminSidebar history={history} />
            <GoBackButton onClickCallback={() => history.push("/admin/dashboard")} />
            <ListGroup>
                <div style={{ display: "flex" }}>
                    <ListGroup.Item
                        action
                        variant={activePageEntries ? "primary" : "default"}
                        onClick={() => {
                            history.push("/admin/natjecaji/predmeti");
                        }}
                        className="custom-list-item-pages"
                    >
                        Natječaji za prikupljanje predmeta
                    </ListGroup.Item>
                    {activePageEntries && (
                        <Link to="/admin/natjecaji/predmeti/dodaj">
                            <button
                                type="button"
                                onClick={() => {}}
                                className="admin-add-new-button no-style-button"
                            >
                                <i className="fas fa-plus-circle" />
                            </button>
                        </Link>
                    )}
                </div>
                <div style={{ display: "flex" }}>
                    <ListGroup.Item
                        action
                        onClick={() => {
                            history.push("/admin/natjecaji/programi");
                        }}
                        variant={activePageSubjects ? "primary" : "default"}
                        className="custom-list-item-pages"
                    >
                        Natječaji za prikupljanje prijedloga programa
                    </ListGroup.Item>
                    {activePageSubjects && (
                        <Link to="/admin/natjecaji/programi/dodaj">
                            <button
                                type="button"
                                onClick={() => {}}
                                className="admin-add-new-button no-style-button"
                            >
                                <i className="fas fa-plus-circle" />
                            </button>
                        </Link>
                    )}
                </div>
            </ListGroup>
            <div className="admin-dashboard-wrapper">
                {currentPage && (
                    <Row className="text-left" style={{ marginBottom: "50px" }}>
                        <Col sm="12" md="6">
                            <h2>{activePageCopy.heading}</h2>
                            <h4>{currentPage.alias}</h4>
                            <p>
                                <span>Od </span>
                                <span><CroatianDateFormat date={currentPage.activeFrom} /></span>
                                <span> do </span>
                                <span><CroatianDateFormat date={currentPage.activeTo} /></span>
                            </p>
                            <Button
                                variant="warning"
                                onClick={() => history.push(`${activePageCopy.editLink}${currentPage.id}`)}
                            >
                                Editiraj tekstove
                            </Button>
                            <Button
                                onClick={() => history.push(activePageCopy.seeItemsLink)}
                            >
                                Pogledaj prijedloge
                            </Button>
                        </Col>
                    </Row>
                )}
                <Row className="align-content-center">
                    <Col sm="12" md="4">
                        <Card>
                            <Card.Body>
                                <h3>Budući natječaji</h3>
                                <Card.Text>{activePageCopy.plannedPages.heading}</Card.Text>
                                <Button
                                    variant="primary"
                                    onClick={() => { history.push(activePageCopy.plannedPages.link); }}
                                >
                                    Pogledaj/Editiraj
                                </Button>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm="12" md="4">
                        <Card bg="secondary" text="white">
                            <Card.Body>
                                <h3>Prošli natječaji</h3>
                                <Card.Text>{activePageCopy.archivedPages.heading}</Card.Text>
                                <Button
                                    variant="primary"
                                    onClick={() => { history.push(activePageCopy.archivedPages.link); }}
                                >
                                    Pogledaj
                                </Button>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default PagesOverview;

PagesOverview.propTypes = {
    history: HistoryPropType.isRequired,
    // pages: PagesPropType.isRequired,
    pages: PropTypes.shape().isRequired,
    activePage: PropTypes.oneOf(Object.keys(POSSIBLE_ACTIVE_PAGES)).isRequired,
};
