/* eslint max-len: 0 */
import React from "react";
import PropTypes from "prop-types";

const ORIGINAL_WIDTH = 12.305;
const ORIGINAL_HEIGHT = 11.547;

function SVG({ scale }) {
    const width = ORIGINAL_WIDTH * scale;
    const height = ORIGINAL_HEIGHT * scale;
    const widthString = `${width}px`;
    const heightString = `${height}px`;

    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width={widthString}
            height={heightString}
            viewBox={`0 0 ${ORIGINAL_WIDTH} ${ORIGINAL_HEIGHT}`}
            enableBackground={`new 0 0 ${ORIGINAL_WIDTH} ${ORIGINAL_HEIGHT}`}
        >
            <polygon fill="#1B1C20" points="12.305,5.773 6.531,0 6.002,0.506 10.947,5.406 0,5.406 0,6.119 10.947,6.119 6.025,11.041 6.531,11.547 " />
        </svg>
    );
}

export default SVG;

SVG.propTypes = {
    scale: PropTypes.number,
};

SVG.defaultProps = {
    scale: 1,
};
