import React from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import uuidv1 from "uuid/v1";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import AdminSidebar from "../../AdminSidebar";
import CroatianDateFormat from "../../../common/CroatianDateFormat";
import { HistoryPropType, PagePropType } from "../../../../customPropTypes";
import GoBackButton from "../../common/GoBackButton";

function PageList({
    history, pages, editPage, deletePage, seeUserEntries, title, onDeleteClicked, baseLink,
}) {
    const [showModal, setShowModal] = React.useState(false);
    const [currentPageId, setCurrentPageId] = React.useState(null);
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    return (
        <div>
            <AdminSidebar history={history} />
            <GoBackButton onClickCallback={() => history.push(baseLink)} />
            {pages.length > 0 ? (
                <div>
                    <h2>{title}</h2>
                    <br />
                    <br />
                    <Table striped bordered>
                        <thead>
                            <tr>
                                <th>Redni broj</th>
                                <th>Početak natječaja</th>
                                <th>Kraj natječaja</th>
                                <th>Interni naziv natječaja</th>
                                <th>Glavni tekst na stranici</th>
                                <th>Akcije</th>
                            </tr>
                        </thead>
                        <tbody>
                            {pages && pages.map((page, index) => (
                                <tr key={uuidv1()}>
                                    <td>{index + 1}</td>
                                    <td><CroatianDateFormat date={page.activeFrom} /></td>
                                    <td><CroatianDateFormat date={page.activeTo} /></td>
                                    <td>{page.alias}</td>
                                    <td>{page.contents.length > 0 && page.contents[0].text}</td>
                                    <td>
                                        { editPage && (
                                            <Button
                                                type="button"
                                                variant="warning"
                                                onClick={() => history.push(`${baseLink}/edit/${page.id}`)}
                                            >
                                                Editiraj
                                            </Button>

                                        )}
                                        { deletePage && (
                                            <Button
                                                type="button"
                                                variant="danger"
                                                onClick={() => {
                                                    setCurrentPageId(page.id);
                                                    handleShow();
                                                }}
                                            >
                                                Pobriši
                                            </Button>
                                        )}
                                        { seeUserEntries && (
                                            <Button
                                                type="button"
                                                variant="info"
                                                onClick={() => history.push(`${baseLink}/arhivirani/${page.id}/prijedlozi`)}
                                            >
                                                Pogledaj prijedloge
                                            </Button>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <Modal show={showModal} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Brisanje natječaja</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Jeste li sigurni da želite pobrisati ovaj natječaj?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Odustani
                            </Button>
                            <Button
                                variant="primary"
                                onClick={() => {
                                    onDeleteClicked(currentPageId);
                                    handleClose();
                                }}
                            >
                                Pobriši
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            ) : (
                <p>Ne postoji niti jedan natječaj.</p>
            )}
        </div>
    );
}

export default PageList;

PageList.propTypes = {
    history: HistoryPropType.isRequired,
    pages: PropTypes.arrayOf(PagePropType).isRequired,
    title: PropTypes.string.isRequired,
    editPage: PropTypes.bool,
    deletePage: PropTypes.bool,
    seeUserEntries: PropTypes.bool,
    onDeleteClicked: PropTypes.func,
    baseLink: PropTypes.string.isRequired,
};

PageList.defaultProps = {
    editPage: false,
    deletePage: false,
    seeUserEntries: false,
    onDeleteClicked: () => {},
};
