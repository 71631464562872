import React from "react";
import PropTypes from "prop-types";
import uuidv1 from "uuid/v1";
import { Route } from "react-router";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
    HistoryPropType, MatchPropType, ExhibitionEntryPropType, ExtraSettingsPropType,
} from "../../../customPropTypes";
import ExhibitionSubjectCard from "./ExhibitionSubjectCard";
import SVGtitle from "../../../media/svgs/exhibition-subjects/page-title";
import SVGArrowBackground1 from "../../../media/svgs/exhibition-subjects/background-arrow-blue";
import SVGArrowBackground2 from "../../../media/svgs/exhibition-subjects/background-arrow-yellow";
import Copy from "../../../content/WebsiteTextContent";
import ExhibitionAddNewButton from "../common/ExhibitionAddNewButton";
import ExhibitionSubjectContainer from "../../../containers/website/ExhibitionSubjectContainer";
import TextAccordion from "../TextAccordion";
import ExhibitionSubjectAddNew from "./ExhibitionSubjectAddNew";
import Filters from "../common/Filters";

function ExhibitionSubjectsList({
    match, getExhibitionSubjects, approvedSubjects, onVoteSubject,
    getVotingData, onSubmitNew, sortSubjects, onUnvoteSubject,
    extraSettings, currentSubjectPage,
}) {
    const accordionData = {
        title: Copy.exhibitionSubjectsPage.accordion.title,
        text: [Copy.exhibitionSubjectsPage.accordion.text],
    };
    const [showAddModal, setShowAddModal] = React.useState(false);
    const [browserContentHeight, setBrowserContentHeight] = React.useState(0);

    React.useEffect(() => {
        getExhibitionSubjects();
    }, [getExhibitionSubjects]);

    React.useEffect(() => {
        const listOfIds = approvedSubjects.map((entry) => entry.id);
        if (listOfIds.length > 0) {
            getVotingData(listOfIds);
        }
    }, [getVotingData, approvedSubjects]);

    React.useEffect(() => {
        const contentHeight = document.getElementsByTagName("body")[0].clientHeight;
        setBrowserContentHeight(contentHeight);
    }, [browserContentHeight, getExhibitionSubjects, getVotingData, approvedSubjects]);

    const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);

    React.useEffect(() => {
        function handleResize() {
            setScreenWidth(window.innerWidth);
        }

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    let leftColumnEntries = [];
    let rightColumnEntries = [];

    if (screenWidth > 991) {
        if (approvedSubjects.length > 0 && approvedSubjects.length === 1) {
            leftColumnEntries = approvedSubjects;
        } else {
            rightColumnEntries = approvedSubjects.reduce((accum, current, currentIndex) => {
                if (currentIndex % 2 !== 0) {
                    return [...accum, current];
                }
                return accum;
            }, []);
            leftColumnEntries = approvedSubjects.reduce((accum, current, currentIndex) => {
                if (currentIndex % 2 === 0) {
                    return [...accum, current];
                }
                return accum;
            }, []);
        }
    } else {
        leftColumnEntries = approvedSubjects;
    }

    const handleClose = () => setShowAddModal(false);
    const handleShow = () => setShowAddModal(true);

    let paragraph;

    if (currentSubjectPage) {
        const content = currentSubjectPage.contents.find((content) => content.title === "Main page text");
        paragraph = content && content.text;
    }

    return (
        <Container>
            {!extraSettings.hideBackgrounds && (
                <div className="custom-subjects-arrow-wrapper-1">
                    <SVGArrowBackground1 />
                </div>
            )}
            {!extraSettings.hideBackgrounds && (
                <div className="custom-subjects-arrow-wrapper-2">
                    <SVGArrowBackground2 />
                </div>
            )}
            <Row>
                <SVGtitle />
            </Row>
            <Row className="custom-exhibition-subjects-intro-text">
                <Col sm="12" md="12" lg="7">
                    <p>{paragraph}</p>
                    <p>{Copy.exhibitionSubjectsPage.intro2}</p>
                    <p className="narrow-text">
                        <span>Osim predlaganja, oznakom </span>
                        <span className="custom-bold">sviđa mi se</span>
                        <span> možete podržati i prijedloge drugih korisnika. Prijedlozi s najviše oznaka </span>
                        <span className="custom-bold">sviđa mi se</span>
                        <span> predstavljat će vrijedne smjernice Građanskom muzejskom vijeću u kreiranju muzejskih sadržaja po vašoj mjeri.</span>
                    </p>
                </Col>
                <Col sm="12" md="12" lg="5">
                    <TextAccordion data={accordionData} wrapperClassName="custom-exhibition-accordion exhibition-subjects" />
                </Col>
            </Row>
            <Row>
                <ExhibitionAddNewButton onClickCallback={handleShow} customClassName="suggest-subject-button" />
            </Row>
            <Filters sortFn={sortSubjects} />
            <Row className="justify-content-between">
                <Col className="custom-exhibition-list-column" lg="5">
                    {leftColumnEntries.map((entry) => (
                        <div key={uuidv1()} className="custom-exhibition-column-wrapper">
                            <ExhibitionSubjectCard
                                title={entry.title}
                                description={entry.description}
                                onVoteSubject={onVoteSubject}
                                onUnvoteSubject={onUnvoteSubject}
                                id={entry.id}
                            />
                        </div>
                    ))}
                </Col>
                {rightColumnEntries.length > 0 && (
                    <Col className="custom-exhibition-list-column" lg="5">
                        {rightColumnEntries.map((entry) => (
                            <div key={uuidv1()} className="custom-exhibition-column-wrapper">
                                <ExhibitionSubjectCard
                                    title={entry.title}
                                    description={entry.description}
                                    onVoteSubject={onVoteSubject}
                                    onUnvoteSubject={onUnvoteSubject}
                                    id={entry.id}
                                />
                            </div>
                        ))}
                    </Col>
                )}
            </Row>
            <ExhibitionSubjectAddNew
                show={showAddModal}
                onHideCallback={handleClose}
                onSubmit={onSubmitNew}
            />
            <Route
                exact
                path={`${match.path}/program:id`}
                render={(props) => <ExhibitionSubjectContainer history={props.history} match={props.match} />}
            />
        </Container>
    );
}

export default ExhibitionSubjectsList;

ExhibitionSubjectsList.propTypes = {
    history: HistoryPropType.isRequired,
    match: MatchPropType.isRequired,
    getExhibitionSubjects: PropTypes.func.isRequired,
    approvedSubjects: PropTypes.arrayOf(ExhibitionEntryPropType),
    onVoteSubject: PropTypes.func.isRequired,
    onUnvoteSubject: PropTypes.func.isRequired,
    getVotingData: PropTypes.func.isRequired,
    votingData: PropTypes.shape({}),
    onSubmitNew: PropTypes.func.isRequired,
    extraSettings: ExtraSettingsPropType.isRequired,
    sortSubjects: PropTypes.func.isRequired,
};

ExhibitionSubjectsList.defaultProps = {
    approvedSubjects: [],
    votingData: {},
};
