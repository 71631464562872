import React from "react";
import PropTypes from "prop-types";
import { HistoryPropType, PagePropType } from "../../../../customPropTypes";
import PageList from "../common/PageList";

function PlannedEntriesPages({
    history, pages, getExhibitionSubjectPages, onPageDeleteClicked,
}) {
    React.useEffect(() => {
        getExhibitionSubjectPages();
    }, [getExhibitionSubjectPages]);

    return (
        <div>
            <PageList
                history={history}
                pages={pages}
                title="Pregled planiranih natječaja za prikupljanje programa"
                editPage
                deletePage
                onDeleteClicked={onPageDeleteClicked}
                baseLink="/admin/natjecaji/programi"
            />
        </div>
    );
}

export default PlannedEntriesPages;

PlannedEntriesPages.propTypes = {
    history: HistoryPropType.isRequired,
    pages: PropTypes.arrayOf(PagePropType),
    getExhibitionSubjectPages: PropTypes.func.isRequired,
    onPageDeleteClicked: PropTypes.func.isRequired,
};

PlannedEntriesPages.defaultProps = {
    pages: [],
};
