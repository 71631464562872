import React from "react";
import uuidv1 from "uuid/v1";
import PropTypes from "prop-types";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ExhibitionEntryCard from "./ExhibitionEntryCard";
import ExhibitionAddNewButton from "../common/ExhibitionAddNewButton";

function TwoColumnList({
    items, onVoteEntry, onUnvoteEntry, handleShow, isPageActive,
}) {
    let leftColumnEntries = [];
    let rightColumnEntries = [];

    if (items.length > 0) {
        rightColumnEntries = items.reduce((accum, current, currentIndex) => {
            if (currentIndex % 2 !== 0) {
                return [...accum, current];
            }
            return accum;
        }, []);
        leftColumnEntries = items.reduce((accum, current, currentIndex) => {
            if (currentIndex % 2 === 0) {
                return [...accum, current];
            }
            return accum;
        }, []);
    }

    return (
        <Row className="justify-content-between">
            <Col className="custom-exhibition-list-column" lg="5">
                {leftColumnEntries.map((entry, index) => (
                    <div key={uuidv1()} className="custom-exhibition-column-wrapper">
                        <ExhibitionEntryCard
                            title={entry.title}
                            description={entry.description}
                            contentUrl={entry.contentUrl}
                            onVoteEntry={onVoteEntry}
                            onUnvoteEntry={onUnvoteEntry}
                            id={entry.id}
                            isPageActive={isPageActive}
                        />
                        {isPageActive && index % 2 === 0 && <ExhibitionAddNewButton onClickCallback={handleShow} /> } 
                    </div>
                ))}
            </Col>
            {rightColumnEntries.length > 0 && (
                <Col className="custom-exhibition-list-column" lg="5">
                    {rightColumnEntries.map((entry, index) => (
                        <div key={uuidv1()} className="custom-exhibition-column-wrapper">
                            <ExhibitionEntryCard
                                title={entry.title}
                                description={entry.description}
                                contentUrl={entry.contentUrl}
                                onVoteEntry={onVoteEntry}
                                onUnvoteEntry={onUnvoteEntry}
                                id={entry.id}
                                isPageActive={isPageActive}
                            />
                            {isPageActive && index % 2 !== 0 && <ExhibitionAddNewButton onClickCallback={handleShow} /> } 
                        </div>
                    ))}
                </Col>
            )}
        </Row>
    );
}

export default TwoColumnList;

TwoColumnList.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape).isRequired,
};
