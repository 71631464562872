import { connect } from "react-redux";
import CreatePage from "../../../../components/admin/pages/subjects/CreatePage";
import { pages, exhibitionSubject } from "../../../../services/requests";
import Copy from "../../../../content/AdminTextContent";

const mapStateToProps = () => ({
    text: Copy.pages.exhibitionSubjects.addNew,
});

const mapDispatchToProps = (dispatch) => ({
    getPages: () => dispatch(exhibitionSubject.getPages()),
    onSubmit: (values, setStatus) => dispatch(pages.addNew(values, setStatus)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreatePage);
