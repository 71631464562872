import React from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { HistoryPropType, FormStatusPropType } from "../../../../customPropTypes";

export default function CreateEditFormInner({
    history, handleSubmit, touched, handleChange, values, errors, status, setFieldValue, handleBlur,
    getPages, text,
}) {
    const [selectedStartDate, setSelectedStartDate] = React.useState(values.activeFrom);
    const [selectedEndDate, setSelectedEndDate] = React.useState(values.activeTo);

    React.useEffect(() => {
        getPages();
    }, [getPages]);

    React.useEffect(() => {
        setSelectedStartDate(values.activeFrom);
    }, [values.activeFrom]);

    React.useEffect(() => {
        setSelectedEndDate(values.activeTo);
    }, [values.activeTo]);

    const onHideCallback = () => history.goBack();

    return (
        <div>
            <Modal
                show
                onHide={onHideCallback}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName="custom-exhibition-entry-modal contest-add-new"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{text.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {status && status.type === "success" ?
                        (
                            <div className="custom-add-entry-success">
                                <div>{status.message}</div>
                            </div>
                        ) :
                        (
                            <Form onSubmit={handleSubmit}>
                                <Form.Group as={Row} controlId="formAlias">
                                    <Col sm="12" md="4" className="custom-form-label-disclaimer-wrapper">
                                        <Form.Label className="text-left">{text.aliasTitle}</Form.Label>
                                        <Form.Text className="text-muted">{text.aliasExplanation}</Form.Text>
                                    </Col>
                                    <Col sm="12" md="8">
                                        <Form.Control
                                            type="text"
                                            name="alias"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.alias}
                                            isInvalid={touched.alias && !!errors.alias}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.alias}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formContent-0-text">
                                    <Col sm="12" md="4" className="custom-form-label-disclaimer-wrapper">
                                        <Form.Label className="text-left">
                                            {text.content.mainPageTextTitle}
                                        </Form.Label>
                                    </Col>
                                    <Col sm="12" md="8">
                                        <Form.Control
                                            as="textarea"
                                            type="text"
                                            name="content[0].text"
                                            onChange={handleChange}
                                            value={values.content[0].text}
                                            onBlur={handleBlur}
                                            rows="8"
                                            isInvalid={(touched.content &&
                                                touched.content.length > 0 &&
                                                touched.content[0].text) && (
                                                errors.content &&
                                                errors.content.length > 0 &&
                                                !!errors.content[0].text
                                            )}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.content &&
                                            errors.content.length > 0 &&
                                            errors.content[0].text}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formActiveFrom">
                                    <Col sm="12" md="4" className="custom-form-label-disclaimer-wrapper">
                                        <Form.Label className="text-left">{text.startDateTitle}</Form.Label>
                                        <Form.Text className="text-muted">
                                            {text.startDateExplanation}
                                        </Form.Text>
                                    </Col>
                                    <Col sm="12" md="8">
                                        <DatePicker
                                            selected={selectedStartDate}
                                            onChange={(v) => {
                                                setFieldValue("activeFrom", v, false);
                                                setSelectedStartDate(v);
                                            }}
                                            className="form-control"
                                            isInvalid={touched.activeFrom && !!errors.activeFrom}
                                            onBlur={handleBlur}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formActiveTo">
                                    <Col sm="12" md="4" className="custom-form-label-disclaimer-wrapper">
                                        <Form.Label className="text-left">{text.endDateTitle}</Form.Label>
                                    </Col>
                                    <Col sm="12" md="8">
                                        <DatePicker
                                            className="form-control"
                                            selected={selectedEndDate}
                                            onChange={(v) => {
                                                setFieldValue("activeTo", v, false);
                                                setSelectedEndDate(v);
                                            }}
                                            isInvalid={touched.activeTo && !!errors.activeTo}
                                            onBlur={handleBlur}
                                        />
                                    </Col>
                                </Form.Group>
                                <Row className="custom-admin-pages-form-buttons-wrapper">
                                    <Button
                                        type="submit"
                                        variant="primary"
                                    >
                                        {text.submitButton}
                                    </Button>
                                    <Button
                                        type="button"
                                        variant="danger"
                                        onClick={onHideCallback}
                                    >
                                        {text.cancelButton}
                                    </Button>
                                </Row>
                            </Form>
                        )}
                </Modal.Body>
            </Modal>
        </div>
    );
}

CreateEditFormInner.propTypes = {
    history: HistoryPropType.isRequired,
    values: PropTypes.shape({
        type: PropTypes.number,
        alias: PropTypes.string,
        activeFrom: PropTypes.instanceOf(Date),
        activeTo: PropTypes.instanceOf(Date),
        content: PropTypes.arrayOf(PropTypes.shape({
            title: PropTypes.string,
            text: PropTypes.string,
            tag: PropTypes.string,
        })),
    }).isRequired,
    errors: PropTypes.shape({
        type: PropTypes.number,
        alias: PropTypes.string,
        activeFrom: PropTypes.instanceOf(Date),
        activeTo: PropTypes.instanceOf(Date),
        content: PropTypes.arrayOf(PropTypes.shape({
            title: PropTypes.string,
            text: PropTypes.string,
            tag: PropTypes.string,
        })),
    }).isRequired,
    touched: PropTypes.shape({
        type: PropTypes.bool,
        alias: PropTypes.bool,
        activeFrom: PropTypes.shape(),
        activeTo: PropTypes.shape(),
        content: PropTypes.arrayOf(PropTypes.shape({
            title: PropTypes.bool,
            text: PropTypes.bool,
            tag: PropTypes.bool,
        })),
    }).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    status: FormStatusPropType,
    getPages: PropTypes.func.isRequired,
    text: PropTypes.shape({
        title: PropTypes.string,
        aliasTitle: PropTypes.string,
        aliasExplanation: PropTypes.string,
        content: {
            mainPageTextTitle: PropTypes.string,
        },
        startDateTitle: PropTypes.string,
        startDateExplanation: PropTypes.string,
        endDateTitle: PropTypes.string,
        submitButton: PropTypes.string,
        cancelButton: PropTypes.string,
    }).isRequired,
};

CreateEditFormInner.defaultProps = {
    status: null,
};
