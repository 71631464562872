import React from "react";
import PropTypes from "prop-types";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { ExhibitionEntryPropType, HistoryPropType, PagePropType } from "../../../customPropTypes";
import AdminSidebar from "../AdminSidebar";
import Content from "../../../content/AdminTextContent";
import AdminSubjectNewEntry from "./AdminSubjectNewEntry";
import AdminExhibitionSubjectsList from "./AdminExhibitionSubjectsList";

function AdminExhibitionSubjects({
    title, defaultActiveKey, newSubjects, approvedSubjects, rejectedSubjects, history,
    approveSubject, rejectSubject, approveRejectedSubject, rejectApprovedSubject,
    activePage,
}) {
    const [currentNewEntryIndex, setCurrentNewEntryIndex] = React.useState(0);

    const onNextEntryClicked = () => setCurrentNewEntryIndex(currentNewEntryIndex + 1);
    const onPreviousEntryClicked = () => setCurrentNewEntryIndex(currentNewEntryIndex - 1);
    const resetNewEntriesList = () => {
        if (currentNewEntryIndex > 0) {
            onPreviousEntryClicked();
        }
    };

    const tabsContent = Content.exhibitionEntries.tabs;

    let currentTitle;

    currentTitle =  activePage && activePage.alias ? `${title} "${activePage.alias}"` : title;

    return (
        <div>
            <AdminSidebar history={history} />
            <h3 className="admin-exhibition-title">{currentTitle}</h3>
            <Tabs defaultActiveKey={defaultActiveKey} id="uncontrolled-tab-example">
                <Tab eventKey={tabsContent[0].eventKey} title={tabsContent[0].title}>
                    {newSubjects.length > 0 ? (
                        <AdminSubjectNewEntry
                            entry={newSubjects[currentNewEntryIndex]}
                            order={currentNewEntryIndex + 1}
                            totalEntriesNumber={newSubjects.length}
                            onNextEntryClicked={onNextEntryClicked}
                            onPreviousEntryClicked={onPreviousEntryClicked}
                            onApproveClicked={(subject) => {
                                approveSubject(subject);
                                resetNewEntriesList();
                            }}
                            onRejectClicked={(subject) => {
                                rejectSubject(subject);
                                resetNewEntriesList();
                            }}
                        />
                    ) : null}
                </Tab>
                <Tab eventKey={tabsContent[1].eventKey} title={tabsContent[1].title}>
                    {approvedSubjects.length > 0 ?
                        (
                            <AdminExhibitionSubjectsList
                                data={approvedSubjects}
                                showRejectButton
                                onRejectClicked={rejectApprovedSubject}
                            />
                        ) : null
                    }
                </Tab>
                <Tab eventKey={tabsContent[2].eventKey} title={tabsContent[2].title}>
                    {rejectedSubjects.length > 0 ?
                        (
                            <AdminExhibitionSubjectsList
                                data={rejectedSubjects}
                                showApproveButton
                                onApproveClicked={approveRejectedSubject}
                            />
                        ) : null
                    }
                </Tab>
            </Tabs>
        </div>
    );
}

export default AdminExhibitionSubjects;

AdminExhibitionSubjects.propTypes = {
    title: PropTypes.string.isRequired,
    defaultActiveKey: PropTypes.string.isRequired,
    newSubjects: PropTypes.arrayOf(ExhibitionEntryPropType),
    approvedSubjects: PropTypes.arrayOf(PropTypes.shape({})),
    approveSubject: PropTypes.func.isRequired,
    rejectSubject: PropTypes.func.isRequired,
    rejectedSubjects: PropTypes.arrayOf(PropTypes.shape({})),
    approveRejectedSubject: PropTypes.func.isRequired,
    rejectApprovedSubject: PropTypes.func.isRequired,
    history: HistoryPropType.isRequired,
    activePage: PagePropType.isRequired,
};

AdminExhibitionSubjects.defaultProps = {
    newSubjects: [],
    approvedSubjects: [],
    rejectedSubjects: [],
};
