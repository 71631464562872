import React from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import { HistoryPropType, ExhibitionEntryPropType } from "../../../customPropTypes";
import EntryVoteButtonContainer from "../../../containers/website/EntryVoteButtonContainer";
import SVGxButton from "../../../media/svgs/common/x";
import SVGxButtonHover from "../../../media/svgs/common/x-hover";
import { checkImageURL, checkVideoURL, checkAudioURL } from "../../../helpers/files";

function ExhibitionEntry({
    history, entry, voteEntry, unvoteEntry,
}) {
    const onHideCallback = () => {
        history.push("/prijedlozi");
    };
    const [isCloseButtonHovering, setIsCloseButtonHovering] = React.useState(false);

    return (
        entry ? (
            <div>
                <Modal
                    show
                    onHide={onHideCallback}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    dialogClassName="custom-exhibition-entry-modal"
                >
                    {checkImageURL(entry.contentUrl) && <img src={entry.contentUrl} alt="" className="custom-entry-enlarged-image" />}
                    {checkVideoURL(entry.contentUrl) && (
                        <video controls src={entry.contentUrl} className="custom-exhibition-entry-card-video-enlarged">
                            <track default kind="captions" />
                        </video>
                    )}
                    {checkAudioURL(entry.contentUrl) && (
                        <audio controls className="custom-exhibition-entry-card-video-enlarged">
                            <source src={entry.contentUrl} />
                            <track default kind="captions" />
                            Your browser does not support the audio element.
                        </audio>
                    )}
                    <button
                        type="button"
                        href="#"
                        onClick={onHideCallback}
                        className="no-style-button custom-modal-close-button"
                        onMouseEnter={() => setIsCloseButtonHovering(true)}
                        onMouseLeave={() => setIsCloseButtonHovering(false)}
                    >
                        {isCloseButtonHovering ? <SVGxButtonHover /> : <SVGxButton />}
                    </button>
                    <h4 style={{ marginTop: "20px", marginBottom: "0", fontWeight: "400" }}>{entry.title}</h4>
                    <p style={{ marginTop: "20px", marginBottom: "20px" }}>{entry.description}</p>
                    {/* <div>
                        <EntryVoteButtonContainer
                            entryId={entry.id}
                            onVoteCallback={voteEntry}
                            onUnvoteCallback={unvoteEntry}
                        />
                    </div> */}
                </Modal>

            </div>
        ) : null
    );
}

export default ExhibitionEntry;

ExhibitionEntry.propTypes = {
    entry: ExhibitionEntryPropType,
    history: HistoryPropType.isRequired,
    voteEntry: PropTypes.func.isRequired,
    unvoteEntry: PropTypes.func.isRequired,
};

ExhibitionEntry.defaultProps = {
    entry: null,
};
