import { connect } from "react-redux";
import CookiesPopup from "../../components/website/CookiesPopup";
import { extraSettings } from "../../services/requests";

const mapStateToProps = (state) => ({
    extraSettings: state.extraSettings,
});

const mapDispatchToProps = (dispatch) => ({
    getCookiesAcceptanceData: () => dispatch(extraSettings.getCookiesData()),
    acceptCookies: () => dispatch(extraSettings.acceptCookies()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CookiesPopup);
