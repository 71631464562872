export function groupEntriesByStatus(entries) {
    const newEntries = [];
    const approvedEntries = [];
    const rejectedEntries = [];

    entries.forEach((current) => {
        switch (current.status) {
        case 2:
            approvedEntries.push(current);
            break;
        case 3:
            rejectedEntries.push(current);
            break;
        default:
            newEntries.push(current);
            break;
        }
    });

    return { new: newEntries, approved: approvedEntries, rejected: rejectedEntries };
}

export const removeFromList = (list, id) => list.reduce((accum, current) => {
    if (current.id !== id) {
        accum.push(current);
    }
    return accum;
}, []);

export const addToList = (baseList, fromList, id) => [...baseList, fromList.find(
    (current) => current.id === id
)];

export function updateSurvey(baseList, updatedSurvey) {
    return baseList.map((currentSurvey) => {
        if (currentSurvey.id === updatedSurvey.id) {
            return updatedSurvey;
        }
        return currentSurvey;
    });
}

export function deleteSurvey(baseList, surveyid) {
    return baseList.reduce((accum, current) => {
        if (current.id !== surveyid) return [...accum, current];
        return accum;
    }, []);
}

export function deleteMapPin(baseList, pinId) {
    return baseList.reduce((accum, current) => {
        const reducedPins = current.pinInfos.reduce((pinsAccum, pinsCurrent) => {
            if (pinsCurrent.id !== pinId) {
                return [...pinsAccum, pinsCurrent];
            }
            return pinsAccum;
        }, []);

        if (reducedPins.length === 0) {
            return accum;
        }

        return [...accum, { ...current, pinInfos: reducedPins }];
    }, []);
}

export function updatePages(baseList, updatedPage) {
    const { current, archived, planned } = baseList;

    if (updatedPage.id === current.id) {
        return {
            current: updatedPage,
            archived,
            planned,
        };
    }

    const matchedArchive = archived.find((p) => p.id === updatedPage.id);
    if (matchedArchive) {
        return {
            current,
            archived: [...archived, updatedPage],
            planned,
        };
    }

    const matchedPlanned = planned.find((p) => p.id === updatedPage.id);
    if (matchedPlanned) {
        return {
            current,
            archived,
            planned: [...planned, updatedPage],
        };
    }

    return baseList;
}

export function removePage(baseList, pageId) {
    const { current, archived, planned } = baseList;

    if (pageId === current.id) {
        return {
            current: {},
            archived,
            planned,
        };
    }

    const matchedPlanned = planned.find((p) => p.id === pageId);
    if (matchedPlanned) {
        const newPlanned = planned.reduce((accum, currentPlanned) => {
            if (currentPlanned.id !== pageId) {
                return [...accum, currentPlanned];
            }
            return accum;
        }, []);

        return {
            current,
            archived,
            planned: newPlanned,
        };
    }

    return baseList;
}
