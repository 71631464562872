/* eslint max-len: 0 */
import React from "react";
import PropTypes from "prop-types";

const ORIGINAL_WIDTH = 135.679;
const ORIGINAL_HEIGHT = 14.02;

function SVG({ scale }) {
    const width = ORIGINAL_WIDTH * scale;
    const height = ORIGINAL_HEIGHT * scale;
    const widthString = `${width}px`;
    const heightString = `${height}px`;

    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width={widthString}
            height={heightString}
            viewBox={`0 0 ${ORIGINAL_WIDTH} ${ORIGINAL_HEIGHT}`}
            enableBackground={`new 0 0 ${ORIGINAL_WIDTH} ${ORIGINAL_HEIGHT}`}
        >
            <g>
                <path fill="none" stroke="#1B1C20" d="M7.905,13.19c2.239,0,3.92-0.379,5.04-1.141c1.119-0.76,1.68-1.752,1.68-2.979 c0-1.053-0.423-1.857-1.27-2.41c-0.848-0.553-2.057-0.957-3.631-1.211l-2.1-0.34c-1.627-0.252-2.439-0.646-2.439-1.18 c0-0.666,0.812-1,2.439-1c0.934,0,1.833,0.141,2.7,0.42s1.553,0.641,2.06,1.08l1.58-2.459c-1.479-1.201-3.547-1.801-6.199-1.801 c-2.014,0-3.561,0.363-4.641,1.09S1.506,2.938,1.506,4.11c0,1.961,1.553,3.213,4.659,3.76l2.32,0.4 c0.84,0.146,1.437,0.291,1.79,0.43c0.353,0.141,0.529,0.344,0.529,0.611c0,0.346-0.213,0.623-0.64,0.83s-1.04,0.309-1.84,0.309 c-1.08,0-2.106-0.16-3.08-0.479c-0.974-0.32-1.793-0.729-2.46-1.221l-1.5,2.6c0.707,0.508,1.641,0.939,2.8,1.301 C5.245,13.011,6.518,13.19,7.905,13.19z" />
                <polygon fill="none" stroke="#1B1C20" points="24.984,12.95 30.165,0.411 26.346,0.411 22.885,10.151 19.505,0.411 15.525,0.411 20.545,12.95" />
                <rect x="32.305" y="0.411" fill="none" stroke="#1B1C20" width="3.561" height="12.539" />
                <path fill="none" stroke="#1B1C20" d="M46.385,10.271h-2.92V7.812h2.74V5.55h-2.74V3.091h3.08c1.053,0,1.873,0.303,2.46,0.91 s0.88,1.51,0.88,2.709c0,1.201-0.312,2.094-0.939,2.682C48.318,9.978,47.465,10.271,46.385,10.271z M46.865,12.95 c1.906,0,3.496-0.543,4.77-1.629c1.273-1.088,1.91-2.623,1.91-4.611c0-1.092-0.178-2.049-0.53-2.869s-0.847-1.477-1.479-1.971 c-0.633-0.492-1.36-0.859-2.18-1.1c-0.82-0.24-1.738-0.359-2.75-0.359h-6.701V5.55h-1.5v2.262h1.5v5.139H46.865z" />
                <path fill="none" stroke="#1B1C20" d="M61.925,3.21l1.96,5.102h-3.98L61.925,3.21z M65.505,12.95h3.979l-5.26-12.539h-4.4 L54.465,12.95h3.779l0.801-2.26h5.68L65.505,12.95z" />
                <polygon fill="none" stroke="#1B1C20" points="93.265,12.95 93.265,0.411 87.865,0.411 84.785,9.63 81.484,0.411 76.084,0.411 76.084,12.95 79.645,12.95 79.645,4.03 82.824,12.95 86.545,12.95 89.705,4.03 89.705,12.95" />
                <rect x="96.944" y="0.411" fill="none" stroke="#1B1C20" width="3.56" height="12.539" />
                <path fill="none" stroke="#1B1C20" d="M114.465,13.19c2.24,0,3.92-0.379,5.039-1.141c1.121-0.76,1.681-1.752,1.681-2.979 c0-1.053-0.423-1.857-1.271-2.41c-0.847-0.553-2.057-0.957-3.63-1.211l-2.1-0.34c-1.628-0.252-2.44-0.646-2.44-1.18 c0-0.666,0.812-1,2.44-1c0.933,0,1.833,0.141,2.7,0.42s1.553,0.641,2.06,1.08l1.58-2.459c-1.479-1.201-3.547-1.801-6.2-1.801 c-2.013,0-3.561,0.363-4.64,1.09c-1.08,0.727-1.62,1.678-1.62,2.85c0,1.961,1.553,3.213,4.66,3.76l2.32,0.4 c0.84,0.146,1.437,0.291,1.789,0.43c0.354,0.141,0.53,0.344,0.53,0.611c0,0.346-0.213,0.623-0.64,0.83 c-0.428,0.207-1.04,0.309-1.84,0.309c-1.08,0-2.107-0.16-3.08-0.479c-0.973-0.32-1.793-0.729-2.461-1.221l-1.5,2.6 c0.707,0.508,1.641,0.939,2.801,1.301C111.805,13.011,113.077,13.19,114.465,13.19z" />
                <polygon fill="none" stroke="#1B1C20" points="135.805,12.95 135.805,10.11 127.385,10.11 127.385,7.95 133.624,7.95 133.624,5.392 127.385,5.392 127.385,3.251 135.404,3.251 135.404,0.411 123.824,0.411 123.824,12.95" />
            </g>
        </svg>
    );
}

export default SVG;

SVG.propTypes = {
    scale: PropTypes.number,
};

SVG.defaultProps = {
    scale: 1,
};
