/* eslint max-len: 0 */
import React from "react";
import PropTypes from "prop-types";

const ORIGINAL_WIDTH = 17.478;
const ORIGINAL_HEIGHT = 11;

function SVG({ scale }) {
    const width = ORIGINAL_WIDTH * scale;
    const height = ORIGINAL_HEIGHT * scale;
    const widthString = `${width}px`;
    const heightString = `${height}px`;

    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width={widthString}
            height={heightString}
            viewBox={`0 0 ${ORIGINAL_WIDTH} ${ORIGINAL_HEIGHT}`}
            enableBackground={`new 0 0 ${ORIGINAL_WIDTH} ${ORIGINAL_HEIGHT}`}
        >
            <g>
                <polygon fill="#1B1C20" points="0,6.742 0.329,7.07 3.54,3.861 3.54,11 4.005,11 4.005,3.861 7.2,7.086 7.53,6.742 3.766,2.977" />
                <path fill="#1B1C20" d="M13.847,0h-1.709L8.448,11h1.23l1.033-3h4.426l1.049,3h1.291L13.847,0z M11.072,7l1.86-5.523L14.792,7 H11.072z" />
            </g>
        </svg>
    );
}

export default SVG;

SVG.propTypes = {
    scale: PropTypes.number,
};

SVG.defaultProps = {
    scale: 1.5,
};
