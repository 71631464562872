import React from "react";
import PropTypes from "prop-types";
import SVGsend from "../../media/svgs/exhibition-entries/send-entry";
import SVGsendHover from "../../media/svgs/exhibition-entries/send-entry-hover";

function SendFormButton({ disabled }) {
    const [isButtonHovering, setIsButtonHovering] = React.useState(false);

    return (
        <div>
            {disabled ? (
                <button
                    disabled={disabled}
                    type="button"
                    className="no-style-button custom-form-svg-button"
                >
                    {!disabled ? <SVGsendHover /> : <SVGsend />}
                </button>
            ) : (
                <button
                    disabled={disabled}
                    type="submit"
                    className="no-style-button custom-form-svg-button"
                    onMouseEnter={() => setIsButtonHovering(true)}
                    onMouseLeave={() => setIsButtonHovering(false)}
                >
                    {isButtonHovering && !disabled ? <SVGsendHover /> : <SVGsend />}
                </button>
            )}
        </div>
    );
}

export default SendFormButton;

SendFormButton.propTypes = {
    disabled: PropTypes.bool,
};

SendFormButton.defaultProps = {
    disabled: false,
};
