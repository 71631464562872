import React from "react";
import PropTypes from "prop-types";
import uuidv1 from "uuid/v1";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import { HistoryPropType } from "../../../customPropTypes";

function AdminDashboardCard({
    history, title, text, icon, badge, buttonLabel, buttonLink,
}) {
    return (
        <div>
            <Card>
                <Card.Body>
                    {badge ? (
                        <div className="admin-dashboard-card-badges">
                            <Badge
                                key={uuidv1()}
                                pill
                                variant={badge.variant}
                                className="admin-dashboard-badge"
                            >
                                {badge.text}
                            </Badge>
                        </div>
                    ) : null
                    }
                    <h3>{title}</h3>
                    {icon && <i className={`fas fa-${icon} admin-dashboard-icon`} />}
                    <Card.Text>{text}</Card.Text>
                    <Button
                        variant="primary"
                        onClick={() => { history.push(buttonLink); }}
                    >
                        {buttonLabel}
                    </Button>
                </Card.Body>
            </Card>
        </div>
    );
}

export default AdminDashboardCard;

AdminDashboardCard.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    icon: PropTypes.string,
    history: HistoryPropType.isRequired,
    buttonLabel: PropTypes.string.isRequired,
    badge: PropTypes.shape({
        text: PropTypes.string,
        variant: PropTypes.string,
    }),
    buttonLink: PropTypes.string.isRequired,
};

AdminDashboardCard.defaultProps = {
    badge: {},
    icon: null,
};
