const extraSettingsActions = {
    hideBackgrounds: () => (dispatch) => {
        dispatch({
            type: "HIDE_BACKGROUNDS",
            payload: true,
        });
    },
    showBackgrounds: () => (dispatch) => {
        dispatch({
            type: "HIDE_BACKGROUNDS",
            payload: false,
        });
    },
    increaseFontSize: () => (dispatch) => {
        dispatch({
            type: "INCREASE_FONT_SIZE",
            payload: true,
        });
    },
    resetFontSize: () => (dispatch) => {
        dispatch({
            type: "INCREASE_FONT_SIZE",
            payload: false,
        });
    },
    acceptCookies: () => (dispatch) => {
        dispatch({
            type: "ACCEPT_COOKIES",
            payload: true,
        });
    },
};

export default extraSettingsActions;
