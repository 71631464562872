/* eslint max-len: 0 */
import React from "react";
import PropTypes from "prop-types";

const ORIGINAL_WIDTH = 863.407;
const ORIGINAL_HEIGHT = 369.746;

function SVG({ scale }) {
    const width = ORIGINAL_WIDTH * scale;
    const height = ORIGINAL_HEIGHT * scale;
    const widthString = `${width}px`;
    const heightString = `${height}px`;

    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width={widthString}
            height={heightString}
            viewBox={`0 0 ${ORIGINAL_WIDTH} ${ORIGINAL_HEIGHT}`}
            enableBackground={`new 0 0 ${ORIGINAL_WIDTH} ${ORIGINAL_HEIGHT}`}
        >
            <g>
                <defs>
                    <rect id="SVGID_1_" width="863.407" height="369.746" />
                </defs>
                <path fill="#E5002E" d="M523.748,119.698H397.211L630.209,0l233.197,119.834H736.868 c0,0,8.307,123.88-50.029,197.718c-35.135,44.49-82.1,59.312-151.941,49.115c-49.463-7.23-115.349-56.395-157.92-136.074 c-37.954-71.051-57.25-113.117-57.25-113.117l0.479,148.048H0.648c0,0-11.256-142.718,60.013-212.661 c54.019-53.007,172.208-42.724,226.82,3.091c33.849,28.379,41.707,42.533,66.584,84.586 c17.512,29.628,83.843,137.993,141.717,137.993C533.43,278.533,523.748,119.698,523.748,119.698" />
            </g>
        </svg>
    );
}

export default SVG;

SVG.propTypes = {
    scale: PropTypes.number,
};

SVG.defaultProps = {
    scale: 1.2,
};
