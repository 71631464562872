import { SVG_PIN_PATHS_COUNT } from "../components/website/map/CityPin";
import { deleteMapPin } from "../helpers/lists";

const numberOfPins = SVG_PIN_PATHS_COUNT;
export const getRandomPinIndex = () => Math.floor(Math.random() * numberOfPins);

const defaultState = [];

export default (state = defaultState, action) => {
    switch (action.type) {
    case "FETCH_MAP_PINS":
        return action.payload;
    case "ASSIGN_RANDOM_PIN_ARROWS":
        return [...state.map((pin) => {
            return { ...pin, pinSvgIndex: getRandomPinIndex() };
        })];
    case "DELETE_PIN_BY_ID":
        return deleteMapPin(state, action.payload);
    case "ADD_NEW_PIN":
        return [...state, action.payload];
    default:
        return state;
    }
};
