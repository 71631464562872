import React from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import { checkImageURL, checkVideoURL } from "../../helpers/files";

function MediaModal(props) {
    const { contentUrl, show, onHide } = props;
    return (
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton />
            <Modal.Body>
                {checkImageURL(contentUrl) && <img src={contentUrl} className="media-modal-img" alt="" />}
                {checkVideoURL(contentUrl) && (
                    <video controls src={contentUrl} className="media-modal-video">
                        <track default kind="captions" />
                    </video>
                )}
            </Modal.Body>
        </Modal>
    );
}

export default MediaModal;

MediaModal.propTypes = {
    contentUrl: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
};
