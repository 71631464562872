import React from "react";
import SideNav, { NavItem, NavIcon, NavText } from "@trendmicro/react-sidenav";

function AdminSidebar({ history }) {
    return (
        <SideNav
            onSelect={(selected) => {
                history.push(`/admin/${selected}`);
                // Add your code here
            }}
            className="custom-admin-sidebar-wrapper"
        >
            <SideNav.Toggle />
            <SideNav.Nav>
                <NavItem eventKey="dashboard">
                    <NavIcon>
                        <i className="fa fa-fw fa-home" style={{ fontSize: "1.75em" }} />
                    </NavIcon>
                    <NavText>
                        Home
                    </NavText>
                </NavItem>
                <NavItem eventKey="predmeti">
                    <NavIcon>
                        <i className="fa fa-fw fa-chair" style={{ fontSize: "1.75em" }} />
                    </NavIcon>
                    <NavText>
                        Prijedlozi predmeta
                    </NavText>
                </NavItem>
                <NavItem eventKey="ankete">
                    <NavIcon>
                        <i className="fa fa-fw fa-poll" style={{ fontSize: "1.75em" }} />
                    </NavIcon>
                    <NavText>
                        Ankete
                    </NavText>
                </NavItem>
                <NavItem eventKey="programi">
                    <NavIcon>
                        <i className="fa fa-fw fa-align-center" style={{ fontSize: "1.75em" }} />
                    </NavIcon>
                    <NavText>
                        Programi
                    </NavText>
                </NavItem>
                <NavItem eventKey="mapa">
                    <NavIcon>
                        <i className="fas fa-map-marker-alt" style={{ fontSize: "1.75em" }} />
                    </NavIcon>
                    <NavText>
                        Mapa
                    </NavText>
                </NavItem>
            </SideNav.Nav>
        </SideNav>
    );
}

export default AdminSidebar;
