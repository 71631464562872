import React from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";

function CustomFormLabel({ text, disclaimer, customStyle }) {
    return (
        <Col sm="12" md="3" className="custom-form-label-disclaimer-wrapper" style={customStyle}>
            <Form.Label className="text-left">{text}</Form.Label>
            {disclaimer && <Form.Text className="text-muted">{disclaimer}</Form.Text>}
        </Col>
    );
}

export default CustomFormLabel;

CustomFormLabel.propTypes = {
    text: PropTypes.string,
    disclaimer: PropTypes.string,
};

CustomFormLabel.defaultProps = {
    text: "",
    disclaimer: null,
};
