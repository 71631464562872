import React from "react";
import uuidv1 from "uuid/v1";
import PropTypes from "prop-types";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ExhibitionEntryCard from "./ExhibitionEntryCard";
import ExhibitionSubjectCard from "./ExhibitionSubjectCard";

function OneColumnList({ items, isEntries }) {
    return (
        <Row className="justify-content-between">
            <Col className="custom-exhibition-list-column" sm="12">
                {items.map((item) => (
                    <div key={uuidv1()} className="custom-exhibition-column-wrapper">
                        {isEntries ? (
                            <ExhibitionEntryCard
                                title={item.title}
                                description={item.description}
                                contentUrl={item.contentUrl}
                                votes={item.votes}
                            />
                        ) : (
                            <ExhibitionSubjectCard
                                title={item.title}
                                description={item.description}
                                votes={item.votes}
                            />
                        )}
                    </div>
                ))}
            </Col>
        </Row>
    );
}

export default OneColumnList;

OneColumnList.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape).isRequired,
    isEntries: PropTypes.bool,
};

OneColumnList.defaultProps = {
    isEntries: false,
};
