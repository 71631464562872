import React from "react";
import PropTypes from "prop-types";
import uuidv1 from "uuid/v1";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { HistoryPropType } from "../../../customPropTypes";
import AdminSidebar from "../AdminSidebar";
import AdminDashboardCard from "./AdminDashboardCard";
import Content from "../../../content/AdminTextContent";

function AdminDashboard({ history, newEntriesCount, newSubjectsCount }) {
    const getBadges = (badgeText, itemsCount) => {
        switch (itemsCount) {
        case "entries":
            return newEntriesCount > 0 ? {
                text: `${badgeText} ${newEntriesCount}`,
                variant: "success",
            } : {};
        case "subjects":
            return newSubjectsCount > 0 ? {
                text: `${badgeText} ${newSubjectsCount}`,
                variant: "success",
            } : {};
        default:
            return {};
        }
    };

    const cardsContent = Content.dashboard;

    return (
        <div className="admin-dashboard-wrapper">
            <AdminSidebar history={history} />
            <Row className="align-content-center">
                {Object.keys(cardsContent).map((card) => (
                    <Col sm="12" md="6" key={uuidv1()}>
                        <AdminDashboardCard
                            history={history}
                            title={cardsContent[card].title}
                            text={cardsContent[card].text}
                            icon={cardsContent[card].icon}
                            badge={cardsContent[card].badgeText && getBadges(cardsContent[card].badgeText, cardsContent[card].itemsCount)}
                            buttonLabel={cardsContent[card].buttonLabel}
                            buttonLink={cardsContent[card].buttonLink}
                        />
                    </Col>
                ))}
            </Row>
        </div>
    );
}

export default AdminDashboard;

AdminDashboard.propTypes = {
    history: HistoryPropType.isRequired,
    newEntriesCount: PropTypes.number.isRequired,
    newSubjectsCount: PropTypes.number.isRequired,
};
