import React from "react";

function SVG() {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="587.716px" height="583.757px" viewBox="0 0 587.716 583.757" enableBackground="new 0 0 587.716 583.757">
            <switch>
                <g>
                    <polygon fill="#FFDE14" points="426.889,0 89.734,337.153 35,282.418 0,583.757 303.318,550.736 250.562,497.981 587.716,160.827" />
                </g>
            </switch>
        </svg>
    );
}

export default SVG;
