import { connect } from "react-redux";
import "../../css/App.css";
import exhibitionActions from "../../action-creators/admin/exhibitionActions";
import exhibitionSubjectActions from "../../action-creators/exhibitionSubjectActions";
import AdminApp from "../../components/admin/AdminApp";
import {
    exhibitionEntry, survey, exhibitionSubject, map,
} from "../../services/requests";

const mapStateToProps = (state) => ({
    ...state,
});

const mapDispatchToProps = (dispatch) => ({
    onApproveEntry: (entry) => dispatch(
        exhibitionEntry.approve(entry, exhibitionActions.approveEntryById)
    ),
    onRejectEntry: (entry) => dispatch(
        exhibitionEntry.reject(entry, exhibitionActions.rejectEntryById)
    ),
    onRejectApprovedEntry: (entry) => dispatch(
        exhibitionEntry.reject(entry, exhibitionActions.rejectApprovedEntryById)
    ),
    onApproveRejectedEntry: (entry) => dispatch(
        exhibitionEntry.approve(entry, exhibitionActions.approveRejectedEntryById)
    ),
    onAddNewSurvey: (values, history) => dispatch(survey.add(values, history)),
    getEntries: () => dispatch(exhibitionEntry.getAll()),
    getSurveys: () => dispatch(survey.getAll()),
    onChangeSurveyStatus: (data, status) => dispatch(survey.changeStatus(data, status)),
    onEditSurvey: (values, history, surveyId, surveyStatus) => dispatch(survey.update(values, history, surveyId, surveyStatus)),
    onDeleteSurvey: (id) => dispatch(survey.delete(id)),
    // exhibition subjects
    getSubjects: () => dispatch(exhibitionSubject.getAll()),
    onApproveSubject: (subject) => dispatch(
        exhibitionSubject.approve(subject, exhibitionSubjectActions.approveSubjectById)
    ),
    onRejectSubject: (subject) => dispatch(
        exhibitionSubject.reject(subject, exhibitionSubjectActions.rejectSubjectById)
    ),
    onRejectApprovedSubject: (entry) => dispatch(
        exhibitionSubject.reject(entry, exhibitionSubjectActions.rejectApprovedSubjectById)
    ),
    onApproveRejectedSubject: (entry) => dispatch(
        exhibitionSubject.approve(entry, exhibitionSubjectActions.approveRejectedSubjectById)
    ),
    getAllMapPins: () => dispatch(map.getAll()),
    deletePinById: (id, successCallback) => dispatch(map.deletePinById(id, successCallback)),
    getExhibitionEntryPages: () => dispatch(exhibitionEntry.getPages()),
    getExhibitionSubjectPages: () => dispatch(exhibitionSubject.getPages()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminApp);
