import React from "react";
import PropTypes from "prop-types";
import { HistoryPropType, PagesPropType } from "../../../../customPropTypes";
import PagesOverview, { POSSIBLE_ACTIVE_PAGES } from "../common/PagesOverview";

function ExhibitionEntriesPages({ history, pages, getExhibitionEntryPages }) {
    React.useEffect(() => {
        getExhibitionEntryPages();
    }, [getExhibitionEntryPages]);

    return (
        <PagesOverview history={history} pages={pages} activePage={POSSIBLE_ACTIVE_PAGES.ENTRIES} />
    );
}

export default ExhibitionEntriesPages;

ExhibitionEntriesPages.propTypes = {
    history: HistoryPropType.isRequired,
    getExhibitionEntryPages: PropTypes.func.isRequired,
    pages: PropTypes.shape(),
};

ExhibitionEntriesPages.defaultProps = {
    pages: {},
};
