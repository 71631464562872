import React from "react";
import uuidv1 from "uuid/v1";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import AdminSidebar from "../AdminSidebar";
import AdminSurveyCustomAnswersModal from "./AdminSurveyCustomAnswersModal";
import Content from "../../../content/AdminTextContent";
import { SurveyPropType, HistoryPropType } from "../../../customPropTypes";
import { ANSWER_TYPES } from "../../../helpers/constants";

function AdminSurveyResults({ survey, history }) {
    const [modalShow, setModalShow] = React.useState(false);
    const [somethingElseAnswers, setSomethingElseAnswers] = React.useState([]);
    const textContent = Content.surveys.surveyResults;

    const data = survey.questions.map((question) => {
        const defaultAnswers = [];
        const customAnswers = [];

        question.answers.forEach((answer) => {
            if (answer.type === ANSWER_TYPES.PREDEFINED) defaultAnswers.push(answer);
            if (answer.type === ANSWER_TYPES.CUSTOM) customAnswers.push(answer);
        });

        return {
            text: question.text,
            defaultAnswers,
            customAnswers,
        };
    });
    return (
        survey ? (
            <div>
                <AdminSidebar history={history} />
                <h2>{textContent.bigTitle}</h2>
                <h3 className="admin-exhibition-title">{survey.title}</h3>
                <Table bordered>
                    <thead>
                        <tr>
                            <th>{textContent.question}</th>
                            <th>{textContent.answer}</th>
                            <th>{textContent.numberOfVotes}</th>
                            <th>{textContent.specialAnswersTitle}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.length > 0 && (
                            data.map((question, questionIndex) => (
                                question.defaultAnswers.map((answer, index) => {
                                    const questionOrder = questionIndex + 1;
                                    const questionParity = (questionOrder % 2 === 0) ? "even" : "odd";

                                    return (
                                        <tr
                                            key={uuidv1()}
                                            className={`admin-survey-question-${questionParity}`}
                                        >
                                            {index === 0 && (
                                                <td
                                                    className="admin-survey-table-question"
                                                    rowSpan={question.defaultAnswers.length}
                                                >
                                                    {question.text}
                                                </td>
                                            )}
                                            <td className="admin-survey-table-answer">
                                                {answer.text}
                                            </td>
                                            <td>{answer.votes}</td>
                                            {index === 0 && (
                                                <td
                                                    rowSpan={question.defaultAnswers.length}
                                                >
                                                    {question.customAnswers.length > 0 && (
                                                        <div className="admin-survey-table-something-else">
                                                            {`${question.customAnswers.length} odgovor(a)`}
                                                            <Button onClick={() => {
                                                                setModalShow(true);
                                                                setSomethingElseAnswers(question.customAnswers);
                                                            }}
                                                            >
                                                                {textContent.somethingElseAnswers.showMoreButton}
                                                            </Button>
                                                        </div>
                                                    )}
                                                </td>
                                            )}
                                        </tr>
                                    );
                                })
                            ))
                        )}
                    </tbody>
                </Table>
                <AdminSurveyCustomAnswersModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    answers={somethingElseAnswers}
                    title={textContent.somethingElseAnswers.popup.title}
                />
            </div>
        ) : null
    );
}

export default AdminSurveyResults;

AdminSurveyResults.propTypes = {
    survey: SurveyPropType,
    history: HistoryPropType.isRequired,
};

AdminSurveyResults.defaultProps = {
    survey: null,
};
