import React from "react";

function SVG({
    style = {},
    fill = "#000",
    width = "100%",
    className = "",
    viewBox = "0 0 40 26"
}) {
    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="133.871px"
            height="116.554px"
            viewBox="0 0 133.871 116.554"
            enableBackground="new 0 0 133.871 116.554"
        >
            <switch>
                <g>
                    <g>
                        <rect x="7.358" y="42.716" fill="#241F21" width="15.207" height="1.356" />
                        <rect x="11.535" y="56.468" transform="matrix(0.335 0.9422 -0.9422 0.335 66.5826 29.7546)" fill="#241F21" width="1.356" height="11.154"/>
                        <path fill="#241F21" d="M0.005,103.461c0-1.69,1.225-2.479,2.705-2.479c0.561,0,1.14,0.152,1.652,0.399l-0.418,1.015 c-0.417-0.294-0.863-0.465-1.271-0.465c-0.854,0-1.434,0.428-1.434,1.51c0,1.158,0.627,1.566,1.471,1.566 c0.19,0,0.371-0.048,0.514-0.095v-1.528h1.148v2.078c-0.531,0.285-1.244,0.475-1.861,0.475 C0.992,105.938,0.005,105.131,0.005,103.461"/>
                        <path fill="#241F21" d="M7.278,103.403c0.465,0,0.797-0.209,0.797-0.721c0-0.465-0.209-0.685-0.826-0.685H6.708v1.405H7.278z M5.532,101.05h1.821c1.414,0,1.955,0.627,1.955,1.585c0,0.627-0.294,1.12-0.815,1.386l1.215,1.841H8.36l-0.978-1.594H6.708 v1.594H5.532V101.05z"/>
                        <rect fill="none" width="133.871" height="116.554"/>
                        <path fill="#241F21" d="M12.435,102.188l-0.285,0.941l-0.294,0.834h1.167l-0.294-0.825L12.435,102.188z M10.005,105.862 l1.859-4.812h1.225l1.871,4.812h-1.254l-0.38-1.063h-1.747l-0.369,1.063H10.005z"/>
                        <path fill="#241F21" d="M17.704,104.922c1.121,0,1.349-0.598,1.349-1.414c0-0.864-0.276-1.509-1.435-1.509h-0.739v0.968h0.797 v0.826h-0.797v1.129H17.704z M15.702,103.793h-0.428v-0.826h0.428v-1.918h1.945c1.918,0,2.639,0.959,2.639,2.336 c0,1.396-0.522,2.478-2.647,2.478h-1.937V103.793z"/>
                        <rect fill="none" width="133.871" height="116.554"/>
                        <path fill="#241F21" d="M23.183,102.188l-0.284,0.941l-0.294,0.834h1.166l-0.293-0.825L23.183,102.188z M20.753,105.862 l1.86-4.812h1.226l1.869,4.812h-1.253l-0.38-1.063h-1.747l-0.37,1.063H20.753z"/>
                        <polygon fill="#241F21" points="26.45,101.05 27.655,101.05 29.165,103.042 29.972,104.182 29.962,103.128 29.962,101.05 31.063,101.05 31.063,105.861 29.923,105.861 28.405,103.84 27.562,102.645 27.57,103.812 27.57,105.861 26.45,105.861 "/>
                        <path fill="#241F21" d="M32.071,105.482l0.399-0.987c0.303,0.246,0.911,0.493,1.423,0.493c0.38,0,0.684-0.104,0.684-0.389 c0-0.37-0.437-0.551-0.939-0.75c-0.664-0.275-1.442-0.579-1.442-1.481c0-0.806,0.749-1.385,1.804-1.385 c0.578,0,1.072,0.123,1.623,0.397l-0.343,1.008c-0.256-0.267-0.769-0.456-1.243-0.456c-0.361,0-0.598,0.123-0.598,0.37 c0,0.294,0.37,0.437,0.816,0.607c0.703,0.266,1.557,0.579,1.557,1.604c0,0.901-0.883,1.424-2.003,1.424 C33.277,105.938,32.642,105.814,32.071,105.482"/>
                        <path fill="#241F21" d="M38.243,103.897l-0.219,0.047v1.918h-1.177v-4.813h1.177v1.956l0.295-0.086l1.396-1.87h1.3l-1.794,2.298 c0.218,0.228,1.756,2.288,1.974,2.516h-1.423L38.243,103.897z"/>
                        <path fill="#241F21" d="M45.364,103.461c0-1.092-0.408-1.529-1.319-1.529c-0.884,0-1.291,0.465-1.291,1.558 c0,0.968,0.37,1.509,1.28,1.509C44.937,104.998,45.364,104.486,45.364,103.461 M41.521,103.489c0-1.443,0.93-2.506,2.524-2.506 c1.519,0,2.553,0.75,2.553,2.478c0,1.414-0.948,2.477-2.563,2.477C42.517,105.938,41.521,105.055,41.521,103.489"/>
                        <rect fill="none" width="133.871" height="116.554"/>
                        <polygon fill="#241F21" points="49.866,101.05 51.214,101.05 52.078,103.118 52.724,104.874 53.368,103.1 54.214,101.05 55.476,101.05 55.476,105.861 54.384,105.861 54.384,102.948 54.015,104.067 53.302,105.861 52.087,105.861 51.365,104.135 50.882,102.834 50.882,105.861 49.866,105.861 "/>
                        <path fill="#241F21" d="M56.798,104.144v-3.094h1.168v2.952c0,0.788,0.36,0.996,1.072,0.996c0.693,0,1.073-0.267,1.073-1.016 v-2.933h1.138v3.085c0,1.31-1.109,1.803-2.249,1.803C57.813,105.938,56.798,105.511,56.798,104.144"/>
                        <rect fill="none" width="133.871" height="116.554"/>
                        <polygon fill="#241F21" points="62.22,105.122 63.853,102.996 64.659,101.999 62.372,101.999 62.372,101.05 66.093,101.05 66.093,101.847 64.563,103.831 63.673,104.922 66.15,104.922 66.15,105.861 62.22,105.861 "/>
                        <polygon fill="#241F21" points="67.225,101.05 70.688,101.05 70.688,101.998 68.401,101.998 68.401,102.948 70.384,102.948 70.384,103.878 68.401,103.878 68.401,104.912 70.688,104.912 70.688,105.861 67.225,105.861 "/>
                        <path fill="#241F21" d="M70.994,106.1c0.56-0.123,1.024-0.276,1.024-0.873v-4.178h1.168v4.301c0,0.959-0.739,1.519-1.84,1.708L70.994,106.1z"/>
                        <path fill="#241F21" d="M74.184,105.482l0.398-0.987c0.304,0.246,0.911,0.493,1.425,0.493c0.379,0,0.684-0.104,0.684-0.389 c0-0.37-0.437-0.551-0.94-0.75c-0.664-0.275-1.442-0.579-1.442-1.481c0-0.806,0.749-1.385,1.803-1.385 c0.58,0,1.073,0.123,1.623,0.397l-0.342,1.008c-0.256-0.267-0.77-0.456-1.242-0.456c-0.361,0-0.599,0.123-0.599,0.37 c0,0.294,0.37,0.437,0.815,0.607c0.703,0.266,1.558,0.579,1.558,1.604c0,0.901-0.883,1.424-2.003,1.424 C75.389,105.938,74.754,105.814,74.184,105.482"/>
                        <path fill="#241F21" d="M80.355,103.897l-0.218,0.047v1.918H78.96v-4.813h1.178v1.956l0.294-0.086l1.396-1.87h1.301l-1.795,2.298 c0.219,0.228,1.756,2.288,1.975,2.516h-1.424L80.355,103.897z"/>
                        <path fill="#241F21" d="M87.478,103.461c0-1.092-0.41-1.529-1.32-1.529c-0.883,0-1.291,0.465-1.291,1.558 c0,0.968,0.37,1.509,1.281,1.509C87.05,104.998,87.478,104.486,87.478,103.461 M83.632,103.489c0-1.443,0.93-2.506,2.525-2.506 c1.518,0,2.553,0.75,2.553,2.478c0,1.414-0.949,2.477-2.562,2.477C84.629,105.938,83.632,105.055,83.632,103.489"/>
                        <rect fill="none" width="133.871" height="116.554"/>
                        <polygon fill="#241F21" points="91.333,101.05 92.558,101.05 93.422,103.479 93.782,104.809 94.114,103.489 94.893,101.05 96.116,101.05 94.409,105.861 93.108,105.861 "/>
                        <rect x="96.878" y="101.05" fill="#241F21" width="1.178" height="4.812"/>
                        <path fill="#241F21" d="M98.578,106.1c0.56-0.123,1.024-0.276,1.024-0.873v-4.178h1.168v4.301c0,0.959-0.739,1.519-1.84,1.708 L98.578,106.1z"/>
                        <rect fill="none" width="133.871" height="116.554"/>
                        <polygon fill="#241F21" points="102.13,101.05 105.593,101.05 105.593,101.998 103.306,101.998 103.306,102.948 105.29,102.948 105.29,103.878 103.306,103.878 103.306,104.912 105.593,104.912 105.593,105.861 102.13,105.861 "/>
                        <path fill="#241F21" d="M108.538,100.718l-0.428-0.484l1.301-1.319l0.617,0.759L108.538,100.718z M106.431,103.479 c0-1.575,1.111-2.496,2.63-2.496c0.531,0,1.147,0.142,1.604,0.397l-0.4,0.97c-0.398-0.285-0.863-0.418-1.252-0.418 c-0.826,0-1.348,0.521-1.348,1.527c0,1.017,0.474,1.538,1.356,1.538c0.485,0,0.997-0.189,1.367-0.418l0.313,0.949 c-0.569,0.285-1.195,0.408-1.774,0.408C107.343,105.938,106.431,105.121,106.431,103.479"/>
                        <rect fill="none" width="133.871" height="116.554"/>
                        <polygon fill="#241F21" points="111.7,101.05 115.165,101.05 115.165,101.998 112.878,101.998 112.878,102.948 114.861,102.948 114.861,103.878 112.878,103.878 112.878,104.912 115.165,104.912 115.165,105.861 111.7,105.861 "/>
                        <path fill="#241F21" d="M119.913,103.897l-0.219,0.047v1.918h-1.176v-4.813h1.176v1.956l0.295-0.086l1.396-1.87h1.301 l-1.795,2.298c0.219,0.228,1.756,2.288,1.975,2.516h-1.424L119.913,103.897z"/>
                        <rect fill="none" width="133.871" height="116.554"/>
                        <path fill="#241F21" d="M125.431,102.188l-0.285,0.941l-0.294,0.834h1.167l-0.294-0.825L125.431,102.188z M123.001,105.862 l1.859-4.812h1.225l1.871,4.812h-1.254l-0.379-1.063h-1.748l-0.369,1.063H123.001z"/>
                        <path fill="#241F21" d="M132.229,103.461c0-1.092-0.408-1.529-1.319-1.529c-0.884,0-1.291,0.465-1.291,1.558 c0,0.968,0.368,1.509,1.28,1.509C131.801,104.998,132.229,104.486,132.229,103.461 M128.384,103.489 c0-1.443,0.932-2.506,2.526-2.506c1.519,0,2.552,0.75,2.552,2.478c0,1.414-0.947,2.477-2.562,2.477 C129.381,105.938,128.384,105.055,128.384,103.489"/>
                        <rect fill="none" width="133.871" height="116.554"/>
                        <polygon fill="#241F21" points="4.665,110.545 3.821,112.596 3.176,114.371 2.53,112.614 1.667,110.545 0.318,110.545 0.318,115.357 1.333,115.357 1.333,112.329 1.818,113.63 2.54,115.357 3.753,115.357 4.467,113.564 4.837,112.443 4.837,115.357 5.929,115.357 5.929,110.545 "/>
                        <path fill="#241F21" d="M10.763,112.956c0-1.092-0.408-1.528-1.319-1.528c-0.883,0-1.291,0.466-1.291,1.557 c0,0.968,0.37,1.509,1.281,1.509C10.335,114.493,10.763,113.981,10.763,112.956 M6.917,112.984c0-1.443,0.932-2.506,2.526-2.506 c1.519,0,2.554,0.75,2.554,2.478c0,1.414-0.949,2.478-2.563,2.478C7.915,115.434,6.917,114.551,6.917,112.984"/>
                        <path fill="#241F21" d="M14.987,114.418c1.121,0,1.35-0.598,1.35-1.414c0-0.864-0.275-1.509-1.434-1.509h-0.74v2.923H14.987z M12.985,110.545h1.946c1.917,0,2.64,0.959,2.64,2.335c0,1.396-0.523,2.479-2.648,2.479h-1.938V110.545z"/>
                        <rect fill="none" width="133.871" height="116.554"/>
                        <polygon fill="#241F21" points="18.579,110.545 22.044,110.545 22.044,111.495 19.755,111.495 19.755,112.444 21.739,112.444 21.739,113.374 19.755,113.374 19.755,114.408 22.044,114.408 22.044,115.358 18.579,115.358 "/>
                        <polygon fill="#241F21" points="23.118,110.545 24.295,110.545 24.295,114.408 26.383,114.408 26.383,115.358 23.118,115.358 "/>
                        <path fill="#241F21" d="M28.899,114.978l0.4-0.987c0.303,0.247,0.91,0.494,1.422,0.494c0.381,0,0.685-0.104,0.685-0.39 c0-0.37-0.437-0.55-0.94-0.749c-0.664-0.276-1.443-0.58-1.443-1.481c0-0.807,0.75-1.386,1.805-1.386 c0.579,0,1.072,0.124,1.623,0.398l-0.342,1.007c-0.256-0.266-0.77-0.456-1.243-0.456c-0.361,0-0.599,0.123-0.599,0.37 c0,0.294,0.371,0.437,0.816,0.607c0.702,0.267,1.557,0.579,1.557,1.604c0,0.902-0.883,1.424-2.002,1.424 C30.104,115.434,29.47,115.31,28.899,114.978"/>
                        <path fill="#241F21" d="M33.544,113.64v-3.095h1.167v2.952c0,0.788,0.36,0.997,1.073,0.997c0.692,0,1.071-0.266,1.071-1.016 v-2.934h1.14v3.085c0,1.311-1.11,1.804-2.25,1.804C34.559,115.434,33.544,115.006,33.544,113.64"/>
                        <path fill="#241F21" d="M41.233,114.418c1.12,0,1.348-0.598,1.348-1.414c0-0.864-0.274-1.509-1.433-1.509h-0.74v2.923H41.233z M39.231,110.545h1.945c1.918,0,2.639,0.959,2.639,2.335c0,1.396-0.521,2.479-2.648,2.479h-1.936V110.545z"/>
                        <rect fill="none" width="133.871" height="116.554"/>
                        <rect x="44.824" y="110.545" fill="#241F21" width="1.177" height="4.812"/>
                        <path fill="#241F21" d="M50.824,112.956c0-1.092-0.408-1.528-1.319-1.528c-0.883,0-1.292,0.466-1.292,1.557 c0,0.968,0.371,1.509,1.282,1.509C50.396,114.493,50.824,113.981,50.824,112.956 M46.979,112.984 c0-1.443,0.931-2.506,2.525-2.506c1.518,0,2.553,0.75,2.553,2.478c0,1.414-0.949,2.478-2.562,2.478 C47.976,115.434,46.979,114.551,46.979,112.984"/>
                        <rect fill="none" width="133.871" height="116.554"/>
                        <polygon fill="#241F21" points="53.048,110.545 54.253,110.545 55.763,112.538 56.568,113.678 56.56,112.624 56.56,110.545 57.66,110.545 57.66,115.358 56.521,115.358 55.003,113.336 54.158,112.14 54.167,113.307 54.167,115.358 53.048,115.358 "/>
                        <rect x="58.953" y="110.545" fill="#241F21" width="1.177" height="4.812"/>
                        <path fill="#241F21" d="M62.105,109.103l0.56-0.522l0.949,0.845l0.969-0.845l0.531,0.504l-1.225,1.186h-0.55L62.105,109.103z M61.108,112.975c0-1.575,1.111-2.496,2.629-2.496c0.532,0,1.149,0.144,1.605,0.398l-0.399,0.969 c-0.399-0.285-0.864-0.418-1.253-0.418c-0.826,0-1.349,0.522-1.349,1.528c0,1.016,0.475,1.538,1.358,1.538 c0.484,0,0.996-0.19,1.366-0.418l0.313,0.949c-0.569,0.284-1.195,0.408-1.775,0.408C62.02,115.434,61.108,114.617,61.108,112.975"/>
                        <path fill="#241F21" d="M67.698,113.393l-0.219,0.047v1.918h-1.176v-4.812h1.176v1.956l0.295-0.086l1.395-1.87h1.301 l-1.793,2.297c0.219,0.228,1.754,2.288,1.974,2.516h-1.425L67.698,113.393z"/>
                        <path fill="#241F21" d="M74.743,112.956c0-1.092-0.408-1.528-1.319-1.528c-0.882,0-1.291,0.466-1.291,1.557 c0,0.968,0.37,1.509,1.282,1.509C74.315,114.493,74.743,113.981,74.743,112.956 M70.899,112.984c0-1.443,0.93-2.506,2.524-2.506 c1.519,0,2.554,0.75,2.554,2.478c0,1.414-0.949,2.478-2.562,2.478C71.896,115.434,70.899,114.551,70.899,112.984"/>
                        <path fill="#241F21" d="M76.653,112.956c0-1.688,1.225-2.477,2.705-2.477c0.561,0,1.139,0.15,1.652,0.397l-0.418,1.017 c-0.418-0.294-0.863-0.467-1.272-0.467c-0.854,0-1.433,0.429-1.433,1.511c0,1.157,0.627,1.565,1.471,1.565 c0.189,0,0.371-0.047,0.513-0.095v-1.528h1.148v2.078c-0.532,0.286-1.243,0.476-1.86,0.476 C77.64,115.434,76.653,114.626,76.653,112.956"/>
                        <path fill="#241F21" d="M84.173,113.64v-3.095h1.168v2.952c0,0.788,0.36,0.997,1.072,0.997c0.693,0,1.072-0.266,1.072-1.016 v-2.934h1.141v3.085c0,1.311-1.111,1.804-2.25,1.804C85.188,115.434,84.173,115.006,84.173,113.64"/>
                        <path fill="#241F21" d="M91.655,112.947c0.492,0,0.787-0.209,0.787-0.741c0-0.427-0.17-0.711-0.825-0.711h-0.579v1.452H91.655z M89.861,110.545h1.86c1.414,0,1.955,0.684,1.955,1.642c0,1.025-0.722,1.69-1.994,1.69h-0.645v1.481h-1.177V110.545z"/>
                        <path fill="#241F21" d="M96.27,112.898c0.465,0,0.798-0.208,0.798-0.721c0-0.465-0.209-0.683-0.826-0.683H95.7v1.403H96.27z M94.523,110.545h1.823c1.414,0,1.953,0.627,1.953,1.585c0,0.627-0.293,1.121-0.815,1.386l1.215,1.843h-1.348l-0.978-1.596H95.7 v1.596h-1.177V110.545z"/>
                        <rect fill="none" width="133.871" height="116.554"/>
                        <path fill="#241F21" d="M101.351,111.684l-0.285,0.94l-0.295,0.835h1.168l-0.295-0.825L101.351,111.684z M98.92,115.358 l1.86-4.813h1.225l1.87,4.813h-1.253l-0.379-1.063h-1.748l-0.369,1.063H98.92z"/>
                        <polygon fill="#241F21" points="103.706,110.545 104.931,110.545 105.795,112.975 106.155,114.304 106.487,112.984 107.266,110.545 108.489,110.545 106.782,115.358 105.481,115.358 			"/>
                        <polygon fill="#241F21" points="109.175,110.545 110.352,110.545 110.352,114.408 112.44,114.408 112.44,115.358 109.175,115.358 			"/>
                        <path fill="#241F21" d="M112.518,115.595c0.562-0.123,1.025-0.275,1.025-0.873v-4.177h1.167v4.3c0,0.959-0.74,1.519-1.842,1.709 L112.518,115.595z"/>
                        <rect fill="none" width="133.871" height="116.554"/>
                        <path fill="#241F21" d="M117.806,111.684l-0.285,0.94l-0.294,0.835h1.167l-0.295-0.825L117.806,111.684z M115.376,115.358 l1.859-4.813h1.226l1.87,4.813h-1.254l-0.379-1.063h-1.748l-0.369,1.063H115.376z"/>
                        <polygon fill="#241F21" points="120.997,110.545 122.202,110.545 123.711,112.538 124.518,113.678 124.509,112.624 124.509,110.545 125.61,110.545 125.61,115.358 124.471,115.358 122.952,113.336 122.108,112.14 122.116,113.307 122.116,115.358 120.997,115.358 			"/>
                        <path fill="#241F21" d="M126.058,115.595c0.561-0.123,1.025-0.275,1.025-0.873v-4.177h1.168v4.3c0,0.959-0.74,1.519-1.842,1.709 L126.058,115.595z"/>
                        <rect fill="none" width="133.871" height="116.554"/>
                        <path fill="#241F21" d="M131.062,112.624l0.285-0.939l0.293,0.949l0.295,0.825h-1.168L131.062,112.624z M130.776,110.545 l-1.859,4.812h1.205l0.37-1.063h1.747l0.378,1.063h1.253l-1.869-4.812H130.776z"/>
                        <path fill="#241F21" d="M20.42,1.363h7.619v19.052h-4.433V6.575l-1.323-0.228L17.3,20.415h-5.095L7.188,6.346L5.866,6.575v13.84 H1.433V1.363h7.631l5.873,16.413L20.42,1.363z M19.438,0l-4.545,13.607L10.022,0H0.07v21.777h7.159V10.517l4.015,11.261h7.019 l3.98-11.238v11.238h7.16V0H19.438z"/>
                        <path fill="#241F21" d="M36.302,1.362v9.804c0,3.285,0.817,5.738,2.428,7.291c1.619,1.559,4.222,2.349,7.733,2.349 c3.488,0,6.048-0.783,7.612-2.327c1.562-1.541,2.354-4.002,2.354-7.312V1.362h-4.434v10.39c0,2.011-0.527,3.524-1.564,4.502 c-1.027,0.965-2.385,1.454-4.033,1.454c-1.668,0-3.041-0.488-4.077-1.451c-1.052-0.978-1.585-2.493-1.585-4.505V1.362H36.302z M46.463,22.169c-3.877,0-6.797-0.919-8.678-2.731c-1.89-1.818-2.847-4.602-2.847-8.271V0h7.16v11.752 c0,1.617,0.385,2.797,1.148,3.506c0.788,0.73,1.818,1.087,3.15,1.087c1.309,0,2.322-0.355,3.099-1.085 c0.754-0.708,1.136-1.888,1.136-3.508V0h7.16v11.166c0,3.688-0.928,6.476-2.759,8.282C53.205,21.254,50.321,22.169,46.463,22.169"/>
                        <rect fill="none" width="133.871" height="116.554"/>
                        <path fill="#241F21" d="M64.236,1.363h17.944v2.688L69.458,17.089H82.54v3.326H63.487v-2.817L76.151,4.688H64.236V1.363z M62.873,0v6.051h10.031l-10.78,10.99v4.736h21.778v-6.051h-11.21L83.544,4.605V0H62.873z"/>
                        <path fill="#241F21" d="M90.151,1.363h17.488v3.26H94.585V9.47h10.158v2.806H94.585v4.879h13.707v3.261H90.151V1.363z M88.788,0 v21.777h20.867v-5.986H95.948v-2.153h10.158v-5.53H95.948V5.986h13.055V0H88.788z"/>
                        <path fill="#241F21" d="M115.005,10.721c-0.203,0.698-0.306,1.612-0.306,2.724c0,2.28,0.677,4.036,2.068,5.369 c1.382,1.322,3.489,1.992,6.269,1.992c2.971,0,5.229-0.74,6.719-2.198c1.484-1.456,2.237-3.598,2.237-6.368V1.362h-4.399v10.52 c0,1.894-0.321,3.287-0.98,4.258c-0.708,1.041-1.845,1.568-3.38,1.568s-2.726-0.513-3.543-1.523 c-0.781-0.967-1.177-2.163-1.177-3.554c0-0.545,0.045-1.072,0.135-1.574L115.005,10.721z M123.036,22.169 c-3.142,0-5.568-0.798-7.211-2.371c-1.652-1.581-2.488-3.718-2.488-6.354c0-1.582,0.188-2.798,0.573-3.716l0.193-0.46 l6.269,0.578l-0.229,0.793c-0.177,0.608-0.267,1.278-0.267,1.991c0,1.087,0.285,1.969,0.875,2.698 c0.551,0.684,1.362,1.016,2.481,1.016c1.071,0,1.808-0.318,2.252-0.971c0.494-0.728,0.745-1.902,0.745-3.492V0h7.126v12.239 c0,3.15-0.892,5.62-2.646,7.342C128.956,21.298,126.374,22.169,123.036,22.169"/>
                        <path fill="#241F21" d="M42.085,40.827h5.18c1.662,0,1.891-0.689,1.891-1.435c0-0.423,0-1.304-1.956-1.304h-5.114V40.827z M47.265,42.19h-6.543v-5.465h6.478c2.888,0,3.319,1.671,3.319,2.667C50.519,40.438,50.095,42.19,47.265,42.19 M42.085,48.805 h5.603c0.718,0,1.292-0.149,1.704-0.443c0.22-0.155,0.512-0.442,0.512-1.122c0-0.439-0.154-0.734-0.499-0.956 c-0.429-0.274-1.019-0.414-1.75-0.414h-5.569V48.805z M47.688,50.168h-6.966v-5.661h6.933c0.998,0,1.832,0.212,2.485,0.629 c0.736,0.472,1.127,1.199,1.127,2.104c0,0.954-0.375,1.727-1.084,2.232C49.535,49.934,48.695,50.168,47.688,50.168 M36.289,52.973h12.147c2.613,0,4.547-0.458,5.742-1.36c1.154-0.871,1.716-2.045,1.716-3.591c0-1.198-0.449-2.122-1.375-2.828 c-0.982-0.748-2.373-1.178-4.136-1.277L50.33,42.56c3.153-0.428,4.687-1.729,4.687-3.98c0-1.152,0-4.658-7.492-4.658H36.289 V52.973z M48.437,54.336h-13.51V32.558h12.598c4.04,0,8.855,1.044,8.855,6.021c0,1.56-0.58,3.423-2.938,4.527 c0.723,0.253,1.358,0.587,1.903,1.003c1.27,0.967,1.912,2.283,1.912,3.912c0,1.976-0.758,3.549-2.256,4.679 C53.542,53.801,51.395,54.336,48.437,54.336"/>
                        <path fill="#241F21" d="M61.162,33.92v9.804c0,3.286,0.817,5.738,2.429,7.291c1.618,1.559,4.221,2.349,7.732,2.349 c3.486,0,6.048-0.783,7.613-2.327c1.561-1.541,2.352-4.002,2.352-7.312V33.92h-4.434v10.39c0,2.011-0.526,3.524-1.564,4.502 c-1.027,0.965-2.384,1.454-4.031,1.454c-1.67,0-3.041-0.488-4.078-1.451c-1.053-0.978-1.586-2.493-1.586-4.505V33.92H61.162z M71.323,54.727c-3.877,0-6.797-0.919-8.68-2.731c-1.887-1.817-2.844-4.602-2.844-8.271V32.558h7.158V44.31 c0,1.618,0.387,2.797,1.149,3.506c0.788,0.73,1.819,1.087,3.151,1.087c1.308,0,2.321-0.355,3.098-1.085 c0.754-0.708,1.136-1.888,1.136-3.508V32.558h7.159v11.166c0,3.688-0.928,6.476-2.758,8.282 C78.065,53.812,75.181,54.727,71.323,54.727"/>
                        <path fill="#241F21" d="M92.319,48.609h4.072c1.594,0,2.805-0.408,3.702-1.249c0.872-0.815,1.315-2.116,1.315-3.864 c0-1.763-0.418-3.088-1.241-3.938c-0.829-0.857-1.979-1.274-3.515-1.274h-4.334V48.609z M96.392,49.973h-5.435V36.921h5.696 c1.898,0,3.41,0.568,4.494,1.689c1.077,1.113,1.625,2.757,1.625,4.886c0,2.141-0.589,3.775-1.746,4.859 C99.88,49.429,98.321,49.973,96.392,49.973 M86.524,52.973h10.649c2.923,0,5.387-0.838,7.323-2.49 c1.932-1.648,2.869-3.934,2.869-6.986c0-1.68-0.271-3.16-0.807-4.403c-0.525-1.222-1.267-2.211-2.201-2.939 c-0.959-0.747-2.078-1.31-3.322-1.674c-1.268-0.371-2.709-0.559-4.285-0.559H86.524V52.973z M97.174,54.336H85.161V32.558h11.59 c1.706,0,3.277,0.206,4.668,0.614c1.41,0.412,2.681,1.053,3.777,1.905c1.117,0.872,1.998,2.041,2.615,3.477 c0.609,1.414,0.918,3.077,0.918,4.942c0,3.427-1.125,6.127-3.348,8.022C103.192,53.388,100.43,54.336,97.174,54.336"/>
                        <path fill="#241F21" d="M111.886,33.92v9.804c0,3.286,0.816,5.738,2.428,7.291c1.619,1.559,4.221,2.349,7.732,2.349 c3.488,0,6.049-0.783,7.613-2.327c1.562-1.541,2.354-4.002,2.354-7.312V33.92h-4.434v10.39c0,2.011-0.526,3.524-1.565,4.502 c-1.028,0.965-2.384,1.454-4.032,1.454c-1.669,0-3.041-0.488-4.078-1.451c-1.052-0.978-1.585-2.493-1.585-4.505V33.92H111.886z M122.046,54.727c-3.877,0-6.797-0.919-8.678-2.731c-1.889-1.817-2.846-4.602-2.846-8.271V32.558h7.159V44.31 c0,1.618,0.386,2.797,1.149,3.506c0.787,0.73,1.818,1.087,3.15,1.087c1.309,0,2.322-0.355,3.098-1.085 c0.756-0.708,1.138-1.888,1.138-3.508V32.558h7.159v11.166c0,3.688-0.928,6.476-2.76,8.282 C128.788,53.812,125.905,54.727,122.046,54.727"/>
                        <path fill="#241F21" d="M13.021,66.483c-3.573,0-6.45,0.894-8.55,2.657c-2.092,1.758-3.108,4.128-3.108,7.243 c0,3.143,0.987,5.525,3.019,7.283c2.035,1.758,4.832,2.65,8.314,2.65c2.493,0,4.63-0.501,6.352-1.489 c1.51-0.867,2.689-1.942,3.512-3.201l-2.985-2.068c-0.541,0.86-1.313,1.625-2.303,2.277c-1.257,0.832-2.665,1.253-4.185,1.253 c-2.166,0-3.918-0.609-5.203-1.812c-1.299-1.214-1.959-2.861-1.959-4.894c0-2.009,0.646-3.643,1.922-4.856 c1.266-1.205,2.93-1.816,4.947-1.816c1.651,0,3.088,0.407,4.273,1.208c0.932,0.631,1.667,1.396,2.195,2.282l2.975-2.061 c-0.814-1.264-1.939-2.335-3.352-3.193C17.288,66.977,15.314,66.483,13.021,66.483 M12.696,87.681 c-3.82,0-6.916-1.004-9.205-2.982c-2.316-2.005-3.49-4.802-3.49-8.314c0-3.494,1.209-6.282,3.596-8.286 c2.352-1.976,5.522-2.978,9.425-2.978c2.546,0,4.757,0.56,6.573,1.662c1.81,1.1,3.205,2.522,4.149,4.225l0.3,0.539l-5.322,3.688 l-0.334-0.756c-0.422-0.961-1.123-1.778-2.086-2.43c-0.955-0.646-2.135-0.974-3.508-0.974c-1.679,0-2.99,0.471-4.007,1.44 c-1.009,0.96-1.499,2.226-1.499,3.869c0,1.663,0.5,2.938,1.526,3.898c1.04,0.972,2.437,1.444,4.272,1.444 c1.264,0,2.389-0.336,3.434-1.027c1.037-0.685,1.777-1.498,2.197-2.418l0.34-0.739l5.311,3.681l-0.299,0.538 c-0.945,1.708-2.407,3.139-4.344,4.249C17.796,87.118,15.429,87.681,12.696,87.681"/>
                        <rect fill="none" width="133.871" height="116.554"/>
                        <path fill="#241F21" d="M42.405,85.927h6.822V66.874h-4.27v16.009L34.37,66.874h-6.506v19.053h4.303V69.845L42.405,85.927z M50.591,87.289h-8.936l-8.127-12.766v12.766h-7.027V65.512h8.602l8.492,12.84v-12.84h6.996V87.289z"/>
                        <path fill="#241F21" d="M65.829,70.944c-1.732,0-3.045,0.496-4.016,1.515c-0.98,1.028-1.457,2.333-1.457,3.99 c0,1.61,0.48,2.887,1.467,3.905c0.984,1.011,2.293,1.503,4.006,1.503c1.712,0,3.021-0.492,4.005-1.503 c0.987-1.018,1.467-2.295,1.467-3.905c0-1.657-0.476-2.962-1.456-3.99C68.874,71.44,67.562,70.944,65.829,70.944 M65.829,83.219 c-2.07,0-3.747-0.644-4.982-1.916c-1.23-1.266-1.854-2.898-1.854-4.854c0-1.996,0.617-3.654,1.832-4.931 c1.225-1.285,2.908-1.938,5.004-1.938c2.095,0,3.779,0.652,5.003,1.938c1.215,1.275,1.832,2.935,1.832,4.931 c0,1.955-0.623,3.588-1.853,4.854C69.575,82.575,67.899,83.219,65.829,83.219 M65.829,66.483c-1.536,0-2.996,0.193-4.34,0.576 c-1.328,0.379-2.551,0.964-3.631,1.738c-1.051,0.753-1.898,1.789-2.519,3.078c-0.625,1.301-0.942,2.829-0.942,4.54 c0,1.712,0.317,3.24,0.942,4.541c0.619,1.287,1.47,2.315,2.529,3.059c1.095,0.767,2.319,1.347,3.638,1.726 c1.332,0.382,2.787,0.576,4.322,0.576s2.99-0.194,4.322-0.576c1.318-0.379,2.543-0.959,3.639-1.726 c1.059-0.743,1.908-1.771,2.527-3.059c0.625-1.301,0.942-2.829,0.942-4.541c0-1.711-0.317-3.239-0.942-4.54 c-0.619-1.289-1.467-2.325-2.518-3.078c-1.081-0.774-2.303-1.359-3.632-1.738C68.825,66.677,67.364,66.483,65.829,66.483  M65.829,87.681c-1.663,0-3.243-0.213-4.698-0.63c-1.464-0.42-2.825-1.065-4.045-1.92c-1.252-0.878-2.253-2.084-2.976-3.584 c-0.713-1.486-1.076-3.213-1.076-5.131c0-1.917,0.363-3.644,1.076-5.13c0.722-1.498,1.715-2.708,2.953-3.596 c1.209-0.866,2.571-1.52,4.053-1.941c1.465-0.417,3.051-0.629,4.713-0.629s3.248,0.212,4.712,0.629 c1.481,0.422,2.845,1.075,4.053,1.941c1.239,0.888,2.231,2.098,2.952,3.596c0.715,1.486,1.077,3.213,1.077,5.13 c0,1.918-0.362,3.645-1.077,5.131c-0.721,1.5-1.723,2.706-2.975,3.584c-1.219,0.854-2.58,1.5-4.045,1.92 C69.071,87.468,67.491,87.681,65.829,87.681"/>
                        <path fill="#241F21" d="M81.289,83.791c1.021,0.637,2.312,1.2,3.853,1.677c1.812,0.563,3.836,0.85,6.017,0.85 c3.49,0,6.122-0.585,7.821-1.738c1.64-1.113,2.437-2.515,2.437-4.288c0-1.482-0.575-2.58-1.758-3.353 c-1.291-0.845-3.19-1.473-5.646-1.868l-3.42-0.553c-1.949-0.304-4.545-0.903-4.545-2.595c0-2.31,3.504-2.31,4.654-2.31 c1.581,0,3.131,0.241,4.604,0.717c1.172,0.379,2.168,0.853,2.975,1.413l1.83-2.85c-2.242-1.6-5.327-2.41-9.18-2.41 c-3.125,0-5.539,0.558-7.174,1.659c-1.572,1.057-2.336,2.39-2.336,4.073c0,2.836,2.297,4.618,7.022,5.45l3.774,0.651 c1.428,0.249,2.424,0.49,3.049,0.738c1.127,0.445,1.293,1.211,1.293,1.627c0,0.581-0.246,1.394-1.426,1.965 c-0.795,0.385-1.871,0.571-3.291,0.571c-1.822,0-3.58-0.273-5.229-0.814c-1.357-0.446-2.555-1.001-3.57-1.654L81.289,83.791z  M91.158,87.681c-2.317,0-4.478-0.307-6.421-0.91c-1.949-0.605-3.549-1.35-4.754-2.213l-0.502-0.359l3.133-5.43l0.613,0.454 c1.021,0.756,2.305,1.391,3.812,1.887c1.51,0.496,3.125,0.748,4.803,0.748c1.191,0,2.1-0.147,2.697-0.438 c0.657-0.317,0.657-0.623,0.657-0.737c0-0.125-0.146-0.246-0.433-0.36c-0.529-0.21-1.465-0.432-2.78-0.662l-3.775-0.651 c-5.409-0.951-8.151-3.237-8.151-6.793c0-2.142,0.988-3.893,2.938-5.205c1.865-1.255,4.534-1.891,7.936-1.891 c4.459,0,7.998,1.037,10.521,3.083l0.475,0.384l-3.328,5.183l-0.594-0.518c-0.753-0.652-1.801-1.2-3.117-1.625 c-1.338-0.432-2.746-0.65-4.186-0.65c-2.061,0-3.291,0.354-3.291,0.946c0,0.008,0.038,0.726,3.395,1.248l3.424,0.555 c2.645,0.426,4.722,1.123,6.174,2.071c1.576,1.03,2.376,2.542,2.376,4.494c0,2.229-1.021,4.049-3.033,5.415 C97.815,87.017,94.927,87.681,91.158,87.681"/>
                        <path fill="#241F21" d="M111.503,85.927h4.4V70.2h7.13v-3.326h-18.629V70.2h7.099V85.927z M117.267,87.289h-7.126V71.563h-7.099 v-6.052h21.354v6.052h-7.13V87.289z"/>
                        <path fill="#241F21" d="M127.715,66.875h4.433v19.052h-4.433V66.875z M126.353,87.29h7.16V65.512h-7.16V87.29z"/>			
                        <rect x="11.534" y="56.469" transform="matrix(0.3356 0.942 -0.942 0.3356 66.5604 29.7189)" fill="#241F21" width="1.356" height="11.153"/>
                    </g>
                </g>
            </switch>
        </svg>
    );
}
export default SVG;
