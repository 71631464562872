/* eslint max-len: 0 */
import React from "react";

function SVG() {
    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 317.377 52.389"
            enableBackground="new 0 0 317.377 52.389"
            width="310px"
            height="51.171px"
        >
            <g>
                <path fill="none" stroke="#1B1C20" strokeWidth="2" d="M20.579,31.922h-9.233v-9.859h9.575c1.976,0,3.478,0.35,4.503,1.053 c1.026,0.703,1.539,1.91,1.539,3.621C26.963,30.193,24.835,31.922,20.579,31.922z M11.346,50.504V39.16h9.519 c10.981,0,16.473-4.123,16.473-12.369c0-4.025-1.282-7.039-3.847-9.033c-2.566-1.994-6.698-2.992-12.398-2.992H1.199v35.738H11.346 z" />
                <path fill="none" stroke="#1B1C20" strokeWidth="2" d="M63.385,30.953h-8.664V22.06h8.664c4.371,0,6.556,1.463,6.556,4.389 C69.94,29.451,67.756,30.953,63.385,30.953z M79.118,44.119c-0.835-3.039-1.768-5.168-2.793-6.383 c-0.836-1.027-1.824-1.797-2.964-2.311c-1.14-0.514-2.698-0.807-4.675-0.883c7.867-0.836,11.8-4.027,11.8-9.576 c0-3.42-1.292-5.977-3.876-7.666c-2.584-1.691-6.727-2.535-12.426-2.535H44.575v35.738h10.146V37.451h5.985 c1.634,0,2.945,0.285,3.933,0.855c0.988,0.568,1.721,1.355,2.195,2.363c0.475,1.008,0.855,2.043,1.141,3.107 c0.285,1.062,0.664,2.232,1.14,3.504c0.476,1.275,1.092,2.348,1.853,3.223h10.714C81.682,50.504,79.953,47.16,79.118,44.119z" />
                <polygon fill="none" stroke="#1B1C20" strokeWidth="2" points="123.69,50.504 123.69,42.41 99.693,42.41 99.693,36.254 117.478,36.254 117.478,28.957 99.693,28.957 99.693,22.859 122.551,22.859 122.551,14.765 89.547,14.765 89.547,50.504" />
                <path fill="none" stroke="#1B1C20" strokeWidth="2" d="M150.537,42.865h-8.322V22.402h8.777c3.002,0,5.34,0.865,7.012,2.594 c1.673,1.729,2.509,4.305,2.509,7.725s-0.893,5.967-2.681,7.639C156.047,42.029,153.615,42.865,150.537,42.865z M151.904,50.504 c5.435,0,9.966-1.549,13.595-4.645s5.443-7.477,5.443-13.139c0-3.115-0.504-5.844-1.512-8.182 c-1.006-2.336-2.412-4.207-4.217-5.613c-1.805-1.404-3.875-2.449-6.215-3.135c-2.335-0.684-4.948-1.025-7.835-1.025h-19.096v35.738 H151.904z" />
                <polygon fill="none" stroke="#1B1C20" strokeWidth="2" points="209.247,50.504 209.247,42.125 189.183,42.125 189.183,14.765 179.036,14.765 179.036,50.504" />
                <path fill="none" stroke="#1B1C20" strokeWidth="2" d="M235.296,43.379c-3.309,0-5.928-0.998-7.867-2.992 c-1.938-1.994-2.906-4.551-2.906-7.666c0-3.191,0.961-5.795,2.879-7.811c1.92-2.014,4.551-3.02,7.895-3.02s5.977,1.006,7.895,3.02 c1.92,2.016,2.879,4.617,2.879,7.811c0,3.115-0.969,5.672-2.906,7.666S238.603,43.379,235.296,43.379z M235.296,51.187 c2.811,0,5.441-0.352,7.895-1.055c2.451-0.703,4.693-1.768,6.727-3.191c2.035-1.424,3.639-3.363,4.816-5.814 s1.768-5.271,1.768-8.465c0-3.189-0.59-6.014-1.768-8.465c-1.18-2.451-2.773-4.396-4.789-5.842 c-2.014-1.443-4.256-2.518-6.727-3.221c-2.469-0.703-5.109-1.055-7.922-1.055c-2.814,0-5.453,0.352-7.924,1.055 s-4.711,1.775-6.727,3.221c-2.014,1.443-3.609,3.391-4.789,5.842c-1.178,2.451-1.766,5.273-1.766,8.465 c0,3.193,0.588,6.014,1.766,8.465c1.18,2.451,2.785,4.391,4.818,5.814s4.275,2.488,6.725,3.191 C229.853,50.836,232.481,51.187,235.296,51.187z" />
                <path fill="none" stroke="#1B1C20" strokeWidth="2" d="M286.425,1.199l-7.125,4.39l-7.238-4.39h-4.617l7.639,9.633h8.32 l7.639-9.633H286.425z M297.651,50.504v-8.207h-21.26l20.633-21.146v-6.385h-33.801v8.209h19.209l-20.52,20.918v6.611H297.651z" />
                <rect x="306.032" y="14.765" fill="none" stroke="#1B1C20" strokeWidth="2" width="10.145" height="35.738" />
            </g>
        </svg>
    );
}

export default SVG;
