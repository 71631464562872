const defaultState = {};

export default (state = defaultState, action) => {
    switch (action.type) {
    case "SUBJECT_UPVOTE":
        return {
            ...state,
            [action.payload.id]: action.payload.votes,
        };
    case "SUBJECT_DOWNVOTE":
        return {
            ...state,
            [action.payload.id]: action.payload.votes,
        };
    case "GET_SUBJECT_VOTES":
        return {
            ...state,
            ...action.payload,
        };
    default:
        return state;
    }
};
