import { withFormik } from "formik";
import { stringToDate } from "../../../../helpers/datetime";
import validationSchemas from "../../../../helpers/validation";
import CreateEditFormInner from "../common/CreateEditFormInner";

const handleSubmit = (values, { props, setStatus }) => {
    props.editPage(values, props.pageId, setStatus);
};

export default withFormik({
    mapPropsToValues: (props) => {
        if (props.pageEdited && Object.keys(props.pageEdited).length > 0) {
            const page = props.pageEdited;

            return {
                type: page.type,
                alias: page.alias,
                activeFrom: stringToDate(page.activeFrom),
                activeTo: stringToDate(page.activeTo),
                content: [...page.contents],

            };
        }
        return {
            type: 2,
            alias: "",
            activeFrom: new Date(),
            activeTo: new Date(),
            content: [{
                title: "Main page text",
                text: "",
                tag: "",
            }],
        };
    },
    validationSchema: validationSchemas.addNewExhibitionEntriesPage,
    handleSubmit,
    enableReinitialize: true,
})(CreateEditFormInner);
