import React from "react";
import PropTypes from "prop-types";
import Moment from "react-moment";

function CroatianDateFormat({ date }) {
    return (
        <Moment format="DD.MM.YYYY.">
            {date}
        </Moment>
    );
}

export default CroatianDateFormat;

CroatianDateFormat.propTypes = {
    date: PropTypes.string.isRequired,
};
