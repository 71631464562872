import { connect } from "react-redux";
import CreatePage from "../../../../components/admin/pages/entries/CreatePage";
import { pages, exhibitionEntry } from "../../../../services/requests";
import Copy from "../../../../content/AdminTextContent";

const mapStateToProps = () => ({
    text: Copy.pages.exhibitionEntries.addNew,
});

const mapDispatchToProps = (dispatch) => ({
    getPages: () => dispatch(exhibitionEntry.getPages()),
    onSubmit: (values, setStatus) => dispatch(pages.addNew(values, setStatus)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreatePage);
