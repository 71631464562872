import React from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { withFormik } from "formik";
import validationSchemas from "../../../helpers/validation";
import CustomFormLabel from "../../common/CustomFormLabel";
import FormTooltip from "../FormTooltip";
import SendFormButton from "../SendFormButton";
import GiveUpButton from "../GiveUpButton";

export function ExhibitionSubjectAddNewInner({
    show, onHideCallback, handleSubmit, touched,
    handleChange, values, errors, status, handleBlur, handleReset,
}) {
    const isSubmitButtonDisabled = () => ((Object.keys(touched).length === 0) ||
        (Object.keys(errors).length !== 0) ||
        (status && status.type === "error"));

    const onHideForm = () => {
        onHideCallback();
        handleReset();
    };

    const modalTitle = (status && status.type === "success") ? "Vaš prijedlog je poslan!" : "Predlažem";

    return (
        <Modal show={show} centered onHide={onHideForm} size="lg" dialogClassName="custom-entry-addnew-modal">
            <Modal.Header closeButton>
                <Modal.Title>{modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {status && status.type === "success" ?
                    (
                        <div className="custom-add-entry-success">
                            <div>
                                Hvala na sudjelovanju!
                            </div>
                        </div>
                    ) :
                    (
                        <Form onSubmit={handleSubmit}>
                            <Form.Group as={Row} controlId="formTitle">
                                <CustomFormLabel text="Naslov programa" disclaimer="40 maks. broj znakova" />
                                <Col sm="12" md="9">
                                    <Form.Control
                                        type="text"
                                        name="title"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.title}
                                        isInvalid={touched.title && !!errors.title}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.title}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formDescription">
                                <CustomFormLabel text="Opis programa" disclaimer="400 maks. broj znakova" customStyle={{ top: "-20px" }} />
                                <Col sm="12" md="9">
                                    <Form.Control
                                        as="textarea"
                                        type="text"
                                        name="description"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.description}
                                        isInvalid={touched.description && !!errors.description}
                                        rows="5"
                                    />
                                    <FormTooltip id="description" text="Objasniti zašto predmet predlažete za Civilnu muzejsku zbirku." svgStyle={{ top: "-15px" }} />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.description}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formUserName">
                                <CustomFormLabel text="Vaše ime" />
                                <Col sm="12" md="9">
                                    <Form.Control
                                        type="text"
                                        name="userName"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.userName}
                                        isInvalid={touched.userName && !!errors.userName}
                                    />
                                    <FormTooltip id="username" text="Predlaganje predmeta je anonimno. Vaše ime neće se javno prikazivati uz prijedlog kojeg podnosite." />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.userName}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formUserEmail">
                                <CustomFormLabel text="Vaš e-mail" />
                                <Col sm="12" md="9">
                                    <Form.Control
                                        type="text"
                                        name="userEmail"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.userEmail}
                                        isInvalid={touched.userEmail && !!errors.userEmail}
                                    />
                                    <FormTooltip id="email" text="Vaše osobne podatke prikupljamo isključivo u svrhu stupanja u kontakt s Vama u slučaju da Vaš prijedlog bude među odabranima." />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.userEmail}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formDataProcessingEmail" className="custom-form-checkbox-wrapper">
                                <Form.Check
                                    label="Pristajem da me PPMHP po potrebi kontaktira na uneseni email."
                                    type="checkbox"
                                    id="data-processing-email-checkbox"
                                    name="dataProcessingEmailAgree"
                                    value={values.dataProcessingEmailAgree}
                                    onChange={handleChange}
                                    isInvalid={touched.dataProcessingEmailAgree && !!errors.dataProcessingEmailAgree}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.dataProcessingEmailAgree}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <div className="admin-error-message">
                                {(status && status.type) === "error" && status.message}
                            </div>
                            <div className="custom-form-action-buttons">
                                <SendFormButton disabled={isSubmitButtonDisabled()} />
                                <GiveUpButton onClickCallback={onHideForm} />
                            </div>
                        </Form>
                    )}
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>
    );
}

export const handleSubmit = (values, { props, setStatus }) => {
    props.onSubmit(values, setStatus);
};

export default withFormik({
    mapPropsToValues: () => ({
        title: "",
        description: "",
        userName: "",
        userEmail: "",
        dataProcessingEmailAgree: false,
    }),
    validationSchema: validationSchemas.addNewExhibitionSubject,
    handleSubmit,
})(ExhibitionSubjectAddNewInner);

ExhibitionSubjectAddNewInner.propTypes = {
    show: PropTypes.bool.isRequired,
    onHideCallback: PropTypes.func.isRequired,
    values: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        userName: PropTypes.string,
        userEmail: PropTypes.string,
        exhibitionYear: PropTypes.number,
        dataProcessingEmailAgree: PropTypes.bool,
    }).isRequired,
    errors: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        userName: PropTypes.string,
        userEmail: PropTypes.string,
        dataProcessingEmailAgree: PropTypes.string,
    }).isRequired,
    touched: PropTypes.shape({
        title: PropTypes.bool,
        description: PropTypes.bool,
        userName: PropTypes.bool,
        userEmail: PropTypes.bool,
        dataProcessingEmailAgree: PropTypes.bool,
    }).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleReset: PropTypes.func.isRequired,
    status: PropTypes.shape({
        type: PropTypes.string,
        message: PropTypes.string,
    }),
    handleBlur: PropTypes.func.isRequired,
};

ExhibitionSubjectAddNewInner.defaultProps = {
    status: null,
};
