import { connect } from "react-redux";
import { exhibitionEntry } from "../../../../services/requests";
import ArchivedItems from "../../../../components/admin/pages/entries/ArchivedItems";

const mapStateToProps = (state, ownProps) => {
    const pageId = parseInt(ownProps.match.params.pageId, 10);
    const entries = state.exhibitionEntries.pages.archived && state.exhibitionEntries.pages.archived.find(
        (page) => page.id === pageId
    ).exhibitionEntries;

    return {
        entries,
    };
};

const mapDispatchToProps = (dispatch) => ({
    getExhibitionEntryPages: () => dispatch(exhibitionEntry.getPages()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ArchivedItems);
