import { connect } from "react-redux";
import Homepage from "../../components/website/Homepage";
import { survey } from "../../services/requests";

const mapStateToProps = (state) => ({
    surveyVoting: state.voting.surveys,
    surveys: state.surveys,
    archiveEntriesPages: state.exhibitionEntries.pages.archived,
    archiveSubjectsPages: state.exhibitionSubjects.pages.archived,
});

const mapDispatchToProps = (dispatch) => ({
    getSurveys: () => dispatch(survey.getAll()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Homepage);
