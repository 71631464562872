import React from "react";

function SVG() {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="513.342px" height="111.988px" viewBox="0 0 513.342 111.988" enableBackground="new 0 0 513.342 111.988" className="svg-exhibition-entries-page-title">
            <switch>
                <g>
                    <g>
                        <polygon fill="none" stroke="#1B1C20" strokeWidth="2" points="38.508,50.305 24.941,28.986 38.164,14.565 26.537,14.565 10.92,31.267 10.92,14.565 1,14.565 1,50.305 10.92,50.305 10.92,41.812 17.074,35.257 26.537,50.305"/>
                        <path fill="none" stroke="#1B1C20" strokeWidth="2" d="M62.275,22.546l5.586,14.535H56.518L62.275,22.546z M72.479,50.305 h11.344L68.83,14.565H56.291L41.014,50.305h10.773l2.281-6.441h16.188L72.479,50.305z" />
                        <polygon fill="none" stroke="#1B1C20" strokeWidth="2" points="127.312,50.305 113.746,28.986 126.971,14.565 115.342,14.565 99.725,31.267 99.725,14.565 89.807,14.565 89.807,50.305 99.725,50.305 99.725,41.812 105.881,35.257 115.342,50.305"/>
                        <path fill="none" stroke="#1B1C20" strokeWidth="2" d="M151.252,43.18c-3.307,0-5.928-0.997-7.865-2.992 c-1.939-1.995-2.908-4.551-2.908-7.667c0-3.191,0.959-5.795,2.879-7.809c1.92-2.015,4.551-3.021,7.895-3.021 s5.975,1.007,7.895,3.021c1.92,2.014,2.879,4.617,2.879,7.809c0,3.116-0.969,5.672-2.906,7.667 C157.18,42.183,154.559,43.18,151.252,43.18z M151.252,50.988c2.812,0,5.443-0.352,7.895-1.054 c2.451-0.703,4.693-1.767,6.727-3.191c2.033-1.426,3.639-3.363,4.816-5.815c1.178-2.45,1.768-5.272,1.768-8.464 c0-3.192-0.59-6.013-1.768-8.465c-1.178-2.45-2.773-4.398-4.789-5.843c-2.014-1.443-4.256-2.518-6.725-3.22 c-2.471-0.703-5.111-1.055-7.924-1.055s-5.453,0.352-7.922,1.055c-2.471,0.702-4.713,1.776-6.727,3.22 c-2.014,1.444-3.609,3.393-4.789,5.843c-1.178,2.452-1.766,5.272-1.766,8.465c0,3.191,0.588,6.014,1.766,8.464 c1.18,2.452,2.783,4.39,4.818,5.815c2.033,1.425,4.273,2.488,6.725,3.191C145.809,50.637,148.439,50.988,151.252,50.988z" />
                        <path fill="none" stroke="#1B1C20" strokeWidth="2" d="M215.775,1l-7.125,4.389L201.412,1h-4.617l7.637,9.633h8.322L220.393,1 H215.775z M227.004,50.305v-8.208h-21.26l20.633-21.146v-6.385h-33.801v8.208h19.209l-20.52,20.919v6.612H227.004z" />
                        <polygon fill="none" stroke="#1B1C20" strokeWidth="2" points="269.525,50.305 269.525,42.211 245.529,42.211 245.529,36.055 263.312,36.055 263.312,28.759 245.529,28.759 245.529,22.66 268.387,22.66 268.387,14.565 235.383,14.565 235.383,50.305"/>
                        <polygon fill="none" stroke="#1B1C20" strokeWidth="2" points="308.115,50.305 308.115,41.926 288.051,41.926 288.051,14.565 277.904,14.565 277.904,50.305"/>
                        <rect x="315.924" y="14.565" fill="none" stroke="#1B1C20" strokeWidth="2" width="10.145" height="35.739" />
                        <polygon fill="none" stroke="#1B1C20" strokeWidth="2" points="385.52,50.305 385.52,14.565 370.131,14.565 361.352,40.843 351.947,14.565 336.557,14.565 336.557,50.305 346.703,50.305 346.703,24.883 355.766,50.305 366.367,50.305 375.373,24.883 375.373,50.305"/>
                        <path fill="none" stroke="#1B1C20" strokeWidth="2" d="M414.818,43.18c-3.307,0-5.928-0.997-7.867-2.992 c-1.938-1.995-2.906-4.551-2.906-7.667c0-3.191,0.961-5.795,2.879-7.809c1.92-2.015,4.551-3.021,7.895-3.021 s5.975,1.007,7.895,3.021c1.92,2.014,2.879,4.617,2.879,7.809c0,3.116-0.971,5.672-2.908,7.667S418.123,43.18,414.818,43.18z M414.818,50.988c2.812,0,5.443-0.352,7.895-1.054c2.451-0.703,4.693-1.767,6.727-3.191c2.033-1.426,3.637-3.363,4.816-5.815 c1.178-2.45,1.766-5.272,1.766-8.464c0-3.192-0.588-6.013-1.766-8.465c-1.18-2.45-2.775-4.398-4.789-5.843 c-2.014-1.443-4.256-2.518-6.727-3.22c-2.469-0.703-5.109-1.055-7.922-1.055s-5.453,0.352-7.924,1.055 c-2.469,0.702-4.711,1.776-6.727,3.22c-2.014,1.444-3.609,3.393-4.787,5.843c-1.178,2.452-1.768,5.272-1.768,8.465 c0,3.191,0.59,6.014,1.768,8.464c1.178,2.452,2.783,4.39,4.816,5.815c2.033,1.425,4.275,2.488,6.727,3.191 C409.375,50.637,412.006,50.988,414.818,50.988z" />
                        <path fill="none" stroke="#1B1C20" strokeWidth="2" d="M19.469,102.667h-8.322V82.204h8.779c3.002,0,5.338,0.864,7.01,2.593 s2.508,4.304,2.508,7.724s-0.893,5.966-2.678,7.639C24.979,101.831,22.547,102.667,19.469,102.667z M20.838,110.305 c5.434,0,9.965-1.549,13.594-4.646c3.629-3.098,5.443-7.477,5.443-13.139c0-3.116-0.504-5.843-1.512-8.18 c-1.006-2.337-2.412-4.208-4.217-5.614s-3.875-2.451-6.213-3.135c-2.336-0.684-4.949-1.026-7.838-1.026H1.002v35.739H20.838z" />
                        <path fill="none" stroke="#1B1C20" strokeWidth="2" d="M63.758,82.546l5.586,14.535H58L63.758,82.546z M73.961,110.305h11.342 l-14.99-35.739H57.773l-15.277,35.739H53.27l2.279-6.441h16.189L73.961,110.305z" />
                        <polygon fill="none" stroke="#1B1C20" strokeWidth="2" points="143.898,110.305 143.898,74.565 134.037,74.565 134.037,99.816 117.336,74.565 104.113,74.565 104.113,110.305 114.031,110.305 114.031,85.054 130.105,110.305"/>
                        <path fill="none" stroke="#1B1C20" strokeWidth="2" d="M171.145,82.546l5.586,14.535h-11.344L171.145,82.546z M181.348,110.305 h11.342l-14.99-35.739h-12.541l-15.275,35.739h10.773l2.279-6.441h16.188L181.348,110.305z" />
                        <path fill="none" stroke="#1B1C20" strokeWidth="2" d="M215.148,110.988c6.383,0,11.172-1.083,14.363-3.249 s4.787-4.996,4.787-8.492c0-3.002-1.205-5.292-3.619-6.869c-2.412-1.577-5.861-2.727-10.346-3.448l-5.984-0.969 c-4.637-0.723-6.953-1.843-6.953-3.363c0-1.9,2.316-2.85,6.953-2.85c2.66,0,5.225,0.398,7.695,1.196 c2.469,0.799,4.426,1.824,5.871,3.078l4.502-7.011c-4.217-3.42-10.107-5.13-17.67-5.13c-5.738,0-10.145,1.035-13.223,3.105 c-3.078,2.071-4.617,4.779-4.617,8.123c0,5.586,4.426,9.158,13.281,10.717l6.611,1.14c2.395,0.418,4.096,0.826,5.102,1.225 c1.008,0.399,1.51,0.979,1.51,1.739c0,0.988-0.607,1.776-1.824,2.365c-1.215,0.589-2.963,0.884-5.244,0.884 c-3.078,0-6.004-0.456-8.777-1.368s-5.111-2.071-7.012-3.477l-4.273,7.41c2.014,1.443,4.674,2.679,7.979,3.705 C207.566,110.476,211.195,110.988,215.148,110.988z" />
                        <path fill="none" stroke="#1B1C20" strokeWidth="2" d="M274.027,91.723h-9.234v-9.861h9.576c1.977,0,3.477,0.352,4.504,1.055 c1.025,0.703,1.539,1.91,1.539,3.62C280.412,89.994,278.283,91.723,274.027,91.723z M264.793,110.305V98.962h9.52 c10.982,0,16.473-4.123,16.473-12.369c0-4.028-1.281-7.04-3.848-9.035c-2.564-1.995-6.697-2.992-12.396-2.992h-19.895v35.739 H264.793z" />
                        <path fill="none" stroke="#1B1C20" strokeWidth="2" d="M312.73,82.546l5.586,14.535h-11.344L312.73,82.546z M322.934,110.305 h11.342l-14.99-35.739h-12.541l-15.275,35.739h10.773l2.279-6.441h16.188L322.934,110.305z" />
                        <polygon fill="none" stroke="#1B1C20" strokeWidth="2" points="389.223,110.305 389.223,74.565 373.834,74.565 365.055,100.843 355.65,74.565 340.26,74.565 340.26,110.305 350.406,110.305 350.406,84.883 359.469,110.305 370.072,110.305 379.078,84.883 379.078,110.305"/>
                        <polygon fill="none" stroke="#1B1C20" strokeWidth="2" points="418.977,110.305 418.977,82.773 431.461,82.773 431.461,74.565 396.463,74.565 396.463,82.773 408.889,82.773 408.889,110.305"/>
                        <polygon fill="none" stroke="#1B1C20" strokeWidth="2" points="472.842,110.305 472.842,102.211 448.846,102.211 448.846,96.055 466.629,96.055 466.629,88.759 448.846,88.759 448.846,82.66 471.701,82.66 471.701,74.565 438.699,74.565 438.699,110.305"/>
                        <path fill="none" stroke="#1B1C20" strokeWidth="2" d="M487.49,87.676c0.189-2.014,1.092-3.61,2.707-4.788 s3.582-1.767,5.9-1.767c1.975,0,3.543,0.332,4.701,0.997c1.158,0.666,1.738,1.511,1.738,2.536c0,0.95-0.371,1.71-1.111,2.28 s-2.27,1.387-4.588,2.451c-2.129,0.988-3.525,1.89-4.189,2.707s-0.998,1.929-0.998,3.335c0,0.57,0.133,1.216,0.4,1.938 l0.227,0.628h5.244l0.172-0.97c0.076-0.38,0.229-0.703,0.455-0.969c0.229-0.266,0.57-0.532,1.027-0.798 c0.455-0.267,0.883-0.485,1.281-0.656c0.398-0.17,1.018-0.418,1.854-0.741c0.836-0.322,1.557-0.616,2.166-0.883 c2.584-1.14,4.541-2.404,5.871-3.79c1.33-1.388,1.994-3.05,1.994-4.988c0-2.964-1.443-5.425-4.332-7.382 s-6.84-2.935-11.855-2.935c-5.473,0-9.689,1.292-12.654,3.876s-4.445,5.624-4.445,9.12L487.49,87.676z M500.43,110.305v-8.265 h-10.26v8.265H500.43z" />
                    </g>
                </g>
            </switch>
        </svg>

    );
}

export default SVG;
