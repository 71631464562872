const votingActions = {
    setExhibitionEntries: (id, canVote) => (dispatch) => {
        dispatch({
            type: "EXHIBITION_ENTRY_VOTE",
            payload: { id, canVote },
        });
    },
    setExhibitionSubjects: (id, canVote) => (dispatch) => {
        dispatch({
            type: "EXHIBITION_SUBJECT_VOTE",
            payload: { id, canVote },
        });
    },
    fetchExhibitionEntryVotes: (votes) => (dispatch) => {
        dispatch({
            type: "FETCH_EXHIBITION_ENTRIES_VOTES",
            payload: { ...votes },
        });
    },
    fetchExhibitionSubjectVotes: (votes) => (dispatch) => {
        dispatch({
            type: "FETCH_EXHIBITION_SUBJECTS_VOTES",
            payload: { ...votes },
        });
    },
    fetchSurveyVotes: (votes) => (dispatch) => {
        dispatch({
            type: "FETCH_SURVEY_VOTES",
            payload: { ...votes },
        });
    },
};

export default votingActions;
