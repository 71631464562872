import React from "react";
import PropTypes from "prop-types";
import { HistoryPropType, PagePropType } from "../../../../customPropTypes";
import PageList from "../common/PageList";

function ArchivedSubjectsPages({ history, pages, getExhibitionSubjectPages }) {
    React.useEffect(() => {
        getExhibitionSubjectPages();
    }, [getExhibitionSubjectPages]);

    return (
        <PageList
            history={history}
            pages={pages}
            title="Pregled arhiviranih natječaja za prikupljanje ideja za programe"
            seeUserEntries
            baseLink="/admin/natjecaji/programi"
        />
    );
}

export default ArchivedSubjectsPages;

ArchivedSubjectsPages.propTypes = {
    history: HistoryPropType.isRequired,
    pages: PropTypes.arrayOf(PagePropType).isRequired,
    getExhibitionSubjectPages: PropTypes.func.isRequired,
};
