import React from "react";
import PropTypes from "prop-types";
import ArrowRight from "../../../media/svgs/common/arrow-right";
import RejectButton from "../../common/RejectButton";

function AdminPinInfo({ history, info, deletePinById }) {
    const [currentPinEntryIndex, setCurrentPinEntryIndex] = React.useState(0);

    React.useEffect(() => {
        setCurrentPinEntryIndex(0);
    }, [info]);

    return (
        <div>
            {info[currentPinEntryIndex] && (
                <div>
                    <h4 className="custom-pin-info-heading">{info[currentPinEntryIndex].title}</h4>
                    <RejectButton
                        onRejectCallback={() => deletePinById(info[currentPinEntryIndex].id)}
                        modalTitle={info[currentPinEntryIndex].title}
                        modalText="Jeste li sigurni da želite pobrisati ovaj doživljaj?"
                        buttonText="Pobriši"
                        executeButtonLabel="Pobriši"
                    />
                    {info.length > 1 && (currentPinEntryIndex === info.length - 1) && (
                        <button
                            type="button"
                            onClick={() => setCurrentPinEntryIndex(currentPinEntryIndex - 1)}
                            className="no-style-button"
                        >
                            Prethodni pin
                        </button>
                    )}
                    {info.length > 1 && (currentPinEntryIndex < info.length - 1) && (
                        <button
                            type="button"
                            onClick={() => setCurrentPinEntryIndex(currentPinEntryIndex + 1)}
                            className="no-style-button"
                        >
                            <ArrowRight />
                        </button>
                    )}
                    <img className="custom-pin-info-img" alt={info[currentPinEntryIndex].title} src={info[currentPinEntryIndex].imageUrl} />
                    <p className="custom-pin-info-description">{info[currentPinEntryIndex].description}</p>
                </div>
            )}
        </div>
    );
}

export default AdminPinInfo;

AdminPinInfo.propTypes = {
    info: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        imageUrl: PropTypes.string,
    })).isRequired,
};
