import React from "react";
import PropTypes from "prop-types";
import uuidv1 from "uuid/v1";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { withFormik } from "formik";
import { SurveyPropType } from "../../../customPropTypes";
import SVGsend from "../../../media/svgs/survey/send";
import SVGsendDisabled from "../../../media/svgs/survey/send-disabled";
import SVGsendHover from "../../../media/svgs/survey/send-hover";

// move this out
import { survey } from "../../../services/requests";

export function Survey({
    data, values, handleSubmit, handleChange, setFieldValue, status, canVoteSurvey,
    onNextQuestionClicked, onPreviousQuestionClicked,
    surveyIndex,
}) {
    const [activeQuestionIndex, setActiveQuestionIndex] = React.useState(0);
    const [customAnswersArray, setCustomAnswersArray] = React.useState(data.questions.map(() => ""));
    const [isSendButtonHovering, setIsSendButtonHovering] = React.useState(false);

    function showNextQuestion() {
        if ((activeQuestionIndex + 1) < data.questions.length) {
            const newQuestionIndex = activeQuestionIndex + 1;
            setActiveQuestionIndex(newQuestionIndex);
            onNextQuestionClicked();
        }
    }

    function allowShowingNextQuestion() {
        return values.answers[activeQuestionIndex].answer !== "";
    }

    function showPreviousQuestion() {
        const newQuestionIndex = activeQuestionIndex - 1;
        setActiveQuestionIndex(newQuestionIndex);
        onPreviousQuestionClicked();
    }

    function getPredefinedAnswers(answers) {
        return answers.reduce((accum, current) => {
            if (current.type === 1) {
                return [...accum, current];
            }
            return accum;
        }, []);
    }

    function storeCustomAnswer(questionIndex, value) {
        const newArray = [...customAnswersArray];
        newArray[questionIndex] = value;

        setCustomAnswersArray(newArray);
    }

    const hasVoted = canVoteSurvey === "no" || (status && status.type === "success");
    const numberOfQuestions = data.questions.length;

    const thankYouNoteTextLines = ["Hvala na ispunjavanju ankete!", "Svojim ste odgovorima unaprijedili izložbu Građanskog muzejskog vijeća naziva “(Ne)vidljive granice”.", "Izložbom se propituju ograničenja (manje ili više vidljiva) s kojima se naše udruge - članice Vijeća - susreću u radu.", "Otvorenje izložbe je 15. 1. 2020. u Pomorskom i povijesnom muzeju Hrvatskog primorja Rijeka.", "Zabilježite datum i vidimo se!"];

    return (
        <div className="custom-homepage-survey-wrapper">
            {hasVoted ? (
                <div className="custom-survey-thankyou">{thankYouNoteTextLines.map((line) => <p key={uuidv1()}>{line}</p>)}</div>
            ) : (
                <Form onSubmit={handleSubmit}>
                    <Form.Group as={Row}>
                        <p>{data.questions[activeQuestionIndex].text}</p>
                        {getPredefinedAnswers(data.questions[activeQuestionIndex].answers).map((answer, index) => (
                            <Col sm="12" key={uuidv1()}>
                                <Form.Check
                                    custom
                                    type="radio"
                                    id={`survey-${surveyIndex}-question-${activeQuestionIndex + 1}-answer-${index + 1}`}
                                    name={`answers[${activeQuestionIndex}].answer`}
                                    label={answer.text}
                                    onChange={(e) => {
                                        handleChange(e);
                                        setFieldValue(`answers[${activeQuestionIndex}].isCustomAnswer`, false, false);
                                    }}
                                    checked={values.answers[activeQuestionIndex].answer === answer.text}
                                    value={answer.text}
                                />
                            </Col>
                        ))}
                    </Form.Group>
                    <Form.Group style={{ marginTop: "-15px" }}>
                        {data.questions[activeQuestionIndex].allowCustomAnswer && (
                            <div>
                                <Form.Check
                                    custom
                                    type="radio"
                                    id={`survey-${surveyIndex}-question-${activeQuestionIndex + 1}-answer-${numberOfQuestions}`}
                                    name={`answers[${activeQuestionIndex}].answer`}
                                    label=""
                                    value={customAnswersArray[activeQuestionIndex]}
                                    checked={values.answers[activeQuestionIndex].answer !== "" && values.answers[activeQuestionIndex].answer === customAnswersArray[activeQuestionIndex]}
                                    onChange={() => {}}
                                />
                                <Col sm="12" style={{ paddingLeft: "0" }}>
                                    <Form.Control
                                        className="custom-input-material"
                                        key={`custom-answer-${activeQuestionIndex}`}
                                        type="text"
                                        value={customAnswersArray[activeQuestionIndex]}
                                        onChange={(e) => {
                                            setFieldValue(`answers[${activeQuestionIndex}].answer`, e.target.value, false);
                                            setFieldValue(`answers[${activeQuestionIndex}].isCustomAnswer`, true, false);
                                            storeCustomAnswer(activeQuestionIndex, e.target.value);
                                        }}
                                    />
                                </Col>
                            </div>
                        )}
                    </Form.Group>
                    <div className="custom-survey-last-question-actions">
                        {activeQuestionIndex > 0 && (
                            <button
                                type="button"
                                onClick={showPreviousQuestion}
                                className="no-style-button"
                            >
                                &#60; Prethodno pitanje
                            </button>
                        )}
                        {(activeQuestionIndex < data.questions.length - 1) && (
                            <div style={{ marginLeft: "auto" }}>
                                <button
                                    type="button"
                                    onClick={showNextQuestion}
                                    disabled={!allowShowingNextQuestion()}
                                    className="no-style-button"
                                >
                                    Iduće pitanje &gt;
                                </button>
                            </div>
                        )}
                        {(activeQuestionIndex === data.questions.length - 1) && (
                            values.answers.every((answer) => answer.answer !== "") ? (
                                <div style={{ paddingBottom: "5px" }}>
                                    <button
                                        type="submit"
                                        onClick={handleSubmit}
                                        className="no-style-button"
                                        onMouseEnter={() => setIsSendButtonHovering(true)}
                                        onMouseLeave={() => setIsSendButtonHovering(false)}
                                    >
                                        {isSendButtonHovering ? <SVGsendHover /> : <SVGsend />}
                                    </button>
                                </div>
                            ) : (
                                <div style={{ paddingBottom: "5px" }}>
                                    <button
                                        disabled
                                        type="submit"
                                        onClick={handleSubmit}
                                        className="no-style-button"
                                    >
                                        <SVGsendDisabled />
                                    </button>
                                </div>
                            )
                        )}
                    </div>
                </Form>
            )}
        </div>
    );
}

export const handleSubmit = (values, { props, setStatus }) => {
    survey.vote(props.data.id, values.answers, setStatus);
};

// export default Survey;
export default withFormik({
    mapPropsToValues: (props) => ({
        answers: props.data.questions.map((question) => ({
            question: question.text,
            answer: "",
            isCustomAnswer: false,
        })),
    }),
    // validationSchema: validationSchemas.addNewExhibitionEntry,
    handleSubmit,
})(Survey);

Survey.propTypes = {
    data: SurveyPropType.isRequired,
    values: PropTypes.shape({
        answers: PropTypes.arrayOf(PropTypes.shape({
            question: PropTypes.string,
            answer: PropTypes.string,
            isCustomAnswer: PropTypes.bool,
        })),
    }).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    onNextQuestionClicked: PropTypes.func.isRequired,
    onPreviousQuestionClicked: PropTypes.func.isRequired,
};
