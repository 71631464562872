import React from "react";
import PropTypes from "prop-types";
import SVGgiveUp from "../../media/svgs/exhibition-entries/give-up";
import SVGgiveUpHover from "../../media/svgs/exhibition-entries/give-up-hover";

function GiveUpButton({ onClickCallback }) {
    const [isButtonHovering, setIsButtonHovering] = React.useState(false);

    return (
        <div>
            <button
                type="button"
                onClick={onClickCallback}
                className="no-style-button custom-form-svg-button"
                onMouseEnter={() => setIsButtonHovering(true)}
                onMouseLeave={() => setIsButtonHovering(false)}
            >
                {isButtonHovering ? <SVGgiveUpHover /> : <SVGgiveUp />}
            </button>
        </div>
    );
}

export default GiveUpButton;

GiveUpButton.propTypes = {
    onClickCallback: PropTypes.func.isRequired,
};

GiveUpButton.defaultProps = {
};
