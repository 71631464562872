import { connect } from "react-redux";
import { exhibitionEntry } from "../../services/requests";
import ExhibitionEntry from "../../components/website/exhibition-entries/ExhibitionEntry";

const mapStateToProps = (state, ownProps) => {
    const entryId = parseInt(ownProps.match.params.id, 10);
    const currentEntry = state.exhibitionEntries.approved.find((current) => current.id === entryId);

    return {
        entry: currentEntry,
        canVote: state.voting.exhibitionEntries[entryId],
    };
};

const mapDispatchToProps = (dispatch) => ({
    voteEntry: (id) => dispatch(exhibitionEntry.vote(id)),
    unvoteEntry: (id) => dispatch(exhibitionEntry.unvote(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExhibitionEntry);
