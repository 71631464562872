import React from "react";
import uuidv1 from "uuid/v1";
import PropTypes from "prop-types";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import SVGarrowDown from "../../media/svgs/common/arrow-down";
import SVGarrowUp from "../../media/svgs/common/arrow-up";

function TextAccordion({ opened, data, wrapperClassName }) {
    const [accordionCollapsed, setAccordionCollapsed] = React.useState(false);
    const defaultActiveKey = opened ? "1" : null;

    return (
        <Accordion className={wrapperClassName} defaultActiveKey={defaultActiveKey}>
            <Card>
                <Accordion.Toggle
                    as={Card.Header}
                    eventKey="1"
                    onClick={() => setAccordionCollapsed(!accordionCollapsed)}
                >
                    <span>{data.title}</span>
                    <span>{accordionCollapsed ? <SVGarrowUp /> : <SVGarrowDown />}</span>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="1">
                    <Card.Body>
                        {data.text.map((paragraph) => (
                            <div key={uuidv1()}>{paragraph}</div>
                        ))}
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    );
}

export default TextAccordion;

TextAccordion.propTypes = {
    data: PropTypes.shape({
        title: PropTypes.string,
        text: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
    wrapperClassName: PropTypes.string,
    opened: PropTypes.bool,
};

TextAccordion.defaultProps = {
    wrapperClassName: "custom-exhibition-accordion",
    opened: false,
};
