import React from "react";
import PropTypes from "prop-types";
import uuidv1 from "uuid/v1";
import Table from "react-bootstrap/Table";
import { ExhibitionEntryPropType } from "../../../customPropTypes";
import Content from "../../../content/AdminTextContent";
import MediaModal from "../../common/MediaModal";
import CroatianDateFormat from "../../common/CroatianDateFormat";
import { checkImageURL, checkVideoURL, checkAudioURL } from "../../../helpers/files";
import RejectButton from "../../common/RejectButton";
import ApproveButton from "./ApproveButton";

function AdminExhibitionEntriesList({
    data, showApproveButton, showRejectButton, onApproveClicked, onRejectClicked,
}) {
    const { approvedEntries } = Content.exhibitionEntries;
    const [contentModalShow, setContentModalShow] = React.useState(false);
    const [contentModalUrl, setContentModalUrl] = React.useState("");

    return (
        <div>
            <Table striped bordered>
                <thead>
                    <tr>
                        <th>{approvedEntries.orderTitle}</th>
                        <th>{approvedEntries.dateTitle}</th>
                        <th>{approvedEntries.usernameTitle}</th>
                        <th>{approvedEntries.entryTitleTitle}</th>
                        <th>{approvedEntries.descriptionTitle}</th>
                        <th>{approvedEntries.fileTitle}</th>
                        <th>{approvedEntries.actionTitle}</th>
                        <th>{approvedEntries.votesTitle}</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((entry, index) => (
                        <tr key={uuidv1()}>
                            <td>{index + 1}</td>
                            <td><CroatianDateFormat date={entry.created} /></td>
                            <td>{entry.userName}</td>
                            <td>{entry.title}</td>
                            <td style={{ maxWidth: "420px", wordBreak: "break-all" }}>{entry.description}</td>
                            <td>
                                <button
                                    type="button"
                                    className="no-style-button"
                                    onClick={() => {
                                        setContentModalShow(true);
                                        setContentModalUrl(entry.contentUrl);
                                    }}
                                >
                                    {checkImageURL(entry.contentUrl) && <img className="admin-exhibition-entries-list-content" src={entry.contentUrl} alt="" />}
                                    {checkVideoURL(entry.contentUrl) && (
                                        <video controls className="admin-exhibition-entries-list-content">
                                            <source src={entry.contentUrl} type="video/mp4" />
                                            <source src={entry.contentUrl} type="video/webm" />
                                            <track default kind="captions" />
                                        </video>
                                    )}
                                    {checkAudioURL(entry.contentUrl) && (
                                        <audio controls className="admin-exhibition-entries-list-content">
                                            <source src={entry.contentUrl} />
                                            <track default kind="captions" />
                                            Your browser does not support the audio element.
                                        </audio>
                                    )}
                                </button>
                            </td>
                            <td>
                                {showApproveButton ? (
                                    <ApproveButton
                                        onApproveCallbabck={() => onApproveClicked(entry)}
                                        entryTitle={entry.title}
                                    />
                                ) : null}
                                {showRejectButton ? (
                                    <RejectButton
                                        modalText="Jeste li sigurni da želite promijeniti status na &quot;ODBIJEN&quot;?"
                                        buttonText="Odbij"
                                        executeButtonLabel="Promijeni"
                                        onRejectCallback={() => onRejectClicked(entry)}
                                        modalTitle={entry.title}
                                    />
                                ) : null}
                            </td>
                            <td>{entry.votes}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <MediaModal
                show={contentModalShow}
                contentUrl={contentModalUrl}
                onHide={() => setContentModalShow(false)}
            />
        </div>
    );
}

export default AdminExhibitionEntriesList;

AdminExhibitionEntriesList.propTypes = {
    data: PropTypes.arrayOf(ExhibitionEntryPropType).isRequired,
    showApproveButton: PropTypes.bool,
    showRejectButton: PropTypes.bool,
    onApproveClicked: PropTypes.func,
    onRejectClicked: PropTypes.func,
};

AdminExhibitionEntriesList.defaultProps = {
    showApproveButton: false,
    showRejectButton: false,
    onApproveClicked: () => {},
    onRejectClicked: () => {},
};
