import React from "react";

function SVG() {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="403.675px" height="39.107px" viewBox="0 0 403.675 39.107" enableBackground="new 0 0 403.675 39.107">
            <switch>
                <g>
                    <g>
                        <path fill="none" stroke="#1B1C20" strokeWidth="2" d="M22.204,30.299c-3.307,0-5.928-0.998-7.866-2.994 c-1.938-1.994-2.907-4.549-2.907-7.666c0-3.191,0.96-5.795,2.878-7.809c1.921-2.014,4.552-3.021,7.896-3.021 s5.976,1.008,7.895,3.021s2.878,4.617,2.878,7.809c0,3.117-0.969,5.672-2.907,7.666C28.132,29.301,25.51,30.299,22.204,30.299z  M22.204,38.107c2.812,0,5.443-0.352,7.895-1.055s4.693-1.768,6.725-3.191c2.035-1.426,3.64-3.363,4.817-5.814 c1.179-2.451,1.767-5.273,1.767-8.465s-0.588-6.014-1.767-8.465c-1.178-2.451-2.774-4.398-4.788-5.842s-4.256-2.518-6.726-3.221 S25.016,1,22.204,1c-2.812,0-5.453,0.352-7.924,1.055c-2.469,0.703-4.711,1.777-6.726,3.221c-2.014,1.443-3.61,3.391-4.788,5.842 S1,16.391,1,19.582s0.589,6.014,1.767,8.465s2.783,4.389,4.817,5.814c2.032,1.424,4.274,2.488,6.725,3.191 C16.761,37.756,19.392,38.107,22.204,38.107z" />
                        <path fill="none" stroke="#1B1C20" strokeWidth="2" d="M83.706,18.842h-9.234V8.98h9.576c1.977,0,3.477,0.352,4.503,1.055 s1.539,1.91,1.539,3.619C90.09,17.113,87.962,18.842,83.706,18.842z M74.472,37.424V26.08h9.52 c10.982,0,16.473-4.123,16.473-12.369c0-4.027-1.281-7.039-3.847-9.035c-2.566-1.994-6.698-2.992-12.398-2.992H64.326v35.74 H74.472z" />
                        <path fill="none" stroke="#1B1C20" strokeWidth="2" d="M126.513,17.873h-8.664V8.98h8.664c4.369,0,6.555,1.463,6.555,4.389 C133.067,16.371,130.882,17.873,126.513,17.873z M144.81,37.424c-0.875-1.217-1.729-3.344-2.565-6.385 c-0.836-3.039-1.767-5.168-2.793-6.383c-0.836-1.027-1.824-1.797-2.964-2.311c-1.141-0.512-2.698-0.807-4.674-0.883 c7.865-0.836,11.799-4.027,11.799-9.576c0-3.42-1.292-5.975-3.876-7.666s-6.726-2.537-12.427-2.537h-19.607v35.74h10.146V24.371 h5.984c1.635,0,2.945,0.285,3.934,0.854c0.988,0.57,1.719,1.359,2.195,2.365c0.473,1.008,0.854,2.043,1.139,3.107 c0.285,1.064,0.666,2.232,1.141,3.506s1.092,2.346,1.852,3.221H144.81z" />
                        <path fill="none" stroke="#1B1C20" strokeWidth="2" d="M171.028,30.299c-3.306,0-5.928-0.998-7.865-2.994 c-1.938-1.994-2.907-4.549-2.907-7.666c0-3.191,0.959-5.795,2.878-7.809c1.92-2.014,4.551-3.021,7.895-3.021 s5.976,1.008,7.896,3.021c1.919,2.014,2.878,4.617,2.878,7.809c0,3.117-0.969,5.672-2.907,7.666 C176.956,29.301,174.335,30.299,171.028,30.299z M171.028,38.107c2.812,0,5.443-0.352,7.896-1.055 c2.45-0.703,4.692-1.768,6.726-3.191c2.033-1.426,3.639-3.363,4.816-5.814s1.768-5.273,1.768-8.465s-0.59-6.014-1.768-8.465 s-2.773-4.398-4.788-5.842s-4.256-2.518-6.726-3.221C176.481,1.352,173.841,1,171.028,1c-2.812,0-5.453,0.352-7.923,1.055 s-4.712,1.777-6.726,3.221s-3.61,3.391-4.788,5.842c-1.179,2.451-1.767,5.273-1.767,8.465s0.588,6.014,1.767,8.465 c1.178,2.451,2.782,4.389,4.817,5.814c2.033,1.424,4.273,2.488,6.725,3.191C165.586,37.756,168.217,38.107,171.028,38.107z" />
                        <path fill="none" stroke="#1B1C20" strokeWidth="2" d="M214.007,38.107c5.547,0,9.746-1.396,12.597-4.189 c2.85-2.793,4.274-6.793,4.274-11.998V1.684h-10.089v19.609c0,3.039-0.503,5.301-1.51,6.783c-1.007,1.48-2.651,2.223-4.931,2.223 c-2.318,0-4.075-0.742-5.272-2.223c-1.196-1.482-1.796-3.307-1.796-5.473c0-1.367,0.171-2.641,0.514-3.818l-8.664-0.799 c-0.608,1.443-0.912,3.459-0.912,6.043c0,4.293,1.33,7.713,3.99,10.26C204.867,36.834,208.8,38.107,214.007,38.107z" />
                        <polygon fill="none" stroke="#1B1C20" strokeWidth="2" points="274.54,37.424 274.54,29.33 250.542,29.33 250.542,23.174 268.327,23.174 268.327,15.877 250.542,15.877 250.542,9.779 273.399,9.779 273.399,1.684 240.396,1.684 240.396,37.424 "/>
                        <polygon fill="none" stroke="#1B1C20" strokeWidth="2" points="320.424,37.424 306.858,16.105 320.082,1.684 308.454,1.684 292.836,18.385 292.836,1.684 282.918,1.684 282.918,37.424 292.836,37.424 292.836,28.93 298.991,22.375 308.454,37.424 "/>
                        <polygon fill="none" stroke="#1B1C20" strokeWidth="2" points="346.302,37.424 346.302,9.893 358.785,9.893 358.785,1.684 323.787,1.684 323.787,9.893 336.213,9.893 336.213,37.424 "/>
                        <path fill="none" stroke="#1B1C20" strokeWidth="2" d="M384.036,38.107c6.459,0,11.18-1.473,14.163-4.418 s4.476-7.494,4.476-13.65V1.684h-10.146v19.381c0,3.191-0.789,5.529-2.365,7.012c-1.578,1.48-3.658,2.223-6.242,2.223 c-2.621,0-4.73-0.742-6.326-2.223c-1.596-1.482-2.395-3.82-2.395-7.012V1.684h-10.146v18.355c0,6.117,1.538,10.658,4.616,13.623 C372.749,36.625,377.538,38.107,384.036,38.107z" />
                    </g>
                </g>
            </switch>
        </svg>

    );
}

export default SVG;
