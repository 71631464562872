import React from "react";
import PropTypes from "prop-types";
import uuidv1 from "uuid/v1";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import Content from "../../../content/AdminTextContent";
import { SomethingElseAnswersPropType } from "../../../customPropTypes";

function AdminSurveyCustomAnswersModal({
    show, onHide, title, answers,
}) {
    const popupContent = Content.surveys.surveyResults.somethingElseAnswers.popup;

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            centered
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table bordered>
                    <thead>
                        <tr>
                            <td>{popupContent.answer}</td>
                            <td>{popupContent.numberOfVotes}</td>
                        </tr>
                    </thead>
                    <tbody>
                        {answers.map((answer) => (
                            <tr key={uuidv1()}>
                                <td>{answer.text}</td>
                                <td>{answer.votes}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Modal.Body>
        </Modal>
    );
}

export default AdminSurveyCustomAnswersModal;

AdminSurveyCustomAnswersModal.propTypes = {
    answers: SomethingElseAnswersPropType,
    show: PropTypes.bool,
    onHide: PropTypes.func,
    title: PropTypes.string.isRequired,
};

AdminSurveyCustomAnswersModal.defaultProps = {
    answers: [],
    show: false,
    onHide: () => {},
};
