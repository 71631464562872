const exhibitionSubjectActions = {
    approveSubjectById: (id) => (dispatch) => {
        dispatch({
            type: "APPROVE_SUBJECT",
            payload: { id },
        });
    },
    approveRejectedSubjectById: (id) => (dispatch) => {
        dispatch({
            type: "APPROVE_REJECTED_SUBJECT",
            payload: { id },
        });
    },
    rejectSubjectById: (id) => (dispatch) => {
        dispatch({
            type: "REJECT_SUBJECT",
            payload: { id },
        });
    },
    rejectApprovedSubjectById: (id) => (dispatch) => {
        dispatch({
            type: "REJECT_APPROVED_SUBJECT",
            payload: { id },
        });
    },
    fetchSubjectsSuccess: (subjects) => (dispatch) => {
        dispatch({
            type: "FETCH_SUBJECTS",
            payload: [...subjects],
        });
    },
    fetchApprovedSubjectsSuccess: (subjects) => (dispatch) => {
        dispatch({
            type: "FETCH_APPROVED_SUBJECTS",
            payload: [...subjects],
        });
    },
    sortSubjects: (subjects) => (dispatch) => {
        dispatch({
            type: "SORT_SUBJECTS",
            payload: subjects,
        });
    },
    fetchPagesSuccess: (pages) => (dispatch) => {
        dispatch({
            type: "FETCH_SUBJECT_PAGES",
            payload: pages,
        });
    },
    updatePage: (page) => (dispatch) => {
        dispatch({
            type: "UPDATE_SUBJECTS_PAGE",
            payload: page,
        });
    },
};

export default exhibitionSubjectActions;
