import { connect } from "react-redux";
import { exhibitionEntry } from "../../../../services/requests";
import ArchivedEntriesPages from "../../../../components/admin/pages/entries/Archived";

const mapStateToProps = (state) => ({
    pages: state.exhibitionEntries.pages.archived,
});

const mapDispatchToProps = (dispatch) => ({
    getExhibitionEntryPages: () => dispatch(exhibitionEntry.getPages()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ArchivedEntriesPages);
