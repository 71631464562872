import React from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";

function GoBackButton({ onClickCallback }) {
    return (
        <Button
            type="button"
            className="no-style-button"
            onClick={onClickCallback}
            style={{
                position: "absolute", color: "black", fontSize: "25px", left: "90px", top: "10px",
            }}
        >
            <i className="fas fa-long-arrow-alt-left" />
        </Button>
    );
}

export default GoBackButton;

GoBackButton.propTypes = {
    onClickCallback: PropTypes.func.isRequired,
};
