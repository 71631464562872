import { connect } from "react-redux";
import { exhibitionSubject } from "../../../../services/requests";
import ExhibitionSubjectsPages from "../../../../components/admin/pages/subjects/Overview";

const mapStateToProps = (state) => ({
    pages: state.exhibitionSubjects.pages,
});

const mapDispatchToProps = (dispatch) => ({
    getExhibitionSubjectPages: () => dispatch(exhibitionSubject.getPages()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExhibitionSubjectsPages);
