import React from "react";
import uuidv1 from "uuid/v1";
import PropTypes from "prop-types";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ExhibitionEntryCard from "./ExhibitionEntryCard";
import ExhibitionAddNewButton from "../common/ExhibitionAddNewButton";

function OneColumnList({
    items, onVoteEntry, onUnvoteEntry, handleShow, isPageActive,
}) {
    return (
        <Row className="justify-content-between">
            <Col className="custom-exhibition-list-column" lg="5">
                {items.map((entry, index) => (
                    <div key={uuidv1()} className="custom-exhibition-column-wrapper">
                        <ExhibitionEntryCard
                            title={entry.title}
                            description={entry.description}
                            contentUrl={entry.contentUrl}
                            onVoteEntry={onVoteEntry}
                            onUnvoteEntry={onUnvoteEntry}
                            id={entry.id}
                            isPageActive={isPageActive}
                        />
                        {isPageActive && index % 2 === 0 && <ExhibitionAddNewButton onClickCallback={handleShow} /> } 
                    </div>
                ))}
            </Col>
        </Row>
    );
}

export default OneColumnList;

OneColumnList.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape).isRequired,
};
