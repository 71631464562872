import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import {
    FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon,
} from "react-share";
import EntryVoteButtonContainer from "../../../containers/website/EntryVoteButtonContainer";
import EntryVoteContainer from "../../../containers/website/EntryVotesContainer";
import { checkImageURL, checkVideoURL, checkAudioURL } from "../../../helpers/files";
import EnlargeEntryButton from "./EnlargeEntryButton";

function ExhibitionEntryCard({
    title, description, contentUrl, onVoteEntry, id, onUnvoteEntry, isPageActive,
}) {
    const shareUrl = `${window.location.host}/prijedlozi/prijedlog${id}`;
    const shareTitle = `Podrži i ti prijedlog "${title}" za Civilnu muzejsku zbirku!`;

    return (
        <Card className="custom-exhibition-entry-card">
            <Link to={`/prijedlozi/prijedlog${id}`}>
                {checkImageURL(contentUrl) && <Card.Img variant="top" src={contentUrl} />}
                {checkVideoURL(contentUrl) && (
                    <video controls className="custom-exhibition-entry-card-video" playsInline>
                        <source src={contentUrl} type="video/mp4" />
                        <source src={contentUrl} type="video/webm" />
                        <track default kind="captions" />
                    </video>
                )}
                {checkAudioURL(contentUrl) && (
                    <audio controls className="custom-exhibition-entry-card-audio">
                        <source src={contentUrl} />
                        <track default kind="captions" />
                        Your browser does not support the audio element.
                    </audio>
                )}
            </Link>
            <EnlargeEntryButton entryId={id} />
            <Card.Body>
                <Card.Title>{title}</Card.Title>
                <Card.Text>
                    {description}
                </Card.Text>
                {isPageActive && (
                    <EntryVoteButtonContainer
                        entryId={id}
                        onVoteCallback={onVoteEntry}
                        onUnvoteCallback={onUnvoteEntry}
                    />
                )}
                <Card.Text className="custom-exhibition-entry-card-action-text">
                    <EntryVoteContainer id={id} />
                </Card.Text>
                <div className="custom-card-social-share">
                    <span className="custom-exhibition-entry-card-action-text">PODIJELI NA</span>
                    <FacebookShareButton
                        url={shareUrl}
                        quote={shareTitle}
                    >
                        <FacebookIcon size={32} />
                    </FacebookShareButton>
                    <TwitterShareButton
                        url={shareUrl}
                        title={shareTitle}
                    >
                        <TwitterIcon size={32} />
                    </TwitterShareButton>
                </div>
            </Card.Body>
        </Card>
    );
}

export default ExhibitionEntryCard;

ExhibitionEntryCard.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    contentUrl: PropTypes.string.isRequired,
    onVoteEntry: PropTypes.func.isRequired,
    id: PropTypes.number.isRequired,
    onUnvoteEntry: PropTypes.func.isRequired,
};
