import React from "react";
import PropTypes from "prop-types";
import SVGvote from "../../../media/svgs/exhibition-entries/card-vote";
import SVGvoteHover from "../../../media/svgs/exhibition-entries/card-vote-hover";
import { ExtraSettingsPropType } from "../../../customPropTypes";

function SubjectVoteButton({
    votingData, onVoteCallback, onUnvoteCallback, entryId, extraSettings,
}) {
    const [isVoteButtonHovering, setIsVoteButtonHovering] = React.useState(false);
    const canVote = votingData[entryId];
    const scale = extraSettings.increaseFontSize ? 1.5 : 1;

    return (
        <div>
            {canVote === "yes" && (
                <button
                    type="button"
                    href="#"
                    onClick={() => onVoteCallback(entryId)}
                    className="no-style-button"
                    onMouseEnter={() => setIsVoteButtonHovering(true)}
                    onMouseLeave={() => setIsVoteButtonHovering(false)}
                >
                    {isVoteButtonHovering ? <SVGvoteHover scale={scale} /> : <SVGvote scale={scale} />}
                </button>
            )}
            {canVote === "no" && (
                <button
                    type="button"
                    href="#"
                    onClick={() => onUnvoteCallback(entryId)}
                    className="no-style-button"
                >
                    <SVGvoteHover scale={scale} />
                </button>
            )}
            {canVote === "no" && (
                <div className="custom-entry-vote-thankyou">Hvala na glasanju!</div>
            )}
        </div>
    );
}

export default SubjectVoteButton;

SubjectVoteButton.propTypes = {
    entryId: PropTypes.number.isRequired,
    onVoteCallback: PropTypes.func.isRequired,
    onUnvoteCallback: PropTypes.func.isRequired,
    extraSettings: ExtraSettingsPropType.isRequired,
};

SubjectVoteButton.defaultProps = {
};
