import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Content from "../../content/AdminTextContent";

function AdminNavbar() {
    return (
        <div>
            <Navbar bg="light" className="justify-content-center">
                <Navbar.Brand href="/">{Content.navbar.title}</Navbar.Brand>
            </Navbar>
        </div>
    );
}

export default AdminNavbar;
