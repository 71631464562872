const ACCESS_TOKEN_NAME = "access_token";
const EXPIRATION_NAME = "expiration";

const isValid = () => {
    const now = new Date(Date.now());
    const expDate = new Date(localStorage.getItem(EXPIRATION_NAME));
    const validity = now < expDate;
    if (validity) {
        return validity;
    }
    localStorage.removeItem(ACCESS_TOKEN_NAME);
    localStorage.removeItem(EXPIRATION_NAME);
    return validity;
};

export const setToken = (token) => {
    localStorage.setItem(ACCESS_TOKEN_NAME, token);
};

export const getToken = () => localStorage.getItem(ACCESS_TOKEN_NAME);

export const setExpirationDate = (date) => {
    const utcDate = new Date(date);
    localStorage.setItem(EXPIRATION_NAME, utcDate);
};

export const IsAuthenticated = () => {
    if (localStorage.getItem(ACCESS_TOKEN_NAME) && isValid()) {
        return true;
    }
    return false;
};
