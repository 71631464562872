import React from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { withFormik, FieldArray, Field } from "formik";
import DatePicker from "react-datepicker";
import validationSchemas from "../../../helpers/validation";
import CustomFormLabel from "../../common/CustomFormLabel";
import ButtonAdd from "../../common/ButtonAdd";
import AdminButtonRemove from "./AdminButtonRemove";
import AdminSidebar from "../AdminSidebar";
import { HistoryPropType } from "../../../customPropTypes";
import Content from "../../../content/AdminTextContent";

export function AdminSurveyAddNewFormInner({
    handleSubmit,
    handleChange,
    values,
    errors,
    setFieldValue,
    history,
}) {
    const formContent = Content.surveys.addNew.formElements;
    const [selectedStartDate, setSelectedStartDate] = React.useState(new Date());
    const [selectedEndDate, setSelectedEndDate] = React.useState(new Date());

    const renderAnswer = (arrayHelpers, questionIndex, index) => {
        const key = `question-${questionIndex + 1}-answer-${index + 1}`;

        return (
            <Form.Group
                as={Row}
                controlId={`formQuestions.${questionIndex}.answer[${index}]`}
                key={key}
                className="admin-survey-add-answer-row"
            >
                <CustomFormLabel text={`Tekst odgovora ${index + 1}`} />
                <Col sm="8" md="8">
                    <Field name={`questions.${questionIndex}.answers[${index}].text`} className="form-control" />
                </Col>
                {index === 0 ?
                    <ButtonAdd onClickCallback={() => arrayHelpers.push({ text: "", votes: 0, type: 1 })} /> :
                    <AdminButtonRemove onClickCallback={() => arrayHelpers.remove(index)} />
                }
            </Form.Group>
        );
    };

    const getAnswersFieldArray = (questionIndex) => {
        const answers = values.questions[questionIndex].answers; // eslint-disable-line

        return (
            <FieldArray
                name={`questions.${questionIndex}.answers`}
                render={(arrayHelpers) => (
                    <div>
                        <div>
                            {(answers && answers.length > 0) ?
                                answers.map((friend, index) => renderAnswer(arrayHelpers, questionIndex, index)) :
                                renderAnswer(arrayHelpers, questionIndex, 0)}
                        </div>
                    </div>
                )}
            />
        );
    };

    const getQuestionTitle = (questionIndex) => `${formContent.questionText} ${questionIndex + 1}`;

    const getQuestionFieldArray = (arrayHelpers, index) => {
        const key = `question-${index + 1}`;

        return (
            <div className="admin-survey-question" key={key}>
                <Form.Group
                    as={Row}
                    controlId={`formQuestion-${index}-text`}
                >
                    <CustomFormLabel text={getQuestionTitle(index)} />
                    <Col sm="8" md="8">
                        <Field
                            component="textarea"
                            name={`questions[${index}].text`}
                            className="form-control"
                            rows="5"
                        />
                    </Col>
                    {index !== 0 &&
                        <AdminButtonRemove onClickCallback={() => arrayHelpers.remove(index)} />
                    }
                </Form.Group>
                {getAnswersFieldArray(index)}
                <Row>
                    <CustomFormLabel text={formContent.allowCustomAnswer} />
                    <Col sm="12" md="8">
                        <Field
                            type="checkbox"
                            name={`questions[${index}].allowCustomAnswer`}
                        />
                    </Col>
                </Row>
            </div>
        );
    };

    return (
        <div>
            <AdminSidebar history={history} />
            <Row className="justify-content-sm-center">
                <Col sm="12" lg="12">
                    <div>
                        <Form noValidate onSubmit={handleSubmit} className="admin-form">
                            <Form.Group as={Row} controlId="formTitle">
                                <CustomFormLabel text={formContent.title} />
                                <Col sm="12" md="8">
                                    <Form.Control
                                        type="text"
                                        name="title"
                                        onChange={handleChange}
                                        value={values.title}
                                        isInvalid={!!errors.title}
                                    />
                                </Col>
                                <Form.Control.Feedback type="invalid" as={Col} sm="12">
                                    {errors.title}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formStartDate">
                                <CustomFormLabel text={formContent.startDate} />
                                <Col sm="12" md="8">
                                    <DatePicker
                                        selected={selectedStartDate}
                                        onChange={(v) => {
                                            setFieldValue("startDate", v, false);
                                            setSelectedStartDate(v);
                                        }}
                                        className="form-control"
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formEndDate">
                                <CustomFormLabel text={formContent.endDate} />
                                <Col sm="12" md="8">
                                    <DatePicker
                                        selected={selectedEndDate}
                                        onChange={(v) => {
                                            setFieldValue("endDate", v, false);
                                            setSelectedEndDate(v);
                                        }}
                                        className="form-control"
                                    />
                                </Col>
                            </Form.Group>
                            <hr className="admin-survey-hr" />
                            <FieldArray
                                name="questions"
                                render={(arrayHelpers) => (
                                    <div className="admin-survey-questions">
                                        {values.questions && values.questions.length > 0 ? (
                                            values.questions.map(
                                                (question, i) => getQuestionFieldArray(arrayHelpers, i)
                                            )
                                        ) : getQuestionFieldArray(arrayHelpers, 0)}
                                        <div className="text-right">
                                            <Button
                                                variant="primary"
                                                onClick={() => arrayHelpers.push({ text: "", answers: [{ text: "", votes: 0, type: 1 }], allowCustomAnswer: false })}
                                            >
                                                {formContent.addNewQuestion}
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            />
                            <hr className="admin-survey-hr" />
                            <div className="d-flex justify-content-end admin-form-main-buttons">
                                <div>
                                    <Button type="submit">{formContent.save}</Button>
                                </div>
                                <div>
                                    <Button
                                        variant="secondary"
                                        type="button"
                                        onClick={() => { history.push("/admin/ankete"); }}
                                    >
                                        {formContent.giveUp}
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export const handleSubmit = (values, { props }) => {
    props.onAddNewSurvey(values, props.history);
};

export default withFormik({
    mapPropsToValues: () => ({
        title: "",
        startDate: new Date(),
        endDate: new Date(),
        questions: [{ text: "", answers: [{ text: "", votes: 0, type: 1 }], allowCustomAnswer: false }],
    }),
    validationSchema: validationSchemas.addNewSurveyForm,
    handleSubmit,
})(AdminSurveyAddNewFormInner);

AdminSurveyAddNewFormInner.propTypes = {
    values: PropTypes.shape({
        title: PropTypes.string,
        startDate: PropTypes.instanceOf(Date),
        endDate: PropTypes.instanceOf(Date),
        questions: PropTypes.arrayOf(PropTypes.shape({
            title: PropTypes.string,
            answers: PropTypes.arrayOf(PropTypes.shape({
                text: PropTypes.string,
                votes: PropTypes.number,
                type: PropTypes.number,
            })),
        })),
    }).isRequired,
    errors: PropTypes.shape({
        title: PropTypes.string,
    }).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    history: HistoryPropType.isRequired,
};
