import { connect } from "react-redux";
import TopBar from "../../components/website/common/TopBar";
import { extraSettings } from "../../services/requests";

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
    hideBackgrounds: () => dispatch(extraSettings.hideBackgrounds()),
    showBackgrounds: () => dispatch(extraSettings.showBackgrounds()),
    increaseFontSize: () => dispatch(extraSettings.increaseFontSize()),
    resetFontSize: () => dispatch(extraSettings.resetFontSize()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
